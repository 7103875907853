import React from 'react';

import Button, { ButtonProps } from 'components/@ui/Button';
import Fonts from 'styles/fonts';
import { clsx, parseSx } from '@helpers';

const ToastButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      size="small"
      variant="text"
      color="secondary"
      {...props}
      sx={clsx(
        {
          fontFamily: Fonts.ProximaNova,
          p: 0.75,
          height: 25,
          borderBottom: 1,
          borderColor: 'transparent',
          '&:hover ': {
            color: 'primary.main',
            borderColor: 'primary.main',
          },
        },
        ...parseSx(props.sx),
      )}
    />
  );
};

export default React.memo(ToastButton);
