export const FEATURES = [
  {
    name: 'Payroll',
    iconName: 'feature-payroll',
    imagePNG: require('assets/shortlistPDF/features/payroll.png'),
  },
  {
    name: 'Performance',
    iconName: 'feature-performance',
    imagePNG: require('assets/shortlistPDF/features/performance.png'),
  },
  {
    name: 'ATS / Recruiting',
    iconName: 'feature-ats-recruiting',
    imagePNG: require('assets/shortlistPDF/features/ATS.png'),
  },
  {
    name: 'HRIS – Employee Files',
    iconName: 'feature-hris-employee-files',
    imagePNG: require('assets/shortlistPDF/features/HRIS.png'),
  },
  {
    name: 'Comp Management',
    iconName: 'feature-comp-management',
    imagePNG: require('assets/shortlistPDF/features/comp-management.png'),
  },
  {
    name: 'Engagement',
    iconName: 'feature-engagement',
    imagePNG: require('assets/shortlistPDF/features/engagement.png'),
  },
  {
    name: 'Benefits Admin',
    iconName: 'feature-benefits-admin',
    imagePNG: require('assets/shortlistPDF/features/ben-admin.png'),
  },
  {
    name: 'Learning & Training',
    iconName: 'feature-learning-training',
    imagePNG: require('assets/shortlistPDF/features/learning.png'),
  },
  {
    name: 'Time & Attendance',
    iconName: 'feature-time-attendance',
    imagePNG: require('assets/shortlistPDF/features/time-attendance.png'),
  },
  {
    name: 'Onboarding',
    iconName: 'feature-onboarding',
    imagePNG: require('assets/shortlistPDF/features/onboarding.png'),
  },
];

export const CHARACTERISTICS = [
  {
    name: 'Highly configurable platform',
    iconName: 'characteristics-highly-configurable-platform',
    imagePNG: require('assets/shortlistPDF/characteristics/highly-configurable-platform.png'),
  },
  {
    name: 'Strong in workforce management',
    iconName: 'characteristics-strong-in-workforce-management',
    imagePNG: require('assets/shortlistPDF/characteristics/strong-in-workforce-management.png'),
  },
  {
    name: 'Integrated product architecture',
    iconName: 'characteristics-integrated-product-architecture',
    imagePNG: require('assets/shortlistPDF/characteristics/integrated-product-architecture.png'),
  },
  {
    name: 'Modern User Interface',
    iconName: 'characteristics-modern-user-interface',
    imagePNG: require('assets/shortlistPDF/characteristics/modern-user-interface.png'),
  },
  {
    name: 'Globally capable',
    iconName: 'characteristics-globally-capable',
    imagePNG: require('assets/shortlistPDF/characteristics/global-capable.png'),
  },
  {
    name: 'Large market share',
    iconName: 'characteristics-cost-effective-system',
    imagePNG: require('assets/shortlistPDF/characteristics/large-market-share.png'),
  },
  {
    name: 'Great customer support',
    iconName: 'characteristics-great-customer-support',
    imagePNG: require('assets/shortlistPDF/characteristics/great-customer-support.png'),
  },
  {
    name: 'Managed service offering',
    iconName: 'characteristics-managed-service-offering',
    imagePNG: require('assets/shortlistPDF/characteristics/managed-service-offering.png'),
  },
  {
    name: 'Cost-effective system',
    iconName: 'characteristics-cost-effective-system',
    imagePNG: require('assets/shortlistPDF/characteristics/cost-effective-system.png'),
  },
  {
    name: 'Strong integration capabilities',
    iconName: 'characteristics-strong-integration-capabilities',
    imagePNG: require('assets/shortlistPDF/characteristics/strong-integration-capabilities.png'),
  },
  {
    name: 'Good for reporting',
    iconName: 'characteristics-good-for-reporting',
    imagePNG: require('assets/shortlistPDF/characteristics/good-for-reporting.png'),
  },
  {
    name: 'End-to-end offering',
    iconName: 'characteristics-end-to-end-offering',
    imagePNG: require('assets/shortlistPDF/characteristics/end-to-end-offering.png'),
  },
];
