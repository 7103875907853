export const switchShortlistIconName = (
  isShortlisted?: boolean,
  isDisabled?: boolean,
  isLoadingState?: boolean,
) => {
  switch (true) {
    case (isShortlisted && isLoadingState) || isShortlisted:
      return 'heart-shortlisted-filled-path';
    case !isShortlisted && (!isDisabled || isLoadingState):
      return 'heart-shortlisted-empty-path';
    case isDisabled:
      return 'disabled-add-shortlist-path';

    default:
      return 'heart-shortlisted-empty-path';
  }
};
