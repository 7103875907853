import React from 'react';

import Icon, { IconProps } from 'components/@ui/Icon';

const PhaseStartIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      className="phase-start-icon"
      width={14}
      fontSize={'14px !important'}
      color="inherit"
      borderRadius={0}
      {...props}
    />
  );
};

export default React.memo(PhaseStartIcon);
