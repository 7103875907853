import React from 'react';

import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';

import FAQBlockWrapper from './FAQBlockWrapper';
import OutSailFreeInfo from './OutSailFreeInfo';
import ForVendorInfo from './ForVendorInfo';
import BenefitOutSailInfo from './BenefitOutSailInfo';

const MainInformationBody: React.FC = () => {
  return (
    <Box display="flex" mt={6} flexDirection="column" alignItems="center">
      <Typography semiBold fontSize={20} lineHeight="28px">
        FAQs
      </Typography>
      <Box mt={3} display="flex" flexDirection="column" gap="12px 0" width={1}>
        <FAQBlockWrapper title="How is OutSail free?">
          <OutSailFreeInfo />
        </FAQBlockWrapper>
        <FAQBlockWrapper title="What are the benefits of joining OutSail?">
          <BenefitOutSailInfo />
        </FAQBlockWrapper>
        <FAQBlockWrapper title="What if I'm a broker, consultant or vendor?">
          <ForVendorInfo />
        </FAQBlockWrapper>
      </Box>
    </Box>
  );
};

export default MainInformationBody;
