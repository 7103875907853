import { TimeLineBar } from '../@types';

const { NamesEnum } = TimeLineBar;

export const icons = {
  [NamesEnum['Requirements']]: 'phase-requirements',
  [NamesEnum['Matches']]: 'phase-matches',
  [NamesEnum['Evaluation']]: 'phase-evaluation',
  [NamesEnum['Finalization']]: 'phase-finalization',
  [NamesEnum['Implementation']]: 'phase-implementation',
  [NamesEnum['Go-Live']]: 'phase-go-live',
  [NamesEnum['CloseAllPhases']]: 'close-all-phases',
};

export const toltips = {
  [NamesEnum['Requirements']]: 'Build Requirements',
  [NamesEnum['Matches']]: 'Vendor Matches',
  [NamesEnum['Evaluation']]: 'Vendor Evaluation',
  [NamesEnum['Finalization']]: 'Finalization',
  [NamesEnum['Implementation']]: 'Implementation',
  [NamesEnum['Go-Live']]: 'Go-Live',
  [NamesEnum['CloseAllPhases']]: 'close-all-phases',
};
