import { permissionCreate } from '@permissions';

const eliminate = permissionCreate('WORKSPACE_ELIMINATE');
const finalize = permissionCreate('WORKSPACE_FINALIZE');
const removeFromWorkspace = permissionCreate('WORKSPACE_REMOVE_FROM_WORKSPACE');
const requestDemo = permissionCreate('WORKSPACE_REQUEST_DEMO');
const designDemo = permissionCreate('WORKSPACE_DESIGN_DEMO');

export const WORKSPACE = {
  [eliminate]: eliminate,
  [finalize]: finalize,
  [removeFromWorkspace]: removeFromWorkspace,
  [requestDemo]: requestDemo,
  [designDemo]: designDemo,
};

export default WORKSPACE;
