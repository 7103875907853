import React, { useCallback, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import { all, any, equals, find, propEq } from 'ramda';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';

import { useFetchChangeWorkspace, useFetchProject } from '@api/@hooks';
import { useIsLogin, UseOpenModal, useOpenModal } from '@hooks';
import { checkStatus } from '@helpers';
import { MAX_WORKSPACE_ITEMS } from '@constants';

type WrapperProps = {
  vendorId: number;
};

export type WorkplaceButtonHOCWrapperType = UseOpenModal & {
  isDisabled: boolean;
  isWorkspace: boolean;
  tooltipTitle: string;
  handleClick: () => void;
  isLoading?: boolean;
  isVendorOfChoice: boolean;
};

const useTooltipText = (isWorkspace: boolean) => {
  const [{ records }] = useFetchProject();
  const { chosenProduct, workspacedProducts } = records.project;

  switch (true) {
    case Boolean(chosenProduct):
      return 'You have already chosen Vendor-of-Choice';
    case isWorkspace:
      return 'To remove a vendor, go to the workspace page';
    case workspacedProducts.length >= MAX_WORKSPACE_ITEMS:
      return 'Your workspace is full';
    default:
      return 'Add to workspace';
  }
};

const WorkplaceButtonHOC = <T,>(Component: React.FC<T & WorkplaceButtonHOCWrapperType>) => {
  const Wrapper: React.FC<T & WrapperProps> = (props) => {
    const { vendorId } = props;
    const isLogin = useIsLogin();
    const { handleClose, handleOpen, open } = useOpenModal();
    const [{ loading }, fetchChangeWorkspace] = useFetchChangeWorkspace('add');
    const [{ records, loading: loadingProject }, fetchProject] = useFetchProject();
    const { id: projectId, chosenProduct, workspacedProducts, finalised } = records.project;

    const [, setCookie] = useCookies([`os_vendor_instruction_${projectId}`]);

    const isWorkspace = useMemo(() => {
      return Boolean(find(propEq(vendorId, 'id'), workspacedProducts));
    }, [vendorId, workspacedProducts]);

    const isDisabled = useMemo(
      () =>
        any(equals(true))([
          isWorkspace,
          Boolean(chosenProduct),
          Boolean(loading),
          Boolean(loadingProject),
          workspacedProducts.length >= MAX_WORKSPACE_ITEMS,
        ]),
      [isWorkspace, chosenProduct, loading, loadingProject, workspacedProducts.length],
    );

    const isOpen = useMemo(
      () => all(equals(false))([loading, loadingProject]),
      [loading, loadingProject],
    );

    const addProductToShortlist = useCallback(async () => {
      if (isLogin && !isDisabled) {
        const response = await fetchChangeWorkspace(vendorId);
        if (checkStatus(response?.status)) {
          toast.success('Vendor added to workspace');
          if (finalised) {
            const expires = new Date();
            expires.setDate(14);
            setCookie(`os_vendor_instruction_${projectId}`, true, {
              expires,
              sameSite: 'strict',
            });
          }
          fetchProject();
        }
      }
    }, [
      isLogin,
      isDisabled,
      fetchChangeWorkspace,
      vendorId,
      fetchProject,
      finalised,
      setCookie,
      projectId,
    ]);

    useUpdateEffect(() => {
      if (open && isOpen) {
        handleClose();
      }
    }, [isOpen]);

    return (
      <Component
        handleClick={addProductToShortlist}
        isDisabled={isDisabled}
        isWorkspace={isWorkspace}
        tooltipTitle={useTooltipText(isWorkspace)}
        isLoading={Boolean(chosenProduct) || Boolean(loading) || Boolean(loadingProject)}
        isVendorOfChoice={Boolean(chosenProduct)}
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open && isOpen}
        {...props}
      />
    );
  };
  return Wrapper;
};

export default WorkplaceButtonHOC;
