import React from 'react';
import classname from 'clsx';
import { Theme } from '@mui/material';
import { CSSObject } from '@emotion/react';

import { clsx, makeSxStyles, parseSx } from '@helpers';

import Box, { BoxProps } from '../Box';

type StylesSx = {
  name: string;
  hover?: boolean;
  cursor?: boolean;
  disabled?: boolean;
  isColor?: boolean;
  beforeProps?: CSSObject;
};

export interface IconProps extends BoxProps, StylesSx {}

const useSx = makeSxStyles(
  ({ hover, cursor, disabled, isColor, beforeProps }: Omit<StylesSx, 'name'>) => ({
    root: {
      ...(cursor && { cursor: 'pointer' }),
      ...(hover && {
        transition: (theme: Theme) => theme.transitions.create('background'),
        '&:hover': {
          background: (theme: Theme) => theme.palette.action.hover,
        },
      }),
      ...(disabled && {
        background: 'none',
        '&::before': {
          opacity: 0.5,
        },
        '&:hover': {
          background: 'none',
        },
      }),
      '&::before': {
        ...(beforeProps && { ...beforeProps }),
        ...(isColor && { color: 'inherit' }),
      },
    },
  }),
);

// @ts-ignore
const Icon: React.FC<IconProps> = React.forwardRef((props, ref) => {
  const {
    beforeProps,
    className,
    color,
    cursor,
    disabled,
    fontSize = 24,
    name,
    hover,
    width = 24,
    sx,
    ...other
  } = props;
  const sxes = useSx({ hover, cursor, disabled, isColor: !!color, beforeProps });

  return (
    <Box
      className={classname('icon', `icon-${name}`, className)}
      sx={clsx(sxes.root, ...parseSx(sx))}
      component="i"
      ref={ref}
      color={color}
      fontSize={fontSize}
      width={width}
      minWidth={width}
      height={width}
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="100%"
      overflow="hidden"
      {...other}
    />
  );
});

export default React.memo(Icon);
