export default (size = 8) => ({
  '&::-webkit-scrollbar': {
    width: size,
    height: size,
    ...(size === 0 && { display: 'none' }),
  },
  '&::-webkit-scrollbar-thumb': {
    ...(size !== 0 && { border: 2 }),
    borderColor: 'transparent',
    background: '#C9D1D9',
    borderRadius: 4,
    backgroundClip: 'content-box',
  },
  '&::-webkit-scrollbar-track ': {
    background: '#F9FAFB',
    borderRadius: size / 2,
  },
  scrollbarWidth: 'thin',
  scrollbarColor: '#C9D1D9 #F9FAFB',
  ...(size === 0 && {
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  }),
});
