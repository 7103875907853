import React from 'react';

import NavbarProvider from './Context';
import NavbarWrapper from './NavbarWrapper';
import NavbarHeader from './NavbarHeader';
import NavbarBody from './NavbarBody';

const Navbar: React.FC = () => {
  return (
    <NavbarProvider>
      <NavbarWrapper>
        <NavbarHeader />
        <NavbarBody />
      </NavbarWrapper>
    </NavbarProvider>
  );
};

export default Navbar;
