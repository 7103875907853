import React from 'react';

import Box from 'components/@ui/Box';
import TypographyMobile from 'components/@ui/TypographyMobile';
import TableCell from 'components/@ui/TableCell';

type TabletHeatMapHeaderRowTitleProps = {
  collSpan?: number;
  rowSpan?: number;
};

const TabletHeatMapHeaderRowTitle: React.FC<TabletHeatMapHeaderRowTitleProps> = ({
  collSpan = 1,
  rowSpan = 1,
}) => (
  <TableCell
    sx={{ position: 'sticky', left: 0, zIndex: 16, bgcolor: 'secondary.main' }}
    rowSpan={rowSpan}
    colSpan={collSpan}
  >
    <Box display="flex" alignItems="center" justifyContent="center" height={1}>
      <TypographyMobile semiBold mobileSmallFontSize>
        Best vendors
      </TypographyMobile>
    </Box>
  </TableCell>
);

export default TabletHeatMapHeaderRowTitle;
