import React, { useMemo } from 'react';
import { not } from 'ramda';

import AbilityControl, { useAbility } from '@permissions';
import { useFetchProject } from '@api/@hooks';

import ToDoItemWithTooltip from './ToDoItemWithTooltip';
import TodoItem from './TodoItem';
import { RequestDemoButton, TooltipTextObject } from '../@components';

const RequestDemoTodoItem: React.FC = () => {
  const [{ records }] = useFetchProject();
  const { project } = records;
  const canUseDesignDemo = useAbility().abilityCheck('WORKSPACE_REQUEST_DEMO');
  const hideTooltip = not(canUseDesignDemo) || Boolean(project.workspacedProducts.length);

  return useMemo(
    () => (
      <ToDoItemWithTooltip
        hideTooltip={hideTooltip}
        type="text"
        source={{ text: TooltipTextObject.emptyWorkspace }}
      >
        <TodoItem>
          <AbilityControl permission="WORKSPACE_DESIGN_DEMO">
            <RequestDemoButton />
          </AbilityControl>
        </TodoItem>
      </ToDoItemWithTooltip>
    ),
    [hideTooltip],
  );
};

export default RequestDemoTodoItem;
