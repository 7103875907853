import React from 'react';

import Icon from 'components/@ui/Icon';
import Typography from 'components/@ui/Typography';
import Box from 'components/@ui/Box';

type SwitchLabelProps = {
  label: string;
  iconName: string;
  isIcon: boolean;
};

const SwitchLabel: React.FC<SwitchLabelProps> = ({ label, iconName, isIcon }) => {
  return (
    <Box display="flex" alignItems="center">
      {isIcon && <Icon name={iconName} width={24} height={24} mr={1.5} color="inherit" />}
      <Typography>{label}</Typography>
    </Box>
  );
};

export default React.memo(SwitchLabel);
