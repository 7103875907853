import React, { useCallback } from 'react';
import { Redirect } from 'react-router-dom';

import { useIsLogin } from '@hooks';

import DefaultRoute, { DefaultRouteProps } from './DefaultRoute';
import { ROUTES } from './config';

const PublicRoute: React.FC<DefaultRouteProps> = (props) => {
  const { forbiden, fallback: Fallback, ...other } = props;
  const isLogin = useIsLogin();

  const FallbackComponent = useCallback(
    () => (Fallback ? <Fallback /> : <Redirect to={ROUTES.vendors.root} />),
    [Fallback],
  );

  return <DefaultRoute forbiden={forbiden || isLogin} fallback={FallbackComponent} {...other} />;
};

export default React.memo(PublicRoute);
