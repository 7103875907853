import React from 'react';

import Divider from 'components/@ui/Divider';

import ToDoItemsBody from './ToDoItemsBody';
import ToDoItemsHeader from './ToDoItemsHeader';
import ToDoItemsProvider from './Context';

type ToDoItemsProps = {
  todoName: string;
};

const ToDoItems: React.FC<ToDoItemsProps> = ({ todoName }) => {
  return (
    <ToDoItemsProvider>
      <ToDoItemsHeader name={todoName} />
      <Divider />
      <ToDoItemsBody />
    </ToDoItemsProvider>
  );
};

export default React.memo(ToDoItems);
