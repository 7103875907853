import React from 'react';
import MuiBadge, { BadgeProps as MuiBadgeProps } from '@mui/material/Badge';

export interface BadgeProps extends MuiBadgeProps {}

// @ts-ignore
const Badge: React.FC<MuiBadgeProps> = React.forwardRef((props, ref) => (
  <MuiBadge ref={ref} {...props} />
));

export default React.memo(Badge);
