import React from 'react';
import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem';

import { clsx, makeSxStyles, parseSx } from '@helpers';

export interface MenuItemProps extends MuiMenuItemProps {}

const useSx = makeSxStyles({
  root: {
    transition: (theme) => theme.transitions.create('background-color'),
    '&:hover': { bgcolor: 'dropdownHover.main' },
    '&:active, &.Mui-focusVisible': { bgcolor: 'dropdownHover.dark' },
  },
});

// @ts-ignore
const MenuItem: React.FC<MuiMenuItemProps> = React.forwardRef((props, ref) => {
  const { sx, ...other } = props;
  const sxex = useSx();

  return <MuiMenuItem ref={ref} disableRipple sx={clsx(sxex.root, ...parseSx(sx))} {...other} />;
});

export default React.memo(MenuItem);
