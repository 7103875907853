import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';

import MenuItemSelect from 'components/@ui/MenuItemSelect';
import ListItemText from 'components/@ui/ListItemText';
import UserAvatar from 'components/@main/UserAvatar';
import { useOnline, useUser } from '@hooks';
import { projectIdStorage } from '@servises';

export type MenuItemsProps = {
  onClosePopover: () => void;
};

const Projects: React.FC<MenuItemsProps> = ({ onClosePopover }) => {
  const { projectId: currentProjectId, projects, id } = useUser();
  const isOnline = useOnline();

  const handleClick = useCallback(
    (projectId: number) => () => {
      if (projectId === currentProjectId || !isOnline) return onClosePopover();
      projectIdStorage.set(id, projectId);
      window.location.reload();
    },
    [currentProjectId, id, isOnline, onClosePopover],
  );

  return useMemo(() => {
    return (
      <>
        {projects.map(({ projectName, projectId, ownerAvatar, ownerName }) => (
          <MenuItemSelect
            key={projectId}
            onClick={handleClick(projectId)}
            className={clsx({ 'Mui-selected': projectId === currentProjectId })}
          >
            <UserAvatar size={24} src={ownerAvatar} name={ownerName} />
            <ListItemText
              disableTypography
              sx={{
                ml: 1,
                textWrap: 'wrap',
                whiteSpace: 'normal',
                wordBreak: 'break-all',
                wordWrap: 'break-word',
              }}
            >
              {projectName}
            </ListItemText>
          </MenuItemSelect>
        ))}
      </>
    );
  }, [currentProjectId, handleClick, projects]);
};

export default React.memo(Projects);
