import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import Icon from 'components/@ui/Icon';
import Button from 'components/@ui/Button';
import { ROUTES } from '@routes/config';
import { useFetchProject } from '@api/@hooks';

import { useToDo } from '../../ToDo';

const DesignMyDemoButton: React.FC = () => {
  const { handleCloseToDoPopover } = useToDo();
  const [
    {
      records: { project },
    },
  ] = useFetchProject();

  return useMemo(
    () => (
      <Box
        display="flex"
        justifyContent={{ mobile: 'flex-end', tablet: 'flex-start', laptop: 'flex-end' }}
        alignItems="center"
        pl={{ mobile: 0, tablet: 4.5, laptop: 0 }}
      >
        <Button
          to={ROUTES.processMapping.moduleQuestions}
          endIcon={
            <Icon name="wrench" color="inherit" sx={{ '&:before': { transition: 'none' } }} />
          }
          size="small"
          onClick={handleCloseToDoPopover}
          disabled={
            !project.workspacedProducts.length || (!project.submitted && !project.finalised)
          }
          sx={{
            width: { mobile: '100%', tablet: 'auto', laptop: 'auto' },
            height: { mobile: 56, laptop: 32 },
            fontSize: { mobile: 18, laptop: 16 },
            lineHeight: { mobile: '32px', laptop: '24px' },
            my: { mobile: 1.5, laptop: 0 },
          }}
          variant={project.finalised ? 'text' : 'contained'}
        >
          {project.finalised ? 'Demo details' : 'Design Demo'}
        </Button>
      </Box>
    ),
    [
      handleCloseToDoPopover,
      project.workspacedProducts.length,
      project.finalised,
      project.submitted,
    ],
  );
};

export default DesignMyDemoButton;
