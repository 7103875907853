import React from 'react';

import TimeLineBarProvider from './Context';
import Phases, { PhasesProvider } from './Phases';
import { Line } from './@components';

const TimeLineBar: React.FC = () => {
  return (
    <TimeLineBarProvider>
      <Line />
      <PhasesProvider>
        <Phases />
      </PhasesProvider>
    </TimeLineBarProvider>
  );
};

export default TimeLineBar;
