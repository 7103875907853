import baseAPI, { APIResponse } from '@api';

import { ShortlistPDFReportProps } from './config';

const apiCredentials = baseAPI();

//--------------------------------------------------------------------------------------------------
// Shortlist PDF Report
//--------------------------------------------------------------------------------------------------

export function fetchShortlistPDFReport(id: number): APIResponse<ShortlistPDFReportProps> {
  return apiCredentials.get(`/projects/${id}/shortlisted_products/report`);
}

//--------------------------------------------------------------------------------------------------
