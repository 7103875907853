import React from 'react';
import { IMaskInput } from 'react-imask';

type TextMaskProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};

const TextFieldMask: React.FC<TextMaskProps> = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="0       0       0       0       0"
      definitions={{ '#': /[1-9]/ }}
      inputRef={ref as React.ForwardedRef<HTMLInputElement>}
      onAccept={(value: string) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default React.memo(TextFieldMask);
