import React, { useCallback, useMemo, useState } from 'react';
import { useUnmount } from 'react-use';

import { createContext } from '@helpers';
import { Children, UseState } from '@types';

import { useFetchCheckedTodoItem, useFetchSendMeetRequest } from './@hook';
import { TimeLineBar } from '../@types';

export type ItemProps = {
  todoItem: TimeLineBar.TodoItem;
};

type ItemType = 'Advisor' | 'Product';

type ChangeTodoItemCompleted = (
  todoItemId: number,
  todoCompleted: boolean,
  setMarked: UseState<boolean>,
) => () => void;

type SendMeetRequest = (
  todoItemId: number,
  itemId: number,
  itemType: ItemType,
  setStateForButton: UseState<boolean>,
  setHideTooltip?: UseState<boolean>,
) => () => void;

type ToDoItemsContextProps = {
  changeTodoItemCompleted: ChangeTodoItemCompleted;
  sendMeetRequest: SendMeetRequest;
};

const [Provider, useToDoItems] = createContext<ToDoItemsContextProps>();
export { useToDoItems };

const ToDoItemsProvider: React.FC<Children> = ({ children }) => {
  const [, fetchCheckedTodoItem] = useFetchCheckedTodoItem();
  const [, fetchSendMeetRequest] = useFetchSendMeetRequest();

  const [isChangingStatusTodoItem, setIsChangingStatusTodoItem] = useState<boolean>(false);
  const [isSendingMeetRequest, setIsSendingMeetRequest] = useState<boolean>(false);

  const changeTodoItemCompleted = useCallback<ChangeTodoItemCompleted>(
    (todoItemId, todoCompleted, setMarked) => async () => {
      if (isChangingStatusTodoItem) return;
      setIsChangingStatusTodoItem(true);
      const result = await fetchCheckedTodoItem(todoItemId, !todoCompleted);
      if (result.status < 400) {
        setMarked((prev) => !prev);
      }
      setIsChangingStatusTodoItem(false);
    },
    [isChangingStatusTodoItem, setIsChangingStatusTodoItem, fetchCheckedTodoItem],
  );

  const sendMeetRequest = useCallback<SendMeetRequest>(
    (todoItemId, itemId, itemType, setStateForButton, setHideToolTip?) => async () => {
      if (isSendingMeetRequest) return;
      setIsSendingMeetRequest(true);
      const result = await fetchSendMeetRequest(todoItemId, { itemId, itemType });
      if (result.status < 400) {
        setStateForButton(true);
        if (setHideToolTip) setHideToolTip(false);
      }
      setIsSendingMeetRequest(false);
    },
    [isSendingMeetRequest, setIsSendingMeetRequest, fetchSendMeetRequest],
  );

  useUnmount(() => {
    setIsSendingMeetRequest(false);
    setIsChangingStatusTodoItem(false);
  });

  const contextValue = useMemo(
    () => ({
      changeTodoItemCompleted,
      sendMeetRequest,
    }),
    [changeTodoItemCompleted, sendMeetRequest],
  );

  return <Provider value={contextValue}>{children}</Provider>;
};

export default React.memo(ToDoItemsProvider);
