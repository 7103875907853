import { useMemo } from 'react';
import { Theme } from '@mui/material';

import { clsx, makeSxStyles, parseSx } from '@helpers';
import { Sizes } from '@theme';

import Collapse from '../Collapse';
import { TextFieldProps } from './TextField';
import { inCollapseHelperText } from './@helpers';

type StylesSx = { size?: Sizes };

export const makeSxUseTextField = makeSxStyles(({ size }: StylesSx) => ({
  root: {
    textarea: (theme: Theme) => theme.scrollbar(),
  },
  InputProps: {
    padding: 0,
    minHeight: (theme: Theme) => parseInt(theme.spacing(1)) * theme.sizes[size || 'medium'],
    '&::after, &::before': { content: 'none' },
    transition: (theme: Theme) =>
      theme.transitions.create(['border-color', 'background, box-shadow']),
    borderWidth: 1,
    bgcolor: 'primary.contrastText',
    borderColor: 'dropdownHover.contrastText',
    borderStyle: 'solid',
    borderRadius: 1,
    '&:hover': { borderColor: 'primary.main' },
    '&.Mui-focused ': { borderColor: 'primary.main', bgcolor: 'secondary.main' },
    '&.Mui-error ': { borderColor: 'error.main' },
    '&.Mui-disabled ': {
      borderColor: 'gray.light',
      color: 'gray.main',
      bgcolor: 'background.default',
    },
  },
  inputProps: {
    px: 1.25,
    py: 0,
    lineHeight: '18px',
    '&.Mui-disabled ': {
      WebkitTextFillColor: (theme: Theme) => theme.palette.gray.main,
    },
    '&.MuiInputBase-input&.MuiInput-input': {
      '&::placeholder': {
        color: 'gray.main',
        opacity: '1!important',
        transition: 'none',
      },
    },
    '&:-webkit-autofill': {
      WebkitBackgroundClip: 'text',
    },
  },
  labelProps: {
    '&.Mui-focused, &.Mui-error': { color: 'text.primary' },
    '& +.MuiInputBase-root ': { mt: 0 },
    pb: 1,
    position: 'relative',
    transform: 'none',
    width: 'fit-content',
    pointerEvents: 'auto',
    lineHeight: 1.5,
    color: 'text.primary',
  },
  helperTextProps: {
    m: 0,
    mt: 1,
    fontSize: 12,
    lineHeight: '16px',
    textAlign: 'left',
    color: 'error.main',
    transition: (theme: Theme) => theme.transitions.create('all'),
  },
  helperTextErrorProps: { mt: 0 },
}));

export type UseTextFieldProps = Pick<
  TextFieldProps,
  | 'size'
  | 'InputProps'
  | 'inputProps'
  | 'InputLabelProps'
  | 'FormHelperTextProps'
  | 'error'
  | 'helperText'
  | 'collapseHelperText'
  | 'sx'
>;

const useTextField = <T extends UseTextFieldProps>(props: T) => {
  const {
    size,
    InputProps,
    inputProps,
    InputLabelProps,
    FormHelperTextProps,
    error,
    helperText,
    collapseHelperText,
    sx,
  } = props;
  const sxex = useMemo(() => makeSxUseTextField({ size }), [size]);

  return {
    sx: clsx(sxex.root, ...parseSx(sx)),
    InputProps: {
      ...InputProps,
      sx: clsx(sxex.InputProps, ...parseSx(InputProps?.sx)),
    },
    inputProps: {
      ...inputProps,
      sx: clsx(sxex.inputProps, ...parseSx(inputProps?.sx)),
    },
    InputLabelProps: {
      disableAnimation: true,
      shrink: false,
      ...InputLabelProps,
      sx: clsx(sxex.labelProps, ...parseSx(InputLabelProps?.sx)),
    },
    FormHelperTextProps: {
      ...FormHelperTextProps,
      sx: clsx(
        sxex.helperTextProps,
        !inCollapseHelperText({ collapseHelperText, error, helperText }) &&
          sxex.helperTextErrorProps,
        ...parseSx(FormHelperTextProps?.sx),
      ),
    },
    helperText: (
      <Collapse in={inCollapseHelperText({ collapseHelperText, error, helperText })}>
        {helperText}&nbsp;
      </Collapse>
    ),
    error,
  };
};

export default useTextField;
