import React, { useMemo } from 'react';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { Theme } from '@mui/material';
import { not } from 'ramda';

import ButtonUi from 'components/@ui/Button';
import Tooltip from 'components/@ui/Tooltip';
import Typography from 'components/@ui/Typography';
import When from 'components/@main/When';
import { useIsLogin, useIsTablet, useUser } from '@hooks';
import { clsx, makeSxStyles } from '@helpers';

import ComingIcon from './ComingIcon';
import { NavbarButtonType } from '../NavbarButtonsData';
import { useNavbar } from '../@hooks';
import {
  clickButtonAnimation,
  clickButtonIconAnimation,
  clickButtonMobileAnimation,
} from './animations';
import { closeMobileNavbarAfterChangePage } from '../@servises';

const sxses = makeSxStyles({
  root: {
    '.MuiButton-startIcon': { margin: 0 },
  },
  button: {
    width: 48,
    height: 48,
    minWidth: 24,
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    borderRadius: 6,
    color: 'primary.light',
  },
  buttonActive: {
    bgcolor: 'secondary.main',
    animation: `${clickButtonIconAnimation} 350ms linear`,
    boxShadow: 3,
    color: 'blue.main',
    '&:hover, &:active': {
      bgcolor: 'secondary.main',
      boxShadow: 8,
    },
  },
  buttonOpenNavbar: {
    position: 'relative',
    height: { mobile: '100%', tablet: '100%', laptop: '100px' },
    width: { mobile: '100%', tablet: '100%', laptop: '120px' },
    display: 'flex',
    flexDirection: 'column',
    color: 'primary.light',
    border: 1,
    borderRadius: 0,
    borderColor: 'action.hover',
    transition: (theme: Theme) =>
      theme.transitions.create([
        'background-color',
        'border-color',
        'border-radius',
        'box-shadow',
        'color',
      ]),
    '&:hover, &:active': {
      borderColor: 'blue.main',
    },
  },
  buttonOpenNavbarIsTouchDevice: {
    height: '100%',
    width: '100%',
  },
  buttonActiveOpenNavbar: {
    width: { mobile: '106%', tablet: '106%', laptop: '136px' },
    height: { mobile: '106%', tablet: '106%', laptop: '120px' },
    minHeight: { mobile: '106%', tablet: '106%', laptop: '100px' },
    margin: { mobile: '-1% -3%', tablet: '-1% -3%', laptop: '-10px -8px' },
    animation: {
      mobile: `${clickButtonMobileAnimation} 350ms linear`,
      tablet: `${clickButtonMobileAnimation} 350ms linear`,
      laptop: `${clickButtonAnimation} 350ms linear`,
    },
    zIndex: 2,
    borderRadius: 2,
    bgcolor: 'secondary.main',
    boxShadow: 3,
    color: 'primary.main',
    '&:hover, &:active': {
      bgcolor: 'secondary.main',
      boxShadow: 8,
    },
  },
  buttonActiveOpenNavbarIsTouchDevice: {
    width: '106%',
    height: '106%',
    margin: '-1% -3%',
    animation: `${clickButtonMobileAnimation} 350ms linear`,
  },
})();

type ButtonProps = {
  buttonData: NavbarButtonType;
};

const Button: React.FC<ButtonProps> = ({ buttonData }) => {
  const { isOpenNavbar, setIsOpenNavbarMobile } = useNavbar();
  const { pathname } = useLocation();
  const { verified } = useUser();
  const isLogin = useIsLogin();
  const isTablet = useIsTablet();
  const forbiden = useMemo(() => not(verified) || not(isLogin), [isLogin, verified]);

  const checkedLocation = useMemo(
    () => Boolean(matchPath(pathname, { path: buttonData.links, exact: true })),
    [buttonData.links, pathname],
  );

  return useMemo(
    () => (
      <NavLink
        to={forbiden ? pathname : buttonData.link}
        className="navbar-body_link"
        onClick={isTablet ? closeMobileNavbarAfterChangePage(setIsOpenNavbarMobile) : undefined}
      >
        <When
          condition={isOpenNavbar}
          disabledWrapper
          fallback={
            <Tooltip disableInteractive title={buttonData.name} placement="right">
              <ButtonUi
                variant="text"
                startIcon={buttonData.img}
                sx={clsx(sxses.root, sxses.button, checkedLocation && sxses.buttonActive)}
              />
            </Tooltip>
          }
        >
          <ButtonUi
            variant="text"
            startIcon={buttonData.img}
            sx={clsx(
              sxses.root,
              sxses.buttonOpenNavbar,
              isTablet && sxses.buttonOpenNavbarIsTouchDevice,
              checkedLocation && sxses.buttonActiveOpenNavbar,
              checkedLocation && isTablet && sxses.buttonActiveOpenNavbarIsTouchDevice,
            )}
          >
            {buttonData.comingSoon && <ComingIcon checkedLocation={checkedLocation} />}
            <Typography semiBold mt={1.25} color="inherit">
              {buttonData.name}
            </Typography>
          </ButtonUi>
        </When>
      </NavLink>
    ),
    [
      forbiden,
      pathname,
      buttonData.link,
      buttonData.img,
      buttonData.comingSoon,
      buttonData.name,
      isOpenNavbar,
      checkedLocation,
      isTablet,
      setIsOpenNavbarMobile,
    ],
  );
};

export default React.memo(Button);
