import React, { useLayoutEffect, useMemo, useState } from 'react';
import useWebSocket, { Options } from 'react-use-websocket';
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';
import { toast } from 'react-toastify';

import { useIsLogin } from '@hooks';
import { jwt } from '@servises';
import { createContext } from '@helpers';
import { WS_URL } from '@constants';
import { Children, UseState } from '@types';

import OfflineProvider from './OfflineContext';
import { ToastWsError } from './Toasts';
import { defaultFilter } from './@helpers';
import { noClosingToastProps, titleToastError } from './config';
import { WsURL } from './types';

type WsProviderProps = {
  filter?: Options['filter'];
} & Children;

type WsContextType = {
  setWsURL: UseState<WsURL>;
} & WebSocketHook;

const [Provider, , WsContext] = createContext<WsContextType>();
export { WsContext };

const WsProvider: React.FC<WsProviderProps> = ({ children, filter }) => {
  const { client, token, uid } = jwt.getUserTokenValues();
  const [wsURL, setWsURL] = useState<WsURL>(null);
  const isLogin = useIsLogin();

  const { readyState, ...other } = useWebSocket(wsURL, {
    share: true,
    shouldReconnect: () => true,
    reconnectAttempts: 20,
    reconnectInterval: (attemptNumber) => Math.min(attemptNumber + 5000, 15000),
    onReconnectStop: () => {
      toast.info(<ToastWsError title={titleToastError.reconnectionLimit} />, noClosingToastProps);
    },
    filter: filter || defaultFilter,
    queryParams: {
      client: client ?? '',
      token: token ?? '',
      uid: encodeURIComponent(uid ?? ''),
    },
  });

  useLayoutEffect(() => {
    toast.dismiss();
    setWsURL(isLogin ? WS_URL : null);
  }, [isLogin]);

  const contextValue = useMemo<WsContextType>(
    () => ({ readyState, setWsURL, ...other }),
    [other, readyState],
  );

  return (
    <Provider value={contextValue}>
      <OfflineProvider>{children}</OfflineProvider>
    </Provider>
  );
};

export default WsProvider;
