import { Draft, createSlice as createSliceToolkit, Dispatch } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

import { APIErrorResponse, BaseAPI } from '@api';
import { DeepPartial } from '@types';

import makeToastError from './makeToastError';

export type ErrorState = {
  errors?: string | string[] | Record<'fullMessages', string[]> | Record<string, string[]>;
  error?: string;
};

export type StoreState<S, E = ErrorState> = {
  records: S;
  headers: AxiosResponse<S>['headers'];
  loading: boolean;
  loaded: boolean;
  initial: boolean;
  status?: number;
  error: APIErrorResponse<E> | null;
};

export type OptionsType<T> = {
  hideToastMessageError?: boolean;
  initialState?: DeepPartial<T>;
  enebleReInitialStateAfterError?: boolean;
  omitErrorStatus?: number[];
  recordHeaders?: boolean;
};

export default <T, P extends unknown[], N extends string>(
  name: N,
  request: BaseAPI<T, P> | undefined,
  options: OptionsType<T> = {},
) => {
  const {
    hideToastMessageError,
    initialState = {} as DeepPartial<T>,
    enebleReInitialStateAfterError,
    omitErrorStatus,
    recordHeaders,
  } = options;

  const initialStateSlice: StoreState<T> = {
    records: initialState as T,
    headers: {},
    loading: false,
    loaded: false,
    initial: true,
    status: undefined,
    error: null,
  };

  const slice = createSliceToolkit({
    name,
    initialState: initialStateSlice,
    reducers: {
      PENDING: (state) => {
        state.loading = true;
        state.loaded = false;
        state.status = undefined;
        state.error = null;
      },
      FULFILLED: (state, action: { payload: Draft<AxiosResponse<T>>; type: string }) => {
        state.records = action.payload.data;
        state.status = action.payload.status;
        state.loading = false;
        state.loaded = true;
        state.initial = false;
        if (recordHeaders) state.headers = action.payload.headers;
      },
      UPDATE: (state, action: { payload: Partial<StoreState<Draft<T>>>; type: string }) => {
        if (action.payload?.records) state.records = action.payload.records;
        state.status = undefined;
        state.error = null;
      },
      REJECTED: (state, action: { payload: APIErrorResponse<ErrorState>; type: string }) => {
        if (enebleReInitialStateAfterError) state.records = initialStateSlice.records as Draft<T>;
        state.error = action?.payload;
        state.status = action?.payload?.status ?? 999;
        state.loading = false;
        state.loaded = false;
        state.initial = false;
      },
    },
  });

  const { FULFILLED, REJECTED, PENDING } = slice.actions;

  const action = (...payload: P) => {
    return async (dispatch: Dispatch) => {
      try {
        if (request) {
          dispatch(PENDING());
          const response = await request(...payload);
          dispatch(FULFILLED(response as Draft<AxiosResponse<T>>));
          return response;
        }
      } catch (e) {
        dispatch(REJECTED(e as AxiosError<ErrorState>));
        hideToastMessageError || makeToastError(e as AxiosError<ErrorState>, omitErrorStatus);
        return e as AxiosResponse<T>;
      }
    };
  };

  return { ...slice, action };
};
