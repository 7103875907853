import React from 'react';

import Box from 'components/@ui/Box';
import Icon from 'components/@ui/Icon';
import Typography from 'components/@ui/Typography';
import Tooltip from 'components/@ui/Tooltip';
import { useIsTablet } from '@hooks';

type FeaturesAndCharacteristicsModalStatusState = {
  status: 'active' | 'onlyProject' | 'disabled' | 'onlyProduct';
};

type ModuleProps = {
  moduleEl: {
    name: string;
    iconName: string;
  };
} & FeaturesAndCharacteristicsModalStatusState;

const TooltipTitle: React.FC<FeaturesAndCharacteristicsModalStatusState> = ({ status }) => {
  switch (status) {
    case 'active':
      return <>Required and available</>;
    case 'onlyProduct':
      return <>NOT required and available</>;
    case 'onlyProject':
      return <>Required and NOT available</>;
    case 'disabled':
      return <>NOT required and NOT available</>;
  }
};

const statusColor = {
  active: 'greenLight.main',
  disabled: 'gray.main',
  onlyProduct: 'error.main',
  onlyProject: 'error.main',
};

const FeaturesAndCharacteristicsModal: React.FC<ModuleProps> = ({ moduleEl, status }) => {
  const isTablet = useIsTablet();

  return (
    <Tooltip
      title={isTablet ? null : <TooltipTitle status={status} />}
      disableInteractive
      placement="top"
      componentsProps={{
        popper: {
          sx: {
            zIndex: 1700,
            '.MuiTooltip-tooltip': {
              backgroundColor: 'gray.dark',
            },
            '.MuiTooltip-arrow': {
              color: 'gray.dark',
            },
          },
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width={80}
        height={80}
        position="relative"
        color={status === 'disabled' || status === 'onlyProduct' ? 'gray.main' : 'text.primary'}
      >
        {status !== 'onlyProduct' && (
          <Icon
            name={status === 'active' ? 'check' : 'delete'}
            width={24}
            height={24}
            position="absolute"
            left={0}
            top={-5}
            color={statusColor[status]}
            borderRadius={0}
          />
        )}
        <Icon name={moduleEl.iconName} width={24} height={24} color="inherit" borderRadius={0} />
        <Typography
          textAlign="center"
          mt={0.75}
          color="inherit"
          fontSize={12}
          lineHeight="15px"
          height={40}
        >
          {moduleEl.name}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default React.memo(FeaturesAndCharacteristicsModal);
