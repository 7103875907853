import React from 'react';
import { StepLabelProps } from '@mui/material';

import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import Collapse from 'components/@ui/Collapse';
import Divider from 'components/@ui/Divider';
import Icon from 'components/@ui/Icon';
import Typography from 'components/@ui/Typography';
import StepLabel from 'components/@ui/StepLabel';
import Stepper from 'components/@ui/Stepper';
import Step from 'components/@ui/Step';
import { clsx } from '@helpers';
import { Children } from '@types';

import MobileCustomPhaseStepIcon from '../@mobile/MobileCustomPhaseStepIcon';
import { Badge, Line } from '../@components';
import { icons } from '../Phases';
import { phases } from './config';
import { TimeLineMobileLayout } from '../TimeLineLayout';
import { sxsesPhaseWrapper } from '../Phase';
import { sxsesMobilePhaseButton } from '../@mobile';
import { colorGenerator } from '../@helpers';

type StepMapProps = Children & {
  renderIconComponent: StepLabelProps['StepIconComponent'];
};
const StepMap: React.FC<StepMapProps> = ({ children, renderIconComponent }) => {
  return (
    <Step>
      <StepLabel
        sx={{ width: '100%', position: 'relative', py: 0 }}
        StepIconComponent={renderIconComponent}
      >
        {children}
      </StepLabel>
    </Step>
  );
};

const Mobile: React.FC = () => {
  return (
    <TimeLineMobileLayout>
      <Line />
      <Box zIndex={2} position="relative" width="100vw" px={2}>
        {phases(true).map((phase) => (
          <Box
            display="flex"
            flexWrap="nowrap"
            key={phase.id}
            sx={clsx(sxsesPhaseWrapper[phase.status], phase.isSelected && { pb: 3 })}
            flexDirection="column"
          >
            <Box width="100%" p={0} bgcolor="inherit" borderRadius={10}>
              <Badge width="100%" status={phase.status} IconProps={{ right: 1, top: -2 }}>
                <Button
                  sx={clsx(sxsesMobilePhaseButton[phase.status], sxsesMobilePhaseButton.mobile, {
                    color: colorGenerator(phase.status, 'phaseText'),
                  })}
                >
                  <Box display="flex" alignItems="center">
                    <Icon
                      name={icons[phase.name]}
                      fontSize="24px"
                      width={24}
                      color="inherit"
                      borderRadius={0}
                    />
                    <Typography color="inherit" semiBold ml={1}>
                      {phase.name}
                    </Typography>
                  </Box>
                  <Icon name={'blue-arrow-down-path'} fontSize="16px" width={24} color="inherit" />
                </Button>
              </Badge>
            </Box>
            <Collapse orientation="vertical" in={phase.isSelected}>
              <Box display="flex" alignItems="center" flexDirection="column" height="100%">
                <Stepper
                  activeStep={0}
                  orientation="vertical"
                  connector={null}
                  sx={{ width: '100%', px: 3 }}
                >
                  {phase.todos.map((todo, index, array) => (
                    <StepMap
                      key={todo.id}
                      renderIconComponent={() => <MobileCustomPhaseStepIcon status={todo.status} />}
                    >
                      <Button
                        aria-describedby={`${todo.id}`}
                        variant="contained"
                        name={todo.name}
                        fullWidth
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          bgcolor: 'inherit',
                          '&:hover, &:active': { bgcolor: 'inherit' },
                          height: 64,
                          py: 2,
                          pl: 0.5,
                          pr: 0,
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography
                            color="text.primary"
                            semiBold
                            fontSize={18}
                            lineHeight="32px"
                            mr={1}
                          >
                            {todo.name}
                          </Typography>
                        </Box>
                        <Icon name="right" color="text.primary" />
                      </Button>
                      {array.length - 1 !== index && (
                        <Divider
                          orientation="vertical"
                          sx={{
                            position: 'absolute',
                            borderWidth: 2,
                            height: 40,
                            left: 10,
                            top: 44,
                            borderColor: colorGenerator(todo.status, 'stepperConnector'),
                          }}
                        />
                      )}
                    </StepMap>
                  ))}
                </Stepper>
              </Box>
            </Collapse>
          </Box>
        ))}
      </Box>
    </TimeLineMobileLayout>
  );
};

export default Mobile;
