import { Expansion } from '@types';

export type WsURL = string | null;

export enum MessageType {
  confirm = 'confirm_subscription',
  subscribe = 'subscribe',
  unsubscribe = 'unsubscribe',
  disconnect = 'disconnect',
  ping = 'ping',
}

export enum ReasonType {
  unauthorized = 'unauthorized',
  null = '',
}

export type UnauthorizedType = {
  type: MessageType.disconnect;
  reason?: ReasonType.unauthorized;
};

export type PingType = {
  type: MessageType.ping;
  reason?: ReasonType.null;
};

export type SubscriptionType = {
  identifier: string;
  type: MessageType.confirm;
};

export type MessageMainType<A, E extends Expansion> = {
  action: A;
} & E;

export type SubscriptionMainType<A, E extends Expansion = {}, I extends string = string> = {
  identifier?: I;
  message?: MessageMainType<A, E>;
};

export type Update<T> = (state: T) => void;
