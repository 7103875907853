import { useContext, useMemo } from 'react';
import { isPlainObject } from 'ramda-adjunct';

import { AbilityContext } from '../Context';
import { AbilityCheckProps, abilityCheck } from '../@helpers';
import { AbilityControlType } from '../AbilityControl';

const useAbility = () => {
  const abilities = useContext(AbilityContext);

  return useMemo(
    () => ({
      abilities,

      /**
       * @param props AbilityCheckProps | AbilityControlType['permission']
       *
       * @see AbilityCheckProps:
       * @param ability AbilityType;
       * @default 'USE'
       * @param permission PermissionsProp;
       * @required
       * Current permission.
       * string | string[]
       * @param method PermissionsArrayMethod;
       * @default 'all'
       * Method 'all' | 'any'.
       *
       * @see AbilityControlType['permission']:
       * @param permission PermissionsProp;
       *
       * @returns boolean.
       */
      abilityCheck: (props: AbilityCheckProps | AbilityControlType['permission']) =>
        abilityCheck({
          abilities,
          ...(isPlainObject(props) ? (props as AbilityCheckProps) : { permission: props }),
        }),
    }),
    [abilities],
  );
};
export default useAbility;
