import React from 'react';

import AppBar from 'components/@ui/AppBar';
import Box from 'components/@ui/Box';
import Toolbar from 'components/@ui/Toolbar';

import ButtonToggle from './ButtonToggle';
import Title from './Title';
import TimelineButton from './TimelineButton';

const NavbarHeaderMenuMobile: React.FC = () => {
  return (
    <Box position="fixed" top={0} zIndex={299} width="100%">
      <AppBar
        sx={{
          bgcolor: 'primary.contrastText',
          color: 'text.primary',
          boxShadow: 3,
        }}
      >
        <Toolbar sx={{ minHeight: 80 }}>
          <ButtonToggle />
          <Title />
          <TimelineButton />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavbarHeaderMenuMobile;
