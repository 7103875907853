import { useCallback, useMemo, useRef } from 'react';
import { useUnmount } from 'react-use';

const useSetInterval = (defaultTimeout?: number) => {
  const intervalId = useRef<number | undefined>();

  const setIntervalFn = useCallback(
    (cb: () => void, timeout?: number) => {
      intervalId.current = window.setInterval(cb, timeout ?? defaultTimeout);
    },
    [defaultTimeout],
  );

  const clearIntervalFn = useCallback(() => {
    window.clearInterval(intervalId.current);
  }, []);

  useUnmount(() => window.clearInterval(intervalId.current));

  return useMemo(
    () => ({
      setInterval: setIntervalFn,
      clearInterval: clearIntervalFn,
    }),
    [clearIntervalFn, setIntervalFn],
  );
};

export default useSetInterval;
