import { useLinkedIn as useLinkedInReact } from 'react-linkedin-login-oauth2';
import { useLinkedInType as UseLinkedInTypeReact } from 'react-linkedin-login-oauth2/build/types';

import { LINKEDIN_OAUTH_CLIENT_ID } from '@constants';

export type UseLinkedInType = Omit<UseLinkedInTypeReact, 'clientId'> & {
  clientId?: UseLinkedInTypeReact['clientId'];
};

const useLinkedIn = (props: UseLinkedInType) => {
  return useLinkedInReact({
    scope: 'profile,openid,email',
    clientId: LINKEDIN_OAUTH_CLIENT_ID ?? '',
    ...props,
  });
};

export default useLinkedIn;
