import React from 'react';
import { keyframes } from '@emotion/react';
import { omit } from 'ramda';

import Box, { BoxProps } from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';
import { clsx, parseSx } from '@helpers';

const spinner = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

const Text: React.FC = () => <Typography>Loading, one moment please...</Typography>;

export type LoaderProps = {
  TextComponent?: React.ReactNode;
  hideText?: boolean;
  spinnerProps?: BoxProps;
};

const Loader: React.FC<LoaderProps & BoxProps> = ({
  TextComponent = <Text />,
  hideText,
  spinnerProps = {},
  ...other
}) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height="50vh"
    {...other}
  >
    <Box
      width={40}
      height={40}
      mx="auto"
      my={7.5}
      fontSize={10}
      position="relative"
      border={4}
      borderColor="gray.main"
      borderRadius="50%"
      borderLeft="4px solid transparent"
      flex="0 0 auto"
      {...omit(['sx'], spinnerProps)}
      sx={clsx(
        {
          animation: `${spinner} 1s infinite linear`,
          textIndent: '-9999em',
          transform: 'translateZ(0)',
        },
        ...parseSx(spinnerProps?.sx),
      )}
    />
    {!hideText && TextComponent}
  </Box>
);

export default React.memo(Loader);
