import { useCallback, useDeferredValue } from 'react';
import { createSelector } from 'reselect';

import { useAppDispatch } from '@redux/store';
import { useAPI, useEvent } from '@hooks';

import { selectors, vendors } from '../@slices';
import { VendorsResponse } from '../@api';
import { makeRandomVendors } from '../@helpers';

const selector = createSelector(selectors.root, (state) => state.vendors);

const useFetchVendors = () => {
  return useAPI(
    useEvent(() => vendors.action()),
    selector,
  );
};

export default useFetchVendors;

export const useUpdateVendors = () => {
  const dispatch = useAppDispatch();
  return useEvent((records: VendorsResponse) => {
    dispatch(vendors.actions.UPDATE({ records }));
  });
};

export const useUpdateRandomVendors = () => {
  const [{ records }] = useFetchVendors();
  const deferredRecords = useDeferredValue(records);
  const updateVendors = useUpdateVendors();
  return useCallback(() => {
    const randomVendors = makeRandomVendors(deferredRecords);
    updateVendors(randomVendors);
    return randomVendors;
  }, [deferredRecords, updateVendors]);
};
