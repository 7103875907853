import React from 'react';
import { Provider } from 'react-redux';

import store from '@redux/store';
import { Children } from '@types';

const ReduxProvider: React.FC<Children> = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

export default ReduxProvider;
