import React, { useMemo } from 'react';
import { not } from 'ramda';

import Box from 'components/@ui/Box';
import { useIsLogin } from '@hooks';

import Wrapper from './Wrapper';
import Login from './Login';
import Unauthorized from './Unauthorized';

const NavbarHeader: React.FC = () => {
  const isLogin = useIsLogin();
  const forbiden = useMemo(() => not(isLogin), [isLogin]);

  return (
    <Wrapper>
      <Box
        mt={isLogin ? 2.5 : 2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
      >
        {forbiden ? <Unauthorized /> : <Login />}
      </Box>
    </Wrapper>
  );
};

export default NavbarHeader;
