import React from 'react';

import Box from 'components/@ui/Box';
import TableCell from 'components/@ui/TableCell';
import TableRow from 'components/@ui/TableRow';
import Typography from 'components/@ui/Typography';
import InfoTooltipIcon from 'components/@ui/InfoTooltipIcon';
import { useIsTablet } from '@hooks';
import { Children } from '@types';
import { clsx } from '@helpers';

import heatMapRowStyles from './heatMapRowStyles';
import { EmptyCell } from '../VendorsComponents';
import { cellsSize } from './@config';
import { REMAINDERS_ANHOR } from '../Reminders';

const WorkspaceAddRow: React.FC<Children> = ({ children }) => {
  const isTablet = useIsTablet();

  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        height={cellsSize.heatMap.height}
        sx={clsx(heatMapRowStyles.rowHeader, isTablet && heatMapRowStyles.tableHeader)}
      >
        <Box display="flex" width="fit-content" id={REMAINDERS_ANHOR.workspace.end}>
          <Typography
            maxHeight={1}
            fontSize={{ mobile: 12, laptop: 16 }}
            lineHeight={{ mobile: 'normal', laptop: 1.5 }}
            mr={{ mobile: 0, laptop: 0.5 }}
            semiBold
          >
            Add to Workspace
          </Typography>
          <InfoTooltipIcon title="Add up to 4 vendors as finalists to demo" />
        </Box>
      </TableCell>
      <EmptyCell />
      {children}
    </TableRow>
  );
};

export default React.memo(WorkspaceAddRow);
