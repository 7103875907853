import React from 'react';

import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';

import TableCell from './TableCell';
import { roundedCostsFn } from '../VendorComponents';

type TableCellCostProps = {
  min: number;
  max: number;
};

const TableCellCost: React.FC<TableCellCostProps> = ({ min, max }) => {
  return (
    <TableCell>
      <Box
        maxWidth={1}
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        flexDirection="column"
        zIndex={4}
      >
        <Typography>${roundedCostsFn(min)}</Typography>
        <Typography lineHeight="8px">-</Typography>
        <Typography>${roundedCostsFn(max)}</Typography>
      </Box>
    </TableCell>
  );
};

export default TableCellCost;
