import { getUserLocales } from 'get-user-locale';

const userLocales = getUserLocales();

type DateParams = ConstructorParameters<typeof Date>[0];
type LocaleDateStringParams = Parameters<Date['toLocaleDateString']>;

export default (date: DateParams, ...options: LocaleDateStringParams) => {
  const [locales = userLocales, toLocaleDateStringOptions] = options;
  return new Date(date).toLocaleDateString(locales, toLocaleDateStringOptions);
};
