import React from 'react';

import Box from 'components/@ui/Box';
import Image from 'components/@ui/Image';
import TypographyMobile from 'components/@ui/TypographyMobile';
import { useIsMobile, useIsTablet } from '@hooks';

const FeaturesContent: React.FC = () => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mt={6}
      flexDirection={isMobile ? 'column' : 'row'}
      gap={isMobile ? '48px 0' : 0}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Image
          src={require('assets/hide-market-insights.png')}
          width={isTablet && !isMobile ? 160 : 240}
          height={isTablet && !isMobile ? 160 : 240}
        />
        <TypographyMobile semiBold mt={isMobile ? 3 : 6} mobileRegularFontSize={isTablet} width={1}>
          Market Insights
        </TypographyMobile>
        <Box component="ul" sx={{ listStyle: 'disc', pl: 3 }}>
          <TypographyMobile component="li" mobileRegularFontSize={isTablet}>
            50+ HRIS vendor profiles
          </TypographyMobile>
          <TypographyMobile component="li" mobileRegularFontSize={isTablet}>
            User-reported pros & cons
          </TypographyMobile>
          <TypographyMobile component="li" mobileRegularFontSize={isTablet}>
            Verified pricing estimates
          </TypographyMobile>
          <TypographyMobile component="li" mobileRegularFontSize={isTablet}>
            Sorted by your goals & needs
          </TypographyMobile>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Image
          src={require('assets/hide-evaluation-tools.png')}
          width={isTablet && !isMobile ? 160 : 240}
          height={isTablet && !isMobile ? 160 : 240}
        />
        <TypographyMobile semiBold mt={isMobile ? 3 : 6} mobileRegularFontSize={isTablet} width={1}>
          Evaluation Tools
        </TypographyMobile>
        <Box component="ul" sx={{ listStyle: 'disc', pl: 3 }}>
          <TypographyMobile component="li" mobileRegularFontSize={isTablet}>
            Project plans & timelines
          </TypographyMobile>
          <TypographyMobile component="li" mobileRegularFontSize={isTablet}>
            Shared scorecards
          </TypographyMobile>
          <TypographyMobile component="li" mobileRegularFontSize={isTablet}>
            Suggested vendor questions
          </TypographyMobile>
          <TypographyMobile component="li" mobileRegularFontSize={isTablet}>
            Team collaboration
          </TypographyMobile>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Image
          src={require('assets/hide-advisor-services.png')}
          width={isTablet && !isMobile ? 160 : 240}
          height={isTablet && !isMobile ? 160 : 240}
        />
        <TypographyMobile semiBold mt={isMobile ? 3 : 6} mobileRegularFontSize={isTablet} width={1}>
          Advisory Services
        </TypographyMobile>
        <Box component="ul" sx={{ listStyle: 'disc', pl: 3 }}>
          <TypographyMobile component="li" mobileRegularFontSize={isTablet}>
            Access to HRIS industry experts
          </TypographyMobile>
          <TypographyMobile component="li" mobileRegularFontSize={isTablet}>
            Help with proposal review
          </TypographyMobile>
          <TypographyMobile component="li" mobileRegularFontSize={isTablet}>
            Negotiation guidance
          </TypographyMobile>
          <TypographyMobile component="li" mobileRegularFontSize={isTablet}>
            Implementation prep
          </TypographyMobile>
        </Box>
      </Box>
    </Box>
  );
};

export default FeaturesContent;
