import React from 'react';

import {
  createTheme,
  GlobalStyles,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';

import Fonts from 'styles/fonts';
import * as overrides from 'components/@ui/overrides';
import { Children } from '@types';

import shadows from './shadows';
import scrollbar from './scrollbar';
import sizes from './sizes';

export type CustomColor = {
  active1: string;
  active2: string;
  hover1: string;
  hover2: string;
  disabled1: string;
  disabled2: string;
};

declare module '@mui/material/styles' {
  interface Theme {
    scrollbar: typeof scrollbar;
    sizes: typeof sizes;
  }

  interface ThemeOptions {
    scrollbar?: typeof scrollbar;
    sizes?: typeof sizes;
  }

  interface Palette {
    actionPrimary: CustomColor;
    actionError: CustomColor;
    green: Palette['primary'];
    greenLight: Palette['primary'];
    greenActions: CustomColor;
    gray: Palette['primary'];
    blue: Palette['primary'];
    red: Palette['primary'];
    redActions?: CustomColor;
    yellow: Palette['primary'];
    yellowActions?: CustomColor;
    yellowLight: Palette['primary'];
    phaseTooltip: Palette['primary'];
    dropdownHover: Palette['primary'];
  }

  interface PaletteOptions {
    actionPrimary?: Partial<CustomColor>;
    actionError?: Partial<CustomColor>;
    green?: PaletteOptions['primary'];
    greenLight?: PaletteOptions['primary'];
    greenActions?: Partial<CustomColor>;
    gray?: PaletteOptions['primary'];
    blue?: PaletteOptions['primary'];
    red?: PaletteOptions['primary'];
    redActions?: Partial<CustomColor>;
    yellow?: PaletteOptions['primary'];
    yellowActions?: Partial<CustomColor>;
    yellowLight?: PaletteOptions['primary'];
    phaseTooltip?: PaletteOptions['primary'];
    dropdownHover: PaletteOptions['primary'];
  }

  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

const theme = createTheme({
  components: { ...overrides },
  breakpoints: {
    values: {
      mobile: 320,
      tablet: 744,
      laptop: 1024,
      desktop: 9999,
    },
  },
  typography: {
    allVariants: {
      fontFamily: Fonts.ProximaNova,
      color: '#3E4359',
      letterSpacing: 0,
    },
  },
  spacing: 8,
  palette: {
    background: {
      default: '#FAFBFF',
    },
    primary: {
      main: '#586DDA',
      light: '#97A4E8',
      dark: '#2F49D1',
      contrastText: '#FFFFFF',
    },
    actionPrimary: {
      active1: '#2036A9',
      active2: '#D5D9F6',
      disabled1: '#97A4E8',
      hover1: '#EBEDFB',
    },
    secondary: {
      main: '#FFFFFF',
      dark: 'rgba(218, 221, 237, 0.3)',
      light: '#F2F4FF',
      contrastText: '#050D38',
    },
    text: {
      primary: '#3E4359',
      secondary: '#FFFFFF',
      disabled: '#3E466D',
    },
    error: {
      main: '#E13A05',
      light: '#FDEDE8',
      dark: '#C33204',
      contrastText: '#FFFFFF',
    },
    actionError: {
      active1: '#AA2B04',
      active2: '#F7D4D5',
      disabled1: '#FC9F82',
      disabled2: '#FF9999',
      hover1: '#FBEAEA',
    },
    divider: '#DADDED',
    action: {
      hover: '#EBEDFB',
      active: '#D5D9F6',
    },
    green: {
      main: '#0BA62D',
      dark: '#DAF2E0',
      light: '#C6EBD0',
    },
    greenActions: {
      active1: '#7BD191',
      active2: '#8FD6A0',
      hover1: '#A1DEB1',
      hover2: '#B4E4C0',
    },
    yellowActions: {
      active1: '#F5E77B',
      hover1: '#F8EFAA',
    },
    redActions: {
      active1: '#F1997E',
      active2: '#ED7550',
    },
    gray: {
      main: '#9FA2B6',
      light: '#EEEFF7',
      dark: '#3E4359',
      contrastText: '#686C88',
    },
    blue: {
      main: '#586DDA',
      light: '#E1E5F9',
    },
    red: {
      main: '#FBE1D9',
      dark: '#F6BDAC',
      light: '#FEF4F1',
      contrastText: '#E50000',
    },
    yellow: {
      main: '#D9B441',
      dark: '#BF9A26',
      light: '#FBF7EA',
      contrastText: '#F7EFD4',
    },
    yellowLight: {
      main: '#F3E7BF',
      dark: '#FCF8D9',
    },
    greenLight: {
      main: '#5A8C31',
      dark: '#426624',
      light: '#EEF8EC',
      contrastText: '#D7F1C6',
    },
    phaseTooltip: {
      main: '#4CD968',
      light: '#F9FF0F',
      contrastText: '#BDEAA0',
    },
    dropdownHover: {
      main: '#F4F5FA',
      light: '#FAFBFF',
      dark: '#E5E7F1',
      contrastText: '#D2D6E9',
    },
  },
  sizes,
  scrollbar,
  shadows,
});

const ThemeProvider: React.FC<Children> = ({ children }) => (
  <StyledEngineProvider>
    <MuiThemeProvider theme={theme}>
      <GlobalStyles
        styles={(theme) => ({
          body: { color: theme.palette.text.primary, fontFamily: Fonts.ProximaNova },
        })}
      />
      {children}
    </MuiThemeProvider>
  </StyledEngineProvider>
);

export default ThemeProvider;
