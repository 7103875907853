import React from 'react';

import Image from 'components/@ui/Image';
import { ButtonProps } from 'components/@ui/Button';

import ButtonSocial from '../ButtonSocial';

const ButtonGoogle: React.FC<ButtonProps> = (props) => {
  return (
    <ButtonSocial
      startIcon={<Image width={20} height={20} src={require('../assets/google-icon.png')} />}
      children="Sign in with Google"
      {...props}
    />
  );
};

export default React.memo(ButtonGoogle);
