import baseAPI, { APIResponse } from '@api';

import { Criterion, Question, Vendor } from '../@types';

const api = baseAPI(false);
const apiCredentials = baseAPI();
//-------------------------------------------------------------------------
// Get Characteristic
//-------------------------------------------------------------------------
export function fetchCharacteristic(id: number): APIResponse<Criterion.Criterion> {
  return api.get(`/product_characteristics/${id}`);
}
//-------------------------------------------------------------------------
// Get Vendors
//-------------------------------------------------------------------------
export function fetchVendors(projectId: number): APIResponse<Vendor.Vendor[]> {
  return apiCredentials.get(`/projects/${projectId}/workspaced_products`);
}
//-------------------------------------------------------------------------
// Get Vendor Personal Score
//-------------------------------------------------------------------------
export type VendorPersonalScoreResponse = { score: number };
export function fetchVendorPersonalScore(
  projectId: number,
  vendorId: number,
): APIResponse<VendorPersonalScoreResponse> {
  return apiCredentials.get(`/projects/${projectId}/scorecard/client_scores`, {
    params: { product_id: vendorId },
  });
}
//-------------------------------------------------------------------------
// Get Criteria for Vendor
//-------------------------------------------------------------------------
export type FetchCriteriaResponse = {
  keyCriteria: Criterion.Criterion[];
  statistic: Vendor.Statistic;
};
export type FetchCriteriaQuery = { vendorId: number };
export function fetchCriteria(
  projectId: number,
  { vendorId }: FetchCriteriaQuery,
): APIResponse<FetchCriteriaResponse> {
  return apiCredentials.get(`/projects/${projectId}/scorecard/`, {
    params: { product_id: vendorId },
  });
}
//-------------------------------------------------------------------------
// Get Criteria for Settings
//-------------------------------------------------------------------------
export type FetchCriteriaSettingsResponse = { keyCriteria: Criterion.CriterionSettings[] };
export function fetchCriteriaSettings(
  projectId: number,
): APIResponse<FetchCriteriaSettingsResponse> {
  return apiCredentials.get(`/projects/${projectId}/criteria`);
}
//-------------------------------------------------------------------------
// Get Total Weight
//-------------------------------------------------------------------------
export type FetchTotalWeightResponse = { totalWeight: number };
export function fetchTotalWeight(projectId: number): APIResponse<FetchTotalWeightResponse> {
  return apiCredentials.get(`/projects/${projectId}/criteria/total_weight`);
}
//-------------------------------------------------------------------------
// Get Criterion Scorecard
//-------------------------------------------------------------------------
export type FetchCriterionScorecardResponse = Criterion.Criterion;
export type FetchCriterionScorecardGetQuery = { vendorId: number };
export function fetchCriterionScorecardGet(
  projectId: number,
  criteriaId: number,
  { vendorId }: FetchCriterionScorecardGetQuery,
): APIResponse<FetchCriterionScorecardResponse> {
  return apiCredentials.get(`/projects/${projectId}/scorecard/${criteriaId}`, {
    params: { product_id: vendorId },
  });
}
//-------------------------------------------------------------------------
// Get Criterion
//-------------------------------------------------------------------------
export type FetchCriterionModalResponse = Criterion.CriterionLite;
export function fetchCriterionModalGet(
  projectId: number,
  criteriaId: number,
): APIResponse<FetchCriterionModalResponse> {
  return apiCredentials.get(`/projects/${projectId}/criteria/${criteriaId}`);
}
//-------------------------------------------------------------------------
// Create Criterion
//-------------------------------------------------------------------------
export type FetchCriterionCreateBody = {
  name: string;
  weight: number;
  description: string;
  questions: { name: string }[];
};
export function fetchCriterionCreate(
  projectId: number,
  { questions, ...other }: FetchCriterionCreateBody,
): APIResponse<FetchCriterionScorecardResponse> {
  return apiCredentials.post(`/projects/${projectId}/criteria`, {
    questions_attributes: questions,
    ...other,
  });
}
//-------------------------------------------------------------------------
// Edit Criterion
//-------------------------------------------------------------------------
export type FetchCriterionEditBody = {
  name?: string;
  weight?: number;
  description?: string;
  questions?: Partial<Question.QuestionLite> & { _destroy?: boolean }[];
};
export function fetchCriterionEdit(
  projectId: number,
  criteriaId: number,
  { questions, ...other }: FetchCriterionEditBody,
): APIResponse<FetchCriterionScorecardResponse> {
  return apiCredentials.put(`/projects/${projectId}/criteria/${criteriaId}`, {
    id: criteriaId,
    ...(questions && { questions_attributes: questions }),
    ...other,
  });
}
//-------------------------------------------------------------------------
// Order Criterion on the setting page
//-------------------------------------------------------------------------
export type FetchCriterionOrderBody = { position: number };
export function fetchCriterionOrder(
  projectId: number,
  criterionId: number,
  { position }: FetchCriterionOrderBody,
): APIResponse<Criterion.CriterionSettings> {
  return apiCredentials.put(`/projects/${projectId}/criteria/${criterionId}/move`, { position });
}
//-------------------------------------------------------------------------
// Delete Criterion
//-------------------------------------------------------------------------
export function fetchCriterionDelete(projectId: number, criteriaId: number) {
  return apiCredentials.delete(`/projects/${projectId}/criteria/${criteriaId}`);
}
//-------------------------------------------------------------------------
// Pre Delete Information of Criterion
//-------------------------------------------------------------------------
export type PreDeleteCriterionScoreProps<T = string | number> = {
  name: string;
  notes: T;
  scores: T;
};
export type FetchPreDeleteCriterionResponse = { products: PreDeleteCriterionScoreProps[] };
export function fetchPreDeleteCriteria(
  projectId: number,
  criterionId: number,
): APIResponse<FetchPreDeleteCriterionResponse> {
  return apiCredentials.get(`/projects/${projectId}/criteria/${criterionId}/pre_delete`);
}

//-------------------------------------------------------------------------
// Order Question on the scorecard page
//-------------------------------------------------------------------------
export type FetchQuestionResponse = Question.Question;
export type FetchQuestionOrderBody = { position: number };
export function fetchQuestionOrder(
  projectId: number,
  criterionId: number,
  questionId: number,
  { position }: FetchQuestionOrderBody,
): APIResponse<FetchQuestionResponse> {
  return apiCredentials.put(
    `/projects/${projectId}/criteria/${criterionId}/questions/${questionId}/move`,
    { position },
  );
}
//-------------------------------------------------------------------------
// Question add on the scorecard page
//-------------------------------------------------------------------------
export type FetchQuestionProps = {
  vendorId: number;
  name: string;
  questionId?: number;
};
export function fetchQuestionAdd(
  projectId: number,
  criterionId: number,
  questionProps: FetchQuestionProps,
): APIResponse<Question.Question> {
  const { name, vendorId } = questionProps;
  return apiCredentials.post(`/projects/${projectId}/criteria/${criterionId}/questions`, {
    question: { name },
    product_id: vendorId,
  });
}
//-------------------------------------------------------------------------
// Question edit on the scorecard page
//-------------------------------------------------------------------------
export function fetchQuestionEdit(
  projectId: number,
  criterionId: number,
  questionProps: FetchQuestionProps,
): APIResponse<Question.Question> {
  const { name, vendorId, questionId } = questionProps;
  return apiCredentials.put(
    `/projects/${projectId}/criteria/${criterionId}/questions/${questionId}`,
    { question: { name }, product_id: vendorId },
  );
}
//-------------------------------------------------------------------------
// Question delete on the scorecard page
//-------------------------------------------------------------------------
export function fetchQuestionDelete(projectId: number, criterionId: number, questionId: number) {
  return apiCredentials.delete(
    `/projects/${projectId}/criteria/${criterionId}/questions/${questionId}`,
  );
}
//-------------------------------------------------------------------------
// Question status change on the scorecard page
//-------------------------------------------------------------------------
export function fetchQuestionStatus(
  projectId: number,
  questionStatusId: number,
  status: boolean,
): APIResponse<FetchQuestionResponse> {
  return apiCredentials.put(`/projects/${projectId}/question_statuses/${questionStatusId}`, {
    question_status: { completed: status },
  });
}
//-------------------------------------------------------------------------
// Get Total Questions Count for Vendor
//-------------------------------------------------------------------------
export type FetchQuestionTotalCountResponse = { questionsTotalCount: number };
export function fetchQuestionTotalCount(
  projectId: number,
  vendorId: number,
): APIResponse<FetchQuestionTotalCountResponse> {
  return apiCredentials.get(`/projects/${projectId}/scorecard/questions_count`, {
    params: { product_id: vendorId },
  });
}
//-------------------------------------------------------------------------
// Create Evaluate
//-------------------------------------------------------------------------
export type FetchEvaluateResponse = {
  score: {
    id: number;
    score: number;
  };
};
export type FetchEvaluateCreateBody = {
  score: number;
  clientId: number;
  vendorId: number;
};
export function fetchEvaluateCreate(
  projectId: number,
  criterionId: number,
  { score, clientId, vendorId }: FetchEvaluateCreateBody,
): APIResponse<FetchEvaluateResponse> {
  return apiCredentials.post(`/projects/${projectId}/criteria/${criterionId}/criterium_scores`, {
    criterium_score: {
      score,
      client_id: clientId,
      product_id: vendorId,
    },
  });
}
//-------------------------------------------------------------------------
// Change Evaluate
//-------------------------------------------------------------------------
export type FetchEvaluateChangeBody = {
  score: number;
  scoreId: number;
};
export function fetchEvaluateChange(
  projectId: number,
  criterionId: number,
  { score, scoreId }: FetchEvaluateChangeBody,
): APIResponse<FetchEvaluateResponse> {
  return apiCredentials.put(
    `/projects/${projectId}/criteria/${criterionId}/criterium_scores/${scoreId}`,
    { criterium_score: { score } },
  );
}
//-------------------------------------------------------------------------
// Delete Evaluate
//-------------------------------------------------------------------------
export function fetchEvaluateDelete(projectId: number, criterionId: number, scoreId: number) {
  return apiCredentials.delete(
    `/projects/${projectId}/criteria/${criterionId}/criterium_scores/${scoreId}`,
  );
}
