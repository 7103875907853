import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAPI } from '@hooks';

import { countries, selectors } from '../@slices';

const selector = createSelector(selectors.global, (state) => state.countries);

const useFetchCountries = () => {
  return useAPI(
    useCallback(() => countries.action(), []),
    selector,
  );
};

export default useFetchCountries;
