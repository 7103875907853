import React, { useMemo } from 'react';

import TableHead from 'components/@ui/TableHead';
import TableBody from 'components/@ui/TableBody';
import When from 'components/@main/When';
import { useIsMobile } from '@hooks';

import HeatMapTableHeader from './HeatMapTableHeader';
import HeatMapShortlistAdd from './HeatMapShortlistAdd';
import HeatMapWorkspaceAdd from './HeatMapWorkspaceAdd';
import HeatMapRequirementsMatch from './HeatMapRequirementsMatch';
import HeatMapSizeMatch from './HeatMapSizeMatch';
import HeatMapFeatureMatch from './HeatMapFeatureMatch';
import HeatMapCharacteristicMatch from './HeatMapCharacteristicMatch';
import HeatMapProsAndCons from './HeatMapProsAndCons';
import HeatMapSoftwareCost from './HeatMapSoftwareCost';
import HeatMapImplementationCost from './HeatMapImplementationCost';
import { EmptyCell, EmptyRow, MobileLinkToVendor, VendorsBodyWrapper } from '../VendorsComponents';
import { AddOrDeleteShortlistIconButton, AddToWorkplaceIconButton } from '../VendorComponents';
import { useFetchVendors } from './@hooks';
import {
  MobileHeaderChip,
  MobileHeatMapViewWrapper,
  MobileVendorContainerWrapper,
  MobileVendorHeader,
  RequirementsMatchRow,
  ShortlistAddRow,
  TableContainer,
  TableHeaderCellUi,
  TableHeaderRow,
  TableRowWrapper,
  WorkspaceAddRow,
  rowTitles,
} from '../HeatMapViewComponents';

const MobileHeatMapView: React.FC = () => {
  const [{ records }] = useFetchVendors();

  return useMemo(
    () => (
      <MobileHeatMapViewWrapper>
        {records.map((vendor) => (
          <MobileVendorContainerWrapper key={vendor.id}>
            <MobileVendorHeader
              vendorId={vendor.id}
              vendorName={vendor.name}
              AddOrDeleteShortlistedComponent={AddOrDeleteShortlistIconButton}
              AddToWorkplaceButtonComponent={AddToWorkplaceIconButton}
            >
              <MobileLinkToVendor to={undefined} />
            </MobileVendorHeader>
            <MobileHeaderChip
              scoreMatch={vendor.scoreMatch}
              sizeMatch={vendor.sizeMatch}
              featureMatch={vendor.featureMatch}
              characteristicMatch={vendor.characteristicMatch}
            />
          </MobileVendorContainerWrapper>
        ))}
      </MobileHeatMapViewWrapper>
    ),
    [records],
  );
};

const rowsOnHover = [
  { name: rowTitles.sizeMatchScalability, Row: HeatMapSizeMatch },
  { name: rowTitles.featureMatch, Row: HeatMapFeatureMatch },
  { name: rowTitles.characteristicMatch, Row: HeatMapCharacteristicMatch },
  { name: rowTitles.prosAndCons, Row: HeatMapProsAndCons },
  { name: rowTitles.softwareCosts, Row: HeatMapSoftwareCost },
  { name: rowTitles.implementationCost, Row: HeatMapImplementationCost },
];

const VendorsBody: React.FC = () => {
  const isMobile = useIsMobile();

  return (
    <VendorsBodyWrapper>
      <When disabledWrapper condition={!isMobile} fallback={<MobileHeatMapView />}>
        <TableContainer>
          <TableHead>
            <TableHeaderRow>
              <HeatMapTableHeader />
            </TableHeaderRow>
          </TableHead>
          <TableBody>
            <ShortlistAddRow>
              <HeatMapShortlistAdd />
            </ShortlistAddRow>
            <WorkspaceAddRow>
              <HeatMapWorkspaceAdd />
            </WorkspaceAddRow>
            <RequirementsMatchRow>
              <HeatMapRequirementsMatch />
            </RequirementsMatchRow>
            {rowsOnHover.map(({ name, Row }) => (
              <TableRowWrapper key={name}>
                <TableHeaderCellUi title={name} />
                <EmptyCell />
                <Row />
              </TableRowWrapper>
            ))}
            <EmptyRow />
          </TableBody>
        </TableContainer>
      </When>
    </VendorsBodyWrapper>
  );
};

export default VendorsBody;
