import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { FetchProjectCreateBody } from '@api/project';
import { useAPI } from '@hooks';

import { projectCreate, selectors } from '../@slices';

const selector = createSelector(selectors.project, (state) => state.projectCreate);

const useFetchProjectCreate = () => {
  return useAPI(
    useCallback((props: FetchProjectCreateBody) => projectCreate.action(props), []),
    selector,
  );
};

export default useFetchProjectCreate;
