import React, { memo, useMemo } from 'react';
import MuiTextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { FormHelperTextProps as MiuFormHelperTextProps } from '@mui/material/FormHelperText';
import { BoxProps, Theme } from '@mui/material';

import { clsx, makeSxStyles, parseSx } from '@helpers';
import { Sizes } from '@theme';

import IconSelectComponent from './IconSelectComponent';
import useTextField, { makeSxUseTextField } from './useTextField';

type StylesSx = { size?: Sizes };

export interface FormHelperTextProps extends Partial<MiuFormHelperTextProps<'div'>> {
  component?: BoxProps['component'];
}

export interface TextFieldProps extends Omit<StandardTextFieldProps, 'size'>, StylesSx {
  FormHelperTextProps?: FormHelperTextProps;
  collapseHelperText?: boolean;
  initialShowPassword?: boolean;
}

export const makeSx = makeSxStyles({
  selectProps: {
    '.MuiSelect-select ': {
      display: 'flex',
      alignItems: 'center',
      minHeight: 'inherit !important',
      pr: '36px !important',
      borderRadius: 1,
      '&:focus': {
        backgroundColor: 'inherit',
        borderRadius: 1,
      },
      '&[aria-expanded="true"]': {
        '& ~ .icon:before': {
          transform: 'rotate(180deg)',
        },
      },
    },
  },
  menuProps: {
    maxHeight: 256,
    boxShadow: 'none',
    '.MuiPaper-root ': (theme: Theme) => ({
      ...theme.scrollbar(),
      boxShadow: '0px 4px 16px rgba(18, 29, 84, 0.2)',
    }),
  },
});

// @ts-ignore
const TextField: React.FC<TextFieldProps> = React.forwardRef((props, ref) => {
  const { size, inputProps, SelectProps, multiline, collapseHelperText, ...other } = props;
  const sxex = useMemo(() => makeSx(), []);
  const sxexUseTextField = useMemo(() => makeSxUseTextField({ size }), [size]);

  const {
    inputProps: inputPropsModify,
    FormHelperTextProps: FormHelperTextPropsModify,
    ...otherModifyProps
  } = useTextField({
    collapseHelperText,
    ...props,
  });

  return (
    <MuiTextField
      ref={ref}
      variant="standard"
      inputProps={{
        ...inputPropsModify,
        ...inputProps,
        sx: clsx(
          ...parseSx(inputPropsModify?.sx),
          multiline && { py: 1.875 },
          ...parseSx(inputProps?.sx),
        ),
      }}
      SelectProps={{
        IconComponent: IconSelectComponent,
        ...SelectProps,
        sx: clsx(sxexUseTextField.InputProps, sxex.selectProps, ...parseSx(SelectProps?.sx)),
        MenuProps: {
          sx: clsx(sxex.menuProps, ...parseSx(SelectProps?.MenuProps?.sx)),
          ...SelectProps?.MenuProps,
        },
      }}
      multiline={multiline}
      FormHelperTextProps={{ component: 'div', ...FormHelperTextPropsModify }}
      {...other}
      {...otherModifyProps}
    />
  );
});

export default memo(TextField);
