import { isValidNumber } from 'ramda-adjunct';
import { find, propEq } from 'ramda';

import { User } from '@types';

import objectStorage from './object.storage';

const PROJECT_ID_KEY = 'os_project_id' as const;

const projectIdStorage = objectStorage<number, number>(PROJECT_ID_KEY);

const get = (userId: number) => {
  const state = projectIdStorage.get(userId);
  if (state && isValidNumber(state)) {
    return state;
  } else {
    return null;
  }
};

const findProject = (projectId: number | null, user: User.ProjectsField) => {
  return find(propEq(projectId, 'projectId'), user.projects || []);
};

const getProject = (user: User.UserForStorage) => {
  const value = get(user.id);
  const project = findProject(value, { projects: user.projects });
  if (value && project) {
    return project;
  } else {
    projectIdStorage.remove(user.id);
    return user.projects?.at(0);
  }
};

const getProjectId = (user: User.UserForStorage) => {
  return getProject(user)?.projectId;
};

export default {
  ...projectIdStorage,
  get,
  findProject,
  getProject,
  getProjectId,
  storageKey: PROJECT_ID_KEY,
};
