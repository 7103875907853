import React from 'react';

import Typography from 'components/@ui/Typography';

import TableCell from './TableCell';

type HeatMapRequirementsMatchProps = {
  cellContent: number;
};

const RequirementsMatchCell: React.FC<HeatMapRequirementsMatchProps> = ({ cellContent }) => {
  return (
    <TableCell>
      <Typography
        maxWidth={1}
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        zIndex={4}
        color="blue.main"
        fontSize={24}
        lineHeight={2}
        semiBold
      >
        {cellContent?.toFixed(0)}
      </Typography>
    </TableCell>
  );
};

export default React.memo(RequirementsMatchCell);
