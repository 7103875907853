import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import Icon from 'components/@ui/Icon';
import Typography from 'components/@ui/Typography';
import Image from 'components/@ui/Image';
import When from 'components/@main/When';

import { usePhotoCreator } from './Context';

type MakeSize = (size?: number) => string | undefined;

const makeSizeFn = (size?: number) => {
  if (!size) return;
  const kB = size / 1024;
  if (kB >= 1024) {
    return (size / 1000)?.toFixed(1) + ' MB';
  } else {
    return kB?.toFixed(1) + ' KB';
  }
};

export type PhotoViewerProps = {
  enableSizeBlock?: boolean;
  makeSize?: MakeSize;
};

const PhotoViewer: React.FC<PhotoViewerProps> = ({ makeSize = makeSizeFn, enableSizeBlock }) => {
  const { savedImage, handleDelete, loading } = usePhotoCreator();

  const image = useMemo(
    () => ({
      image: savedImage instanceof File ? savedImage : undefined,
      ...(savedImage instanceof File && { src: URL.createObjectURL(savedImage) }),
      ...(typeof savedImage === 'string' && { src: savedImage }),
    }),
    [savedImage],
  );

  return (
    <Box display="flex" alignItems="center" position="relative">
      <Image sx={{ borderRadius: '100%' }} src={image.src} width={80} height={80} />
      <Box display="flex" flexDirection="column" ml={1.25}>
        <Button
          disabled={loading}
          size="small"
          variant="text"
          color="error"
          endIcon={<Icon name="delete" />}
          sx={{ px: 1.5, width: 'fit-content' }}
          onClick={handleDelete}
        >
          Delete photo
        </Button>
        <When disabledWrapper condition={enableSizeBlock}>
          <Typography fontSize={13} mt={0.5} ml={1.5}>
            {makeSize(image.image?.size)}
          </Typography>
        </When>
      </Box>
    </Box>
  );
};

export default PhotoViewer;
