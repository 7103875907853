import React from 'react';

import Tooltip, { TooltipProps } from 'components/@ui/Tooltip';
import { UseTolltipDebounce, useTolltipDebounce } from '@hooks';

export type TooltipDebounceProps = TooltipProps &
  UseTolltipDebounce & {
    styleloading?: React.CSSProperties;
  };

const TooltipDebounce: React.FC<TooltipDebounceProps> = (props) => {
  const { debounce, error, onOpen, onClose, onFetch, children, styleloading, title, ...other } =
    props;
  const { loading, ...tolltipDebounceState } = useTolltipDebounce({
    onOpen,
    onClose,
    onFetch,
    debounce,
    error,
    title,
  });

  return (
    <Tooltip {...other} {...tolltipDebounceState}>
      {React.Children.only(
        React.cloneElement(children, {
          style: { ...(loading && { cursor: 'progress' }), ...styleloading },
        }),
      )}
    </Tooltip>
  );
};

export default React.memo(TooltipDebounce);
