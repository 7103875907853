import { map, multiply, pipe, range } from 'ramda';

const rangeFn = (newStep: number) => map(pipe(multiply(newStep)));

export const scaleGrid = (extremumMax: number) => {
  const step = Math.round(extremumMax / 5);
  let result;
  switch (true) {
    case step < 100 && step > 10 && step % 10 !== 0:
      const smallRest = step % 10;
      const newSmallStep = step - smallRest;
      result = rangeFn(newSmallStep)(range(0, Math.round(extremumMax / newSmallStep) + 1));
      return result;
    case step < 1000 && step > 100 && step % 100 !== 0:
      const mediumRest = step % 100;
      const newMediumStep = step - mediumRest;
      result = rangeFn(newMediumStep)(range(0, Math.round(extremumMax / newMediumStep) + 1));
      return result;
    case step < 10000 && step > 1000 && step % 1000 !== 0:
      const bigRest = step % 1000;
      const newBigStep = step - bigRest;
      result = rangeFn(newBigStep)(range(0, Math.round(extremumMax / newBigStep) + 1));
      return result;
    default:
      result = rangeFn(step)(range(0, 5));
      result.push(extremumMax);
      return result;
  }
};
