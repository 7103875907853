import React, { useRef, useState } from 'react';
import AvatarEditor, { AvatarEditorProps } from 'react-avatar-editor';
import { Theme } from '@mui/material';

import Box, { BoxProps } from 'components/@ui/Box';
import Button, { ButtonProps } from 'components/@ui/Button';
import Slider, { SliderProps } from 'components/@ui/Slider';
import { clsx, parseSx } from '@helpers';

import { usePhotoCreator } from './Context';

export type PhotoEditorProprs = {
  rootProps?: BoxProps;
  editorWrapperProps?: BoxProps;
  editorProps?: Partial<AvatarEditorProps>;
  sliderProps?: SliderProps;
  buttonProps?: ButtonProps;
};

const PhotoEditor: React.FC<PhotoEditorProprs> = (props) => {
  const editorRef = useRef<AvatarEditor | null>(null);
  const { rootProps, editorWrapperProps, editorProps, sliderProps, buttonProps } = props;
  const [scale, setScale] = useState(1);
  const { onSetNewFile, uploadedFile, loading } = usePhotoCreator();

  const handleChangeScale = (_: Event, newValue: number | number[]) => {
    setScale(newValue as number);
  };

  const handleSave = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const fileName = `${uploadedFile?.name.slice(0, 40) ?? 'noname'}.png`;
      canvas.toBlob((blob) => {
        if (blob) {
          const newFile = new File([blob], fileName, { type: 'image/png' });
          onSetNewFile?.(newFile);
        }
      }, 'image/png');
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={3} {...rootProps}>
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        pb={3}
        mb={-3}
        {...editorWrapperProps}
        sx={clsx({ '&': (theme: Theme) => theme.scrollbar() }, ...parseSx(editorWrapperProps?.sx))}
      >
        <AvatarEditor
          ref={editorRef}
          image={uploadedFile || ''}
          width={280}
          height={280}
          border={[61, 0]}
          borderRadius={140}
          scale={scale}
          {...editorProps}
        />
      </Box>
      <Slider
        value={scale}
        onChange={handleChangeScale}
        min={0.25}
        max={4}
        step={0.01}
        {...sliderProps}
        sx={clsx(
          {
            color: 'text.primary',
            '& .MuiSlider-thumb': {
              height: 21,
              width: 21,
              backgroundColor: 'text.primary',
              '&:focus, &:hover, &.Mui-active': {
                boxShadow: 0,
              },
            },
          },
          ...parseSx(sliderProps?.sx),
        )}
      />
      <Button onClick={handleSave} children="Save photo" disabled={loading} {...buttonProps} />
    </Box>
  );
};

export default PhotoEditor;
