import snakecaseKeys from 'snakecase-keys';
import baseAPI, { APIResponse } from '@api';
import { MainFields, WorkspaceProducts } from '@types';

const apiCredentials = baseAPI();
const api = baseAPI(false);

//--------------------------------------------------------------------------------------------------
// Get Advisor
//--------------------------------------------------------------------------------------------------
export type FetchAdvisorResponse = {
  advisor: {
    id: number;
    avatarUrl: string;
    avatarSmallUrl: string;
    name: string;
    email: string;
    phoneNumber: string;
    calendlyUrl: string;
    genericCalendlyUrl: string;
  };
};

export function fetchAdvisor(projectId: number): APIResponse<FetchAdvisorResponse> {
  return api.get(`/projects/${projectId}/advisor`);
}

//--------------------------------------------------------------------------------------------------
export type FetchProductResponse = WorkspaceProducts.Product;
//--------------------------------------------------------------------------------------------------
// Get Workspace Products
//--------------------------------------------------------------------------------------------------
export function fetchWorkspaceProducts(projectId: number): APIResponse<FetchProductResponse[]> {
  return apiCredentials.get(`/projects/${projectId}/workspaced_products`);
}

//--------------------------------------------------------------------------------------------------
// Get Workspace Product by id
//--------------------------------------------------------------------------------------------------
export function fetchWorkspaceProductById(
  projectId: number,
  vendorId: number,
): APIResponse<{ product: FetchProductResponse }> {
  return apiCredentials.get(`/projects/${projectId}/workspaced_products/${vendorId}`);
}

//--------------------------------------------------------------------------------------------------
// Add vendor to workspace
//--------------------------------------------------------------------------------------------------
export function fetchAddToWorkspace(projectId: number, vendorId: number) {
  return apiCredentials.post(`/projects/${projectId}/workspaced_products`, {
    workspaced_product: { product_id: vendorId },
  });
}

//--------------------------------------------------------------------------------------------------
// Delete vendor from Workspace
//--------------------------------------------------------------------------------------------------
export function fetchDeleteFromWorkspace(projectId: number, vendorId: number) {
  return apiCredentials.delete(`/projects/${projectId}/workspaced_products/${vendorId}`);
}

//--------------------------------------------------------------------------------------------------
// Get Shortlist Products
//--------------------------------------------------------------------------------------------------
export function fetchShortlistProducts(projectId: number): APIResponse<FetchProductResponse[]> {
  return apiCredentials.get(`/projects/${projectId}/shortlisted_products`);
}

//--------------------------------------------------------------------------------------------------
// Get Shortlist Product by id
//--------------------------------------------------------------------------------------------------
export function fetchShortlistProductById(
  projectId: number,
  vendorId: number,
): APIResponse<{ product: FetchProductResponse }> {
  return apiCredentials.get(`/projects/${projectId}/shortlisted_products/${vendorId}`);
}

//--------------------------------------------------------------------------------------------------
// Add vendor to Shortlist
//--------------------------------------------------------------------------------------------------
export function fetchAddToShortlisted(projectId: number, vendorId: number) {
  return apiCredentials.post(`/projects/${projectId}/shortlisted_products`, {
    shortlisted_product: { product_id: vendorId },
  });
}

//--------------------------------------------------------------------------------------------------
// Delete vendor from  Shortlist
//--------------------------------------------------------------------------------------------------
export function fetchDeleteFromShortlist(projectId: number, vendorId: number) {
  return apiCredentials.delete(`/projects/${projectId}/shortlisted_products/${vendorId}`);
}

//--------------------------------------------------------------------------------------------------
// Get countries
//--------------------------------------------------------------------------------------------------
export type FetchCountriesResponse = { countries: MainFields[] };

export function fetchCountries(): APIResponse<FetchCountriesResponse> {
  return api.get('/countries');
}

//--------------------------------------------------------------------------------------------------
// Get Project Goals
//--------------------------------------------------------------------------------------------------
export type FetchProjectGoalsResponse = { characteristics: MainFields<{ goal: string }>[] };

export type FetchProjectGoalsProps = {
  hrisMatch?: boolean;
};

export function fetchProjectGoals(
  params?: FetchProjectGoalsProps,
): APIResponse<FetchProjectGoalsResponse> {
  return api.get('/product_characteristics', { params: snakecaseKeys(params ?? {}) });
}

//--------------------------------------------------------------------------------------------------
// Get Project Scope
//--------------------------------------------------------------------------------------------------
export type FetchProjectScopeResponse = { productModules: MainFields[] };

export function fetchProjectScope(): APIResponse<FetchProjectScopeResponse> {
  return api.get('/product_modules');
}

//--------------------------------------------------------------------------------------------------
// Get Client Roles
//--------------------------------------------------------------------------------------------------
export type FetchClientRolesResponse = { clientRoles: MainFields[] };

export function fetchClientRoles(): APIResponse<FetchClientRolesResponse> {
  return api.get('/client_roles');
}

//--------------------------------------------------------------------------------------------------
