import React from 'react';

import Box, { BoxProps } from 'components/@ui/Box';
import DialogActions from 'components/@ui/DialogActions';

const DialogActionsWrapper: React.FC<BoxProps> = ({ children, ...other }) => {
  return (
    <DialogActions>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        mt={6}
        pb={3}
        px={3}
        {...other}
      >
        {children}
      </Box>
    </DialogActions>
  );
};

export default React.memo(DialogActionsWrapper);
