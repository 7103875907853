import React from 'react';

import ButtonsMenuList from './ButtonsMenuList';
import HeaderButton, { HeaderButtonProps } from './HeaderButton';

const SortMenuButtonUI: React.FC<HeaderButtonProps> = React.memo(({ iconProps, ...other }) => {
  return (
    <HeaderButton
      iconProps={{ name: ButtonsMenuList.sort[0].titleIcon, ...iconProps }}
      children={ButtonsMenuList.sort[0].title}
      {...other}
    />
  );
});

export default React.memo(SortMenuButtonUI);
