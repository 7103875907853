import React, { useMemo } from 'react';

import Icon from 'components/@ui/Icon';
import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import { clsx } from '@helpers';

import Logo from './Logo';
import LogoLabel from './LogoLabel';
import { makeSX, useFetchAdvisor } from '../@hook';

const AdvisorCalendlyButton: React.FC = () => {
  const [{ records }] = useFetchAdvisor();
  const { advisor } = records;

  return useMemo(
    () => (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginLeft={4.5}
        bgcolor="transparent"
      >
        <Box display="flex" alignItems="center">
          <Logo src={advisor.avatarSmallUrl} />
          <LogoLabel label={advisor.name} />
        </Box>
        <a target="_blank" href={advisor.calendlyUrl} rel="noopener noreferrer">
          <Button
            sx={clsx(makeSX.buttonStyle)}
            endIcon={<Icon name="calendar-plus-icon-path" color="inherit" borderRadius={0} />}
          >
            Schedule
          </Button>
        </a>
      </Box>
    ),
    [advisor],
  );
};

export default AdvisorCalendlyButton;
