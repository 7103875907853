import { createSelector } from 'reselect';

import { RootState } from '@redux/store';

const root = (state: RootState) => state.scorecard.evaluate;

const evaluateCreate = createSelector(root, (state) => state.evaluateCreate);
const evaluateChange = createSelector(root, (state) => state.evaluateChange);
const evaluateDelete = createSelector(root, (state) => state.evaluateDelete);

export default { root, evaluateCreate, evaluateChange, evaluateDelete };
