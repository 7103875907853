import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAPI } from '@hooks';

import { advisor, selectors } from '../@slices';

const selector = createSelector(selectors.global, (state) => state.advisor);

const useFetchAdvisor = () => {
  return useAPI(
    useCallback((projectId: number) => advisor.action(projectId), []),
    selector,
  );
};

export default useFetchAdvisor;
