import snakecaseKeys from 'snakecase-keys';
import toSnakeCase from 'to-snake-case';

export type Payload<T> = Record<string, T>;
export type Fields = string | number | boolean | null | undefined | File;

const checkFileExists = <T extends Payload<Fields>>(props: T) => {
  for (const field in props) {
    const value = props[field];
    if (value instanceof File) return true;
  }
  return false;
};

const makeFieldFromWrapKey = (field: string, wrapKey?: string) => {
  const snakeField = toSnakeCase(field);
  return wrapKey ? `${wrapKey}[${snakeField}]` : snakeField;
};

export const makeUserFetchConfig = <T extends Payload<Fields>>(props: T) => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } as const };
  return checkFileExists(props) ? config : undefined;
};

export const makeUserFetchPayload = <T extends Payload<Fields>>(props: T, wrapKey?: string) => {
  if (checkFileExists(props)) {
    const formData = new FormData();
    for (const field in props) {
      const value = props[field];
      const fieldName = makeFieldFromWrapKey(field, wrapKey);
      if (value instanceof File) {
        formData.append(fieldName, value, value.name);
      } else if (value !== undefined && value !== null) {
        formData.append(fieldName, value?.toString());
      }
    }
    return formData;
  } else {
    return wrapKey ? snakecaseKeys({ [wrapKey]: { ...props } }) : snakecaseKeys(props);
  }
};

export const makeUserFetchArguments = <T extends Payload<Fields>>(props: T, wrapKey?: string) => {
  return [makeUserFetchPayload(props, wrapKey), makeUserFetchConfig(props)] as const;
};
