import { permissionCreate } from '@permissions';

const addNewUser = permissionCreate('TEAM_ADD_NEW_USER');
const changeUserRights = permissionCreate('TEAM_CHANGE_USER_RIGHTS');
const removeUser = permissionCreate('TEAM_REMOVE_USER');
const changeGroupUser = permissionCreate('TEAM_CHANGE_GROUP_USER');
const manageGroup = permissionCreate('TEAM_MANAGE_GROUP');
const resendInvitation = permissionCreate('TEAM_RESENT_INVITATION');

export const TEAM = {
  [addNewUser]: addNewUser,
  [changeUserRights]: changeUserRights,
  [removeUser]: removeUser,
  [changeGroupUser]: changeGroupUser,
  [manageGroup]: manageGroup,
  [resendInvitation]: resendInvitation,
};

export default TEAM;
