import { createSlice, createSliceSimple } from '@redux/slices';

import {
  fetchAdvisor,
  fetchCheckedTodoItem,
  fetchSendMeetRequestTodoItem,
  fetchTodoItem,
} from '../../@api';

export const todoItemData = createSlice('FETCH_TODO_ITEM', fetchTodoItem, {
  initialState: { todoItems: [] },
});
export const todoAdvisorData = createSlice('FETCH_ADVISOR_DATA', fetchAdvisor);
export const checkedTodoItem = createSliceSimple('FETCH_CHECKED_TODO_ITEM', fetchCheckedTodoItem);
export const sendMeetRequest = createSliceSimple(
  'FETCH_SEND_MEET_REQUEST_TODO_ITEM',
  fetchSendMeetRequestTodoItem,
);
