import * as yup from 'yup';
import yupPassword from 'yup-password';

yupPassword(yup);

const min = yup.string().trim().min(12);
const minUppercase = yup.string().trim().minUppercase(1);
const minLowercase = yup.string().trim().minLowercase(1);
const minNumbers = yup.string().trim().minNumbers(1);
const minSymbols = yup.string().trim().minSymbols(1);

export { min, minUppercase, minLowercase, minNumbers, minSymbols };

export default yup
  .string()
  .trim()
  .required('This field is required')
  .min(12, 'At least 12 characters long')
  .minUppercase(1, 'Invalid password')
  .minLowercase(1, 'Invalid password')
  .minNumbers(1, 'Invalid password')
  .minSymbols(1, 'Invalid password');
