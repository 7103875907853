import { useCallback } from 'react';

import { useAPI } from '@hooks';

import { updatePassword } from '../@slices';
import { FetchUpdatePasswordBody } from '../user';

const useFetchUpdatePassword = () => {
  return useAPI(useCallback((props: FetchUpdatePasswordBody) => updatePassword.action(props), []));
};

export default useFetchUpdatePassword;
