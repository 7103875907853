import baseAPI, { APIResponse } from '@api';

const apiCredentials = baseAPI();
//-------------------------------------------------------------------------
// Get All Scores
//-------------------------------------------------------------------------
export type CriteriumScoreProps = {
  criteriumEvaluate: number;
  productId: number;
};
export type VendorProps = {
  name: string;
  id: number;
  vendor: string;
  logo: string;
  totalEvaluate: number;
  totalScore: number;
};
export type KeyCriteriaMain = {
  id: number;
  name: string;
  weight: number;
};
export type KeyCriteriaProps = KeyCriteriaMain & {
  scores: CriteriumScoreProps[];
};
export type FetchAllScoresResponse = {
  keyCriteria: KeyCriteriaProps[];
  products: VendorProps[];
};
export function fetchAllScores(projectId: number): APIResponse<FetchAllScoresResponse> {
  return apiCredentials.get(`/projects/${projectId}/scores`);
}
//-------------------------------------------------------------------------
// Get Target Score
//-------------------------------------------------------------------------
export type ScoreProps = {
  client?: string;
  clientAvatar?: string | null;
  hidden: boolean;
  id: number;
  evaluate: number;
};
export type FetchTargetScoreResponse = {
  keyCriteria: {
    criteriumId: number;
    criteriumName: string;
    weightedScore: number;
    project_id: number;
    weight: number;
    rawAverage: number;
  };
  scores: ScoreProps[];
};
export type FetchTargetScoreProps = {
  vendorId: number;
};
export function fetchTargetScore(
  projectId: number,
  criterionId: number,
  props: FetchTargetScoreProps,
): APIResponse<FetchTargetScoreResponse> {
  const { vendorId } = props;
  return apiCredentials.get(`/projects/${projectId}/scores/${criterionId}`, {
    params: { product_id: vendorId },
  });
}
//-------------------------------------------------------------------------
// Hide Client Evaluate
//-------------------------------------------------------------------------
export type FetchHideClientEvaluateProps = {
  hidden: boolean;
  scoreId: number;
};
export function fetchHideClientEvaluate(
  projectId: number,
  criterionId: number,
  props: FetchHideClientEvaluateProps,
): APIResponse<FetchTargetScoreResponse> {
  const { scoreId, hidden } = props;
  return apiCredentials.put(
    `/projects/${projectId}/criteria/${criterionId}/criterium_scores/${scoreId}`,
    { criterium_score: { hidden } },
  );
}
//-------------------------------------------------------------------------
// Get Scores Notes Data
//-------------------------------------------------------------------------
export type FetchScoreNotesProps = {
  vendorId: number;
};
export type FetchScoreNotesResponse = {
  keyCriteria: {
    concerns: number;
    strengths: number;
  };
};
export function fetchScoreNotes(
  projectId: number,
  criterionId: number,
  props: FetchScoreNotesProps,
): APIResponse<FetchScoreNotesResponse> {
  const { vendorId } = props;
  return apiCredentials.get(`/projects/${projectId}/criteria/${criterionId}/product_notes`, {
    params: { product_id: vendorId },
  });
}
//-------------------------------------------------------------------------
