import snakecaseKeys from 'snakecase-keys';

import baseAPI, { APIResponse } from '@api';

import { Team } from '../@types';

const apiCredentials = baseAPI();
//--------------------------------------------------------------------------------------------------
// Get Team Members
//--------------------------------------------------------------------------------------------------
export type FetchTeamMembersResponse = Team.Member[];
export function fetchTeamMembers(projectId: number): APIResponse<FetchTeamMembersResponse> {
  return apiCredentials.get(`/projects/${projectId}/team_members`);
}
//--------------------------------------------------------------------------------------------------
// Edit Team Member
//--------------------------------------------------------------------------------------------------
export type FetchEditTeamMemberBody = { role: Team.Role };
export function fetchEditTeamMember(
  projectId: number,
  memberId: number,
  props: FetchEditTeamMemberBody,
) {
  return apiCredentials.put(`/projects/${projectId}/team_members/${memberId}`, {
    project_client: props,
  });
}
//--------------------------------------------------------------------------------------------------
// Get user pre delete data
//--------------------------------------------------------------------------------------------------
export type FetchMemberPreDeleteDataResponse = { notesCount: number; scoresCount: number };
export function fetchMemberPreDeleteData(
  projectId: number,
  memberId: number,
): APIResponse<FetchMemberPreDeleteDataResponse> {
  return apiCredentials.get(`/projects/${projectId}/team_members/${memberId}/pre_delete`);
}
//--------------------------------------------------------------------------------------------------
// Delete Team Member
//--------------------------------------------------------------------------------------------------
export type FetchDeleteTeamMemberParams = { keepScores: boolean; keepNotes: boolean };
export function fetchDeleteTeamMember(
  projectId: number,
  memberId: number,
  params: FetchDeleteTeamMemberParams,
) {
  return apiCredentials.delete(`/projects/${projectId}/team_members/${memberId}`, {
    ...(params && { params: snakecaseKeys(params) }),
  });
}
//--------------------------------------------------------------------------------------------------
// Get Team Invitations
//--------------------------------------------------------------------------------------------------
export type FetchTeamInvitationsResponse = Team.Invited[];
export function fetchTeamInvitations(projectId: number): APIResponse<FetchTeamInvitationsResponse> {
  return apiCredentials.get(`/projects/${projectId}/invitations`);
}
//--------------------------------------------------------------------------------------------------
// Create Invitations
//--------------------------------------------------------------------------------------------------
export type FetchCreateInvitationsBody = {
  emails: string[];
  role: Team.Role;
  groupIds: number[];
};
export type FetchCreateInvitationsErrorResponse = {
  errors: {
    email: string;
    errors: { email?: string; role?: string }[];
  }[];
};
export function fetchCreateInvitations(
  projectId: number,
  props: FetchCreateInvitationsBody,
): APIResponse<FetchCreateInvitationsErrorResponse> {
  return apiCredentials.post(`/projects/${projectId}/invitations`, {
    invitation: snakecaseKeys(props),
  });
}
//--------------------------------------------------------------------------------------------------
// Edit Invitation
//--------------------------------------------------------------------------------------------------
export type FetchEditInvitationBody = { role: Team.Role };
export function fetchEditInvitation(
  projectId: number,
  memberId: number,
  props: FetchEditInvitationBody,
) {
  return apiCredentials.put(`/projects/${projectId}/invitations/${memberId}`, {
    invitation: props,
  });
}
//--------------------------------------------------------------------------------------------------
// Resend Invitation
//--------------------------------------------------------------------------------------------------
export function fetchResendInvitation(projectId: number, memberId: number) {
  return apiCredentials.put(`/projects/${projectId}/invitations/${memberId}/resend_invitation`);
}
//--------------------------------------------------------------------------------------------------
// Delete Invitation
//--------------------------------------------------------------------------------------------------
export function fetchDeleteInvitation(projectId: number, memberId: number) {
  return apiCredentials.delete(`/projects/${projectId}/invitations/${memberId}`);
}
//--------------------------------------------------------------------------------------------------
