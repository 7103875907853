import { createSelector } from 'reselect';

import { RootState } from '@redux/store';

const root = (state: RootState) => state.scorecard.notes;

const noteEdit = createSelector(root, (state) => state.noteEdit);
const noteCreate = createSelector(root, (state) => state.noteCreate);
const noteDelete = createSelector(root, (state) => state.noteDelete);
const noteLike = createSelector(root, (state) => state.noteLike);
const noteDislike = createSelector(root, (state) => state.noteDislike);

export default { root, noteEdit, noteCreate, noteDelete, noteLike, noteDislike };
