import React, { useCallback } from 'react';

import { ScrollVisibilityApiType } from '../Context';

const useOnWheel = (isPopoverOpen?: boolean) => {
  return useCallback(
    (apiObj: ScrollVisibilityApiType, ev: React.WheelEvent) => {
      const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
      if (isThouchpad || isPopoverOpen) {
        ev.stopPropagation();
        return;
      }
      if (ev.deltaY < 0) {
        apiObj.scrollNext();
      } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
      }
    },
    [isPopoverOpen],
  );
};

export default useOnWheel;
