import React from 'react';

import Box from 'components/@ui/Box';
import { useIsTablet } from '@hooks';

import TableCell from './TableCell';
import { AddToWorkplaceIconButtonWithHOC } from '../VendorComponents';

type WorkspaceAddCellProps = {
  productId: number;
  AddToWorkplaceButtonComponent?: React.FC<any>;
};

const WorkspaceAddCell: React.FC<WorkspaceAddCellProps> = ({
  productId,
  AddToWorkplaceButtonComponent = AddToWorkplaceIconButtonWithHOC,
}) => {
  const isTablet = useIsTablet();

  return (
    <TableCell>
      <Box position="relative" height="100%" zIndex={4}>
        <AddToWorkplaceButtonComponent vendorId={productId} hideToolTip={isTablet} />
      </Box>
    </TableCell>
  );
};

export default React.memo(WorkspaceAddCell);
