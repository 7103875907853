import React, { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { isUndefined } from 'ramda-adjunct';
import { not } from 'ramda';

import Typography from 'components/@ui/Typography';
import { useIsTablet, useMobileTimeline } from '@hooks';

import NavbarButtonsData, { OtherPages } from '../NavbarButtonsData';
import { useNavbar } from '../@hooks';

const Title: React.FC = () => {
  const { isOpenNavbarMobile } = useNavbar();
  const { pathname } = useLocation();
  const [{ records: isOpenTimelineMobile }] = useMobileTimeline();
  const isTablet = useIsTablet();

  const title = useMemo(() => {
    if (not(isTablet)) return undefined;
    if (isOpenNavbarMobile) return 'Menu';
    if (isOpenTimelineMobile) return 'Timeline';
    return [...OtherPages, ...NavbarButtonsData].find(({ links, exact }) =>
      Boolean(matchPath(pathname, { path: links, exact: isUndefined(exact) || exact })),
    )?.name;
  }, [isOpenNavbarMobile, isOpenTimelineMobile, isTablet, pathname]);

  return useMemo(
    () => (
      <Typography
        textAlign="center"
        color="text.primary"
        fontSize={24}
        lineHeight="32px"
        semiBold
        variant="h6"
        component="div"
        sx={{ flexGrow: 1 }}
      >
        {title}
      </Typography>
    ),
    [title],
  );
};

export default Title;
