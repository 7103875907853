import React, { memo } from 'react';
import { Theme } from '@mui/material';

import { clsx, parseSx } from '@helpers';

import Icon, { IconProps } from '../Icon';

export interface IconSelectComponentProps extends IconProps {
  iconStyle?: React.CSSProperties;
}

const IconSelectComponent: React.FC<IconSelectComponentProps> = (props) => {
  const { sx, name = 'dark-down-arrow-path', iconStyle, ...other } = props;
  return (
    <Icon
      name={name}
      sx={clsx(
        {
          '&.icon ': { right: 10, ...iconStyle },
          '&:before': {
            transition: (theme: Theme) => theme.transitions.create(['transform', 'opacity']),
          },
        },
        ...parseSx(sx),
      )}
      {...other}
    />
  );
};
export default memo(IconSelectComponent);
