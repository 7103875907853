import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import { useFetchProject } from '@api/@hooks';

import TodoItem from './TodoItem';
import { VendorChoiceReferenceButton } from '../@components';

const VendorsChoiceReference: React.FC = () => {
  const [{ records }] = useFetchProject();
  const { project } = records;

  return useMemo(
    () => (
      <>
        {project.chosenProduct ? (
          <Box display="flex" flexDirection="column" mt={2}>
            {project.workspacedProducts.map(
              (vendor) =>
                vendor.id === project.chosenProduct?.productId && (
                  <VendorChoiceReferenceButton key={vendor.id} vendor={vendor} />
                ),
            )}
          </Box>
        ) : (
          <></>
        )}
      </>
    ),
    [project.chosenProduct, project.workspacedProducts],
  );
};

const VendorsChoiceReferenceTodoItem: React.FC = () => {
  return (
    <TodoItem>
      <VendorsChoiceReference />
    </TodoItem>
  );
};

export default VendorsChoiceReferenceTodoItem;
