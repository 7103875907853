import React, { useCallback, useMemo } from 'react';
import { Props, ScrollMenu } from 'react-horizontal-scrolling-menu';
import { Theme } from '@mui/material';

import Box, { BoxProps } from 'components/@ui/Box';
import { clsx, makeSxStyles } from '@helpers';
import { Children } from '@types';
import { useIsTablet } from '@hooks';

import { useControlScroll, useOnWheel } from './@hooks';
import { ScrollVisibilityApiType, usePhases } from './Context';

const sxses = makeSxStyles({
  root: {
    '.react-horizontal-scrolling-menu--scroll-container': (theme: Theme) => theme.scrollbar(0),
    userSelect: 'none',
  },
  hasScroll: {
    cursor: 'ew-resize',
  },
  dragging: {
    '& *': { cursor: 'ew-resize !important' },
  },
})();

const PhasesScrollMenuWrapper: React.FC<BoxProps> = (props) => {
  return (
    <Box
      zIndex={2}
      position="relative"
      width={1}
      {...props}
      sx={clsx(sxses.root, { ...props.sx })}
    />
  );
};

export type PhasesScrollMenuProps = Required<Children<Props['children']>>;

const PhasesScrollMenuMobile: React.FC<PhasesScrollMenuProps> = (props) => {
  return <PhasesScrollMenuWrapper px={{ mobile: 2, tablet: 2 }} {...props} />;
};

const PhasesScrollMenuDesktop: React.FC<PhasesScrollMenuProps> = (props) => {
  const { dragState, scrollMenuApiRef, isPopoverOpen } = usePhases();
  const { dragStop, dragStopCb, dragStartCb, dragMove, dragging } = dragState;
  const onWheel = useOnWheel(isPopoverOpen);
  const hasScroll = useControlScroll();

  const handleDrag = useCallback(
    ({ scrollContainer }: ScrollVisibilityApiType) =>
      (ev: React.MouseEvent) =>
        dragMove(ev, (posDiff) => {
          if (isPopoverOpen) return ev.stopPropagation();
          if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += posDiff;
          }
        }),
    [dragMove, isPopoverOpen],
  );

  return useMemo(
    () => (
      <PhasesScrollMenuWrapper
        onMouseLeave={dragStop}
        sx={clsx(hasScroll && sxses.hasScroll, hasScroll && dragging && sxses.dragging)}
      >
        <ScrollMenu
          apiRef={scrollMenuApiRef}
          onWheel={onWheel}
          onMouseDown={dragStartCb}
          onMouseUp={dragStopCb}
          onMouseMove={handleDrag}
          {...props}
        />
      </PhasesScrollMenuWrapper>
    ),
    [
      dragStop,
      hasScroll,
      dragging,
      scrollMenuApiRef,
      onWheel,
      dragStartCb,
      dragStopCb,
      handleDrag,
      props,
    ],
  );
};

const PhasesScrollMenu: React.FC<PhasesScrollMenuProps> = (props) => {
  const isTablet = useIsTablet();
  const Component = isTablet ? PhasesScrollMenuMobile : PhasesScrollMenuDesktop;

  return <Component {...props} />;
};

export default React.memo(PhasesScrollMenu);
