import camelcaseKeys from 'camelcase-keys';
import { captureMessage, showReportDialog } from '@sentry/react';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { values } from 'ramda';
import { isArray, isString } from 'ramda-adjunct';

import { APIErrorResponse, TOKEN_EXPIRED } from '@api';

import { ErrorState } from './createSlice';

const getError = (response?: AxiosResponse<ErrorState, any>) => {
  const data = camelcaseKeys(response?.data || {}, { deep: true });
  if (data.error) return data.error;
  if (!data.errors) return undefined;
  const { errors } = data;
  if (isString(errors)) return errors;
  if (isArray(errors)) return errors[0];
  if (errors.fullMessages) return errors.fullMessages[0];
  return values(errors)[0][0];
};

const make403Error = async (error?: string) => {
  const { statusesToastSliceData } = await import('./statusesToastSlice');
  const [{ records }, updateSliceData] = statusesToastSliceData();
  const { 403: toastId } = records;
  if (toastId) {
    toast.update(toastId, {
      render: error,
      toastId: toastId,
      onClose: () => updateSliceData((state) => ({ ...state, 403: 0 })),
    });
  } else {
    const toastNewId = toast.error(error, {
      onClose: () => updateSliceData((state) => ({ ...state, 403: 0 })),
      closeOnClick: false,
      autoClose: false,
      draggable: false,
      closeButton: false,
    });
    updateSliceData((state) => ({ ...state, 403: toastNewId }));
  }
};

export default async (error?: APIErrorResponse<ErrorState>, omitErrorStatus: number[] = []) => {
  if (!error) return undefined;
  const { response } = error;
  const status = response?.status ?? 0;
  const parseError = getError(response);

  switch (true) {
    case error.message === TOKEN_EXPIRED.name:
      break;
    case status === 403:
      // TODO: clarify the errors for the 403th statuses
      if (!omitErrorStatus.includes(status)) {
        await make403Error(parseError);
      }
      break;
    case status === 422:
      // TODO: clarify the errors to the 422th statuses
      if (!omitErrorStatus.includes(status)) {
        toast.error(parseError);
      }
      break;
    case status >= 400 && status < 500:
      // TODO: add errors for the 400th statuses
      toast.error(parseError);
      break;
    case status >= 500:
      // TODO: clarify the errors for the 500 and 500th statuses
      toast.error(error?.message || `Request failed with status code ${status}`, {
        autoClose: false,
      });
      showReportDialog({
        eventId: captureMessage(
          (response && JSON.stringify(response)) || (error && JSON.stringify(response)),
        ),
      });
      break;
    default:
      break;
  }
};
