import { objectStorage } from '@servises';

const NAVBAR_OPEN_KEY = 'os_is_navbar_collapse' as const;

const navbarCollapse = objectStorage<number, boolean>(NAVBAR_OPEN_KEY);

const get = (userId: number) => {
  return navbarCollapse.get(userId) === true;
};

export default {
  ...navbarCollapse,
  get,
};
