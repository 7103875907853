import React from 'react';

import { TableCellColored, TypographyCellUi } from '../HeatMapViewComponents';
import { useFetchVendors } from './@hooks';

const HeatMapCharacteristicMatch: React.FC = () => {
  const [{ records }] = useFetchVendors();

  return (
    <>
      {records.map((product) => (
        <TableCellColored key={product.id} cellContent={product.characteristicMatch}>
          <TypographyCellUi>{product.characteristicMatch}</TypographyCellUi>
        </TableCellColored>
      ))}
    </>
  );
};

export default HeatMapCharacteristicMatch;
