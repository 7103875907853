import { ComponentsPropsList, Theme } from '@mui/material';
import { Property } from 'csstype';

import Fonts from 'styles/fonts';

type Overrides = {
  ownerState: ComponentsPropsList['MuiButton'];
  theme: Theme;
};

export const MuiButton = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      textTransform: 'initial' as Property.TextTransform,
      fontFamily: Fonts.ProximaNovaBold,
      fontSize: 16,
      lineHeight: 1,
      minWidth: 48,
      fontWeight: 'normal',
      textDecoration: 'initial',
      boxShadow: 'none',
      '&:hover, &:active, &:focus, &:disabled': {
        boxShadow: 'none',
      },
    },
    containedPrimary: ({ theme }: Overrides) => ({
      '&:active': {
        backgroundColor: theme.palette.actionPrimary.active1,
      },
      '&:disabled': {
        backgroundColor: theme.palette.actionPrimary.disabled1,
        color: theme.palette.primary.contrastText,
      },
    }),
    containedError: ({ theme }: Overrides) => ({
      '&:active': {
        backgroundColor: theme.palette.actionError.active1,
      },
      '&:disabled': {
        backgroundColor: theme.palette.actionError.disabled1,
        color: theme.palette.error.contrastText,
      },
    }),
    outlinedPrimary: ({ theme }: Overrides) => ({
      borderColor: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.actionPrimary.hover1,
      },
      '&:active': {
        backgroundColor: theme.palette.actionPrimary.active2,
        color: theme.palette.actionPrimary.active1,
        borderColor: theme.palette.actionPrimary.active1,
      },
      '&:disabled': {
        color: theme.palette.actionPrimary.disabled1,
        borderColor: theme.palette.actionPrimary.disabled1,
      },
    }),
    textPrimary: ({ theme }: Overrides) => ({
      '&:hover': {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.actionPrimary.hover1,
      },
      '&:active': {
        backgroundColor: theme.palette.actionPrimary.active2,
        color: theme.palette.actionPrimary.active1,
      },
      '&:disabled': {
        color: theme.palette.actionPrimary.disabled1,
      },
    }),
    textError: ({ theme }: Overrides) => ({
      '&:hover': {
        backgroundColor: theme.palette.actionError.hover1,
      },
      '&:active': {
        backgroundColor: theme.palette.actionError.active2,
      },
      '&:disabled': {
        color: theme.palette.actionError.disabled2,
      },
    }),
    textInherit: ({ theme }: Overrides) => ({
      '&:active': {
        backgroundColor: theme.palette.actionPrimary.hover1,
      },
    }),
  },
};
