import React, { useCallback, useMemo, useState } from 'react';
import { useUnmount, useUpdateEffect } from 'react-use';

import { useSetInterval } from '@hooks';
import { createContext } from '@helpers';
import { Children } from '@types';

export type TimeConversionFn<T> = (time: number) => T;

const getReturnValues = <T extends React.ReactNode>(time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  const minutesParse = minutes < 10 ? `0${minutes}` : minutes;
  const secondsParse = seconds < 10 ? `0${seconds}` : seconds;
  return `${minutesParse}:${secondsParse}` as T;
};

export type UseCountdown<T = React.ReactNode> = {
  ready: boolean;
  start: (time?: number) => void;
  stop: () => void;
  pause: () => void;
  resume: () => void;
  paused: boolean;
  time: T;
  rawTime: number;
};

const useCountdown = <T extends React.ReactNode>(
  timeCountdown: number = 59,
  timeConversionFn?: TimeConversionFn<T>,
) => {
  const [ready, setReady] = useState(false);
  const [paused, setPaused] = useState(false);
  const [time, setTime] = useState(timeCountdown);
  const { setInterval, clearInterval } = useSetInterval();

  const tick = useCallback(() => setTime((state) => state - 1), []);

  const start = useCallback(
    (newTime?: number) => {
      clearInterval();
      setReady(false);
      setTime(newTime ?? timeCountdown);
      setInterval(() => tick(), 1000);
    },
    [clearInterval, setInterval, tick, timeCountdown],
  );

  const stop = useCallback(() => {
    clearInterval();
    setTime(timeCountdown);
  }, [clearInterval, timeCountdown]);

  const pause = useCallback(() => {
    setPaused(true);
    clearInterval();
  }, [clearInterval]);

  const resume = useCallback(
    (newTime?: number) => {
      setPaused(false);
      start(newTime ?? time);
    },
    [start, time],
  );

  useUpdateEffect(() => {
    if (time < 1) {
      setReady(true);
      clearInterval();
      setTime(0);
    }
  }, [time]);

  useUnmount(() => {
    setReady(false);
  });

  return useMemo<UseCountdown<T>>(
    () => ({
      ready,
      start,
      stop,
      pause,
      resume,
      paused,
      time: timeConversionFn ? timeConversionFn(time) : getReturnValues(time),
      rawTime: time,
    }),
    [ready, start, stop, pause, resume, paused, timeConversionFn, time],
  );
};

export default useCountdown;

export const [Provider, useCountdownState] = createContext<UseCountdown>();

type CountdownProviderProps<T> = Children & {
  timeCountdown?: number;
  timeConversionFn?: TimeConversionFn<T>;
};

export const CountdownProvider = <T extends React.ReactNode>(props: CountdownProviderProps<T>) => {
  const { timeCountdown, timeConversionFn, children } = props;
  const countdownState = useCountdown(timeCountdown, timeConversionFn);
  return <Provider value={countdownState}>{children}</Provider>;
};
