import React from 'react';

import { TableHead as MuiTableHead, TableHeadProps } from '@mui/material';

// @ts-ignore
const TableHead: React.FC<TableHeadProps> = React.forwardRef((props, ref) => {
  return <MuiTableHead {...props} ref={ref}></MuiTableHead>;
});

export default React.memo(TableHead);
