import { TimeLineBar } from '../@types';

export default <T extends TimeLineBar.Phase['finishDate']>(
  phases: TimeLineBar.Phase[],
  phaseId: number,
  finishDate: T,
) => {
  return phases.map((phase) => ({
    ...phase,
    ...(phase.id === phaseId && { finishDate }),
  }));
};
