import React from 'react';
import MuiSlider, { SliderProps as MuiSliderProps } from '@mui/material/Slider';

export interface SliderProps extends MuiSliderProps {}

// @ts-ignore
const Slider: React.FC<SliderProps> = React.forwardRef((props, ref) => (
  <MuiSlider ref={ref} {...props} />
));

export default React.memo(Slider);
