import { User } from '@types';

import owner from './owner';
import admin from './admin';
import client from './client';

export default {
  [User.ROLES.owner]: owner,
  [User.ROLES.admin]: admin,
  [User.ROLES.client]: client,
};
