import { Draft, createSlice as createSliceToolkit } from '@reduxjs/toolkit';

export const createSliceData = <T, N extends string = string>(name: N, initialState: T) => {
  return createSliceToolkit({
    name,
    initialState: { records: initialState },
    reducers: {
      FULFILLED: (state, action: { payload: Draft<T>; type: string }) => {
        state.records = action.payload;
      },
    },
  });
};

export default createSliceData;

class Wrapper<T, N extends string = string> {
  wrapped = (name: N, initialState: T) => createSliceData<T, N>(name, initialState);
}

export type CreateSliceDataReturn<T> = ReturnType<Wrapper<T>['wrapped']>;
