import React, { Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import AppLayout from '@layout/AppLayout';
import Loader from 'components/@common/Loader';
import { LinkedinCallback } from 'components/@common/Auth';

import Route, { RouteRole } from './Route';
import { NoMatchPageComponent } from './@components';
import { ROUTES } from './config';
import { routes } from './routes.list';

const RedirectRoutes = {
  BuildRequirements: () => <Redirect to={ROUTES.auth.buildRequirements.startMatching} />,
  Login: () => <Redirect to={ROUTES.auth.login.email} />,
};

const Routes: React.FC = () => (
  <AppLayout>
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={ROUTES.auth.linkedin} component={LinkedinCallback} />
        {routes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
        <Route
          exact
          path={ROUTES.startMatching}
          routeRole={RouteRole.public}
          component={RedirectRoutes.BuildRequirements}
        />
        <Route
          exact
          path={ROUTES.login}
          routeRole={RouteRole.public}
          component={RedirectRoutes.Login}
        />
        <Route path="*" component={NoMatchPageComponent} />
      </Switch>
    </Suspense>
  </AppLayout>
);

export default Routes;
