import React from 'react';

import NoMatchPageComponent from './NoMatchPageComponent';

export interface FallbackComponentProps {
  redirectToNoMatchPage?: boolean;
  fallback?: React.ElementType;
}

export const FallbackComponent: React.FC<FallbackComponentProps> = (props) => {
  const { fallback: Fallback, redirectToNoMatchPage } = props;
  if (Fallback && !redirectToNoMatchPage) {
    return <Fallback />;
  } else {
    return <NoMatchPageComponent />;
  }
};

export default React.memo(FallbackComponent);
