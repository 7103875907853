import React from 'react';
import { useHistory } from 'react-router-dom';

import IconButton, { IconButtonProps } from 'components/@ui/IconButton';
import { ROUTES } from '@routes/config';
import { clsx, parseSx } from '@helpers';

type MobileLinkToVendorProps = Partial<IconButtonProps> & {
  vendorId?: number;
};

const MobileLinkToVendor: React.FC<MobileLinkToVendorProps> = ({ vendorId, ...other }) => {
  const history = useHistory();

  return (
    <IconButton
      to={{ pathname: ROUTES.vendorPageRoot + vendorId, state: history.location.pathname }}
      iconName="arrow-box-right-path"
      {...other}
      sx={clsx({ width: 48, height: 48, borderRadius: 1 }, ...parseSx(other.sx))}
    />
  );
};

export default React.memo(MobileLinkToVendor);
