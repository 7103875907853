import { useCallback, useMemo } from 'react';
import { createSelector } from 'reselect';

import { useDispatchSetUser } from 'components/@common/Auth';
import { useAPI, useUser } from '@hooks';

import { selectors, updateUser } from '../@slices';
import { FetchUpdateUserBody } from '../user';

const selector = createSelector(selectors.user, (state) => state.updateUser);

const useFetchUpdateUser = () => {
  const { id: userId } = useUser();

  const [data, fetch] = useAPI(
    useCallback((props: FetchUpdateUserBody) => updateUser.action(props, userId), [userId]),
    selector,
  );

  const dispatchSetUser = useDispatchSetUser();

  const fetchUpdateUser = useCallback(
    async (props: FetchUpdateUserBody) => {
      const response = await fetch(props);
      dispatchSetUser(response, false);
      return response;
    },
    [dispatchSetUser, fetch],
  );

  return useMemo(() => [data, fetchUpdateUser] as const, [data, fetchUpdateUser]);
};

export default useFetchUpdateUser;
