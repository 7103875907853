import snakecaseKeys from 'snakecase-keys';

import baseAPI from '@api';

const api = baseAPI(false);

//--------------------------------------------------------------------------------------------------
// Recovery Password Send Email
//--------------------------------------------------------------------------------------------------
export function fetchSendEmail(email: string) {
  return api.post('/client_auth/password ', { email });
}
//--------------------------------------------------------------------------------------------------
// Recovery Password Send Code
//--------------------------------------------------------------------------------------------------
export type FetchSendCodeProps = { email: string; verificationCode: string };
export function fetchSendCode(props: FetchSendCodeProps) {
  return api.post('/client_auth/validate_verification_code', snakecaseKeys(props));
}
//--------------------------------------------------------------------------------------------------
// Recovery Password Update Password
//--------------------------------------------------------------------------------------------------
export type FetchUpdatePasswordProps = { email: string; password: string };
export function fetchUpdatePassword(props: FetchUpdatePasswordProps) {
  return api.put('/client_auth/password', props);
}
//--------------------------------------------------------------------------------------------------
