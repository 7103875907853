import React from 'react';

import Icon from 'components/@ui/Icon';
import Typography from 'components/@ui/Typography';

type TooltipTextProps = {
  type?: TooltipTextObject;
};

export enum TooltipTextObject {
  'skipPro' = 'skipPro',
  'showDemoAgain' = 'showDemoAgain',
  'vendorProposal' = 'vendorProposal',
  'emptyWorkspace' = 'emptyWorkspace',
  'needRequestDemo' = 'needRequestDemo',
}

const TooltipText: React.FC<TooltipTextProps> = ({ type }) => {
  switch (type) {
    case 'skipPro':
      return (
        <>
          This action will mark all PRO to-does as done. You can undo this action any time, by
          pressing <Icon name="undo-icon-path" fontSize="11px" width={11} height={11} /> Undo
        </>
      );
    case 'showDemoAgain':
      return (
        <Typography maxWidth={80} mx={1.25} my={0.25}>
          Press <Icon name="undo-icon-path" fontSize="11px" width={11} height={11} /> Undo to show
          demo again
        </Typography>
      );
    case 'vendorProposal':
      return (
        <Typography maxWidth={110} mx={1.25} my={0.25}>
          Once vendors prepare a proposal, you will receive email. Usually it takes 1-3 days.
        </Typography>
      );
    case 'emptyWorkspace':
      return (
        <Typography maxWidth={110} mx={1.25} my={0.25}>
          You need to add vendors to your workspace
        </Typography>
      );
    case 'needRequestDemo':
      return (
        <Typography maxWidth={110} mx={1.25} my={0.25}>
          Finish requesting demos to activate
        </Typography>
      );
    default:
      return null;
  }
};

export default React.memo(TooltipText);
