import React from 'react';
import { LinkedInCallback as LinkedInCallbackOauth } from 'react-linkedin-login-oauth2';

import { AppLayoutWrapper } from '@layout/AppLayout';

const LinkedinCallback: React.FC = () => {
  return (
    <AppLayoutWrapper>
      <LinkedInCallbackOauth />
    </AppLayoutWrapper>
  );
};

export default LinkedinCallback;
