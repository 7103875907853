import React from 'react';

import Box from 'components/@ui/Box';
import { clsx } from '@helpers';

import { Badge, Button } from '../@components';
import { makeSxToDoButton, ToDoStartIconUi } from '../ToDo';
import { TimeLineBar } from '../@types';

const sxesToDoButton = makeSxToDoButton();

type ToDosProps = {
  todos: TimeLineBar.ToDo[];
};
const ToDos: React.FC<ToDosProps> = ({ todos }) => {
  return (
    <>
      {todos.map((todo, index) => (
        <Box key={todo.id} pr={1} py={1.5} {...(index === 0 && { pl: 0 })}>
          <Badge status={todo.status} IconProps={{ right: -2, top: -4 }}>
            <Button
              variant="contained"
              status={todo.status}
              sx={clsx(sxesToDoButton.button, sxesToDoButton[todo.status])}
              startIcon={<ToDoStartIconUi {...todo} />}
              name={todo.name}
            >
              {todo.name}
            </Button>
          </Badge>
        </Box>
      ))}
    </>
  );
};

export default React.memo(ToDos);
