import React from 'react';

import Buttons from './Buttons';
import Wrapper from './Wrapper';

const NavbarBody: React.FC = () => {
  return (
    <Wrapper>
      <Buttons />
    </Wrapper>
  );
};

export default NavbarBody;
