import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import Collapse from 'components/@ui/Collapse';
import { Children } from '@types';
import { useIsTablet } from '@hooks';

import { usePhase } from '../Phase';
import { usePhases } from '../Phases';

const ToDosWrapper: React.FC<Children> = ({ children }) => {
  const { isSelected } = usePhase();
  const { emptySpase } = usePhases();
  const isTablet = useIsTablet();

  return useMemo(
    () => (
      <Collapse orientation={isTablet ? 'vertical' : 'horizontal'} in={isSelected}>
        <Box
          display="flex"
          alignItems="center"
          flexDirection={{ mobile: 'column', laptop: 'row' }}
          minWidth={emptySpase > 0 ? emptySpase : 0}
          height="100%"
        >
          {children}
        </Box>
      </Collapse>
    ),
    [children, emptySpase, isSelected, isTablet],
  );
};

export default ToDosWrapper;
