import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAPI, useUser } from '@hooks';

import { project, selectors } from '../@slices';

const selector = createSelector(selectors.root, (state) => state.project);
const { action } = project;

const useFetchProject = () => {
  const { projectId } = useUser();

  return useAPI(
    useCallback(() => action(projectId ?? 0), [projectId]),
    selector,
  );
};

export default useFetchProject;
