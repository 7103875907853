import React, { useMemo, useState } from 'react';

import Icon from 'components/@ui/Icon';
import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import { Product } from '@types';
import { clsx } from '@helpers';

import Logo from './Logo';
import LogoLabel from './LogoLabel';
import { makeSX } from '../@hook';
import { useToDoItems } from '../Context';
import { useToDoItem } from '../TodoItem';

type VendorProposalButtonProps = {
  vendor: Product.Product;
};

const VendorChoiceProposalButton: React.FC<VendorProposalButtonProps> = ({ vendor }) => {
  const { todoItem } = useToDoItem();
  const { sendMeetRequest } = useToDoItems();
  const [isDisabled, setIsDisabled] = useState(
    Boolean(todoItem.proposals.find((proposal) => proposal.meetableId === vendor.id)),
  );

  return useMemo(
    () => (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginLeft={4.5}
        bgcolor="transparent"
      >
        <Box display="flex" alignItems="center">
          <Logo src={vendor.logoSmallUrl} />
          <LogoLabel label={vendor.name} />
        </Box>
        <Button
          sx={clsx(makeSX.buttonStyle, { color: 'blue.main' })}
          endIcon={
            <Icon
              name={isDisabled ? 'proposal-sent-icon-path' : 'send-mail-icon-path'}
              borderRadius={0}
              color="inherit"
            />
          }
          onClick={sendMeetRequest(todoItem.id, vendor.id, 'Product', setIsDisabled)}
          disabled={isDisabled}
        >
          {isDisabled ? 'Check email' : 'Request proposal'}
        </Button>
      </Box>
    ),
    [vendor.logoSmallUrl, vendor.name, vendor.id, isDisabled, sendMeetRequest, todoItem.id],
  );
};

export default VendorChoiceProposalButton;
