import { combineReducers } from 'redux';

import { CA, P, createS, createSS } from '@redux/slices';

import {
  fetchCreateUser,
  fetchSendNewEmail,
  fetchUpdateEmail,
  fetchUpdatePassword,
  fetchUpdateUser,
} from '../user';

export const createUser = createS(CA(P('CREATE_USER')), fetchCreateUser);
export const updateUser = createS(CA(P('UPDATE_USER')), fetchUpdateUser);
export const sendNewEmail = createSS(CA(P('SEND_NEW_EMAIL')), fetchSendNewEmail);
export const updateEmail = createSS(CA(P('UPDATE_EMAIL')), fetchUpdateEmail);
export const updatePassword = createSS(CA(P('UPDATE_PASSWORD')), fetchUpdatePassword);

export default combineReducers({
  createUser: createUser.reducer,
  updateUser: updateUser.reducer,
  updateEmail: updateEmail.reducer,
});
