import {
  getBarPosition,
  getBarWidth,
  getExtremumMax,
  getExtremumMin,
  getExtremumSize,
} from './globalDiagramsHelpers';

type GetBarProps = {
  maxAcceptable: number;
  minAcceptable: number;
  currentHeadcount: number;
  futureHeadcount: number;
  extremumMax: number;
  extremumMin: number;
  maxDiagramValue: number;
  isOverMax: boolean;
};

export const getCompanyBarWidth = (
  companyHeadcount: number,
  futureHeadcount: number,
  acceptableMinSize: number,
  acceptableMaxSize: number,
) => {
  // TODO check logic in future
  const min =
    acceptableMaxSize > companyHeadcount
      ? acceptableMinSize > companyHeadcount
        ? acceptableMinSize
        : companyHeadcount
      : 0;
  // TODO check logic in future
  const max =
    companyHeadcount < acceptableMaxSize
      ? acceptableMinSize < futureHeadcount
        ? futureHeadcount < acceptableMaxSize
          ? futureHeadcount
          : acceptableMaxSize
        : 0
      : 0;
  return max - min;
};

export const getDataGrid = (
  extremumMin: number,
  extremumMax: number,
  acceptableIdealMin: number,
  acceptableIdealMax: number,
  futureHeadcount: number,
  companyHeadcount: number,
  companyPosition: string,
) => [
  { number: extremumMin, left: 0 },
  {
    number: companyHeadcount,
    left: companyPosition,
  },
  {
    number: Math.round((acceptableIdealMax + acceptableIdealMin) / 2),
    left: `${
      (Math.round((acceptableIdealMax + acceptableIdealMin) / 2 - extremumMin) /
        (extremumMax - extremumMin)) *
      100
    }%`,
    acceptableIdeal: true,
  },
  {
    number: futureHeadcount,
    left: getBarPosition(futureHeadcount, extremumMin, extremumMax - extremumMin),
  },
  { number: extremumMax, left: '100%' },
];

const getWidthOfBlock = (isOverMax: boolean) => (isOverMax ? 19 : 20);

const getWidthOfCoupleBlocks = (isOverMax: boolean) => (isOverMax ? 38 : 40);

const getWidthOfBlocks = (isCurrentExtremumMin: boolean, isOverMax: boolean) => {
  if (isCurrentExtremumMin) {
    return getWidthOfCoupleBlocks(isOverMax);
  } else {
    return getWidthOfBlock(isOverMax);
  }
};

const getCompanyWidth = (isOverMax: boolean) => (isOverMax ? 43 : 60);

export const getBarPositionSizeMatch = ({
  minAcceptable,
  currentHeadcount,
  futureHeadcount,
  extremumMax,
  extremumMin,
  maxDiagramValue,
  isOverMax,
}: GetBarProps) => {
  const isCurrentExtremumMin = currentHeadcount === extremumMin;
  const isFutureExtremumMax = futureHeadcount === extremumMax;
  const companyWidth = getCompanyWidth(isOverMax);
  const widthOfBlock = getWidthOfBlock(isOverMax);
  const widthOfCoupleBlocks = getWidthOfCoupleBlocks(isOverMax);
  const widthOfBlockAndCompany = isOverMax ? 62 : 80;

  if (maxDiagramValue <= minAcceptable) {
    return 81;
  }

  if (minAcceptable < currentHeadcount) {
    switch (true) {
      case isFutureExtremumMax:
        return (
          widthOfCoupleBlocks * ((minAcceptable - extremumMin) / (currentHeadcount - extremumMin))
        );
      default:
        return widthOfBlock * ((minAcceptable - extremumMin) / (currentHeadcount - extremumMin));
    }
  }
  if (minAcceptable >= futureHeadcount) {
    return isCurrentExtremumMin
      ? companyWidth +
          widthOfCoupleBlocks *
            ((minAcceptable - futureHeadcount) / (extremumMax - futureHeadcount))
      : widthOfBlockAndCompany +
          widthOfBlock * ((minAcceptable - futureHeadcount) / (extremumMax - futureHeadcount));
  }

  if (minAcceptable >= currentHeadcount && minAcceptable < futureHeadcount) {
    switch (true) {
      case isFutureExtremumMax && isCurrentExtremumMin:
        return (
          (isOverMax ? 81 : 100) *
          ((minAcceptable - currentHeadcount) / (futureHeadcount - currentHeadcount))
        );
      case isCurrentExtremumMin:
        return (
          companyWidth * ((minAcceptable - currentHeadcount) / (futureHeadcount - currentHeadcount))
        );
      default:
        return (
          widthOfBlock +
          companyWidth * ((minAcceptable - currentHeadcount) / (futureHeadcount - currentHeadcount))
        );
    }
  }

  return 0;
};

export const getBarWidthSizeMatch = ({
  maxAcceptable,
  minAcceptable,
  currentHeadcount,
  futureHeadcount,
  extremumMax,
  extremumMin,
  maxDiagramValue,
  isOverMax,
}: GetBarProps) => {
  const isCurrentExtremumMin =
    currentHeadcount === extremumMin ||
    futureHeadcount === extremumMax ||
    (isOverMax && futureHeadcount === maxDiagramValue);
  const widthOfBlocks = getWidthOfBlocks(isCurrentExtremumMin, isOverMax);
  const companyWidth = getCompanyWidth(isOverMax);

  const widthBetweenCurrentAndFuture = futureHeadcount - currentHeadcount;
  const widthFutureAndMinAcceptable = futureHeadcount - minAcceptable;

  const widthBetweenCurrentAndExtremumMin = currentHeadcount - extremumMin;
  const widthBetweenCurrentAndMinAcceptable = currentHeadcount - minAcceptable;

  const widthMaxAcceptableAndMinAcceptable =
    (maxAcceptable > maxDiagramValue ? maxDiagramValue : maxAcceptable) - minAcceptable;
  const widthMaxAcceptableAndFuture =
    (maxAcceptable > maxDiagramValue && isOverMax ? maxDiagramValue + 1 : maxAcceptable) -
    futureHeadcount;
  const widthMaxAcceptableAndCurrent = maxAcceptable - currentHeadcount;

  const widthExtremumMaxAndFuture =
    (isOverMax ? maxDiagramValue + 1 : extremumMax) - futureHeadcount;
  const addWidthAfterMaxDiagramValue = maxAcceptable > maxDiagramValue && isOverMax ? 19 : 0;

  if (maxDiagramValue <= minAcceptable) {
    return 19;
  }

  if (minAcceptable < currentHeadcount && maxAcceptable > futureHeadcount)
    return (
      companyWidth +
      widthOfBlocks * (widthMaxAcceptableAndFuture / widthExtremumMaxAndFuture) +
      widthOfBlocks * (widthBetweenCurrentAndMinAcceptable / widthBetweenCurrentAndExtremumMin) +
      addWidthAfterMaxDiagramValue
    );

  if (minAcceptable >= futureHeadcount)
    return (
      widthOfBlocks * (Math.abs(widthMaxAcceptableAndMinAcceptable) / widthExtremumMaxAndFuture) +
      addWidthAfterMaxDiagramValue
    );

  if (maxAcceptable <= currentHeadcount)
    return (
      widthOfBlocks *
      (Math.abs(widthMaxAcceptableAndMinAcceptable) / widthBetweenCurrentAndExtremumMin)
    );

  if (minAcceptable >= currentHeadcount && maxAcceptable <= futureHeadcount) {
    if (
      currentHeadcount === extremumMin &&
      (futureHeadcount === extremumMax || futureHeadcount === maxDiagramValue)
    )
      return (
        ((isOverMax ? 81 : 100) * Math.abs(widthMaxAcceptableAndMinAcceptable)) /
        widthBetweenCurrentAndFuture
      );
    return (
      (companyWidth * Math.abs(widthMaxAcceptableAndMinAcceptable)) / widthBetweenCurrentAndFuture
    );
  }
  if (
    minAcceptable >= currentHeadcount &&
    minAcceptable <= futureHeadcount &&
    maxAcceptable > futureHeadcount
  )
    return (
      (widthFutureAndMinAcceptable / widthBetweenCurrentAndFuture) * companyWidth +
      widthOfBlocks * (widthMaxAcceptableAndFuture / widthExtremumMaxAndFuture) +
      addWidthAfterMaxDiagramValue
    );

  if (
    minAcceptable < currentHeadcount &&
    maxAcceptable >= currentHeadcount &&
    maxAcceptable <= futureHeadcount
  )
    return (
      (widthMaxAcceptableAndCurrent / widthBetweenCurrentAndFuture) * companyWidth +
      widthOfBlocks * (widthBetweenCurrentAndMinAcceptable / widthBetweenCurrentAndExtremumMin)
    );

  return 0;
};

export const sizeMatchScalabilityModalDiagram = (
  acceptableMaxSize: number,
  acceptableMinSize: number,
  acceptableIdealMin: number,
  acceptableIdealMax: number,
  futureHeadcount: number,
  companyHeadcount: number,
) => {
  const extremumMax = getExtremumMax(acceptableMaxSize, futureHeadcount);
  const extremumMin = getExtremumMin(acceptableMinSize, companyHeadcount);
  const extremumSize = getExtremumSize(extremumMax, extremumMin);

  const acceptableSize = acceptableMaxSize - acceptableMinSize;
  const acceptableBarWidth = getBarWidth(acceptableSize, extremumSize);
  const acceptableBarPosition = getBarPosition(acceptableMinSize, extremumMin, extremumSize);
  const acceptableBarTextWidth =
    (acceptableMaxSize - Math.round((acceptableIdealMax + acceptableIdealMin) / 2)) / extremumSize;

  const acceptableIdealVendorSize = acceptableIdealMax - acceptableIdealMin;
  const acceptableIdealBarWidth = getBarWidth(acceptableIdealVendorSize, acceptableSize);
  const acceptableIdealPosition = getBarPosition(
    acceptableIdealMin,
    acceptableMinSize,
    acceptableSize,
  );

  const companyWidth = getBarWidth(futureHeadcount - companyHeadcount, extremumSize);
  const companyPosition = getBarPosition(companyHeadcount, extremumMin, extremumSize);

  const companyAvailableBar = getBarWidth(
    getCompanyBarWidth(companyHeadcount, futureHeadcount, acceptableMinSize, acceptableMaxSize),
    futureHeadcount - companyHeadcount,
  );
  const errorBarWidthMin = getBarWidth(
    acceptableMinSize - companyHeadcount,
    futureHeadcount - companyHeadcount,
  );
  const errorBarWidthMax = getBarWidth(
    futureHeadcount - acceptableMaxSize,
    futureHeadcount - companyHeadcount,
  );
  const errorBarPositionMax = getBarPosition(
    acceptableMaxSize > companyHeadcount ? acceptableMaxSize : companyHeadcount,
    companyHeadcount,
    futureHeadcount - companyHeadcount,
  );

  const dataGrid = getDataGrid(
    extremumMin,
    extremumMax,
    acceptableIdealMin,
    acceptableIdealMax,
    futureHeadcount,
    companyHeadcount,
    companyPosition,
  );

  return {
    acceptableSize,
    acceptableIdealVendorSize,
    extremumMax,
    extremumMin,
    extremumSize,
    acceptableBarWidth,
    acceptableIdealBarWidth,
    acceptableBarPosition,
    acceptableIdealPosition,
    companyWidth,
    errorBarWidthMin,
    errorBarWidthMax,
    errorBarPositionMax,
    dataGrid,
    companyPosition,
    acceptableBarTextWidth,
    companyAvailableBar,
  };
};
