import React from 'react';
import { useMount } from 'react-use';

import Image from 'components/@ui/Image';
import { ButtonProps } from 'components/@ui/Button';

import msalInstance from './msalInstance';
import ButtonSocial from '../ButtonSocial';

const ButtonMicrosoft: React.FC<ButtonProps> = (props) => {
  useMount(async () => {
    await msalInstance.initialize();
  });

  return (
    <ButtonSocial
      startIcon={<Image width={20} height={20} src={require('../assets/microsoft-icon.png')} />}
      children="Sign in with Microsoft"
      {...props}
    />
  );
};

export default React.memo(ButtonMicrosoft);
