import React from 'react';
import { Theme } from '@mui/material';

import Dialog from 'components/@ui/Dialog';
import When from 'components/@main/When';
import IconButton from 'components/@ui/IconButton';
import Box from 'components/@ui/Box';
import { useIsMobile, useIsTablet } from '@hooks';
import { Children } from '@types';

type DialogWrapperProps = {
  openModal: boolean;
  closeModal: () => void;
};

const DialogWrapper: React.FC<DialogWrapperProps & Children> = ({
  openModal,
  closeModal,
  children,
}) => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  return (
    <Dialog
      open={openModal}
      PaperProps={{
        sx: {
          width: isMobile ? 'fit-content' : '100%',
          borderRadius: 4,
          padding: isTablet ? 1.5 : 3,
          maxWidth: 864,
          position: 'relative',
          m: isTablet ? 2 : 3,
          backgroundColor: isTablet ? 'transparent' : 'secondary.main',
          '&': (theme) => theme.scrollbar(isTablet ? 0 : 8),
        },
      }}
    >
      <When
        disabledWrapper
        condition={isTablet}
        fallback={
          <>
            <IconButton
              iconName="cross"
              sx={{ position: 'absolute', top: 12, right: 12 }}
              onClick={closeModal}
            />
            {children}
          </>
        }
      >
        <Box display="flex" justifyContent="flex-end">
          <Box bgcolor="secondary.main" width="fit-content" borderRadius={20}>
            <IconButton onClick={closeModal} iconName="cross" />
          </Box>
        </Box>
        <Box
          mt={1.5}
          bgcolor="secondary.main"
          borderRadius={4}
          p={1.5}
          overflow="auto"
          sx={{ '&': (theme: Theme) => theme.scrollbar(0) }}
        >
          {children}
        </Box>
      </When>
    </Dialog>
  );
};

export default React.memo(DialogWrapper);
