import React from 'react';

import Typography from 'components/@ui/Typography';
import Box from 'components/@ui/Box';
import Checkbox from 'components/@ui/Checkbox';
import { useRequestDemoModal } from './@hooks';

type DemoRequestVendorBoxProps = {
  name: string;
  request: boolean;
  touched: boolean;
  productId: number;
  isDisabled: boolean;
};

const DemoRequestVendorBox: React.FC<DemoRequestVendorBoxProps> = ({
  name,
  request,
  touched,
  productId,
  isDisabled,
}) => {
  const { handlerCheckBox } = useRequestDemoModal();

  return (
    <Box display="flex" mt={1.5}>
      <Typography width={145} semiBold>
        {name}
      </Typography>
      <Box width={108} pl={0.25}>
        <Checkbox
          disabled={isDisabled}
          checked={request}
          onChange={handlerCheckBox(productId, 'requestDemo')}
        />
      </Box>
      <Box width={98} pl={0.25}>
        <Checkbox checked={touched} onChange={handlerCheckBox(productId, 'alreadyInTouch')} />
      </Box>
    </Box>
  );
};

export default DemoRequestVendorBox;
