import React, { useMemo } from 'react';
import { Theme } from '@mui/material';

import Box, { BoxProps } from 'components/@ui/Box';
import { clsx, parseSx } from '@helpers';

import { useRequestDemoModal } from './@hooks';
import { MODAL_STEP } from './Context';

const BoxPagination: React.FC<BoxProps> = React.memo(({ sx, ...other }) => {
  return (
    <Box
      position="absolute"
      display="block"
      component="span"
      bgcolor="action.active"
      width={8}
      height={8}
      borderRadius={1}
      left={0}
      sx={clsx(
        { transition: (theme: Theme) => theme.transitions.create(['left']) },
        ...parseSx(sx),
      )}
      {...other}
    />
  );
});

const DialogPagination: React.FC = () => {
  const { modalStep } = useRequestDemoModal();

  return useMemo(
    () => (
      <Box mt={3} mb={1.5} display="flex" justifyContent="center">
        <Box width={32} height={8} display="flex" position="relative">
          <BoxPagination
            width={16}
            bgcolor="primary.light"
            {...(modalStep === MODAL_STEP.HRISProvider && { left: 16 })}
          />
          <BoxPagination left={24} {...(modalStep === MODAL_STEP.HRISProvider && { left: 0 })} />
        </Box>
      </Box>
    ),
    [modalStep],
  );
};

export default DialogPagination;
