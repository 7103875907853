import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import Typography, { TypographyProps } from 'components/@ui/Typography';
import When from 'components/@main/When';
import { CountdownTime } from 'components/@common/Auth';
import { useCountdownState } from '@hooks';
import { Children } from '@types';

type SendCodeAgainProps = Children & {
  typographyProps?: TypographyProps;
};

const SendCodeAgain: React.FC<SendCodeAgainProps> = (props) => {
  const { children, typographyProps } = props;
  const { ready: countdownReady } = useCountdownState();

  return useMemo(
    () => (
      <Box display="flex" justifyContent="center">
        <When condition={countdownReady} disabledWrapper fallback={<CountdownTime />}>
          <Box display="flex">
            <Typography children="I didn't receive a code" {...typographyProps} />
            {children}
          </Box>
        </When>
      </Box>
    ),
    [children, countdownReady, typographyProps],
  );
};

export default React.memo(SendCodeAgain);
