import React from 'react';
import { keyframes } from '@emotion/react';

import Icon from 'components/@ui/Icon';

type ComingIconProps = {
  checkedLocation: boolean;
};

const clickButtonIconAnimation = keyframes({
  '0%': {
    top: 10,
    right: 10,
  },
  '100%': {
    top: 20,
    right: 20,
  },
});

const ComingIcon: React.FC<ComingIconProps> = ({ checkedLocation }) => {
  return (
    <Icon
      name="coming-soon-hummer-icon-path"
      position="absolute"
      width={13}
      height={12}
      fontSize={12}
      sx={{
        ...(checkedLocation && {
          animation: `${clickButtonIconAnimation} 350ms linear`,
        }),
      }}
      top={checkedLocation ? 20 : 10}
      right={checkedLocation ? 20 : 10}
      color={checkedLocation ? 'blue.main' : 'primary.light'}
    />
  );
};
export default React.memo(ComingIcon);
