import React from 'react';
import { Controller } from 'react-hook-form';

import FormControlLabel, { FormControlLabelProps } from 'components/@ui/FormControlLabel';
import Checkbox, { CheckboxProps } from 'components/@ui/Checkbox';

import { useForm } from './Context';

interface CheckBoxFormProps extends Omit<FormControlLabelProps, 'control'> {
  name: string;
  checboxProps?: CheckboxProps;
}

const CheckBoxForm: React.FC<CheckBoxFormProps> = ({ name, checboxProps, ...other }) => {
  const { control } = useForm();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <FormControlLabel
          ref={ref}
          name={name}
          checked={value}
          control={<Checkbox ref={ref} onChange={onChange} onBlur={onBlur} {...checboxProps} />}
          {...other}
        />
      )}
    />
  );
};

export default React.memo(CheckBoxForm);
