import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAPI, useProject } from '@hooks';

import { addToShortlist, deleteFromShortlist, selectors } from '../@slices';

export type ActoinTypeShortlist = 'add' | 'delete';

const action = {
  add: addToShortlist.action,
  delete: deleteFromShortlist.action,
};

const selector = {
  add: createSelector(selectors.global, (state) => state.addToShortlist),
  delete: createSelector(selectors.global, (state) => state.deleteFromShortlist),
};

const useFetchChangeShortlist = (type: ActoinTypeShortlist) => {
  const { id: projectId } = useProject();

  return useAPI(
    useCallback((vendorId: number) => action[type](projectId, vendorId), [projectId, type]),
    selector[type],
  );
};

export default useFetchChangeShortlist;
