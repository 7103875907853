import React from 'react';

import MenuItemUi, { MenuItemProps } from 'components/@ui/MenuItem';
import { clsx, makeSxStyles, parseSx } from '@helpers';

const sxes = makeSxStyles({
  root: {
    padding: '10px 12px',
    color: 'gray.dark',
    '.MuiListItemIcon-root': {
      minWidth: '30px',
    },
  },
})();

const MenuItems: React.FC<MenuItemProps> = ({ sx, ...other }) => {
  return <MenuItemUi sx={clsx(sxes.root, ...parseSx(sx))} {...other} />;
};

export default React.memo(MenuItems);
