import React, { useCallback, useMemo, useRef, useState } from 'react';

const useDrag = () => {
  const [clicked, setClicked] = useState(false);
  const [dragging, setDragging] = useState(false);
  const position = useRef(0);

  const dragStart = useCallback((ev: React.MouseEvent) => {
    position.current = ev.clientX;
    setClicked(true);
  }, []);

  const dragStartCb = useCallback(() => (ev: React.MouseEvent) => dragStart(ev), [dragStart]);

  const dragStop = useCallback(
    () =>
      requestAnimationFrame(() => {
        setDragging(false);
        setClicked(false);
      }),
    [],
  );

  const dragStopCb = useCallback(() => () => dragStop(), [dragStop]);

  const dragMove = useCallback(
    (ev: React.MouseEvent, cb: (posDif: number) => void) => {
      const newDiff = position.current - ev.clientX;
      const movedEnough = Math.abs(newDiff) > 5;
      if (clicked && movedEnough) {
        setDragging(true);
      }
      if (dragging && movedEnough) {
        position.current = ev.clientX;
        cb(newDiff);
      }
    },
    [clicked, dragging],
  );

  return useMemo(
    () => ({
      dragStart,
      dragStop,
      dragStartCb,
      dragStopCb,
      dragMove,
      dragging,
      position,
      setDragging,
    }),
    [dragMove, dragStart, dragStartCb, dragStop, dragStopCb, dragging],
  );
};

export default useDrag;
