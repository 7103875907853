import React from 'react';

import Box from 'components/@ui/Box';
import Checkbox from 'components/@ui/Checkbox';
import Typography from 'components/@ui/Typography';

export type StrengthBarTipProps = {
  condition: boolean;
  text: React.ReactNode;
};

const StrengthBarTip: React.FC<StrengthBarTipProps> = ({ condition, text }) => {
  return (
    <Box display="flex" alignItems="flex-start">
      <Checkbox
        variant="green"
        checked={condition}
        sx={{
          cursor: 'default',
          '&.MuiButtonBase-root .checkBoxIcon': {
            bgcolor: 'secondary.main',
            '&.icon': {
              color: 'greenLight.main',
              borderColor: 'greenLight.main',
            },
          },
        }}
      />
      <Typography
        ml={1.5}
        {...(condition && { color: 'greenLight.main' })}
        sx={{ transition: (theme) => theme.transitions.create('color') }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default React.memo(StrengthBarTip);
