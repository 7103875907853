import React, { useCallback, useMemo, useState } from 'react';
import { useMount, useUnmount } from 'react-use';

import Loader from 'components/@common/Loader';
import {
  createIdentifier,
  UseSubscriptionReturnType,
  useSubscriptionWs,
} from 'components/@main/Ws';
import { useProject } from '@hooks';
import { checkStatus, createContext } from '@helpers';
import { Children } from '@types';

import LayoutWrapper from './LayoutWrapper';
import { useFetchTimeLinePhases, useOpenMobileTimeline } from './@hooks';
import { wsChannels } from './config';

type Ws = { channel: UseSubscriptionReturnType };

type TimeLineBarContextType = {
  loaded: boolean;
  ws: Ws;
};

const [Provider, , TimeLineBarContext] = createContext<TimeLineBarContextType>();
export { TimeLineBarContext };

const TimeLineBarProvider: React.FC<Children> = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const [, fetchTimeLinePhases] = useFetchTimeLinePhases();
  const { id: projectId } = useProject();

  const fetchTimeLine = useCallback(async () => {
    const response = await fetchTimeLinePhases();
    return checkStatus(response?.status);
  }, [fetchTimeLinePhases]);

  const timeLineChannelWs = useSubscriptionWs({
    identifier: createIdentifier(wsChannels.timeLine, projectId),
    fetchRefresh: fetchTimeLine,
    initialSubscribe: loaded,
  });

  const mountFetchTimeLine = useCallback(async () => {
    setLoaded(false);
    const check = await fetchTimeLine();
    setLoaded(check);
  }, [fetchTimeLine]);

  useOpenMobileTimeline();
  useMount(() => mountFetchTimeLine());
  useUnmount(() => setLoaded(false));

  const contextValue = useMemo<TimeLineBarContextType>(
    () => ({ loaded, ws: { channel: timeLineChannelWs } }),
    [loaded, timeLineChannelWs],
  );

  return (
    <Provider value={contextValue}>
      <LayoutWrapper>
        {loaded ? children : <Loader hideText height={80} width="100vw" />}
      </LayoutWrapper>
    </Provider>
  );
};

export default React.memo(TimeLineBarProvider);
