import { useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';

import { UseDynamicRefs, useDynamicRefs } from '@hooks';

import { useFetchTimeLinePhases } from '../../@hooks';

const useEmptyState = () => {
  const [{ records }] = useFetchTimeLinePhases();
  const { width } = useWindowSize();
  const [getRef, setRef] = useDynamicRefs();
  const [emptySpase, setEmptySpase] = useState(0);

  useEffect(() => {
    setEmptySpase(
      records.phases.reduce(
        (acc, { name }) => acc - (getRef(name) as HTMLElement)?.offsetWidth || 0,
        width - records.phases.length,
      ),
    );
  }, [getRef, records.phases, width]);

  return useMemo(
    () => ({ refState: [getRef, setRef] as UseDynamicRefs, emptySpase, setEmptySpase }),
    [emptySpase, getRef, setRef],
  );
};

export type UseEmptyState = ReturnType<typeof useEmptyState>;
export default useEmptyState;
