import { find, propEq } from 'ramda';

import { TimeLineBar } from '../@types';

type Item = TimeLineBar.Status;

export default <T extends Item>(items: T[]) => {
  const selected = find(propEq('current', 'status'), items);
  return selected ?? items[items.length - 1];
};
