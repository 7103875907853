export const cellsSize = {
  heatMap: {
    height: 64,
    width: 61,
  },
} as const;

export const rowTitles = {
  requirementsMatch: 'Match Score',
  sizeMatchScalability: 'Size Match & Scalability',
  featureMatch: 'Feature Match',
  characteristicMatch: 'Characteristic Match',
  prosAndCons: 'Pros and Cons',
  softwareCosts: 'Software Costs',
  implementationCost: 'Implementation Costs',
};
