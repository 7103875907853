import { createContext, useContext } from 'react';

export default <T,>(init?: T) => {
  const context = createContext<T>(init ?? null!);

  const useCurrentContext = <Context = T,>() => {
    const state = useContext(context);
    if (state === null) {
      console.error(`useContext have to be used inside a Provider.`);
      throw new Error(`useContext have to be used inside a Provider.`);
    }
    // @ts-ignore
    return state as Context;
  };

  return [context.Provider, useCurrentContext, context] as const;
};
