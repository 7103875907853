import React, { useCallback } from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { Theme } from '@mui/material';
import { History } from 'history';

import { clsx, makeSxStyles, parseSx } from '@helpers';
import { First } from '@types';

import { BoxProps } from '../Box';

export interface ButtonProps extends MuiButtonProps, Pick<BoxProps, 'component'> {
  to?: First<Parameters<History['push']>>;
}

const sxes = makeSxStyles({
  root: {
    padding: (theme: Theme) => theme.spacing(1, 3),
    transition: (theme: Theme) => theme.transitions.create('all'),
  },
  small: {
    height: (theme: Theme) => parseInt(theme.spacing(1)) * theme.sizes.small,
    minHeight: (theme: Theme) => parseInt(theme.spacing(1)) * theme.sizes.small,
  },
  medium: {
    height: (theme: Theme) => parseInt(theme.spacing(1)) * theme.sizes.medium,
    minHeight: (theme: Theme) => parseInt(theme.spacing(1)) * theme.sizes.medium,
  },
  large: {
    height: (theme: Theme) => parseInt(theme.spacing(1)) * theme.sizes.large,
    minHeight: (theme: Theme) => parseInt(theme.spacing(1)) * theme.sizes.large,
  },
})();

// @ts-ignore
const Button: React.FC<ButtonProps> = React.forwardRef((props, ref) => {
  const { sx, size, to, onClick, ...other } = props;
  const history = useHistory();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClick?.(e);
      to && history.push(to);
    },
    [history, onClick, to],
  );

  return (
    <MuiButton
      sx={clsx(
        sxes.root,
        sxes[size ?? 'medium'],
        sx && 'height' in sx && { minHeight: sx.height },
        ...parseSx(sx),
      )}
      ref={ref}
      color="primary"
      onClick={handleClick}
      variant="contained"
      {...other}
    />
  );
});

export default React.memo(Button);
