import React from 'react';

import TodoItem from './TodoItem';
import { DownloadButton } from '../@components';

const DownloadToDoItem: React.FC = () => {
  return (
    <TodoItem>
      <DownloadButton />
    </TodoItem>
  );
};

export default DownloadToDoItem;
