import * as yup from 'yup';

import { schemaPassword } from 'components/@ui/PasswordStrengthBar';

const urlStartExpression = /^(http:\/\/|https:\/\/)/;
const urlExpression = /^(https?:\/\/)(?![.])(?!.*\.$)[A-Za-z0-9/?=`&_,-.]+$/;
const name = yup.string().trim().required('This field is required');
const website = yup
  .string()
  .trim()
  .required('This field is required')
  .matches(urlStartExpression, {
    message: 'Website URL should start with "https://" or "http://"',
  })
  .matches(urlExpression, {
    message: 'Invalid website url',
  });
const currentHeadcount = yup
  .number()
  .transform((value) => (isNaN(value) ? undefined : value))
  .required('This field is required')
  .min(1, 'This field is required');
const futureHeadcount = yup
  .number()
  .transform((value) => (isNaN(value) ? undefined : value))
  .required('This field is required')
  .moreThan(yup.ref<number>('currentHeadcount'), 'Headcount must be bigger then the current');
const email = yup.string().trim().required('This field is required').email('Invalid email');

export const defaultFieldsSchema = {
  name,
  website,
  currentHeadcount,
  futureHeadcount,
  email,
};

export const startMatchingSchema = {
  defaultValues: {
    name: '',
    website: '',
    currentHeadcount: '',
    futureHeadcount: '',
    receiveEmails: true,
  },
  validationSchema: yup.object().shape({
    name: name.max(256, 'Maximum is 256 characters'),
    website,
    currentHeadcount,
    futureHeadcount,
    receiveEmails: yup.bool(),
  }),
};

export const emailSchema = {
  defaultValues: {
    email: '',
  },
  validationSchema: yup.object().shape({
    email,
  }),
};

export const newEmailSchema = {
  defaultValues: {
    newEmail: '',
  },
  validationSchema: yup.object().shape({
    newEmail: email,
  }),
};

export const passwordSchema = {
  defaultValues: {
    newPassword: '',
  },
  validationSchema: yup.object().shape({
    newPassword: schemaPassword,
  }),
};

export const createUserSchema = {
  defaultValues: {
    email: '',
    fullName: '',
    password: '',
  },
  validationSchema: yup.object().shape({
    email,
    fullName: name.max(256, 'Maximum is 256 characters'),
    password: schemaPassword,
  }),
};

export const codeSchema = {
  defaultValues: {
    code: '',
  },
  validationSchema: yup.object().shape({
    code: yup.string().trim().required().length(5),
  }),
};

export const loginSchema = {
  defaultValues: {
    email: '',
    password: '',
  },
  validationSchema: yup.object().shape({
    email,
    password: name,
  }),
};
