import { TimeLineBar } from '../@types';

const colors = {
  phaseBackground: {
    current: 'yellow.light',
    done: 'greenLight.light',
    next: 'secondary.main',
  },
  phaseBorder: {
    current: 'yellow.contrastText',
    done: 'greenLight.contrastText',
    next: 'gray.light',
  },
  phaseBadge: {
    current: 'yellow.main',
    done: 'greenLight.main',
    next: 'secondary.main',
  },
  phaseText: {
    current: 'yellow.main',
    done: 'greenLight.main',
    next: 'text.primary',
  },
  stepperConnector: {
    current: 'yellow.main',
    done: 'greenLight.main',
    next: 'gray.main',
  },
  stepperIconBackground: {
    current: 'yellow.main',
    done: 'greenLight.main',
    next: 'inherit',
  },
};

export const colorGenerator = (
  status: TimeLineBar.Statuses,
  type:
    | 'phaseText'
    | 'phaseBackground'
    | 'phaseBadge'
    | 'phaseBorder'
    | 'stepperConnector'
    | 'stepperIconBackground',
) => colors[type][status];
