import React from 'react';

import Button, { ButtonProps } from 'components/@ui/Button';
import Tooltip from 'components/@ui/Tooltip';
import IconButton, { IconButtonProps } from 'components/@ui/IconButton';

import WorkplaceButtonHOC, { WorkplaceButtonHOCWrapperType } from './WorkplaceButtonHOC';
import { switchIconColor, switchIconName } from './@helpers';

type AddToWorkplaceProps = WorkplaceButtonHOCWrapperType & {
  hideToolTip?: boolean;
};

type AddToWorkplaceIconButtonProps = AddToWorkplaceProps & {
  iconButtonProps?: Partial<IconButtonProps>;
};

export const AddToWorkplaceIconButton: React.FC<Partial<AddToWorkplaceIconButtonProps>> = ({
  iconButtonProps,
  hideToolTip,
  isWorkspace,
  isDisabled,
  handleClick,
  tooltipTitle,
  isLoading,
  isVendorOfChoice = false,
  open,
  handleOpen,
  handleClose,
}) => (
  <Tooltip
    disableInteractive
    title={!hideToolTip && tooltipTitle}
    maxWidth={165}
    placement="right"
    open={open}
    onOpen={handleOpen}
    onClose={handleClose}
  >
    <div>
      <IconButton
        {...iconButtonProps}
        iconName={switchIconName(isVendorOfChoice, isWorkspace, isDisabled, isLoading)}
        sx={{
          p: 0,
          width: 59,
          height: 62,
          borderRadius: 0,
          '&:disabled': {
            color: 'primary.light',
            opacity: 1,
            'i::before': {
              color: switchIconColor(isVendorOfChoice, isWorkspace, isDisabled, isLoading),
            },
          },
          ...iconButtonProps?.sx,
        }}
        onClick={handleClick}
        disabled={isDisabled}
      />
    </div>
  </Tooltip>
);

type AddToWorkplaceButtonProps = AddToWorkplaceProps & {
  buttonProps?: ButtonProps;
};

export const AddToWorkplaceButton: React.FC<Partial<AddToWorkplaceButtonProps>> = ({
  hideToolTip,
  buttonProps,
  isDisabled,
  handleClick,
  tooltipTitle,
  open,
  handleOpen,
  handleClose,
}) => (
  <Tooltip
    disableInteractive
    title={!hideToolTip && tooltipTitle}
    maxWidth={165}
    open={open}
    onOpen={handleOpen}
    onClose={handleClose}
  >
    <span>
      <Button
        {...buttonProps}
        sx={{
          ...buttonProps?.sx,
        }}
        onClick={handleClick}
        disabled={isDisabled}
      >
        Add to workspace
      </Button>
    </span>
  </Tooltip>
);

export const AddToWorkplaceIconButtonWithHOC = WorkplaceButtonHOC(
  React.memo(AddToWorkplaceIconButton),
);

export const AddToWorkplaceButtonWithHOC = WorkplaceButtonHOC(React.memo(AddToWorkplaceButton));
