import React from 'react';

import Collapse, { CollapseProps } from 'components/@ui/Collapse';

export interface WhenProps extends CollapseProps {
  condition?: boolean;
  fallback?: React.ReactNode;
  disabledWrapper?: boolean;
}

// @ts-ignore
const When: React.FC<CollapseProps & WhenProps> = React.forwardRef((props, ref) => {
  const { condition, fallback, children, disabledWrapper, ...other } = props;

  if (disabledWrapper) return <>{condition ? children : fallback}</>;

  return (
    <>
      <Collapse ref={ref} unmountOnExit timeout={0} {...other} in={condition}>
        {children}
      </Collapse>
      <Collapse ref={ref} unmountOnExit timeout={0} {...other} in={!condition}>
        {fallback}
      </Collapse>
    </>
  );
});

export default React.memo(When);
