import React, { useMemo } from 'react';

import Box, { BoxProps } from 'components/@ui/Box';
import { useIsTablet } from '@hooks';

const DesktopLine: React.FC<BoxProps> = (props) => {
  return (
    <Box
      position="absolute"
      width="calc(100vw - 18px)"
      color="gray.light"
      top="calc(50% - 1px)"
      borderColor="gray.light"
      border={1}
      zIndex={1}
      mx={1}
      {...props}
    />
  );
};

const MobileLine: React.FC<BoxProps> = (props) => {
  return (
    <Box
      position="absolute"
      width={3}
      height="calc(100% - 48px)"
      color="gray.light"
      top="24px"
      left="calc(50% - 2px)"
      borderColor="gray.light"
      bgcolor="gray.light"
      border={1}
      mx={1}
      {...props}
    />
  );
};

const Line: React.FC<BoxProps> = (props) => {
  const isTablet = useIsTablet();

  return useMemo(
    () => (isTablet ? <MobileLine {...props} /> : <DesktopLine {...props} />),
    [isTablet, props],
  );
};

export default Line;
