import { useCallback, useMemo } from 'react';
import { Selector, useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '@redux/store';
import { CreateSliceDataReturn } from '@redux/slices';

export type UpdateCallback<T> = (prevRecords: T) => T;
export type Update<T> = (records: UpdateCallback<T> | T) => void;

const useSliceData = <T,>(
  slice: CreateSliceDataReturn<T>,
  selector: Selector<RootState, { records: T }>,
) => {
  const dispatch = useAppDispatch();
  const data = useSelector<RootState, ReturnType<typeof selector>>(selector);

  const update = useCallback<Update<T>>(
    (records) => {
      if (records instanceof Function) {
        const newRecords = records(data.records);
        dispatch(slice.actions.FULFILLED<T>(newRecords));
      } else {
        dispatch(slice.actions.FULFILLED<T>(records));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.records],
  );

  return useMemo(() => [data, update] as const, [data, update]);
};

export default useSliceData;
