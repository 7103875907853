import { createS, createSS, p } from '@redux/slices';

import {
  fetchEmailValidation,
  fetchResendCode,
  fetchSignIn,
  fetchSignInBySocial,
  fetchSignOut,
  fetchVerifyCode,
} from '../@api';

export const signIn = createS(p('SIGN_IN'), fetchSignIn);
export const emailValidation = createSS(p('EMAIL_VALIDATION'), fetchEmailValidation);
export const signOut = createSS(p('SIGN_OUT'), fetchSignOut, { hideToastMessageError: true });
export const resendCode = createSS(p('RESEND_CODE'), fetchResendCode);
export const verifyCode = createSS(p('VERYFY_CODE'), fetchVerifyCode);
export const signInSocial = createSS(p('SIGN_IN_BY_SOCIAL'), fetchSignInBySocial);

export default {
  signIn: signIn.reducer,
};
