import React, { memo, useCallback } from 'react';

import { Children } from '@types';

import { abilityCheck } from './@helpers';
import { AbilityType } from './ability';
import { PermissionsArrayMethod, PermissionsProp } from './permissions';
import { AbilityContext, AbilityContextType } from './Context';

export type AbilityControlType = {
  /**
   * Ability type.
   * @default 'USE'
   */
  ability?: AbilityType;
  /**
   * Current permission.
   * string | string[]
   * @required
   */
  permission: PermissionsProp;
  /**
   * Method 'all' | 'any'.
   * @default 'all'
   */
  method?: PermissionsArrayMethod;
  /**
   * React.ReactNode.
   * @default null
   */
  fallBack?: React.ReactNode;
} & Children;

/**
 * Provides access to Children based on the client's permissions.
 * @returns If the client has permission to return the Children. Otherwise, returns fallBack.
 */

const AbilityControl: React.FC<AbilityControlType> = memo((props) => {
  const { children, permission, ability, method = 'all', fallBack = null } = props;

  const access = useCallback(
    (abilities: AbilityContextType) => {
      return abilityCheck({ ability, permission, abilities, method }) ? children : fallBack;
    },
    [ability, permission, method, children, fallBack],
  );

  return (
    <AbilityContext.Consumer>
      {useCallback((context) => access(context), [access])}
    </AbilityContext.Consumer>
  );
});

export default AbilityControl;
