import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'react-use';

import { useEvent, useOnline } from '@hooks';

import { useOffline, UseWsOnlineProps } from '../OfflineContext';
import { ToastOffline, ToastOnline } from '../Toasts';
import { CUSTOM_OFFLINE_TOAST_ID, noClosingToastProps } from '../config';

const useToastsOffline = (props?: UseWsOnlineProps) => {
  const { toastId, setToastId } = useOffline();
  const isOnline = useOnline();

  const onClose = useEvent(() => {
    setToastId(0);
  });

  const onRefresh = useCallback(async () => {
    await props?.refresh?.();
    toast.update(CUSTOM_OFFLINE_TOAST_ID, {
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      render: <ToastOnline />,
      closeButton: true,
      closeOnClick: true,
      onClose,
    });
  }, [onClose, props]);

  useUpdateEffect(() => {
    if (isOnline) {
      if (toastId) {
        toast.update(CUSTOM_OFFLINE_TOAST_ID, {
          type: toast.TYPE.SUCCESS,
          render: <ToastOnline />,
          autoClose: false,
          onClose,
        });
      } else {
        toast.success(<ToastOnline />, {
          autoClose: false,
          toastId: CUSTOM_OFFLINE_TOAST_ID,
          onClose,
        });
      }
      onRefresh();
    } else {
      setToastId(
        toast.info(<ToastOffline />, {
          toastId: CUSTOM_OFFLINE_TOAST_ID,
          ...noClosingToastProps,
        }),
      );
    }
  }, [isOnline]);
};

export default useToastsOffline;
