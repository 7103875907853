enum Fonts {
  ProximaNova = 'Proxima Nova',
  ProximaNovaBold = 'Proxima Nova Bold',
  ProximaExtraBold = 'Proxima Extra Bold',
  ProximaSemiBold = 'Proxima Nova Semibold',
  Roboto = 'Roboto',
  RobotoBold = 'Roboto Bold',
  IconFontOutsail = 'icon-font-outsail',
}

// we also need to add new fonts to webfont.ts

export default Fonts;
