import React, { memo, useMemo } from 'react';
import Lazy from 'yup/lib/Lazy';
import { AnyObjectSchema, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm as useFormHook, UseFormReturn, FieldValues, UseFormProps } from 'react-hook-form';

import { createContext } from '@helpers';
import { Children } from '@types';

type ContextType = UseFormReturn<FieldValues>;

export type FormProviderType = UseFormProps & {
  className?: string;
  validationSchema?: AnyObjectSchema | Lazy<any>;
} & Children;

const defaultValidationSchema = object().shape({});

const [, useFormRough, FormContext] = createContext<ContextType>();

export const useForm = <T extends FieldValues>() => useFormRough<UseFormReturn<T>>();
export { FormContext };

export const FormProvider: React.FC<FormProviderType> = (props) => {
  const {
    children,
    validationSchema = defaultValidationSchema,
    reValidateMode = 'onChange',
    ...other
  } = props;

  const { ...methods } = useFormHook({
    reValidateMode,
    resolver: yupResolver(validationSchema),
    ...other,
  });

  const contextValue = useMemo<ContextType>(() => methods, [methods]);

  return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
};

export default memo(FormProvider);
