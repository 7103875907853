import React from 'react';

import Button, { ButtonProps } from 'components/@ui/Button';
import { clsx, parseSx } from '@helpers';

const ButtonSocial: React.FC<ButtonProps> = ({ sx, ...other }) => {
  return (
    <Button
      variant="outlined"
      sx={clsx({ justifyContent: 'flex-start', color: 'text.primary' }, ...parseSx(sx))}
      {...other}
    />
  );
};

export default ButtonSocial;
