import React, { useMemo } from 'react';

import Button from 'components/@ui/Button';
import Icon from 'components/@ui/Icon';
import Menu from 'components/@ui/Menu';
import { useIsTablet, usePopoverAnchor } from '@hooks';
import { clsx, makeSxStyles } from '@helpers';

import MenuItems from './MenuItems';
import UserName from './UserName';
import { useNavbar } from '../@hooks';

const sxes = makeSxStyles({
  button: {
    p: 0,
    height: 'fit-content',
  },
  buttonCloseNavbar: {
    width: 0,
    minWidth: 0,
    marginTop: -1,
    marginLeft: 5,
    '&:active, &:hover': { bgcolor: 'transparent' },
    '.MuiButton-endIcon': {
      marginRight: 0,
      marginTop: -0.25,
      marginLeft: '15px',
      display: 'inherit',
      '& .icon': { fontSize: 14, minWidth: 'inherit', height: 11 },
    },
  },
  buttonOpenNavbar: {
    wordBreak: 'break-word',
    width: 240,
    marginTop: 2,
    marginLeft: 0,
    '&:active, &:hover': { color: 'primary.main' },
    '.MuiButton-endIcon': {
      '& .icon': { fontSize: 18 },
    },
  },
  paper: {
    width: 240,
    border: 1,
    marginLeft: -1,
    borderColor: 'gray.light',
  },
  paperIsTablet: {
    marginLeft: 0,
  },
})();

const MenuButton: React.FC = () => {
  const { onClosePopover, onOpenPopover, anchorPopoverEl } = usePopoverAnchor<HTMLElement>();
  const { isOpenNavbar } = useNavbar();
  const isTablet = useIsTablet();

  return useMemo(
    () => (
      <>
        <Button
          variant="text"
          aria-controls={anchorPopoverEl ? 'Navbar-menu-container' : undefined}
          aria-haspopup="true"
          aria-expanded={anchorPopoverEl ? 'true' : undefined}
          onClick={onOpenPopover}
          endIcon={<Icon color="inherit" name="blue-arrow-down-path" />}
          {...(!isOpenNavbar && { 'aria-label': 'user name' })}
          sx={clsx(
            sxes.button,
            isOpenNavbar
              ? clsx(sxes.buttonOpenNavbar, anchorPopoverEl && { bgcolor: 'action.active' })
              : sxes.buttonCloseNavbar,
          )}
        >
          <UserName />
        </Button>
        <Menu
          anchorEl={anchorPopoverEl}
          open={Boolean(anchorPopoverEl)}
          onClose={onClosePopover}
          PaperProps={{ sx: clsx(sxes.paper, isTablet && sxes.paperIsTablet) }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          MenuListProps={{ disablePadding: true }}
        >
          <MenuItems onClosePopover={onClosePopover} />
        </Menu>
      </>
    ),
    [anchorPopoverEl, isOpenNavbar, isTablet, onClosePopover, onOpenPopover],
  );
};

export default MenuButton;
