import React, { useMemo, useState } from 'react';
import { isEmpty } from 'ramda';

import Box from 'components/@ui/Box';
import { UseState } from '@types';
import { useFetchProject } from '@api/@hooks';

import TodoItem from './TodoItem';
import { TooltipTextObject, VendorsProposalButton } from '../@components';
import { ToDoItemWithTooltip, useToDoItem } from '../TodoItem';

type ProposalTodoItemProps = {
  setHideTooltip: UseState<boolean>;
};

const VendorsProposal: React.FC<ProposalTodoItemProps> = React.memo(({ setHideTooltip }) => {
  const [{ records }] = useFetchProject();
  const { project } = records;

  return useMemo(
    () => (
      <>
        {project.workspacedProducts.length ? (
          <Box display="flex" flexDirection="column" mt={0.5}>
            {project.workspacedProducts.map((vendor) => (
              <VendorsProposalButton
                key={vendor.id}
                vendor={vendor}
                setHideTodoItemTooltip={setHideTooltip}
              />
            ))}
          </Box>
        ) : (
          <></>
        )}
      </>
    ),
    [project.workspacedProducts, setHideTooltip],
  );
});

const VendorsProposalTodoItem: React.FC = () => {
  const { todoItem } = useToDoItem();
  const [{ records }] = useFetchProject();
  const { project } = records;
  const [hideTooltip, setHideTooltip] = useState(
    isEmpty(
      todoItem.proposals.filter(
        (proposal) =>
          project.workspacedProducts.filter((vendor) => vendor.id === proposal.meetableId).length,
      ),
    ),
  );

  return useMemo(
    () => (
      <ToDoItemWithTooltip
        hideTooltip={hideTooltip}
        type="text"
        source={{ text: TooltipTextObject.vendorProposal }}
      >
        <TodoItem>
          <VendorsProposal setHideTooltip={setHideTooltip} />
        </TodoItem>
      </ToDoItemWithTooltip>
    ),
    [hideTooltip],
  );
};

export default VendorsProposalTodoItem;
