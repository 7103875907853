import baseAPI, { APIResponse } from '@api';
import { MainFields, User } from '@types';

const apiCredentials = baseAPI(true);

//--------------------------------------------------------------------------------------------------
// Get Tean Member Settings
//--------------------------------------------------------------------------------------------------
export type FetchTeamMemberResponse = {
  role: User.Roles;
  groups: MainFields<{ projectGroupId: number }>[];
};
export function fetchTeamMember(projectId: number): APIResponse<FetchTeamMemberResponse> {
  return apiCredentials.get(`/projects/${projectId}/project_clients/member`);
}
//--------------------------------------------------------------------------------------------------
// Get Project Team Members witout Owner
//--------------------------------------------------------------------------------------------------
export type FetchTeamMembersResponse = MainFields<User.Avatar>[];
export function fetchTeamMembers(projectId: number): APIResponse<FetchTeamMembersResponse> {
  return apiCredentials.get(`/projects/${projectId}/team_members`, {
    params: { except_owner: true },
  });
}
//--------------------------------------------------------------------------------------------------
// Get Project Team Members
//--------------------------------------------------------------------------------------------------
export function fetchTransferOwnership(projectId: number, userId: number): APIResponse<User.User> {
  return apiCredentials.put(`/projects/${projectId}/team_members/${userId}/transfer_ownership`);
}
//--------------------------------------------------------------------------------------------------
