import React from 'react';

import MobileToDoItemsHeader from './MobileToDoItemsHeader';
import ToDoItemsProvider from '../ToDoItems/Context';
import { ToDoItemsBody } from '../ToDoItems';

type ToDoItemsProps = {
  todoName: string;
};

const MobileToDoItems: React.FC<ToDoItemsProps> = ({ todoName }) => {
  return (
    <ToDoItemsProvider>
      <MobileToDoItemsHeader name={todoName} />
      <ToDoItemsBody />
    </ToDoItemsProvider>
  );
};

export default React.memo(MobileToDoItems);
