import React, { useMemo } from 'react';

import { clsx, makeSxStyles } from '@helpers';

import ToDoPopover from './ToDoPopover';
import ToDoStartIcon from '../ToDoStartIcon';
import { Button } from '../../@components';
import { useToDo } from '../Context';

export const makeSxToDoButton = makeSxStyles({
  button: {
    borderRadius: 24,
    height: 24,
  },
  current: {
    borderColor: 'yellow.main',
    bgcolor: 'yellow.light',
    color: 'yellow.main',
    '&:hover': {
      bgcolor: 'yellow.contrastText',
    },
    '&:active': {
      bgcolor: 'yellowLight.main',
    },
  },
  done: {
    '&:hover': {
      borderColor: 'greenLight.contrastText',
      bgcolor: 'greenLight.light',
    },
    '&:active': {
      bgcolor: 'greenLight.contrastText',
      color: 'gray.dark',
    },
  },
  next: {
    '&:hover': {
      borderColor: 'action.active',
      bgcolor: 'action.hover',
    },
  },
});

const ToDoButton: React.FC = () => {
  const { todo, handleClickToDo } = useToDo();
  const sxes = useMemo(() => makeSxToDoButton(), []);

  return useMemo(
    () => (
      <>
        <Button
          aria-describedby={`${todo.id}`}
          variant="contained"
          onClick={handleClickToDo}
          status={todo.status}
          sx={clsx(sxes.button, sxes[todo.status])}
          startIcon={<ToDoStartIcon />}
          name={todo.name}
        >
          {todo.name}
        </Button>
        <ToDoPopover todoId={todo.id} />
      </>
    ),
    [todo.id, todo.status, todo.name, handleClickToDo, sxes],
  );
};

export default ToDoButton;
