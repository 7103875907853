import { jwt } from '@servises';
import { setSessionExpired } from '@redux/slices';

import { checkAllEquals } from '@helpers';

import parseDataFn from './parseDataFn';
import { MessageType, PingType, ReasonType, UnauthorizedType } from '../types';

const check = (data: PingType | UnauthorizedType) =>
  checkAllEquals(data?.reason === ReasonType.unauthorized);

export default (lastMessage: MessageEvent<PingType | UnauthorizedType>) => {
  const data = parseDataFn(lastMessage);
  switch (true) {
    case !jwt.isTokenAlive():
    case check(data):
      setSessionExpired(true);
      return false;
    case data.type === MessageType.ping:
      return false;
    default:
      return true;
  }
};
