import React from 'react';

import { DialogWrapper, MainInformationBody, MainInformationHeader } from './@components';

type MainInformationProps = {
  openModal: boolean;
  closeModal: () => void;
};

const MainInformation: React.FC<MainInformationProps> = ({ openModal, closeModal }) => {
  return (
    <DialogWrapper openModal={openModal} closeModal={closeModal}>
      <MainInformationHeader />
      <MainInformationBody />
    </DialogWrapper>
  );
};

export default React.memo(MainInformation);
