import React, { useMemo } from 'react';
import { Theme } from '@mui/material';

import BadgeUi from 'components/@ui/Badge';
import Box from 'components/@ui/Box';
import UserAvatar from 'components/@main/UserAvatar';
import Typography from 'components/@ui/Typography';
import { useUser } from '@hooks';
import { clsx, makeSxStyles } from '@helpers';

import { useNavbar } from '../@hooks';

const sxes = makeSxStyles({
  avatar: {
    width: 50,
    fontSize: 50,
    height: 50,
    background: 'transparent',
    color: 'gray.main',
    transition: (theme: Theme) => theme.transitions.create(['width', 'height']),
    '.MuiTypography-root': {
      transition: (theme: Theme) => theme.transitions.create(['font-size']),
      fontSize: 24,
    },
  },
  avatarOpenNavbar: {
    width: 120,
    height: 120,
    '.MuiTypography-root ': {
      fontSize: 70,
    },
    '@media (min-height: 432px) and (max-height: 759px)': {
      fontSize: 92,
      width: 92,
      height: 92,
      '& .icon': { fontSize: 92 },
      '& .MuiTypography-root': {
        fontSize: 50,
      },
    },
  },
  badge: {
    '.MuiBadge-badge': {
      transition: (theme: Theme) => theme.transitions.create(['top', 'right']),
      top: 8,
      right: 2,
      height: 'auto',
      p: 0,
    },
  },
  badgeOpenNavbar: {
    '.MuiBadge-badge ': {
      top: 18,
      right: 15,
    },
  },
})();

export const BadgeContent: React.FC = () => {
  return (
    <Typography
      component="span"
      p="3px 4px"
      color="secondary.main"
      bgcolor="blue.main"
      borderRadius={1}
      fontSize={8}
      fontFamily="inherit"
      lineHeight="9px"
    >
      FREE
    </Typography>
  );
};

const Badge: React.FC = () => {
  const { name, avatar } = useUser();
  const { isOpenNavbar } = useNavbar();

  return useMemo(
    () => (
      <BadgeUi sx={clsx(sxes.badge, isOpenNavbar && sxes.badgeOpenNavbar)}>
        <Box border={1} borderColor="gray.main" borderRadius="100%">
          <UserAvatar
            name={name}
            src={avatar}
            sx={clsx(sxes.avatar, isOpenNavbar && sxes.avatarOpenNavbar)}
          />
        </Box>
      </BadgeUi>
    ),
    [isOpenNavbar, name, avatar],
  );
};

export default Badge;
