import { useMemo, useState } from 'react';
import { useUnmount, useUpdateEffect } from 'react-use';

import { TooltipProps } from 'components/@ui/Tooltip';
import { useEvent, useSetTimeout } from '@hooks';

export type UseTolltipDebounce = {
  debounce?: number;
  onFetch?: () => Promise<any>;
  error?: unknown;
};

type OmitProps = 'children';

export type UseTolltipDebounceProps = UseTolltipDebounce & Omit<TooltipProps, OmitProps>;

const useTolltipDebounce = (props: UseTolltipDebounceProps) => {
  const { debounce = 1000, error, onFetch, onOpen, onClose, title, ...other } = props;
  const [openTooltip, setOpenTooltip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const { setTimeout, clearTimeout } = useSetTimeout();

  const onOpenTooltip: TooltipProps['onOpen'] = useEvent(async (e) => {
    setLoading(true);
    setOpenTooltip(true);
    setTimeout(() => setReady(true), debounce);
    onOpen?.(e);
  });

  const onCloseTooltip: TooltipProps['onClose'] = useEvent((e) => {
    clearTimeout();
    setReady(false);
    setOpenTooltip(false);
    onClose?.(e);
  });

  const onFetchCallback = useEvent(async () => {
    await onFetch?.();
    setLoading(false);
  });

  useUpdateEffect(() => {
    ready && onFetchCallback();
  }, [ready]);

  useUnmount(() => {
    setOpenTooltip(false);
    setLoading(false);
    setReady(false);
  });

  return useMemo(
    () => ({
      open: openTooltip,
      onClose: onCloseTooltip,
      onOpen: onOpenTooltip,
      loading,
      title,
      ...other,
      ...((loading || Boolean(error)) && { slots: { popper: () => null }, title: <></> }),
    }),
    [error, loading, onCloseTooltip, onOpenTooltip, openTooltip, other, title],
  );
};

export default useTolltipDebounce;
export type UseTolltipDebounceReturnType = ReturnType<typeof useTolltipDebounce>;
