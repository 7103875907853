import { NonEmptyString } from '@types';

const createActionCreator = () => {
  const data = new Set<string>();
  return <T extends string>(name: NonEmptyString<T>) => {
    if (data.has(name) && process.env.NODE_ENV === 'development') {
      console.error('Duplicate ACTION is not allowed', name);
      throw new Error(`Duplicate ACTION is not allowed ${name}`);
    }
    data.add(name);
    return name;
  };
};

/**
 * `createAction` for slise
 * @param name unique string, generick `NonEmptyString`
 */

const createAction = createActionCreator();
export default createAction;
