import React from 'react';
import { not } from 'ramda';

import Box from 'components/@ui/Box';
import When from 'components/@main/When';
import TypographyMobile from 'components/@ui/TypographyMobile';
import { getBarColor } from 'components/@common/VendorComponents';

export const getMobileHeatMapChipFontColor = (value: number) => {
  switch (true) {
    case value >= 4:
      return 'greenLight.main';
    case value === 3:
      return 'yellow.dark';
    case value <= 2:
      return 'error.main';
    default:
      return 'text.primary';
  }
};

type ChipValueProps = {
  title?: string;
  value: number;
  bgcolor: string;
  fontColor: string;
};

type MobileHeaderChipProps = {
  scoreMatch: number;
  sizeMatch: number;
  featureMatch: number;
  characteristicMatch: number;
};

const ChipValue: React.FC<ChipValueProps> = React.memo(({ value, bgcolor, fontColor, title }) => {
  return (
    <Box
      display="flex"
      borderRadius={5}
      px={0.75}
      bgcolor={bgcolor}
      maxWidth={title === 'Size & Scalability:' ? 'calc(100% - 210px)' : 1}
    >
      <When condition={Boolean(title)} disabledWrapper>
        <TypographyMobile
          mobileSmallFontSize
          color={fontColor}
          mr={0.5}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {title}
        </TypographyMobile>
      </When>
      <TypographyMobile mobileSmallFontSize color={fontColor} semiBold={not(title)}>
        {value}
        {not(title) && '%'}
      </TypographyMobile>
    </Box>
  );
});

const MobileHeaderChip: React.FC<MobileHeaderChipProps> = ({
  scoreMatch,
  sizeMatch,
  featureMatch,
  characteristicMatch,
}) => {
  return (
    <Box display="flex" mt={1.5} px={1.5} gap="0 4px">
      <ChipValue value={scoreMatch} bgcolor="blue.main" fontColor="secondary.main" />
      <ChipValue
        title="Size & Scalability:"
        value={sizeMatch}
        bgcolor={getBarColor(sizeMatch)}
        fontColor={getMobileHeatMapChipFontColor(sizeMatch)}
      />
      <ChipValue
        title="Feature:"
        value={featureMatch}
        bgcolor={getBarColor(featureMatch)}
        fontColor={getMobileHeatMapChipFontColor(featureMatch)}
      />
      <ChipValue
        title="Characteristic:"
        value={characteristicMatch}
        bgcolor={getBarColor(characteristicMatch)}
        fontColor={getMobileHeatMapChipFontColor(characteristicMatch)}
      />
    </Box>
  );
};

export default React.memo(MobileHeaderChip);
