import { useCallback } from 'react';

import { useAPI, useProject } from '@hooks';

import { sendMeetRequest } from '../@slices';
import { FetchSendMeetRequestProps } from '../../@api';

const { action } = sendMeetRequest;

const useFetchSendMeetRequest = () => {
  const { id: projectId } = useProject();

  return useAPI(
    useCallback(
      (todoId: number, props: FetchSendMeetRequestProps) => action(projectId, todoId, props),
      [projectId],
    ),
  );
};

export default useFetchSendMeetRequest;
