import React, { useMemo } from 'react';

import Typography from 'components/@ui/Typography';
import { useUser } from '@hooks';

import { useNavbar } from '../@hooks';

const UserName: React.FC = () => {
  const { name } = useUser();
  const { isOpenNavbar } = useNavbar();

  return useMemo(
    () => (
      <Typography component="span" semiBold>
        {isOpenNavbar ? name : ''}
      </Typography>
    ),
    [isOpenNavbar, name],
  );
};

export default UserName;
