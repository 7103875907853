import React from 'react';

import { TableCellColored, TypographyCellUi } from '../HeatMapViewComponents';
import { useFetchVendors } from './@hooks';

const HeatMapSizeMatch: React.FC = () => {
  const [{ records }] = useFetchVendors();

  return (
    <>
      {records.map((product) => (
        <TableCellColored key={product.id} cellContent={product.sizeMatch}>
          <TypographyCellUi>{product.sizeMatch}</TypographyCellUi>
        </TableCellColored>
      ))}
    </>
  );
};

export default HeatMapSizeMatch;
