import { useCallback, useMemo } from 'react';

import { useFetchProject } from '@api/@hooks';
import { useProject } from '@hooks';
import { checkStatus } from '@helpers';

import { useSubscriptionWs } from '../@hooks';
import { createIdentifier } from '../@helpers';
import { projects } from './config';

const useConnectToWs = () => {
  const [, fetchProject] = useFetchProject();
  const { id } = useProject();

  const fetchRefresh = useCallback(async () => {
    if (!id) return false;
    const response = await fetchProject();
    return checkStatus(response?.status);
  }, [fetchProject, id]);

  const channel = useSubscriptionWs({
    identifier: createIdentifier(projects.projectHiddenData, id),
    fetchRefresh,
    initialSubscribe: Boolean(id),
  });

  return useMemo(() => channel, [channel]);
};

export default useConnectToWs;
