import { combineReducers } from 'redux';

import { p, createSlice } from '@redux/slices';

import { fetchGetInvitedByToken } from '../@api';

const prefix = 'INVITATION';

export const getInvitedByToken = createSlice(
  p('GET_INVITED_BY_TOKEN', prefix),
  fetchGetInvitedByToken,
  { initialState: { invitedMember: {} } },
);

export default combineReducers({
  getInvitedByToken: getInvitedByToken.reducer,
});
