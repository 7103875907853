import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import Icon from 'components/@ui/Icon';

import { colorGenerator } from '../@helpers';
import { TimeLineBar } from '../@types';

type CustomStepIconProps = {
  status: TimeLineBar.Statuses;
};
const MobileCustomPhaseStepIcon: React.FC<CustomStepIconProps> = ({ status }) => {
  const iconName = useMemo(() => {
    switch (status) {
      case 'done':
        return 'check';
      case 'current':
        return 'hourglass-path';
      default:
        return '';
    }
  }, [status]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={colorGenerator(status, 'stepperIconBackground')}
      width={24}
      height={24}
      borderRadius={10}
      border={2}
      borderColor={colorGenerator(status, 'stepperConnector')}
    >
      {status !== 'next' && (
        <Icon
          name={iconName}
          width={14}
          height={14}
          fontSize={14}
          borderRadius={0}
          color="secondary.main"
        />
      )}
    </Box>
  );
};

export default React.memo(MobileCustomPhaseStepIcon);
