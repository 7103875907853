import { useMemo } from 'react';
import { useUpdateEffect } from 'react-use';

import { useEvent, useOpenModal } from '@hooks';

import { usePhases } from '../../Phases';

const useTolltip = (openProp?: boolean) => {
  const { open, handleClose, handleOpen } = useOpenModal(openProp);
  const { dragState } = usePhases();

  const onOpenTooltip = useEvent(() => {
    !dragState.dragging && handleOpen();
  });

  useUpdateEffect(() => {
    dragState.dragging && handleClose();
  }, [dragState.dragging]);

  return useMemo(
    () => ({ isOpenTooltip: open, onOpenTooltip, onCloseTooltip: handleClose }),
    [open, onOpenTooltip, handleClose],
  );
};

export default useTolltip;
