import { permissionCreate } from '@permissions';

const changeProjectName = permissionCreate('SETTINGS_CHANGE_PROGECT_NAME');
const changeProjectWebsite = permissionCreate('SETTINGS_CHANGE_PROGECT_WEBSITE');
const changeProjectGoLiveDate = permissionCreate('SETTINGS_CHANGE_PROGECT_GO_LIVE_DATE');
const transferOwnership = permissionCreate('SETTINGS_TRANSFER_OWNERSHIP');

export const SETTINGS = {
  [changeProjectName]: changeProjectName,
  [changeProjectWebsite]: changeProjectWebsite,
  [changeProjectGoLiveDate]: changeProjectGoLiveDate,
  [transferOwnership]: transferOwnership,
};

export default SETTINGS;
