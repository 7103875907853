import React from 'react';

import Box, { BoxProps } from 'components/@ui/Box';

const VendorsBodyWrapper: React.FC<BoxProps> = React.memo((props) => {
  return (
    <Box
      mx="auto"
      maxWidth={1032}
      px={{ mobile: 2, laptop: 3 }}
      pb={{ mobile: 10, laptop: 0 }}
      display="flex"
      justifyContent="center"
      minHeight={{ mobile: '300px', laptop: 'fit-content' }}
      sx={{ transform: 'perspective(0px)' }}
      {...props}
    />
  );
});

export default VendorsBodyWrapper;
