import React from 'react';
import MuiDialogActions, {
  DialogActionsProps as MuiDialogActionsProps,
} from '@mui/material/DialogActions';

import { parseSx, clsx } from '@helpers';

export interface DialogActionsProps extends MuiDialogActionsProps {}

// @ts-ignore
const DialogActions: React.FC<DialogActionsProps> = React.forwardRef((props, ref) => {
  const { sx, ...others } = props;

  return (
    <MuiDialogActions ref={ref} disableSpacing sx={clsx({ p: 0 }, ...parseSx(sx))} {...others} />
  );
});

export default React.memo(DialogActions);
