import React, { useCallback, useMemo } from 'react';
import { not } from 'ramda';

import Checkbox from 'components/@ui/Checkbox';
import { useAbility } from '@permissions';

import { useToDoItem } from '../TodoItem';

const CheckboxToDoItem: React.FC = () => {
  const { marked, changeTodoItem } = useToDoItem();
  const canMarkTodoItem = useAbility().abilityCheck('TIME_LINE_MARK_TODO_ITEM');

  const onChangeTodoItem = useCallback(() => {
    if (canMarkTodoItem) {
      changeTodoItem()();
    }
  }, [canMarkTodoItem, changeTodoItem]);

  return useMemo(
    () => (
      <Checkbox
        disabled={not(canMarkTodoItem)}
        checked={marked}
        onChange={onChangeTodoItem}
        variant="green"
      />
    ),
    [canMarkTodoItem, marked, onChangeTodoItem],
  );
};

export default CheckboxToDoItem;
