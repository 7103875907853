import React from 'react';
import XarrowLib, { xarrowPropsType } from 'react-xarrows';
import { useTheme } from '@mui/material';

import Box, { BoxProps } from 'components/@ui/Box';
import Dialog, { DialogProps } from 'components/@ui/Dialog';
import IconButton, { IconButtonProps } from 'components/@ui/IconButton';
import Typography, { TypographyProps } from 'components/@ui/Typography';
import { clsx, parseSx } from '@helpers';

export const REMAINDERS_ANHOR = {
  shortlist: { start: 'Reminder-Vendors-Shortlist-Start', end: 'Reminder-Vendors-Shortlist-End' },
  workspace: { start: 'Reminder-Vendors-Workspace-Start', end: 'Reminder-Vendors-Workspace-End' },
} as const;

export const WORDING = {
  shortlist: 'Add up to 8 vendors to your shortlist',
  workspace: 'Add finalists to your workspace and request a demo',
} as const;

export const Dialog400: React.FC<DialogProps> = (props) => (
  <Dialog
    {...props}
    disableScrollLock
    PaperProps={{
      ...props.PaperProps,
      sx: clsx({ maxWidth: 400 }, ...parseSx(props.PaperProps?.sx)),
    }}
  />
);

export const Xarrow: React.FC<xarrowPropsType> = (props) => {
  const theme = useTheme();

  return (
    <XarrowLib
      strokeWidth={2}
      dashness={{ strokeLen: 7.5, nonStrokeLen: 7.5 }}
      color={theme.palette.yellow.main}
      startAnchor="right"
      endAnchor="right"
      curveness={0.8}
      showHead={false}
      _cpx1Offset={70}
      _cpx2Offset={70}
      _cpy1Offset={15}
      _cpy2Offset={-15}
      SVGcanvasStyle={{ paddingLeft: 2 }}
      {...props}
    />
  );
};

export const RemindersWrapper: React.FC<BoxProps> = (props) => (
  <Box display="flex" flexWrap="nowrap" ml={-1} mr={-2.5} left={8} position="relative" {...props} />
);

export const RemindersWordingBox: React.FC<BoxProps> = (props) => (
  <Box px={3} py={1.5} minHeight={56} bgcolor="yellow.main" borderRadius={28} {...props} />
);

export const RemindersWording: React.FC<TypographyProps> = (props) => (
  <Typography color="text.secondary" semiBold fontSize={13} lineHeight={1.2} {...props} />
);

export const IconButtonReminders: React.FC<Partial<IconButtonProps>> = (props) => (
  <IconButton
    iconName="delete"
    color="inherit"
    {...props}
    IconProps={{ fontSize: 30, width: 30, ...props.IconProps }}
    sx={clsx({ p: 0.25 }, ...parseSx(props.sx))}
  />
);
