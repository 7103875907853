export const switchIconName = (
  isVendorOfChoice: boolean,
  isWorkspace?: boolean,
  isDisabled?: boolean,
  isLoading?: boolean,
) => {
  switch (true) {
    case isWorkspace:
      return 'home-workspace-added-path';
    case !isWorkspace && (!isDisabled || isLoading) && !isVendorOfChoice:
      return 'home-workspace-add-path';
    case isDisabled:
      return 'disabled-add-workspace-path';
    default:
      return 'disabled-add-workspace-path';
  }
};

export const switchIconColor = (
  isVendorOfChoice: boolean,
  isWorkspace?: boolean,
  isDisabled?: boolean,
  isLoading?: boolean,
) => {
  switch (true) {
    case isWorkspace:
      return 'greenLight.main';
    case !isWorkspace && !isDisabled && (isLoading || !isVendorOfChoice):
      return 'blue.main';
    case isDisabled:
      return 'primary.light';
    default:
      return 'primary.light';
  }
};
