import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';
import * as H from 'history';

import { Children, UseState } from '@types';

type ContextType = {
  when: boolean;
  showPrompt: UseState<boolean>;
};

const defaultMessage = 'Routing transition? \nChanges you made may not be saved.';

export type RouterPromptProviderType = {
  disabledRouterTransition?: boolean;
  disabledBrowserTransition?: boolean;
  disabledTransition?: boolean;
  message?: string;
} & Children;

export const RouterPromptContext = React.createContext<ContextType>({} as any);
export const RouterPromtConsumer = RouterPromptContext.Consumer;

export const RouterPromptProvider: React.FC<RouterPromptProviderType> = (props) => {
  const {
    children,
    disabledRouterTransition,
    disabledBrowserTransition,
    disabledTransition,
    message = defaultMessage,
  } = props;

  const [when, showPrompt] = useState(false);

  const contextValue = useMemo<ContextType>(
    () => ({
      when,
      showPrompt,
    }),
    [when],
  );

  const onMessage = useCallback(
    (location: H.Location) => {
      return location.pathname === '/login' || message;
    },
    [message],
  );

  const beforeUnloadListener = useCallback(
    (event: BeforeUnloadEvent) => {
      if (when && !disabledBrowserTransition && !disabledTransition) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      } else {
        return false;
      }
    },
    [disabledBrowserTransition, disabledTransition, when],
  );

  useEffect(() => {
    if (when) {
      window.addEventListener('beforeunload', beforeUnloadListener);
    }
    return () => window.removeEventListener('beforeunload', beforeUnloadListener);
  }, [beforeUnloadListener, when]);

  return (
    <RouterPromptContext.Provider value={contextValue}>
      {children}
      <Prompt when={when && !disabledRouterTransition && !disabledTransition} message={onMessage} />
    </RouterPromptContext.Provider>
  );
};

export default React.memo(RouterPromptProvider);
