import { combineReducers } from 'redux';

import { CA, P, createS } from '@redux/slices';

import { fetchTeamMember, fetchTeamMembers, fetchTransferOwnership } from '../@api';

const SUBTITLE = 'SETTINGS';

export const teamMember = createS(CA(P('TEAM_MEMBER', SUBTITLE)), fetchTeamMember, {
  initialState: { groups: [] },
});
export const teamMembers = createS(
  CA(P('TEAM_MEMBERS_WITH_OUT_OWNER', SUBTITLE)),
  fetchTeamMembers,
  { initialState: [] },
);
export const transferOwnership = createS(
  CA(P('TRANSFER_OWNERSHIP', SUBTITLE)),
  fetchTransferOwnership,
);

export default combineReducers({
  teamMember: teamMember.reducer,
  teamMembers: teamMembers.reducer,
  transferOwnership: transferOwnership.reducer,
});
