import { combineReducers } from 'redux';

import { createSlice } from '@redux/slices';

import { fetchAdvisor } from '../@api';

export const advisorData = createSlice('FETCH_ADVISOR', fetchAdvisor);

export default combineReducers({
  advisor: advisorData.reducer,
});
