import baseAPI, { APIResponse } from '@api';

const apiCredentials = baseAPI();
//-------------------------------------------------------------------------
// Get Request Demo
//-------------------------------------------------------------------------

export type ProductProps = {
  requestDemo: boolean;
  alreadyInTouch: boolean;
  productId: number;
  productName: string;
  logoURL: string;
};

export type FetchRequestDemoResponse = {
  submitted: boolean;
  vendors: string;
  products: ProductProps[];
};

export function fetchRequestDemo(projectId: number): APIResponse<FetchRequestDemoResponse> {
  return apiCredentials.get(`/projects/${projectId}/project_demo_request`);
}

//-------------------------------------------------------------------------
// List for Vendors
//-------------------------------------------------------------------------

export type ProductInList = {
  id: number;
  logoUrl: string;
  name: string;
  logoSmallUrl: string;
};

export type FetchListVendorsResponse = ProductInList[];

export function fetchListVendors(): APIResponse<FetchListVendorsResponse> {
  return apiCredentials.get('/products?products_briefly=true');
}

//-------------------------------------------------------------------------
// Put Request Demo
//-------------------------------------------------------------------------

export type SendRequestDemoProps = {
  alreadyInTouchIds: number[];
  demoRequestedIds: number[];
  HRISVendor: string | null;
};

export function fetchSendRequestDemo(projectId: number, data: SendRequestDemoProps) {
  return apiCredentials.put(`projects/${projectId}/project_demo_request/design_demo`, {
    products: {
      already_in_touch_ids: data.alreadyInTouchIds,
      demo_requested_ids: data.demoRequestedIds,
    },
    project_demo_request: {
      vendor: data.HRISVendor,
    },
  });
}

//-------------------------------------------------------------------------
