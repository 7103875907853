import baseAPI, { APIResponse } from '@api';
import { IdField } from '@types';

import { Team } from '../../Team/@types';

const api = baseAPI(false);
//--------------------------------------------------------------------------------------------------
// Get Invited By Token
//--------------------------------------------------------------------------------------------------
export type FetchGetInvitedByTokenResponse = {
  invitedMember: IdField<{
    clientId: number;
    email: string;
    role: Team.Role;
    projectId: number;
    projectName: string;
    accountExists: boolean;
  }>;
};
export function fetchGetInvitedByToken(token: string): APIResponse<FetchGetInvitedByTokenResponse> {
  return api.get(`/invitations/token_information`, {
    params: { token },
  });
}
//--------------------------------------------------------------------------------------------------
