import React from 'react';
import Typography from 'components/@ui/Typography';
import DemoRequestVendorsContainer from './DemoRequestVendorsContainer';

const DemoRequest: React.FC = () => {
  return (
    <>
      <Typography mt={1.5} textAlign="center">
        You are requesting an initial demo with the following vendors:
      </Typography>

      <DemoRequestVendorsContainer />

      <Typography mt={1.5} fontSize={12} lineHeight="normal" color="gray.main">
        By letting us know which vendors you're already speaking with, you'll continue working with
        the same sales reps and maintain any existing progress.
      </Typography>
    </>
  );
};

export default DemoRequest;
