import React from 'react';

import { Table as MuiTable, TableProps as MuiTableProps } from '@mui/material';

export interface TableProps extends MuiTableProps {}

// @ts-ignore
const Table: React.FC<TableProps> = React.forwardRef((props, ref) => {
  return <MuiTable {...props} ref={ref}></MuiTable>;
});

export default React.memo(Table);
