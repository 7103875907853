import React from 'react';

import Box from 'components/@ui/Box';
import { useIsTablet } from '@hooks';

import TableCell from './TableCell';
import { AddOrDeleteShortlistIconButtonWithHOC } from '../VendorComponents';

type ShortlistAddCellProps = {
  cellId: number;
  AddOrDeleteShortlistedComponent?: React.FC<any>;
};

const ShortlistAddCell: React.FC<ShortlistAddCellProps> = ({
  cellId,
  AddOrDeleteShortlistedComponent = AddOrDeleteShortlistIconButtonWithHOC,
}) => {
  const isTablet = useIsTablet();

  return (
    <TableCell>
      <Box position="relative" height="100%" zIndex={4}>
        <AddOrDeleteShortlistedComponent vendorId={cellId} hideToolTip={isTablet} />
      </Box>
    </TableCell>
  );
};

export default React.memo(ShortlistAddCell);
