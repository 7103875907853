import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useUnmount } from 'react-use';

import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';
import Icon from 'components/@ui/Icon';
import Button from 'components/@ui/Button';
import { useFetchProject } from '@api/@hooks';

import { useFetchSendRequestDemo, useRequestDemoModal } from './@hooks';
import { MODAL_STEP } from './Context';
import { SendRequestDemoProps } from './@api';

const RequestPrepare: React.FC = () => {
  const [isSendingDemo, setIsSendingDemo] = useState(false);

  const { setModalStep, checkBoxState, hRISValue, isHaveHRIS } = useRequestDemoModal();

  const [, fetchSendRequestDemo] = useFetchSendRequestDemo();
  const [, fetchProject] = useFetchProject();

  const sendRequest = useCallback(async () => {
    if (isSendingDemo) return;
    setIsSendingDemo(true);
    const data: SendRequestDemoProps = {
      alreadyInTouchIds: checkBoxState
        .filter((vendor) => vendor.alreadyInTouch)
        .map((vendor) => vendor.productId),
      demoRequestedIds: checkBoxState
        .filter((vendor) => vendor.requestDemo && !vendor.isDisabledRequest)
        .map((vendor) => vendor.productId),
      HRISVendor: isHaveHRIS
        ? hRISValue?.added ?? hRISValue?.label ?? "We don't have an HRIS"
        : "We don't have an HRIS",
    };
    const send = await fetchSendRequestDemo(data);
    if (send.status <= 400) {
      toast.success(
        <>
          <Typography>
            <Typography semiBold component="span">
              Success:
            </Typography>{' '}
            Request sent
          </Typography>
        </>,
      );
      setModalStep(MODAL_STEP.designDemo)();
      fetchProject();
    }
    setIsSendingDemo(false);
  }, [
    checkBoxState,
    isSendingDemo,
    fetchSendRequestDemo,
    setModalStep,
    hRISValue,
    fetchProject,
    isHaveHRIS,
  ]);

  useUnmount(() => setIsSendingDemo(false));

  return useMemo(
    () => (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="28px 24px"
        width={500}
        boxShadow={3}
        borderRadius={4}
        bgcolor="secondary.main"
      >
        <Box display="flex" alignItems="center">
          <Icon name="proposal-sent-icon-path" color="gray.dark" borderRadius={0} />
          <Typography
            color="gray.dark"
            semiBold
            ml={1.25}
            fontSize={24}
            lineHeight="32px"
            component="h2"
          >
            Request prepared
          </Typography>
        </Box>
        <Typography textAlign="center" mt={2} color="gray.dark" px={3}>
          Once the vendor receives the request, <br />
          you will get instructions via email to schedule a meeting.
          <br /> Usually it takes 1-3 days.
        </Typography>
        <Button fullWidth sx={{ marginTop: 3 }} disabled={isSendingDemo} onClick={sendRequest}>
          Confirm and send
        </Button>
        <Button variant="text" fullWidth onClick={setModalStep(MODAL_STEP.cancelRequest)}>
          Cancel request
        </Button>
      </Box>
    ),
    [sendRequest, setModalStep, isSendingDemo],
  );
};

export default RequestPrepare;
