import React, { SyntheticEvent, useCallback, useRef, useState } from 'react';
import MuiTooltip, { tooltipClasses, TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import {
  ClickAwayListener,
  ClickAwayListenerProps,
  Theme,
  useForkRef,
  useMediaQuery,
} from '@mui/material';
import { isNotUndefined } from 'ramda-adjunct';

import When from 'components/@main/When';
import { clsx, makeSxStyles, parseSx } from '@helpers';

const Wrapper: React.FC<ClickAwayListenerProps> = ({ children, ...other }) => {
  const disableHoverTooltip = !useMediaQuery(`(hover: hover`);
  return (
    <When disabledWrapper condition={disableHoverTooltip} fallback={children}>
      <ClickAwayListener {...other}>
        <>{children}</>
      </ClickAwayListener>
    </When>
  );
};

const isOverflowed = <T extends HTMLElement>(el?: T | null) => {
  if (!el) return false;
  return el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight;
};

type StylesSx = {
  hideWhenContentFits?: boolean;
  maxWidth?: number;
  tooltipMargin?: number;
};

export interface TooltipProps extends MuiTooltipProps, StylesSx {
  listenerProps?: ClickAwayListenerProps;
}

const useSx = makeSxStyles(({ maxWidth, tooltipMargin = 0.5 }: StylesSx) => ({
  popper: {
    ...(maxWidth && { maxWidth }),
    [`&.${tooltipClasses.popper}[data-popper-placement*="right"]`]: {
      [`.${tooltipClasses.tooltip}`]: {
        marginLeft: (theme: Theme) => theme.spacing(tooltipMargin),
      },
      [`.${tooltipClasses.arrow}`]: {
        left: (theme: Theme) => theme.spacing(0.25),
      },
    },
    [`&.${tooltipClasses.popper}[data-popper-placement*="left"]`]: {
      [`.${tooltipClasses.tooltip}`]: {
        marginRight: (theme: Theme) => theme.spacing(tooltipMargin),
      },
      [`.${tooltipClasses.arrow}`]: {
        right: (theme: Theme) => theme.spacing(0.25),
      },
    },
    [`&.${tooltipClasses.popper}[data-popper-placement*="top"]`]: {
      [`.${tooltipClasses.tooltip}`]: {
        marginBottom: (theme: Theme) => theme.spacing(tooltipMargin),
      },
      [`.${tooltipClasses.arrow}`]: {
        bottom: (theme: Theme) => theme.spacing(0.25),
      },
    },
    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"]`]: {
      [`.${tooltipClasses.tooltip}`]: {
        marginTop: (theme: Theme) => theme.spacing(tooltipMargin),
      },
      [`.${tooltipClasses.arrow}`]: {
        top: (theme: Theme) => theme.spacing(0.25),
      },
    },
    [`.${tooltipClasses.tooltip} `]: {
      background: (theme: Theme) => theme.palette.text.primary,
      padding: (theme: Theme) => theme.spacing(0.5, 1.5),
      fontSize: 12,
      lineHeight: 1.5,
      ...(maxWidth && { maxWidth }),
    },
    [`.${tooltipClasses.arrow}`]: {
      color: (theme: Theme) => theme.palette.text.primary,
    },
  },
}));

// @ts-ignore
const Tooltip: React.FC<TooltipProps> = React.forwardRef((props, ref) => {
  const {
    hideWhenContentFits,
    onClose,
    onOpen,
    open: openProp,
    PopperProps,
    maxWidth,
    tooltipMargin,
    listenerProps,
    ...other
  } = props;
  const sxes = useSx({ maxWidth, tooltipMargin });
  const disableHoverTooltip = !useMediaQuery(`(hover: hover`);

  const { current: isControlled } = useRef(isNotUndefined(openProp));
  const [openState, setOpenState] = useState(false);
  const open = isControlled ? openProp : openState;
  const ownRef = useRef(null);

  useForkRef(ref, ownRef);

  const handleOpen = useCallback(
    (e: SyntheticEvent<Element, Event>) => {
      if (hideWhenContentFits && !isOverflowed(ownRef.current)) return undefined;
      if (isControlled && onOpen) return onOpen(e);
      setOpenState(true);
    },
    [hideWhenContentFits, isControlled, onOpen],
  );

  const handleClose = useCallback(
    (e: Event | SyntheticEvent<Element, Event>) => {
      if (isControlled && onClose) return onClose(e);
      setOpenState(false);
    },
    [isControlled, onClose],
  );

  return (
    <Wrapper {...listenerProps} onClickAway={handleClose}>
      <MuiTooltip
        arrow
        PopperProps={{
          ...(disableHoverTooltip && { disablePortal: true }),
          ...PopperProps,
          sx: clsx(sxes.popper, ...parseSx(PopperProps?.sx)),
        }}
        TransitionComponent={Fade}
        enterTouchDelay={70}
        leaveTouchDelay={5000}
        {...other}
        ref={ownRef}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        {...(disableHoverTooltip && {
          disableFocusListener: true,
          disableHoverListener: true,
        })}
      />
    </Wrapper>
  );
});

export default React.memo(Tooltip);
