import React, { useEffect, useRef, useState } from 'react';
import { Theme } from '@mui/material';
import { useWindowSize } from 'react-use';

import { TextFieldForm, TextFieldFormProps, useForm } from 'components/@main/Form';
import { useEvent } from '@hooks';
import { clsx, parseSx } from '@helpers';
import Fonts from 'styles/fonts';

import TextMask from './TextFieldMask';
import { Common } from './@types';

export type TextFieldFormCodeProps = TextFieldFormProps & {
  ready?: boolean;
};

const TextFieldFormCode: React.FC<TextFieldFormCodeProps> = (props) => {
  const { disabled, ready, inputProps, InputProps, ...other } = props;
  const [inputWidth, setInputWidth] = useState(400);
  const { width } = useWindowSize();
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (inputRef.current) {
      setInputWidth(inputRef.current.clientWidth);
    }
  }, [width]);

  return (
    <TextFieldForm
      disabled={disabled || ready}
      size="large"
      {...other}
      inputProps={{
        ...inputProps,
        sx: clsx(
          {
            px: 3.5,
            fontSize: 24,
            fontFamily: Fonts.ProximaNovaBold,
            color: 'primary.dark',
            letterSpacing: `calc(${inputWidth * 0.0305}px - 9.42px)`,
            fontVariantNumeric: 'tabular-nums',
            '&.MuiInputBase-input': {
              ...(ready && {
                color: 'greenLight.main',
                WebkitTextFillColor: (theme: Theme) => theme.palette.greenLight.main,
              }),
            },
          },
          ...parseSx(inputProps?.sx),
        ),
      }}
      InputProps={{
        ref: inputRef,
        ...InputProps,
        inputComponent: TextMask as any,
        sx: clsx(
          {
            borderRadius: 4,
            borderWidth: 2,
            boxShadow: 0,
            '&.MuiInputBase-root': {
              '&.Mui-focused': { boxShadow: 8 },
              '&.Mui-error': {
                '.MuiInputBase-input': {
                  color: 'error.main',
                  WebkitTextFillColor: (theme: Theme) => theme.palette.error.main,
                },
              },
              ...(ready && { borderColor: 'greenLight.main' }),
            },
          },
          ...parseSx(InputProps?.sx),
        ),
      }}
    />
  );
};

export default React.memo(TextFieldFormCode);

export const useCodeMutator = (resetField?: boolean) => {
  const {
    reset,
    formState: { errors },
  } = useForm<Common.Code>();
  return useEvent((e: string) => {
    const value = e.replace(/\D/g, '');
    resetField && errors['code'] && reset({ code: value });
    return value;
  });
};
