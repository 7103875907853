import { TimeLineBar } from '../@types';

export type Phase = TimeLineBar.Phase & { isSelected: boolean };

export const phases = (isSelected: boolean): Phase[] => [
  {
    id: 19,
    isSelected,
    name: 'Requirements',
    status: 'current',
    finishDate: '2023-07-06',
    todos: [
      {
        id: 4800,
        status: 'current',
        name: 'Project Goals',
        requiredSubscription: null,
      },
      {
        id: 4801,
        status: 'next',
        name: 'Project Scope',
        requiredSubscription: null,
      },
      {
        id: 4802,
        status: 'next',
        name: 'Sign Up',
        requiredSubscription: null,
      },
    ],
  },
  {
    id: 20,
    isSelected: false,
    name: 'Matches',
    status: 'next',
    finishDate: '2023-07-13',
    todos: [
      {
        id: 4803,
        status: 'next',
        name: 'Sign Up',
        requiredSubscription: null,
      },
      {
        id: 4804,
        status: 'next',
        name: 'Vendor Matches',
        requiredSubscription: null,
      },
      {
        id: 4805,
        status: 'next',
        name: 'Invite Team',
        requiredSubscription: 'pro',
      },
      {
        id: 4806,
        status: 'next',
        name: 'Select Finalists',
        requiredSubscription: null,
      },
      {
        id: 4807,
        status: 'next',
        name: 'Design Demo',
        requiredSubscription: null,
      },
    ],
  },
  {
    id: 21,
    isSelected: false,
    name: 'Evaluation',
    status: 'next',
    finishDate: '2023-08-10',
    todos: [
      {
        id: 4808,
        status: 'next',
        name: 'Initial Demos',
        requiredSubscription: 'pro',
      },
      {
        id: 4809,
        status: 'next',
        name: 'Deep Dive Demos',
        requiredSubscription: 'pro',
      },
      {
        id: 4810,
        status: 'next',
        name: 'Proposal Review',
        requiredSubscription: 'pro+',
      },
      {
        id: 4811,
        status: 'next',
        name: 'Eliminate Finalists',
        requiredSubscription: null,
      },
      {
        id: 4812,
        status: 'next',
        name: 'IT Validation',
        requiredSubscription: null,
      },
    ],
  },
  {
    id: 22,
    isSelected: false,
    name: 'Finalization',
    status: 'next',
    finishDate: '2023-08-17',
    todos: [
      {
        id: 4813,
        status: 'next',
        name: 'Reference',
        requiredSubscription: 'pro',
      },
      {
        id: 4814,
        status: 'next',
        name: 'Vendor-of-Choice',
        requiredSubscription: 'pro+',
      },
      {
        id: 4815,
        status: 'next',
        name: 'Negotiations',
        requiredSubscription: 'pro+',
      },
      {
        id: 4816,
        status: 'next',
        name: 'Implementation Prep',
        requiredSubscription: 'pro+',
      },
      {
        id: 4817,
        status: 'next',
        name: 'Execute Contract',
        requiredSubscription: null,
      },
    ],
  },
  {
    id: 23,
    isSelected: false,
    name: 'Implementation',
    status: 'next',
    finishDate: '2023-10-17',
    todos: [
      {
        id: 4818,
        status: 'next',
        name: 'Implementation',
        requiredSubscription: null,
      },
    ],
  },
  {
    id: 24,
    isSelected: false,
    name: 'Go-Live',
    status: 'next',
    finishDate: null,
    todos: [
      {
        id: 4819,
        status: 'next',
        name: 'Go-Live',
        requiredSubscription: null,
      },
    ],
  },
];
