import { useCallback, useMemo } from 'react';
import { createSelector } from 'reselect';

import { useAPI, useProject } from '@hooks';
import { useAppDispatch } from '@redux/store';
import { FetchProjectBodyUpdate } from '@api/project';
import { checkStatus } from '@helpers';

import { projectGet, projectUpdate, selectors } from '../@slices';

const selector = createSelector(selectors.project, (state) => state.projectUpdate);

const useFetchProjectUpdate = () => {
  const dispatch = useAppDispatch();
  const { id: projectId } = useProject();

  const [data, fetch] = useAPI(
    useCallback(
      (props: FetchProjectBodyUpdate) => projectUpdate.action(projectId, props),
      [projectId],
    ),
    selector,
  );

  const fetchProjectUpdate = useCallback(
    async (props: FetchProjectBodyUpdate) => {
      const response = await fetch(props);
      if (checkStatus(response?.status)) dispatch(projectGet.actions.FULFILLED(response));
      return response;
    },
    [dispatch, fetch],
  );

  return useMemo(() => [data, fetchProjectUpdate] as const, [data, fetchProjectUpdate]);
};

export default useFetchProjectUpdate;
