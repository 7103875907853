import { useCallback, useMemo, useState } from 'react';
import { useUnmount } from 'react-use';

export type AnchorPopoverEl<T extends HTMLElement = HTMLButtonElement> = T | null;
export type OnOpenPopover<T = AnchorPopoverEl, R = void> = (event: React.MouseEvent<T>) => R;

export type UsePopoverAnchorReturnType<T = AnchorPopoverEl> = {
  anchorPopoverEl: T | null;
  onOpenPopover: OnOpenPopover<T>;
  onClosePopover: () => void;
};

const usePopoverAnchor = <T = AnchorPopoverEl>(anchor?: T | null) => {
  const [anchorPopoverEl, setAnchorPopoverEl] = useState<T | null>(anchor ?? null);

  const onOpenPopover = useCallback<OnOpenPopover<T>>((event) => {
    setAnchorPopoverEl(event.currentTarget);
  }, []);

  const onClosePopover = useCallback(() => {
    setAnchorPopoverEl(null);
  }, []);

  useUnmount(() => {
    setAnchorPopoverEl(null);
  });

  return useMemo<UsePopoverAnchorReturnType<T>>(
    () => ({
      anchorPopoverEl,
      onOpenPopover,
      onClosePopover,
    }),
    [anchorPopoverEl, onClosePopover, onOpenPopover],
  );
};

export default usePopoverAnchor;
