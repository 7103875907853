import { combineReducers } from 'redux';

import { createSlice, createSliceData, createSliceSimple } from '@redux/slices';

import {
  fetchAllScores,
  fetchHideClientEvaluate,
  fetchScoreNotes,
  fetchTargetScore,
} from '../@api';

export const scoreData = createSlice('FETCH_TARGET_SCORE', fetchTargetScore);
export const scoreNotesData = createSlice('FETCH_SCORE_NOTES', fetchScoreNotes);
export const hideScore = createSliceSimple('FETCH_HIDE_CLIENT_EVALUATE', fetchHideClientEvaluate);
export const scoresData = createSlice('FETCH_ALL_SCORES', fetchAllScores, {
  initialState: { products: [] },
});

export const onboardingModal = createSliceData('ONBOARDING_MODAL_SCORES', false);

export default combineReducers({
  onboadingModal: onboardingModal.reducer,
  allScores: scoresData.reducer,
  targetScore: scoreData.reducer,
  scoreNotes: scoreNotesData.reducer,
});
