import React, { useMemo } from 'react';

import Icon, { IconProps } from 'components/@ui/Icon';

import { TimeLineBar } from '../@types';
import { useToDo } from './Context';

const iconProps: Partial<IconProps> = {
  width: 'fit-content',
  height: 24,
  fontSize: '24px !important',
  borderRadius: 0,
};

export const ToDoStartIconUi: React.FC<TimeLineBar.ToDo> = (todo) => {
  switch (todo.requiredSubscription) {
    case TimeLineBar.SubscriptionStateEnum['pro']:
      return (
        <Icon name="pro-version-icon-path" {...iconProps}>
          <span className="path1" />
          <span className="path2" />
        </Icon>
      );
    case TimeLineBar.SubscriptionStateEnum['pro+']:
      return (
        <Icon name="pro-icon-path" {...iconProps}>
          <span className="path1" />
          <span className="path2" />
        </Icon>
      );
    default:
      return null;
  }
};

const ToDoStartIcon = () => {
  const { todo } = useToDo();

  return useMemo(() => <ToDoStartIconUi {...todo} />, [todo]);
};

export default ToDoStartIcon;
