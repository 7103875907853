import React from 'react';

import { AppLayoutWrapper, AppLayoutWrapperProps } from '@layout/AppLayout';

import NoMatchPage from './NoMatchPage';

const NoMatchPageComponent: React.FC<AppLayoutWrapperProps> = (props) => {
  return (
    <AppLayoutWrapper {...props}>
      <NoMatchPage />
    </AppLayoutWrapper>
  );
};

export default React.memo(NoMatchPageComponent);
