import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAPI } from '@hooks';

import { listVendors, selectors } from '../@slices';

const selector = createSelector(selectors.root, (state) => state.listVendors);
const { action } = listVendors;

const useFetchListVendors = () => {
  return useAPI(
    useCallback(() => action(), []),
    selector,
  );
};

export default useFetchListVendors;
