import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import Tooltip from 'components/@ui/Tooltip';
import { Children } from '@types';

import PhaseTooltipTitle from './PhaseTooltipTitle';
import { usePhase } from '../Context';

const PhaseTooltip: React.FC<Children> = ({ children }) => {
  const { isOpenTooltip, onOpenTooltip, onCloseTooltip } = usePhase();

  return useMemo(
    () => (
      <Tooltip
        disableInteractive
        open={isOpenTooltip}
        onOpen={onOpenTooltip}
        onClose={onCloseTooltip}
        placement="bottom"
        title={<PhaseTooltipTitle />}
        maxWidth={125}
      >
        <Box width={1}>{children}</Box>
      </Tooltip>
    ),
    [children, onCloseTooltip, onOpenTooltip, isOpenTooltip],
  );
};

export default PhaseTooltip;
