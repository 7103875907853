import React from 'react';
import { Theme } from '@mui/material';

import TableContainerUi from 'components/@ui/TableContainer';
import Table from 'components/@ui/Table';
import { Children } from '@types';

const TableContainer: React.FC<Children> = ({ children }) => {
  return (
    <TableContainerUi
      sx={{
        boxShadow: 3,
        maxWidth: { mobile: 880, laptop: 984 },
        position: 'relative',
        borderRadius: 4,
        maxHeight: { tablet: 'calc(100vh - 180px)', laptop: 'calc(100vh - 180px)' },
        bgcolor: 'secondary.main',
        '&': (theme: Theme) => theme.scrollbar(),
        '& table': {
          height: '1px',
        },
      }}
    >
      <Table sx={{ width: 'max-content', borderCollapse: 'separate', position: 'relative', pr: 2 }}>
        {children}
      </Table>
    </TableContainerUi>
  );
};

export default TableContainer;
