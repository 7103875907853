import React from 'react';

import Box from 'components/@ui/Box';
import Icon from 'components/@ui/Icon';
import Typography from 'components/@ui/Typography';
import When from 'components/@main/When';
import { useIsLogin, useOnline, useSignOut } from '@hooks';

import ToastButton from './ToastButton';
import { useHistory } from 'react-router';
import { ROUTES } from '@routes/config';

const ToastOffline: React.FC = () => {
  const history = useHistory();
  const isLogin = useIsLogin();
  const signOutFn = useSignOut();
  const isOnline = useOnline();

  const signOut = () => {
    signOutFn();
    if (isOnline) {
      history.push(ROUTES.auth.login.email);
    } else {
      window.location.replace(ROUTES.auth.login.email);
    }
  };

  return (
    <>
      <Box display="flex" mb={0.5}>
        <Icon name="Cloud-off" />
        <Typography fontSize={14} lineHeight={24 / 14} ml={1} semiBold>
          You are offline
        </Typography>
      </Box>
      <Typography fontSize={14} lineHeight={24 / 14}>
        Reconnecting...
      </Typography>
      <When condition={isLogin} disabledWrapper>
        <ToastButton onClick={signOut}>Sign Out</ToastButton>
      </When>
    </>
  );
};

export default ToastOffline;
