import { length, clone } from 'ramda';

export default <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  if (length(list) > 1 && startIndex !== endIndex) {
    const result = clone(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }
  return list;
};
