import snakecaseKeys from 'snakecase-keys';

import baseAPI, { APIResponse } from '@api';
import { User } from '@types';

const api = baseAPI(false);
const apiCredentials = baseAPI();

//--------------------------------------------------------------------------------------------------
// Email Validation
//--------------------------------------------------------------------------------------------------
type EmailValidationResponseProps = {
  invitedMember?: boolean;
  errors?: string;
};

export function fetchEmailValidation(email: string): APIResponse<EmailValidationResponseProps> {
  return api.put('/clients/validate_email', { email });
}

//--------------------------------------------------------------------------------------------------
// Sign in
//--------------------------------------------------------------------------------------------------
export type FetchSignInBody = { email: string; password: string };

export function fetchSignIn(props: FetchSignInBody): APIResponse<User.User> {
  return api.post('/client_auth/sign_in', props);
}

//--------------------------------------------------------------------------------------------------
// Resend Code
//--------------------------------------------------------------------------------------------------
export function fetchResendCode() {
  return apiCredentials.get('/clients/resend_code');
}

//--------------------------------------------------------------------------------------------------
// Verify Code
//--------------------------------------------------------------------------------------------------
export function fetchVerifyCode(code: string) {
  return apiCredentials.post('/clients/verify', { verification_code: code });
}

//--------------------------------------------------------------------------------------------------
// Sign out
//--------------------------------------------------------------------------------------------------
export function fetchSignOut() {
  return apiCredentials.delete('/client_auth/sign_out');
}

//--------------------------------------------------------------------------------------------------
// Sign In by Socials
//--------------------------------------------------------------------------------------------------
export type FetchSignInBySocialProps = {
  provider: Exclude<User.Providers, 'email'>;
  accessToken: string;
  inviteToken?: string;
};

export function fetchSignInBySocial(props: FetchSignInBySocialProps): APIResponse<User.UserFields> {
  return api.post('/clients/oauth', snakecaseKeys(props));
}

//--------------------------------------------------------------------------------------------------
