import React from 'react';

import Divider from 'components/@ui/Divider';
import MenuItem from 'components/@ui/MenuItem';

import AddNewProject from './AddNewProject';
import Settings from './Settings';
import SignOut from './SignOut';
import Projects from './Projects';

export type MenuItemsProps = {
  onClosePopover: () => void;
};

const MenuItems: React.FC<MenuItemsProps> = ({ onClosePopover }) => {
  return (
    <>
      <SignOut />
      <Settings onClosePopover={onClosePopover} />
      <MenuItem disabled sx={{ p: 0, minHeight: 0, opacity: '1 !important' }}>
        <Divider sx={{ width: 1 }} />
      </MenuItem>
      <AddNewProject onClosePopover={onClosePopover} />
      <Projects onClosePopover={onClosePopover} />
    </>
  );
};

export default React.memo(MenuItems);
