import { descend, prop, sortWith } from 'ramda';

import { VendorsResponse } from './@api';

export const randomIntFromInterval = (min: number = 1, max: number = 5) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const calculateScoreMatch = (value: number) =>
  randomIntFromInterval(Math.floor(((value - 1) * 100) / 15), Math.floor((value * 100) / 15));

export const makeRandomVendors = (vendors: VendorsResponse) => {
  const vendorsRandom = vendors.map((vendor) => {
    const sizeMatch = randomIntFromInterval();
    const featureMatch = randomIntFromInterval();
    const characteristicMatch = randomIntFromInterval();
    const scoreMatch = calculateScoreMatch(sizeMatch + featureMatch + characteristicMatch);
    return {
      ...vendor,
      scoreMatch,
      sizeMatch,
      featureMatch,
      characteristicMatch,
      softwareFeesMax: 2800,
      softwareFeesMin: 2700,
      implementationCostsMax: 2760,
      implementationCostsMin: 0,
    };
  });
  return sortWith([descend(prop('scoreMatch'))], vendorsRandom);
};
