import { useCallback, useMemo } from 'react';
import { createSelector } from 'reselect';

import { useDispatchSetUser } from 'components/@common/Auth';
import { useAPI } from '@hooks';

import { selectors, updateEmail } from '../@slices';

const selector = createSelector(selectors.user, (state) => state.updateEmail);

const useFetchUpdateEmail = () => {
  const [data, fetch] = useAPI(
    useCallback((email: string, code: string) => updateEmail.action(email, code), []),
    selector,
  );

  const dispatchSetUser = useDispatchSetUser();

  const fetchUpdateEmail = useCallback(
    async (email: string, code: string) => {
      const response = await fetch(email, code);
      dispatchSetUser(response);
      return response;
    },
    [dispatchSetUser, fetch],
  );

  return useMemo(() => [data, fetchUpdateEmail] as const, [data, fetchUpdateEmail]);
};

export default useFetchUpdateEmail;
