import { permissionCreate } from '@permissions';

const useScorecardSettings = permissionCreate('SCORECARD_USE_SETTINGS');
const addKeyCriteria = permissionCreate('SCORECARD_ADD_KEY_CRITERIA');

export const SCORECARD = {
  [useScorecardSettings]: useScorecardSettings,
  [addKeyCriteria]: addKeyCriteria,
};

export default SCORECARD;
