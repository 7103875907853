import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import Icon from 'components/@ui/Icon';
import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';
import Button from 'components/@ui/Button';

import DialogActionsWrapper from './DialogActionsWrapper';
import { useRequestDemoModal } from './@hooks';
import { MODAL_STEP } from './Context';

const CancelRequestModal: React.FC = () => {
  const { requestDemoModalFlowClose, setModalStep } = useRequestDemoModal();

  const closeRequest = useCallback(() => {
    requestDemoModalFlowClose();
    toast.info('Request has been canceled');
  }, [requestDemoModalFlowClose]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center" mt={3} px={3} width={500}>
        <Icon
          name="proposal-sent-icon-path"
          fontSize={96}
          width={96}
          height={96}
          color="error.main"
          borderRadius={0}
        />
        <Box display="flex" flexDirection="column" ml={1.5}>
          <Typography fontSize={24} lineHeight="32px" semiBold component="h2">
            Are you sure you want to cancel request?
          </Typography>
          <Typography mt={1.5}>You can’t undo it.</Typography>
        </Box>
      </Box>
      <DialogActionsWrapper>
        <Button color="error" fullWidth onClick={closeRequest}>
          Yes, сancel request
        </Button>
        <Button
          fullWidth
          variant="text"
          color="inherit"
          onClick={setModalStep(MODAL_STEP.requestPrepared)}
        >
          No, back to request
        </Button>
      </DialogActionsWrapper>
    </>
  );
};

export default CancelRequestModal;
