import React, { useCallback, useMemo, useState } from 'react';

import { createContext } from '@helpers';
import { Children } from '@types';

import { useToDoItems } from '../Context';
import { TimeLineBar } from '../../@types';

type ToDoItemContextType = {
  todoItem: TimeLineBar.TodoItem;
  marked: boolean;
  changeTodoItem: () => () => void;
};

type ToDoItemProviderProps = Children & {
  todoItem: TimeLineBar.TodoItem;
};

const [Provider, useToDoItem] = createContext<ToDoItemContextType>();
export { useToDoItem };

const ToDoItemProvider: React.FC<ToDoItemProviderProps> = ({ children, todoItem }) => {
  const [marked, setMarked] = useState<boolean>(todoItem.completed);

  const { changeTodoItemCompleted } = useToDoItems();

  const changeTodoItem = useCallback(
    () => changeTodoItemCompleted(todoItem.id, marked, setMarked),
    [changeTodoItemCompleted, marked, todoItem.id],
  );

  const contextValue = useMemo<ToDoItemContextType>(
    () => ({ todoItem, marked, changeTodoItem }),
    [changeTodoItem, marked, todoItem],
  );

  return <Provider value={contextValue}>{children}</Provider>;
};

export default React.memo(ToDoItemProvider);
