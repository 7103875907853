import React, { useCallback, useMemo } from 'react';
import { isArray } from 'ramda-adjunct';
import { CircularProgress } from '@mui/material';

import Paper from 'components/@ui/Paper';
import Autocomplete, {
  AutocompleteFilterOptions,
  FilteredOptionsState,
} from 'components/@ui/Autocomplete';
import TextField from 'components/@ui/TextField';
import Typography from 'components/@ui/Typography/Typography';
import Box from 'components/@ui/Box';
import Icon from 'components/@ui/Icon';
import Collapse from 'components/@ui/Collapse';
import Checkbox from 'components/@ui/Checkbox';
import { useOpenModal } from '@hooks';

import HRISOptions from './HRISOptions';
import { OptionProps } from './Context';
import { useFetchListVendors, useRequestDemoModal } from './@hooks';

const loadingOption = [{ label: 'Loading...', disabled: true }];

const HRISProvider: React.FC = () => {
  const {
    handlerHRISSelect,
    hRISValue,
    isHaveHRIS,
    handleChangeIsHRIS,
    isFetchedVendorsList,
    setIsFetchedVendorsList,
  } = useRequestDemoModal();

  const { open, handleOpen, handleClose } = useOpenModal();
  const [{ loading, records }, fetchListVendors] = useFetchListVendors();

  const filter = AutocompleteFilterOptions<OptionProps>();

  const options = useMemo(
    () => records.map((product) => ({ label: product.name, iconName: product.logoSmallUrl })),
    [records],
  );

  const filterOptionsCallback = useCallback(
    (options: OptionProps[], params: FilteredOptionsState<OptionProps>) => {
      const filtered = filter(options, params);

      const { inputValue } = params;

      const isExisting = options.some((option) => inputValue === option.label);
      if (inputValue !== '' && !isExisting) {
        filtered.push({ label: 'Vendor not found', disabled: true, isOptionNotFound: true });
        filtered.push({
          added: inputValue,
          label: `Add new vendor`,
          iconName: 'plus-path',
        });
      }

      return filtered;
    },
    [filter],
  );

  const loadingList = useCallback(async () => {
    handleOpen();
    if (!isFetchedVendorsList) {
      const response = await fetchListVendors();
      setIsFetchedVendorsList(response.status < 400);
    }
  }, [fetchListVendors, isFetchedVendorsList, setIsFetchedVendorsList, handleOpen]);

  return useMemo(
    () => (
      <Box
        position="relative"
        sx={{
          '& .MuiAutocomplete-clearIndicator': {
            width: 14,
            height: 14,
            p: 0,
            mr: 1.5,
            bgcolor: 'red.contrastText',
          },
          '& .MuiAutocomplete-clearIndicator:hover': {
            bgcolor: 'red.contrastText',
          },
        }}
      >
        <Collapse in={isHaveHRIS}>
          <Typography mt={3}>Last thing, who is your current HRIS provider?</Typography>
          <Autocomplete<OptionProps>
            open={open}
            onOpen={loadingList}
            onClose={handleClose}
            freeSolo
            options={loading ? loadingOption : options}
            value={hRISValue}
            clearIcon={
              <Icon
                width={14}
                fontSize={14}
                name="cross-red-background-path"
                color="secondary.main"
              >
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </Icon>
            }
            filterOptions={(options, params) => filterOptionsCallback(options, params)}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.added) {
                return option.added;
              }
              return option.label;
            }}
            onChange={(_, option) => {
              if (option === 'Loading...') return;
              if (typeof option === 'string') {
                return handlerHRISSelect({
                  label: option,
                });
              } else if (!isArray(option)) {
                return handlerHRISSelect(option);
              }
            }}
            getOptionDisabled={(option) => Boolean(option.disabled)}
            ListboxComponent={React.forwardRef((props, ref) => (
              <Box
                component="ul"
                maxHeight="180px !important"
                sx={{ '&': (theme) => theme.scrollbar() }}
                {...props}
                ref={ref}
              />
            ))}
            PaperComponent={(props) => <Paper {...props} sx={{ boxShadow: 11 }} />}
            renderOption={(props, option) => <HRISOptions option={option} {...props} />}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder="Type vendor's name"
                sx={{ '& .MuiInput-root': { pl: 6 } }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} sx={{ mr: 2 }} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <Icon
            name="search-path"
            position="absolute"
            sx={{ visibility: isHaveHRIS ? 'visible' : 'hidden' }}
            top={60}
            left={12}
          />
        </Collapse>
        <Box display="flex" alignItems="center" mt={1.5}>
          <Checkbox checked={!isHaveHRIS} onChange={handleChangeIsHRIS} />
          <Typography ml={1}>We don't have an HRIS</Typography>
        </Box>
      </Box>
    ),
    [
      hRISValue,
      filterOptionsCallback,
      isHaveHRIS,
      handlerHRISSelect,
      handleChangeIsHRIS,
      options,
      open,
      loading,
      loadingList,
      handleClose,
    ],
  );
};

export default HRISProvider;
