import React, { ReactElement, useMemo } from 'react';

import { useNavbar } from '../@hooks';
import { Children } from '@types';

const Wrapper: React.FC<Required<Children<ReactElement>>> = ({ children }) => {
  const { isOpenNavbar } = useNavbar();

  return useMemo(
    () => (
      <>
        {React.Children.only(
          React.cloneElement(children, {
            style: { width: 'auto', ...(isOpenNavbar && { width: 240 }) },
          }),
        )}
      </>
    ),
    [children, isOpenNavbar],
  );
};

export default Wrapper;
