import { useCallback, useLayoutEffect, useRef } from 'react';

export type UseEventProps = (...args: any[]) => any;

export default <T extends UseEventProps>(handler: T) => {
  const handlerRef = useRef<T>(handler);

  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback((...args: any[]) => {
    const fn = handlerRef.current;
    return fn(...args);
  }, []) as T;
};
