import { Project } from '@types';

const setProjectMappingValue = (
  moduleId: number,
  actualProjectMappings: Project.ProjectMappings,
  result: Project.ProjectMappings,
) => {
  switch (moduleId) {
    case 1:
      return (result['payrollRequirements'] = actualProjectMappings['payrollRequirements']);
    case 3:
      return (result['benefitsRequirements'] = actualProjectMappings['benefitsRequirements']);
    case 4:
      return (result['attendanceRequirements'] = actualProjectMappings['attendanceRequirements']);
    case 5:
      return (result['onboardingRequirements'] = actualProjectMappings['onboardingRequirements']);
    case 6:
      return (result['performanceRequirements'] = actualProjectMappings['performanceRequirements']);
    case 8:
      return (result['learningRequirements'] = actualProjectMappings['learningRequirements']);
    case 9:
      return (result['recruitingRequirements'] = actualProjectMappings['recruitingRequirements']);
  }
};

export const filterAndConstructActualProjectMappings = (
  projectMappings: Project.ProjectMappings,
  mandatoryModules: Project.Module[],
  optionalModules: Project.Module[],
): Project.ProjectMappings => {
  const result: Project.ProjectMappings = {
    attendanceRequirements: null,
    benefitsRequirements: null,
    learningRequirements: null,
    onboardingRequirements: null,
    payrollRequirements: null,
    performanceRequirements: null,
    recruitingRequirements: null,
    finalDetails: projectMappings['finalDetails'],
    additionalRequirements: projectMappings['additionalRequirements'],
  };

  mandatoryModules.forEach((mandatoryModule) =>
    setProjectMappingValue(mandatoryModule.id, projectMappings, result),
  );
  optionalModules.forEach((item) => setProjectMappingValue(item.id, projectMappings, result));

  return result;
};
