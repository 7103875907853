import React from 'react';

import TableRow from 'components/@ui/TableRow';
import { Children } from '@types';

import { cellsSize } from './@config';

type TableRowWrapperProps = {
  isHoverSidebarCell?: boolean;
};

const TableRowWrapper: React.FC<TableRowWrapperProps & Children> = ({
  isHoverSidebarCell,
  children,
}) => {
  return (
    <TableRow
      sx={{
        ...(isHoverSidebarCell && {
          '&::after': {
            content: "''",
            boxShadow: 8,
            height: cellsSize.heatMap.height,
            zIndex: 14,
            width: 1,
            opacity: 1,
            left: 0,
            position: 'absolute',
          },
        }),
      }}
    >
      {children}
    </TableRow>
  );
};

export default React.memo(TableRowWrapper);
