import React, { useMemo } from 'react';

import Collapse from 'components/@ui/Collapse';
import { formatDate } from '@helpers';

import { Button } from '../@components';
import { TimeLineBar } from '../@types';
import { useToDos } from '../ToDos/@hooks';

const FinishDate: React.FC = () => {
  const { finishDate: rawFinishDate } = useToDos();
  const finishDate = useMemo(() => rawFinishDate ?? '', [rawFinishDate]);

  return useMemo(
    () => (
      <Collapse in={Boolean(finishDate)} orientation="horizontal">
        <Button
          isEndOfDate
          aria-describedby={`End-Date-Of-Phase-${finishDate}`}
          status={TimeLineBar.StatusesEnum.next}
          sx={{ borderRadius: 24, height: 24, cursor: 'initial', mr: 1 }}
        >
          <time dateTime={formatDate(finishDate)}>{formatDate(finishDate)}</time>
        </Button>
      </Collapse>
    ),
    [finishDate],
  );
};

export default FinishDate;
