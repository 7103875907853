import WebFont from 'webfontloader';

import Fonts from './fonts';

WebFont.load({
  custom: {
    families: [
      Fonts.ProximaNova,
      Fonts.ProximaNovaBold,
      Fonts.ProximaExtraBold,
      Fonts.ProximaSemiBold,
      Fonts.Roboto,
      Fonts.RobotoBold,
      Fonts.IconFontOutsail,
    ],
  },
});
