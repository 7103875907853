import React, { useMemo } from 'react';
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { isEmpty } from 'ramda';
import { formatNumber } from '@helpers';
import { Project, WorkspaceProducts } from '@types';

import { makeItemsList, makeTitle } from './@helpers';

type VendorCardProps = {
  item: WorkspaceProducts.Product;
  chosenProduct: Project.ChosenProduct;
};

const VendorCard: React.FC<VendorCardProps> = React.memo(({ item, chosenProduct }) => {
  const {
    name,
    logoSmallUrl,
    matchScore,
    briefDescription,
    averageCustomerSize,
    availableModules,
    softwareCostMax,
    softwareCostMin,
    implementationCost,
    description,
    characteristics,
    idealEmployeeRangeMin,
    idealEmployeeRangeMax,
    id,
  } = item;

  return (
    <View style={styles.card}>
      <View style={{ position: 'relative' }}>
        {logoSmallUrl && <Image src={logoSmallUrl} style={styles.logo} />}
        <Text style={[styles.title, { position: 'absolute', left: 56, top: 24 }]}>
          {name}
          {chosenProduct?.productId === id ? ' (Vendor of choice)' : null}
        </Text>
      </View>

      <View>
        <Text style={styles.detailsTitle}>Match Score:</Text>
        <Text style={styles.detailsText}>{`${matchScore?.toFixed()}%`}</Text>

        <Text style={styles.detailsTitle}>Brief Description:</Text>
        <Text>{briefDescription || description}</Text>
      </View>
      <View style={styles.details}>
        <Text style={styles.detailsTitle}>Available Module:</Text>
        <Text>
          {availableModules &&
            !isEmpty(availableModules.productModules) &&
            availableModules.productModules.map(({ name }) => `${name}`).join(', ')}
        </Text>
      </View>
      {averageCustomerSize && (
        <View style={styles.details}>
          <Text style={styles.detailsTitle}>Acceptable Employee Range:</Text>
          <Text style={styles.detailsText}>
            {averageCustomerSize.min ? `${formatNumber(averageCustomerSize.min)}` : '-'}
            {averageCustomerSize.max ? ` - ${formatNumber(averageCustomerSize.max)}` : '-'}
          </Text>
        </View>
      )}
      {Boolean(idealEmployeeRangeMin || idealEmployeeRangeMax) && (
        <View style={styles.details}>
          <Text style={styles.detailsTitle}>Ideal Employee Range:</Text>
          <Text style={styles.detailsText}>
            {idealEmployeeRangeMin ? `${formatNumber(idealEmployeeRangeMin)}` : '-'}
            {idealEmployeeRangeMax ? ` - ${formatNumber(idealEmployeeRangeMax)}` : '-'}
          </Text>
        </View>
      )}
      <View style={styles.details}>
        <Text style={styles.detailsTitle}>Software Cost:</Text>
        <Text style={styles.detailsText}>
          {softwareCostMin ? `$${formatNumber(softwareCostMin)}` : '-'}
          {softwareCostMax ? ` - $${formatNumber(softwareCostMax)}` : '-'}
          {Boolean(softwareCostMin || softwareCostMax) && ` per employee per month`}
        </Text>
        <Text style={styles.detailsTitle}>Implementation Cost:</Text>
        {implementationCost ? (
          <Text>~{formatNumber(implementationCost)}% of annual software fees</Text>
        ) : (
          <Text> --</Text>
        )}
      </View>
      {!isEmpty(characteristics.productCharacteristics) && (
        <View style={styles.details}>
          <Text style={styles.detailsTitle}>Product Characteristics:</Text>
          <Text style={styles.detailsText}>
            {characteristics.productCharacteristics
              .map((characteristic) => `${characteristic.name}`)
              .join(', ')}
          </Text>
        </View>
      )}
      <View style={styles.details}>
        {!isEmpty(item.productStrengths) && (
          <>
            <Text style={styles.detailsTitle}>Strengths:</Text>
            {item.productStrengths.map((strength) => (
              <Text key={strength.id} style={styles.detailsText}>
                {strength.name}
              </Text>
            ))}
          </>
        )}

        {!isEmpty(item.productConcerns) && (
          <>
            <Text style={styles.detailsTitle}>Concerns:</Text>
            {item.productConcerns.map((concern) => (
              <Text key={concern.id} style={styles.detailsText}>
                {concern.name}
              </Text>
            ))}
          </>
        )}

        {item.integrationCapabilities && (
          <>
            <Text style={styles.detailsTitle}>Integration Capabilities:</Text>
            <Text style={styles.detailsText}>{item.integrationCapabilities}</Text>
          </>
        )}

        {item.productArchitecture && (
          <>
            <Text style={styles.detailsTitle}>Product Architecture:</Text>
            <Text style={styles.detailsText}>{item.productArchitecture}</Text>
          </>
        )}
      </View>
    </View>
  );
});

type ProjectMappingsProps = [Project.ProjectMappingsAttributes, string | null];
type ModuleProps = { item: ProjectMappingsProps };

const Module: React.FC<ModuleProps> = React.memo(({ item: [title, questions] }) => {
  const itemsList = useMemo(() => makeItemsList(questions), [questions]);

  return questions ? (
    <View style={{ paddingTop: 24 }}>
      <Text style={styles.title}>{makeTitle(title)}</Text>
      {!isEmpty(itemsList) &&
        itemsList.map((item, index) => (
          <View key={item.question} style={styles.details}>
            <Text style={styles.detailsTitle}>
              {index + 1}. {item.question}
            </Text>
            <Text style={styles.detailsAnswer}>{item.answer}</Text>
          </View>
        ))}
    </View>
  ) : null;
});

type WorkspacePDFProps = {
  projectMappings: ProjectMappingsProps[];
  list: WorkspaceProducts.Product[];
  name: string;
  isFinalized: boolean;
  chosenProduct: Project.ChosenProduct;
  pdfFileTitle: string;
};

const WorkspaceProductsPDF: React.FC<WorkspacePDFProps> = React.memo(
  ({ projectMappings, list, name, isFinalized, chosenProduct, pdfFileTitle }) => {
    return (
      <Document>
        <Page size="A4" style={styles.body}>
          <Image src={require(`assets/logo.png`)} style={styles.image} />
          <Text style={styles.header}>{pdfFileTitle}</Text>
          {name && (
            <>
              <Text style={{ textAlign: 'center' }}>Prepared for</Text>
              <Text style={[styles.detailsTitle, { textAlign: 'center', paddingBottom: 32 }]}>
                {name}
              </Text>
            </>
          )}

          {!isEmpty(list) &&
            list.map((item) => (
              <VendorCard key={item.id} item={item} chosenProduct={chosenProduct} />
            ))}

          {isFinalized && (
            <>
              <Text style={[styles.header, { paddingTop: 32 }]}>Project Overview</Text>

              {!isEmpty(projectMappings) &&
                projectMappings.map((item) => <Module item={item} key={item[0]} />)}
            </>
          )}
        </Page>
      </Document>
    );
  },
);

Font.register({
  family: 'Barlow-Medium',
  src: 'https://fonts.gstatic.com/s/barlow/v5/7cHqv4kjgoGqM7E30-8s6Vop.ttf',
});

Font.register({
  family: 'Barlow',
  src: ' https://fonts.gstatic.com/s/barlow/v5/7cHqv4kjgoGqM7E3p-ks6Vop.ttf',
});

const styles = StyleSheet.create({
  body: {
    fontFamily: 'Barlow',
    paddingTop: 32,
    paddingBottom: 56,
    paddingHorizontal: 32,
    fontSize: 12,
    color: '#1e1f23',
  },
  header: {
    fontFamily: 'Barlow-Medium',
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 24,
  },
  image: {
    width: 143,
    height: 56,
    marginVertical: 24,
    marginHorizontal: 'auto',
  },
  card: {
    paddingTop: 24,
    borderBottom: '1px solid #dadded',
  },
  title: {
    fontFamily: 'Barlow-Medium',
    fontSize: 16,
    marginBottom: 8,
  },
  details: {
    marginBottom: 8,
    marginTop: 8,
  },
  detailsTitle: {
    fontFamily: 'Barlow-Medium',
    fontSize: 12,
  },
  detailsAnswer: {
    fontSize: 12,
    paddingLeft: 10,
    paddingTop: 4,
    marginBottom: 8,
  },
  detailsText: {
    fontSize: 12,
    marginBottom: 4,
  },
  logo: {
    width: 40,
    height: 40,
    margin: 8,
  },
});

export default WorkspaceProductsPDF;
