import React from 'react';

import { TableCellProps } from 'components/@ui/TableCell';
import { clsx, parseSx } from '@helpers';

import TableCell from './TableCell';
import { getBarActiveColor, getBarColor, getBarHoverColor } from '../VendorComponents';

export type TableCellColoredProps = TableCellProps & {
  cellContent: number;
};

const TableCellColored: React.FC<TableCellColoredProps> = ({ cellContent, sx, ...other }) => {
  return (
    <TableCell
      sx={clsx(
        {
          borderColor: getBarColor(cellContent),
          bgcolor: getBarColor(cellContent),
          '&:hover': { bgcolor: getBarHoverColor(cellContent) },
          '&:active': { bgcolor: getBarActiveColor(cellContent) },
        },
        ...parseSx(sx),
      )}
      {...other}
    />
  );
};

export default React.memo(TableCellColored);
