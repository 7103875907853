import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useAPI, useEvent, useOnline, useSetTimeout, useSignOut } from '@hooks';
import { ROUTES } from '@routes/config';

import { signOut } from '../@slices';

const useFetchSignOut = () => {
  const history = useHistory();
  const signOutFn = useSignOut();
  const isOnline = useOnline();
  const { setTimeout } = useSetTimeout();
  const [data, fetch] = useAPI(useEvent(() => signOut.action()));

  const fetchSignOut = useEvent(() => {
    fetch();
    setTimeout(() => {
      signOutFn();
      if (isOnline) {
        history.push(ROUTES.auth.login.email);
      } else {
        window.location.replace(ROUTES.auth.login.email);
      }
    });
  });

  return useMemo(() => [data, fetchSignOut] as const, [data, fetchSignOut]);
};

export default useFetchSignOut;
