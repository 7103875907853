import { isPlainObject } from 'ramda-adjunct';
import { omit } from 'ramda';

import { Expansion } from '@types';

import storage from './storage';

type State<S, K extends keyof any> = Expansion<S, K>;

export default <K extends keyof any = number, S = unknown, STORAGE_KEY extends string = string>(
  storageKey: STORAGE_KEY,
) => {
  const get = (key: K) => {
    const allState = storage.getItem<State<S, K>>(storageKey);
    if (!allState) return null;
    try {
      return allState[key];
    } catch {
      return null;
    }
  };

  const set = (key: K, value: S) => {
    if (value === undefined) return;
    const allState = storage.getItem<State<S, K>>(storageKey);
    if (allState && isPlainObject(allState)) {
      storage.setItem(storageKey, { ...allState, [key]: value });
    } else {
      storage.setItem(storageKey, { [key]: value });
    }
  };

  const remove = (key: K) => {
    const allState = storage.getItem<State<S, K>>(storageKey);
    if (allState && isPlainObject(allState)) {
      storage.setItem(storageKey, omit([key as any], allState));
    }
  };

  return {
    get,
    set,
    remove,
    storageKey,
  };
};
