import { useCallback, useMemo, useState } from 'react';
import { useUnmount } from 'react-use';

export type UseOpenModal = {
  handleOpen: () => void;
  handleClose: () => void;
  open: boolean;
};

function useOpenModal(inishial?: boolean) {
  const [open, toggleOpen] = useState(Boolean(inishial));

  const handleOpen = useCallback(() => toggleOpen(true), []);
  const handleClose = useCallback(() => toggleOpen(false), []);

  useUnmount(() => toggleOpen(false));

  return useMemo<UseOpenModal>(
    () => ({
      handleOpen,
      handleClose,
      open,
    }),
    [handleOpen, handleClose, open],
  );
}

export default useOpenModal;
