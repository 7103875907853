import React, { Fragment } from 'react';

import FeatureAndCharacteristicModuleModal from './FeaturesAndCharacteristicsModal';
import { FeatureMatchProps } from './types';
import { FEATURES } from './constants';

type ListWithFeaturesProps = {
  featureMatch: FeatureMatchProps[];
  start: number;
  end?: number;
};

const ListWithFeatures: React.FC<ListWithFeaturesProps> = ({ featureMatch, start, end }) => {
  return (
    <>
      {FEATURES.slice(start, end).map((feature, index) => {
        const needOneFeature = featureMatch?.find((element) => element.moduleName === feature.name);
        let status: 'active' | 'onlyProject' | 'disabled' | 'onlyProduct';
        if (needOneFeature) {
          switch (needOneFeature.matchIndicator) {
            case '+':
              status = 'active';
              break;
            case '-':
              status = 'disabled';
              break;
            case '@':
              status = 'onlyProject';
              break;
            case '#':
              status = 'onlyProduct';
              break;
            default:
              status = 'disabled';
          }
          return (
            <FeatureAndCharacteristicModuleModal
              key={feature.name}
              moduleEl={feature}
              status={status}
            />
          );
        } else {
          return <Fragment key={feature.name} />;
        }
      })}
    </>
  );
};

export default React.memo(ListWithFeatures);
