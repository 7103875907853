import React, { useMemo } from 'react';

import { Children } from '@types';

import { usePhase } from '../Context';
import { Badge } from '../../@components';

const PhaseBadge: React.FC<Children> = ({ children }) => {
  const { phase } = usePhase();

  return useMemo(
    () => (
      <Badge
        width={{ mobile: '100%', tablet: '100%', laptop: 'auto' }}
        status={phase.status}
        IconProps={{ right: 1, top: -2 }}
      >
        {children}
      </Badge>
    ),
    [children, phase.status],
  );
};

export default PhaseBadge;
