import React from 'react';
import { Link, LinkProps, useHistory } from 'react-router-dom';
import { Theme } from '@mui/material';

import Tooltip from 'components/@ui/Tooltip';
import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';
import Image from 'components/@ui/Image';
import { ROUTES } from '@routes/config';
import { useIsTablet } from '@hooks';
import { Product } from '@types';

import TableCell from './TableCell';

type HeatMapVendorButtonProps = { product: Product.HeatMapDataVendor; linkProps?: LinkProps };

const HeatMapVendorButton: React.FC<HeatMapVendorButtonProps> = ({ product, linkProps }) => {
  const history = useHistory();
  const isTablet = useIsTablet();

  return (
    <TableCell sx={{ zIndex: 15, p: 0 }}>
      <Tooltip
        disableInteractive
        title={isTablet ? '' : "Press to see Vendor's profile"}
        maxWidth={115}
        placement="right"
      >
        <Link
          to={{ pathname: ROUTES.vendorPageRoot + product.id, state: history.location.pathname }}
          {...linkProps}
        >
          <Box
            display="flex"
            flexDirection="column"
            p="12px 16px"
            alignItems="center"
            position="relative"
            zIndex={15}
            sx={{
              transition: (theme: Theme) => theme.transitions.create('all'),
              '&:hover': {
                bgcolor: 'dropdownHover.main',
                borderColor: 'blue.main',
              },
              '&:active': {
                bgcolor: 'dropdownHover.dark',
                borderColor: 'blue.main',
              },
            }}
          >
            <Typography
              sx={{ writingMode: 'tb-rl', transform: 'rotate(-180deg)', textDecoration: 'none' }}
              maxHeight={110}
              minHeight={110}
              semiBold
              whiteSpace="nowrap"
              fontSize={16}
              lineHeight="24px"
              textOverflow="ellipsis"
              overflow="hidden"
              height="100%"
              color="text.primary"
              textAlign="left"
            >
              {product.name}
            </Typography>
            <Box mt={1}>
              <Image
                src={product.logoSmallUrl ?? product.logoUrl}
                width={24}
                height={24}
                fit="contain"
              />
            </Box>
          </Box>
        </Link>
      </Tooltip>
    </TableCell>
  );
};

export default React.memo(HeatMapVendorButton);
