import { all, any } from 'ramda';
import { isUndefined } from 'ramda-adjunct';

import { Expansion } from '@types';

import ABILITY, { AbilityType } from '../ability';
import { AbilityContextType } from '../Context';
import { AbilityControlType } from '../AbilityControl';
import { PermissionsType } from '../permissions';

const methods = { all, any };

const permissionCb = (p: PermissionsType, abilityRole: AbilityContextType[AbilityType]) => {
  const allowed = abilityRole?.[p];
  return isUndefined(allowed) ? true : allowed;
};

export type AbilityCheckProps<T extends Expansion = {}> = T &
  Pick<AbilityControlType, 'ability' | 'permission' | 'method'>;

export type AbilitiesCheck = { abilities: AbilityContextType };
export type AbilityCheck<T extends Expansion = {}> = (props: AbilityCheckProps<T>) => boolean;

const abilityCheck: AbilityCheck<AbilitiesCheck> = ({
  ability = ABILITY.USE,
  permission,
  abilities,
  method = 'all',
}) => {
  const abilityRole = abilities[ability];
  return Array.isArray(permission)
    ? methods[method]((p) => permissionCb(p, abilityRole), permission)
    : permissionCb(permission, abilityRole);
};

export default abilityCheck;
