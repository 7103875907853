import baseAPI, { APIResponse } from '@api';

const api = baseAPI(false);
//-------------------------------------------------------------------------
// Get Advisor
//-------------------------------------------------------------------------
export type FetchAdvisorResponse = {
  advisor: {
    id: number;
    avatarUrl: string;
    avatarSmallUrl: string;
    name: string;
    email: string;
    phoneNumber: string;
    calendlyUrl: string;
  };
};
export function fetchAdvisor(projectId: number): APIResponse<FetchAdvisorResponse> {
  return api.get(`/projects/${projectId}/advisor`);
}
//-------------------------------------------------------------------------
