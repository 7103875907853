import { combineReducers } from 'redux';

import { createSlice, createSliceData } from '@redux/slices';

import { fetchTimeLinePhases } from '../@api';
import { todoAdvisorData, todoItemData } from '../ToDoItems/@slices';

export const timeLinePhases = createSlice('FETCH_PHASES', fetchTimeLinePhases, {
  initialState: { phases: [] },
});

export const onboardingModal = createSliceData('ONBOARDING_MODAL', false);

export const mobileTimelineState = createSliceData('MOBILE_TIMELINE_STATE', false);

export default combineReducers({
  phases: timeLinePhases.reducer,
  ondoadingModal: onboardingModal.reducer,
  todoItems: combineReducers({
    todoItem: todoItemData.reducer,
    advisor: todoAdvisorData.reducer,
  }),
  mobileTimeline: mobileTimelineState.reducer,
});
