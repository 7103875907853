import React from 'react';

import Box from 'components/@ui/Box';
import { Children } from '@types';

import TodoItemWrapper from './TodoItemWrapper';
import { CheckboxToDoItem, TodoItemProIcons, TodoItemText } from '../@components';

const TodoItem: React.FC<Children> = ({ children }) => {
  return (
    <TodoItemWrapper>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="flex-start">
          <CheckboxToDoItem />
          <TodoItemText />
        </Box>
        <TodoItemProIcons />
      </Box>
      {children}
    </TodoItemWrapper>
  );
};

export default React.memo(TodoItem);
