import baseAPI, { APIResponse } from '@api';

import { Team } from '../@types';

const apiCredentials = baseAPI();
//--------------------------------------------------------------------------------------------------
// Get Team Groups
//--------------------------------------------------------------------------------------------------
export function fetchTeamGroups(projectId: number): APIResponse<{ groups: Team.Group[] }> {
  return apiCredentials.get(`/projects/${projectId}/project_groups`);
}
//--------------------------------------------------------------------------------------------------
// Get Team Group by id
//--------------------------------------------------------------------------------------------------
export function fetchTeamGroup(
  projectId: number,
  groupId: number,
): APIResponse<{ group: Team.Group }> {
  return apiCredentials.get(`/projects/${projectId}/project_groups/${groupId}`);
}
//--------------------------------------------------------------------------------------------------
// Get Team Group Create
//--------------------------------------------------------------------------------------------------
export type FetchTeamGroupBody = { name: string };
export function fetchTeamGroupCreate(
  projectId: number,
  props: FetchTeamGroupBody,
): APIResponse<{ group: Team.Group }> {
  return apiCredentials.post(`/projects/${projectId}/project_groups`, { project_groups: props });
}
//--------------------------------------------------------------------------------------------------
// Edit Team Group
//--------------------------------------------------------------------------------------------------
export function fetchTeamGroupEdit(
  projectId: number,
  groupId: number,
  props: FetchTeamGroupBody,
): APIResponse<{ group: Team.Group }> {
  return apiCredentials.put(`/projects/${projectId}/project_groups/${groupId}`, {
    project_groups: props,
  });
}
//--------------------------------------------------------------------------------------------------
// Delete Team Group
//--------------------------------------------------------------------------------------------------
export function fetchTeamGroupDelete(projectId: number, groupId: number) {
  return apiCredentials.delete(`/projects/${projectId}/project_groups/${groupId}`);
}
//--------------------------------------------------------------------------------------------------
// Add A User To The Group
//--------------------------------------------------------------------------------------------------
export type UserType = 'ProjectClient' | 'ProjectInvitedMember';
export type FetchUserGroupProps = {
  type: UserType;
  userId: number;
  groupId: number;
};
export function fetchAddUserToGroup(
  projectId: number,
  props: FetchUserGroupProps,
): APIResponse<{ groups: Team.UserGroup[] }> {
  const { type, userId, groupId } = props;
  return apiCredentials.post(
    `/projects/${projectId}/team_members/${userId}/project_participant_groups`,
    { params: { type }, project_participant_group: { project_group_id: groupId } },
  );
}
//--------------------------------------------------------------------------------------------------
// Remove A User From The Group
//--------------------------------------------------------------------------------------------------
export function fetchRemoveUserFromGroup(
  projectId: number,
  props: FetchUserGroupProps,
): APIResponse<{ groups: Team.UserGroup[] }> {
  const { type, userId, groupId } = props;
  return apiCredentials.delete(
    `/projects/${projectId}/team_members/${userId}/project_participant_groups/${groupId}`,
    { params: { type } },
  );
}
//--------------------------------------------------------------------------------------------------
