import React from 'react';
import { all, equals } from 'ramda';

import Box from 'components/@ui/Box';

import StrengthBar from './StrengthBar';
import StrengthBarTip from './StrengthBarTip';
import usePasswordStrengthBar from './usePasswordStrengthBar';

const textScopes = {
  length: 'At least 12 characters long',
  other: 'A combination of uppercase letters, lowercase letters, numbers, and symbols.',
};

export type PasswordStrengthBarProps = {
  name?: string;
};

const PasswordStrengthBar: React.FC<PasswordStrengthBarProps> = ({ name }) => {
  const { hasUppercase, hasLowercase, hasNumber, hasSymbol, hasLength, numberMatches } =
    usePasswordStrengthBar({ name });

  return (
    <Box display="flex" flexDirection="column" width="100%" mt={1} gap={3}>
      <StrengthBar numberMatches={numberMatches} />
      <StrengthBarTip condition={hasLength} text={textScopes.length} />
      <StrengthBarTip
        condition={all(equals(true))([hasUppercase, hasLowercase, hasNumber, hasSymbol])}
        text={textScopes.other}
      />
    </Box>
  );
};

export default React.memo(PasswordStrengthBar);
