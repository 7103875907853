import React from 'react';

import Typography, { TypographyProps } from 'components/@ui/Typography';
import When from 'components/@main/When';
import { useIsTablet } from '@hooks';

const VendorsTitle: React.FC<TypographyProps> = (props) => {
  const isTablet = useIsTablet();

  return (
    <When condition={!isTablet} disabledWrapper>
      <Typography semiBold fontSize={24} lineHeight={1} {...props}>
        Vendors
      </Typography>
    </When>
  );
};

export default React.memo(VendorsTitle);
