import React from 'react';
import { ReadyState } from 'react-use-websocket';

import When from 'components/@main/When';
import { useIsLogin, useWs } from '@hooks';

import useConnectToWs from './useConnectToWs';
import useChangeWsHiddenData from './useChangeWsHiddenData';
import { useToastsOffline } from '../@hooks';

const Offline: React.FC = () => {
  useToastsOffline();
  return null;
};

const ProjectWsConnect: React.FC = () => {
  const channelWs = useConnectToWs();
  useChangeWsHiddenData(channelWs);
  return null;
};

const ProjectWs: React.FC = () => {
  const isLogin = useIsLogin();
  const { readyState } = useWs();

  return (
    <When
      condition={isLogin && readyState === ReadyState.OPEN}
      fallback={<Offline />}
      disabledWrapper
    >
      <ProjectWsConnect />
    </When>
  );
};

export default ProjectWs;
