import React, { useMemo } from 'react';
import { Theme } from '@mui/material';
import { useMount, useUpdateEffect } from 'react-use';

import Box from 'components/@ui/Box';
import Loader from 'components/@common/Loader';
import SlideUi from 'components/@ui/Slide';
import { useMobileTimeline } from '@hooks';

import MobileToDoItems from './MobileToDoItems';
import { useToDo } from '../ToDo';

const MobileToDoItemsContainer: React.FC = () => {
  const { handleCloseToDoPopover, anchorToDoEl, isPopoverData } = useToDo();
  const [{ records: isOpenTimelineMobile }] = useMobileTimeline();

  useUpdateEffect(() => {
    !isOpenTimelineMobile && handleCloseToDoPopover();
  }, [isOpenTimelineMobile, handleCloseToDoPopover]);

  useMount(() => handleCloseToDoPopover());

  return useMemo(
    () => (
      <SlideUi direction="left" in={Boolean(anchorToDoEl)} mountOnEnter>
        <Box
          display="flex"
          flexDirection="column"
          width={1}
          height="calc(100vh - 80px)"
          position="fixed"
          top={80}
          left={0}
          zIndex={200}
          overflow="scroll"
          bgcolor="background.default"
          sx={{ '&': (theme: Theme) => theme.scrollbar(0), overscrollBehavior: 'contain' }}
        >
          {isPopoverData ? (
            <Box component="section" position="relative" width={1} height={1}>
              <MobileToDoItems todoName={anchorToDoEl?.name ?? ''} />
            </Box>
          ) : (
            <Box
              onClick={handleCloseToDoPopover}
              component="section"
              position="relative"
              width={1}
              height={1}
            >
              <Loader />
            </Box>
          )}
        </Box>
      </SlideUi>
    ),
    [anchorToDoEl, isPopoverData, handleCloseToDoPopover],
  );
};

export default MobileToDoItemsContainer;
