import { PermissionsRules } from '@permissions';

import { AbilitiesType } from '../ability';

const TIMELINE_USE_PERMITIONS: PermissionsRules = {
  TIME_LINE_MARK_TODO_ITEM: false,
};

const VENDORS_USE_PERMITIONS: PermissionsRules = {
  VENDORS_ADD_VENDOR_TO_WORKSPACE: false,
};

const WORKSPACE_USE_PERMITIONS: PermissionsRules = {
  WORKSPACE_ELIMINATE: false,
  WORKSPACE_FINALIZE: false,
  WORKSPACE_REMOVE_FROM_WORKSPACE: false,
  WORKSPACE_REQUEST_DEMO: false,
  WORKSPACE_DESIGN_DEMO: false,
};

const TEAM_USE_PERMITIONS: PermissionsRules = {
  TEAM_ADD_NEW_USER: false,
  TEAM_CHANGE_USER_RIGHTS: false,
  TEAM_REMOVE_USER: false,
  TEAM_CHANGE_GROUP_USER: false,
  TEAM_MANAGE_GROUP: false,
  TEAM_RESENT_INVITATION: false,
};

const SUMMARY_USE_PERMITIONS: PermissionsRules = {
  SUMMARY_CLICK_ON_THE_CELL: false,
};

const SCORECARD_USE_PERMITIONS: PermissionsRules = {
  SCORECARD_USE_SETTINGS: false,
  SCORECARD_ADD_KEY_CRITERIA: false,
};

const SETTINGS_USE_PERMITIONS: PermissionsRules = {
  SETTINGS_CHANGE_PROGECT_NAME: false,
  SETTINGS_CHANGE_PROGECT_WEBSITE: false,
  SETTINGS_CHANGE_PROGECT_GO_LIVE_DATE: false,
  SETTINGS_TRANSFER_OWNERSHIP: false,
};

const rule: AbilitiesType = {
  USE: {
    ...TIMELINE_USE_PERMITIONS,
    ...VENDORS_USE_PERMITIONS,
    ...TEAM_USE_PERMITIONS,
    ...WORKSPACE_USE_PERMITIONS,
    ...SUMMARY_USE_PERMITIONS,
    ...SCORECARD_USE_PERMITIONS,
    ...SETTINGS_USE_PERMITIONS,
  },
};

export default rule;
