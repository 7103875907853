import React from 'react';

import Tooltip, { TooltipProps } from 'components/@ui/Tooltip';

import { TooltipText, TooltipTextObject } from '../@components';
import { clsx, makeSxStyles, parseSx } from '@helpers';

type ToDoItemTooltipProps = {
  hideTooltip: boolean;
  type: string;
  source?: {
    srcMP4?: string;
    srcOGG?: string;
    poster?: string;
    text?: TooltipTextObject;
  };
};

const sxses = makeSxStyles({
  popper: {
    '& .MuiTooltip-tooltip': {
      padding: 0.25,
    },
    '& video': {
      height: 183,
      margin: '1px 1px -4px 1px',
    },
    '& .tooltip-name': {
      px: 1,
      py: 0.5,
      maxWidth: 90,
      '& .tooltip-image': {
        width: 10,
        height: 10,
      },
    },
  },
})();

const TooltipTitleValue: React.FC<Omit<ToDoItemTooltipProps, 'hideTooltip'>> = React.memo(
  ({ type, source }) => {
    switch (type) {
      case 'video':
        return (
          <video
            autoPlay
            loop
            width="171"
            height="200px"
            muted
            poster={
              source?.poster ??
              'https://images.unsplash.com/photo-1590573124389-83f2133e0307?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60'
            }
          >
            <source
              src={
                source?.srcMP4 ??
                'https://media.geeksforgeeks.org/wp-content/uploads/20190616234019/Canvas.move_.mp4'
              }
              type="video/mp4"
            />
            <source
              src={
                source?.srcOGG ??
                'https://media.geeksforgeeks.org/wp-content/uploads/20190616234019/Canvas.move_.ogg'
              }
              type="video/ogg"
            />
          </video>
        );
      case 'text':
        return <TooltipText type={source?.text} />;
      default:
        return null;
    }
  },
);

const ToDoItemWithTooltip: React.FC<Omit<TooltipProps, 'title'> & ToDoItemTooltipProps> = ({
  children,
  type,
  source,
  hideTooltip,
  PopperProps,
  ...other
}) => {
  if (hideTooltip) return <>{children}</>;

  return (
    <Tooltip
      title={hideTooltip ? null : <TooltipTitleValue type={type} source={source} />}
      placement="right"
      PopperProps={{ sx: clsx(sxses.popper, ...parseSx(PopperProps?.sx)), ...PopperProps }}
      {...other}
    >
      <div>{children}</div>
    </Tooltip>
  );
};

export default React.memo(ToDoItemWithTooltip);
