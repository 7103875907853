import { PublicClientApplication } from '@azure/msal-browser';

import { MICROSOFT_OAUTH_CLIENT_ID } from '@constants';

const instance = new PublicClientApplication({
  auth: {
    clientId: MICROSOFT_OAUTH_CLIENT_ID ?? '',
  },
});

export default instance;
