import React, { useMemo } from 'react';

import Collapse from 'components/@ui/Collapse';
import Typography from 'components/@ui/Typography';
import Divider from 'components/@ui/Divider';
import Box from 'components/@ui/Box';
import { formatDate } from '@helpers';

import { useToDos } from '../ToDos/@hooks';
import { colorGenerator } from '../@helpers';
import { TimeLineBar } from '../@types';

type MobileFinishDateProps = {
  lastToDoStatus: TimeLineBar.Statuses;
};

const MobileFinishDate: React.FC<MobileFinishDateProps> = ({ lastToDoStatus }) => {
  const { finishDate: rawFinishDate } = useToDos();
  const finishDate = useMemo(() => rawFinishDate ?? '', [rawFinishDate]);

  return useMemo(
    () => (
      <Collapse
        in={Boolean(finishDate)}
        orientation="horizontal"
        sx={{
          width: 1,
          height: 64,
          pl: 3,
          '& .MuiCollapse-wrapperInner': { display: 'flex', alignItems: 'center' },
        }}
      >
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={24}
          height={24}
        >
          <Divider
            sx={{
              position: 'absolute',
              borderWidth: 2,
              height: 48,
              borderColor: colorGenerator(lastToDoStatus, 'stepperConnector'),
              top: -40,
            }}
          />
          <Box
            bgcolor={colorGenerator(lastToDoStatus, 'stepperConnector')}
            width={10}
            height={10}
            borderRadius={10}
          />
        </Box>
        <Typography fontSize={18} lineHeight="32px" color="text.primary" semiBold pl={1.5}>
          {formatDate(finishDate)}
        </Typography>
      </Collapse>
    ),
    [finishDate, lastToDoStatus],
  );
};

export default MobileFinishDate;
