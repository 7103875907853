import { Theme, SxProps } from '@mui/material';

export type SxNameMap<SxKey extends string = string> = Record<SxKey, SxProps<Theme>>;

export type MakeSxStylesPropsType<Props, SxKey extends string = string> =
  | ((props: Props) => SxNameMap<SxKey>)
  | SxNameMap<SxKey>;

export type MakeSxStylesReturnType<Props, SxKey extends string = string> = keyof Props extends never
  ? (props?: any) => Record<SxKey, SxProps<Theme>>
  : (props: Props) => Record<SxKey, SxProps<Theme>>;

export default <Props extends object = {}, SxKey extends string = string>(
    styles: MakeSxStylesPropsType<Props, SxKey>,
  ): MakeSxStylesReturnType<Props, SxKey> =>
  (props: Props) =>
    styles instanceof Function ? styles(props) : styles;
