import React, { useCallback } from 'react';
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom';
import { Theme } from '@mui/material';
import { History } from 'history';

import Icon, { IconProps } from 'components/@ui/Icon';
import { clsx, makeSxStyles, parseSx } from '@helpers';
import { First } from '@types';

type StylesSx = {
  iconName: string;
  to?: First<Parameters<History['push']>>;
  IconProps?: Partial<IconProps>;
  disablePadding?: boolean;
};

export interface IconButtonProps extends MuiIconButtonProps, StylesSx {}

const sxes = makeSxStyles({
  root: {
    padding: 1.25,
    transition: (theme: Theme) => theme.transitions.create(['background', 'opacity']),
    '&:hover': {
      background: (theme: Theme) => theme.palette.action.hover,
    },
    '&:active': {
      background: (theme: Theme) => theme.palette.action.active,
    },
    '&:disabled': {
      opacity: 0.5,
      color: 'inherit',
    },
    height: 'max-content',
  },
  disablePadding: {
    padding: 0,
  },
})();

// @ts-ignore
const IconButton: React.FC<IconButtonProps> = React.forwardRef((props, ref) => {
  const {
    disablePadding,
    color = 'inherit',
    iconName,
    IconProps,
    onClick,
    to,
    sx,
    children,
    ...other
  } = props;

  const history = useHistory();

  const handleclick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClick?.(e);
      to && history.push(to);
    },
    [history, onClick, to],
  );

  return (
    <MuiIconButton
      sx={clsx(sxes.root, disablePadding && sxes.disablePadding, ...parseSx(sx))}
      ref={ref}
      onClick={handleclick}
      color={color}
      disableRipple
      aria-label={`button icon ${iconName}`}
      {...other}
    >
      <Icon name={iconName} borderRadius="inherit" {...IconProps}>
        {children}
      </Icon>
    </MuiIconButton>
  );
});

export default React.memo(IconButton);
