import { combineReducers } from 'redux';

import { createSliceSimple, createPrefix as p } from '@redux/slices';

import {
  fetchNoteCreate,
  fetchNoteDelete,
  fetchNoteDislike,
  fetchNoteEdit,
  fetchNoteLike,
} from '../../@api/notes';

const prefix = 'NOTE';
const pFetch = 'FETCH_SIMPLE';

export const noteCreate = createSliceSimple(p('CREARE', prefix, pFetch), fetchNoteCreate);
export const noteEdit = createSliceSimple(p('EDIT', prefix, pFetch), fetchNoteEdit);
export const noteDelete = createSliceSimple(p('DELETE', prefix, pFetch), fetchNoteDelete);
export const noteLike = createSliceSimple(p('LIKE', prefix, pFetch), fetchNoteLike);
export const noteDislike = createSliceSimple(p('DISLIKE', prefix, pFetch), fetchNoteDislike);

export default combineReducers({
  noteCreate: noteCreate.reducer,
  noteEdit: noteEdit.reducer,
  noteDelete: noteDelete.reducer,
  noteLike: noteLike.reducer,
  noteDislike: noteDislike.reducer,
});
