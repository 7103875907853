import React from 'react';

import Typography from 'components/@ui/Typography';

const OutSailFreeInfo: React.FC = () => {
  return (
    <Typography>
      OutSail is an HRIS broker. When a company makes a purchase through OutSail, the winning HRIS
      vendor pays us a small commission. The vendors don't pass this cost along to you. And we are
      vendor-agnostic, working with every leading HRIS on the market.
    </Typography>
  );
};

export default OutSailFreeInfo;
