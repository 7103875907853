import React from 'react';
import { useHistory } from 'react-router-dom';
import { not } from 'ramda';

import Background, { BackgroundProvider } from 'components/@common/Background';
import { DialogProps } from 'components/@ui/Dialog';
import { AppLayoutWrapper } from '@layout/AppLayout';
import { useUser } from '@hooks';
import { ROUTES } from '@routes/config';

import TermsOfUse from './TermsOfUse';
import PrivacyNotice from './PrivacyNotice';

type RootRoutesFC = {
  TermsOfUse: React.FC<DialogProps>;
  PrivacyNotice: React.FC<DialogProps>;
};

type RootRouteType = Partial<DialogProps> & {
  renderComponent: React.FC<DialogProps>;
};

const RootRoute: React.FC<RootRouteType> = ({ renderComponent: Component, ...other }) => {
  const { id } = useUser();
  const history = useHistory<string>();

  return (
    <AppLayoutWrapper hasNavBar {...{ hasTimeLineBar: Boolean(id), hasTimeLineBarPlug: not(id) }}>
      <Component
        open
        onClose={() => history.push(history.location.state || ROUTES.root)}
        {...other}
      />
      <BackgroundProvider>
        <Background />
      </BackgroundProvider>
    </AppLayoutWrapper>
  );
};

const RootRoutes: RootRoutesFC = {
  TermsOfUse: (props: DialogProps) => <RootRoute renderComponent={TermsOfUse} {...props} />,
  PrivacyNotice: (props: DialogProps) => <RootRoute renderComponent={PrivacyNotice} {...props} />,
};

export default RootRoutes;
