import React, { useMemo } from 'react';
import { Theme } from '@mui/material';

import Box from 'components/@ui/Box';
import { AppLayoutSizes } from '@layout/AppLayout';
import { useAppLayout } from '@layout/AppLayout/@hooks';
import { Children } from '@types';

import NavbarFooter from './NavbarFooter';
import { useNavbar } from './@hooks';

const NavbarCustomWrapper: React.FC<Children> = ({ children }) => {
  const { isOpenNavbar } = useNavbar();
  const { hasTimeLineBar, hasTimeLineBarPlug } = useAppLayout();

  return useMemo(
    () => (
      <Box
        width={isOpenNavbar ? '100%' : 80}
        maxWidth={256}
        minWidth={80}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="background.default"
        overflow="scroll"
        maxHeight={
          hasTimeLineBar || hasTimeLineBarPlug
            ? `calc(100vh - ${AppLayoutSizes.timeLineBarHeight}px)`
            : '100vh'
        }
        boxShadow={{ mobile: 8, tablet: 8, laptop: 'none' }}
        component="nav"
        position={{ mobile: 'fixed', tablet: 'fixed', laptop: 'relative' }}
        left={0}
        zIndex={99}
        height="100%"
        sx={{
          transition: (theme: Theme) => theme.transitions.create('width'),
          '&': (theme: Theme) => theme.scrollbar(0),
        }}
      >
        {children}
      </Box>
    ),
    [isOpenNavbar, hasTimeLineBar, hasTimeLineBarPlug, children],
  );
};

const NavbarCustom: React.FC<Children> = ({ children }) => {
  return (
    <NavbarCustomWrapper>
      <div>{children}</div>
      <NavbarFooter />
    </NavbarCustomWrapper>
  );
};

export default NavbarCustom;
