import { combineReducers } from 'redux';

import { createSlice, p } from '@redux/slices';

import { fetchVendors } from '../@api';

export const vendors = createSlice(p('VENDORS', 'GLOBAL'), fetchVendors, { initialState: [] });

export default combineReducers({
  vendors: vendors.reducer,
});
