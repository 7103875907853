import { useCallback } from 'react';

import { useAPI, useProject } from '@hooks';

import { sendRequestDemo } from '../@slices';
import { SendRequestDemoProps } from '../@api';

const { action } = sendRequestDemo;

const useFetchSendRequestDemo = () => {
  const { id } = useProject();

  return useAPI(useCallback((data: SendRequestDemoProps) => action(id, data), [id]));
};

export default useFetchSendRequestDemo;
