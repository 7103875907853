import {  createSliceSimple } from '@redux/slices';
import { combineReducers } from 'redux';

import { fetchEvaluateCreate, fetchEvaluateChange, fetchEvaluateDelete } from '../../@api';

export const evaluateCreate = createSliceSimple('FETCH_SCORES_CREATE', fetchEvaluateCreate);
export const evaluateChange = createSliceSimple('FETCH_SCORES_CHANGE', fetchEvaluateChange);
export const evaluateDelete = createSliceSimple('FETCH_SCORES_DELETE', fetchEvaluateDelete);

export default combineReducers({
  evaluateCreate: evaluateCreate.reducer,
  evaluateChange: evaluateChange.reducer,
  evaluateDelete: evaluateDelete.reducer,
});
