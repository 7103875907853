import React from 'react';

import Button from './Button';
import NavbarButtonsData from '../NavbarButtonsData';

const Buttons: React.FC = () => {
  return (
    <>
      {NavbarButtonsData.map((buttonData) => (
        <Button key={buttonData.id} buttonData={buttonData} />
      ))}
    </>
  );
};

export default Buttons;
