import React from 'react';

import { ToDoBadge, ToDoBox, ToDoButton } from './ToDoBox';

const ToDo: React.FC = () => {
  return (
    <ToDoBox>
      <ToDoBadge>
        <ToDoButton />
      </ToDoBadge>
    </ToDoBox>
  );
};

export default ToDo;
