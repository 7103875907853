import { useUpdateEffect } from 'react-use';

import { useIsTablet, useMobileTimeline } from '@hooks';

const useOpenMobileTimeline = () => {
  const isTablet = useIsTablet();
  const [{ records: isOpenMobileTimeline }, toggleOpen] = useMobileTimeline();

  useUpdateEffect(() => {
    !isTablet && isOpenMobileTimeline && toggleOpen(false);
  }, [isTablet, isOpenMobileTimeline]);
};

export default useOpenMobileTimeline;
