import React from 'react';

import Typography from 'components/@ui/Typography';

type LogoLabelProps = {
  label: string;
};

const LogoLabel: React.FC<LogoLabelProps> = ({ label }) => {
  return (
    <Typography ml={0.75} color="gray.dark" semiBold wordBreak="break-word" minWidth={90}>
      {label}
    </Typography>
  );
};

export default React.memo(LogoLabel);
