import React, { useMemo } from 'react';

import Typography, { TypographyProps } from 'components/@ui/Typography';

import { useToDoItem } from '../TodoItem';

const TodoItemText: React.FC<TypographyProps> = (props) => {
  const { marked, todoItem } = useToDoItem();

  return useMemo(
    () => (
      <Typography
        component="p"
        semiBold
        ml={1.5}
        sx={{ textDecoration: marked ? 'line-through' : 'none' }}
        color={marked ? 'gray.main' : 'gray.dark'}
        dangerouslySetInnerHTML={{ __html: todoItem.name }}
        {...props}
      />
    ),
    [marked, props, todoItem.name],
  );
};

export default TodoItemText;
