import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import { useFetchProject } from '@api/@hooks';

import TodoItem from './TodoItem';
import { InitialDemoVendorScheduleButton } from '../@components';

const InitialVendorsSchedule: React.FC = () => {
  const [{ records }] = useFetchProject();
  const { project } = records;

  return useMemo(
    () => (
      <>
        {project.workspacedProducts.length ? (
          <Box display="flex" flexDirection="column" mt={0.5}>
            {project.workspacedProducts.map((vendor) => (
              <InitialDemoVendorScheduleButton key={vendor.id} vendor={vendor} />
            ))}
          </Box>
        ) : (
          <></>
        )}
      </>
    ),
    [project.workspacedProducts],
  );
};

const InitialVendorsScheduleTodoItem: React.FC = () => {
  return (
    <TodoItem>
      <InitialVendorsSchedule />
    </TodoItem>
  );
};

export default InitialVendorsScheduleTodoItem;
