import React from 'react';

import Box from 'components/@ui/Box';

import useReminders, { RemindersCaseType } from './useReminders';
import {
  RemindersWrapper,
  RemindersWordingBox,
  REMAINDERS_ANHOR,
  RemindersWording,
  WORDING,
  Xarrow,
  IconButtonReminders,
} from './@components';

type RemindersCaseProps = { cases: RemindersCaseType };

const RemindersCase: React.FC<RemindersCaseProps> = ({ cases }) => {
  return (
    <RemindersWrapper>
      <RemindersWordingBox id={REMAINDERS_ANHOR[cases]?.start}>
        <RemindersWording>{WORDING[cases]}</RemindersWording>
      </RemindersWordingBox>
      <Box color="yellow.main">
        <Xarrow start={REMAINDERS_ANHOR[cases].start} end={REMAINDERS_ANHOR[cases].end} />
        <IconButtonReminders onClick={useReminders().setCookie(cases)} />
      </Box>
    </RemindersWrapper>
  );
};

export default RemindersCase;
