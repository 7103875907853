import React from 'react';

import When from 'components/@main/When';
import { useIsTablet } from '@hooks';

import Tooltip, { TooltipProps } from '../Tooltip';
import Icon, { IconProps } from '../Icon';

type InfoIconProps = {
  title: string;
  tooltipProps?: Partial<TooltipProps>;
  iconProps?: IconProps;
};

const InfoTooltipIcon: React.FC<InfoIconProps> = ({ title, tooltipProps, iconProps }) => {
  const isTablet = useIsTablet();

  return (
    <When disabledWrapper condition={!isTablet}>
      <Tooltip title={title} maxWidth={140} {...tooltipProps}>
        <Icon name="info" width={12} height={12} fontSize={12} color="inherit" {...iconProps} />
      </Tooltip>
    </When>
  );
};

export default React.memo(InfoTooltipIcon);
