import React, { useCallback, useMemo } from 'react';

import IconButton from 'components/@ui/IconButton';
import { useMobileTimeline } from '@hooks';

import { useNavbar } from '../@hooks';

const ButtonToggle: React.FC = () => {
  const { isOpenNavbarMobile, setIsOpenNavbarMobile } = useNavbar();
  const [, toggleOpenMobileTimeline] = useMobileTimeline();

  const handleOpenMobileNavbar = useCallback(() => {
    toggleOpenMobileTimeline(false);
    setIsOpenNavbarMobile((state) => !state);
  }, [setIsOpenNavbarMobile, toggleOpenMobileTimeline]);

  return useMemo(
    () => (
      <IconButton
        onClick={handleOpenMobileNavbar}
        iconName={isOpenNavbarMobile ? 'cross' : 'navbar-mobile-menu-path'}
        sx={{
          bgcolor: 'blue.main',
          p: 1.5,
          '&:hover': { backgroundColor: 'primary.dark' },
          '&:active': { backgroundColor: 'actionPrimary.active1' },
          ...(!isOpenNavbarMobile && {
            boxShadow: 7,
            bgcolor: 'background.default',
            '&:hover': { backgroundColor: 'actionPrimary.hover1' },
            '&:active': { backgroundColor: 'actionPrimary.active2' },
          }),
        }}
        IconProps={{ color: !isOpenNavbarMobile ? 'text.primary' : 'secondary.main' }}
      >
        {isOpenNavbarMobile ? (
          <>
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
          </>
        ) : null}
      </IconButton>
    ),
    [handleOpenMobileNavbar, isOpenNavbarMobile],
  );
};

export default ButtonToggle;
