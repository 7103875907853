import React from 'react';

import TooltipDebounce, { TooltipDebounceProps } from 'components/@ui/TooltipDebounce';
import { clsx, makeSxStyles, parseSx } from '@helpers';

export const sxes = makeSxStyles({
  tooltip: {
    backgroundColor: 'secondary.main',
    color: 'text.primary',
    boxShadow: 8,
    p: 1.5,
    borderRadius: 4,
    m: '0 !important',
  },
})();

const TooltipDebounceCellUi: React.FC<TooltipDebounceProps> = (props) => {
  return (
    <TooltipDebounce
      arrow={false}
      maxWidth={9999}
      placement="right"
      {...props}
      PopperProps={{
        ...props.PopperProps,
        sx: clsx(
          { zIndex: 100, '.MuiTooltip-tooltip': sxes.tooltip },
          ...parseSx(props.PopperProps?.sx),
        ),
      }}
    />
  );
};

export default React.memo(TooltipDebounceCellUi);
