import React, { useCallback, useMemo } from 'react';

import Icon from 'components/@ui/Icon';
import ListItemIcon from 'components/@ui/ListItemIcon';
import ListItemText from 'components/@ui/ListItemText';
import { useFetchSignOut } from 'components/@common/Auth';

import MenuItem from './MenuItem';
import { useNavbar } from '../@hooks';

const SignOut: React.FC = () => {
  const { setIsOpenNavbarMobile } = useNavbar();
  const [, fetchSignOut] = useFetchSignOut();

  const onFetchSignOut = useCallback(() => {
    fetchSignOut();
    setIsOpenNavbarMobile(false);
  }, [fetchSignOut, setIsOpenNavbarMobile]);

  return useMemo(
    () => (
      <MenuItem onClick={onFetchSignOut}>
        <ListItemIcon>
          <Icon name="log-out-path" borderRadius={0} />
        </ListItemIcon>
        <ListItemText>Sign out</ListItemText>
      </MenuItem>
    ),
    [onFetchSignOut],
  );
};

export default React.memo(SignOut);
