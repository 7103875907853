import React from 'react';

import Box from 'components/@ui/Box';
import Icon from 'components/@ui/Icon';

const IconBox: React.FC = () => {
  return (
    <Box
      width={59}
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      zIndex={4}
    >
      <Icon name="info" color="text.primary" />
    </Box>
  );
};

export default IconBox;
