import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAPI, useProject } from '@hooks';

import { minGoLiveDate, selectors } from '../@slices';

const selector = createSelector(selectors.project, (state) => state.minGoLiveDate);

const useFetchMinGoLiveDate = () => {
  const { id } = useProject();

  return useAPI(
    useCallback(() => minGoLiveDate.action(id), [id]),
    selector,
  );
};

export default useFetchMinGoLiveDate;
