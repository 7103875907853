import React, { memo } from 'react';
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography';
import { BoxProps as MuiBoxProps } from '@mui/material/Box';
import { Property } from 'csstype';

import Fonts from 'styles/fonts';
import { clsx, parseSx } from '@helpers';

export interface TypographyProps extends MuiTypographyProps, Pick<MuiBoxProps, 'component'> {
  wordBreak?: Property.WordBreak;
  opacity?: Property.Opacity;
  semiBold?: boolean;
  extraBold?: boolean;
  proximaSemi?: boolean;
}

// @ts-ignore
const Typography: React.FC<TypographyProps> = React.forwardRef((props, ref) => {
  const { sx, semiBold, extraBold, wordBreak, opacity, proximaSemi, ...other } = props;

  return (
    <MuiTypography
      ref={ref}
      fontSize="inherit"
      color="inherit"
      sx={clsx({ opacity, wordBreak }, ...parseSx(sx))}
      {...(proximaSemi && { fontFamily: Fonts.ProximaSemiBold })}
      {...(semiBold && { fontFamily: Fonts.ProximaNovaBold })}
      {...(extraBold && { fontFamily: Fonts.ProximaExtraBold })}
      {...other}
    />
  );
});

export default memo(Typography);
