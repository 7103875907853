import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import Icon from 'components/@ui/Icon';
import Typography from 'components/@ui/Typography';
import { clsx, makeSxStyles } from '@helpers';

import { usePhase } from '../Phase';
import { icons, usePhases } from '../Phases';
import { colorGenerator } from '../@helpers';

export const sxses = makeSxStyles({
  mobile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 24,
    lineHeight: '32px',
    bgcolor: 'transparent',
    width: '100%',
    height: 80,
    p: 3,
    px: 3,
    border: 'none',
    borderRadius: 10,
  },
  current: {
    bgcolor: colorGenerator('current', 'phaseBackground'),
    '&:hover, &:active': {
      bgcolor: colorGenerator('current', 'phaseBackground'),
    },
  },
  done: {
    bgcolor: colorGenerator('done', 'phaseBackground'),
    '&:hover, &:active': {
      bgcolor: colorGenerator('done', 'phaseBackground'),
    },
  },
  next: {
    bgcolor: colorGenerator('next', 'phaseBackground'),
    '&:hover, &:active': {
      bgcolor: colorGenerator('next', 'phaseBackground'),
    },
  },
})();

const MobilePhaseButton: React.FC = () => {
  const { phase } = usePhase();
  const { toogleSelected } = usePhases();

  return useMemo(
    () => (
      <Button
        onClick={toogleSelected(phase)}
        sx={clsx(sxses[phase.status], sxses.mobile, {
          color: colorGenerator(phase.status, 'phaseText'),
        })}
      >
        <Box display="flex" alignItems="center">
          <Icon
            name={icons[phase.name]}
            fontSize="24px"
            width={24}
            color="inherit"
            borderRadius={0}
          />
          <Typography color="inherit" semiBold ml={1}>
            {phase.name}
          </Typography>
        </Box>
        <Icon name={'blue-arrow-down-path'} fontSize="16px" width={24} color="inherit" />
      </Button>
    ),
    [phase, toogleSelected],
  );
};

export default MobilePhaseButton;
