import React from 'react';

import Typography, { TypographyProps } from 'components/@ui/Typography';

// @ts-ignore
const TypographyCellUi: React.FC<TypographyProps> = React.forwardRef((props, ref) => {
  return (
    <Typography
      ref={ref}
      maxWidth={1}
      fontSize={16}
      lineHeight={1.5}
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      zIndex={4}
      {...props}
    />
  );
});

export default React.memo(TypographyCellUi);
