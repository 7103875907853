import { add, map, multiply, pipe, range, find } from 'ramda';

const sizeMatchMaxValues = [5000, 10000, 50000, 100000, 250000, 500000, 1000000];

export const getIsOverMax = (
  maxMinValue: number,
  dataMax: number,
  maxDiagramValue: number,
): boolean => maxMinValue > maxDiagramValue || dataMax > maxDiagramValue;

const minOrEqual = (futureHeadcount: number): number => {
  const predicate = (num: number) => num >= futureHeadcount;
  const result = find(predicate, sizeMatchMaxValues);

  return result ?? 5000;
};

export const getMaxValue = (futureHeadcount: number) => minOrEqual(futureHeadcount);

export const getExtremumMax = (acceptableMaxSize: number, futureHeadcount: number) =>
  acceptableMaxSize <= futureHeadcount ? futureHeadcount : acceptableMaxSize;

export const getExtremumMin = (acceptableMinSize: number, companyHeadcount: number) =>
  acceptableMinSize <= companyHeadcount ? acceptableMinSize : companyHeadcount;

export const getExtremumSize = (extremumMax: number, extremumMin: number) =>
  extremumMax - extremumMin;

export const getBarWidth = (size: number, extremumSize: number) => {
  const width = (size / extremumSize) * 100;
  return width > 0 ? width : 0;
};

export const getBarPosition = (min: number, extremumMin: number, width: number) =>
  `${((min - extremumMin) * 100) / width}%`;

export const getBarColor = (value: number) => {
  switch (true) {
    case value >= 5:
      return 'green.light';
    case value === 4:
      return 'green.dark';
    case value === 3:
      return 'yellowLight.dark';
    case value === 2:
      return 'red.main';
    case value === 1:
      return 'red.dark';
    default:
      return 'transparent';
  }
};

export const getVendorBarBorderColor = (value: number) => {
  switch (true) {
    case value >= 5:
      return 'greenLight.main';
    case value === 4:
      return 'phaseTooltip.main';
    case value === 3:
      return 'yellow.main';
    case value === 2:
      return 'actionError.disabled1';
    case value === 1:
      return 'error.main';
    default:
      return 'transparent';
  }
};

export const getBarHoverColor = (value: number) => {
  switch (true) {
    case value >= 5:
      return 'greenActions.hover1';
    case value === 4:
      return 'greenActions.hover2';
    case value === 3:
      return 'yellowActions.hover1';
    case value === 2:
      return 'red.dark';
    case value === 1:
      return 'redActions.active1';
    default:
      return 'transparent';
  }
};

export const getBarActiveColor = (value: number) => {
  switch (true) {
    case value >= 5:
      return 'greenActions.active1';
    case value === 4:
      return 'greenActions.active2';
    case value === 3:
      return 'yellowActions.active1';
    case value === 2:
      return 'redActions.active1';
    case value === 1:
      return 'redActions.active2';
    default:
      return 'transparent';
  }
};

export const rangeFn = (newStep: number) => map(pipe(multiply(newStep)));

export const rangeFromMinFn = (newStep: number, extremumMin: number) =>
  map(pipe(multiply(newStep), add(extremumMin)));

export const acceptableSizeMatchScaleGrid = (extremumMax: number, extremumMin: number) => {
  const step = Math.round((extremumMax - extremumMin) / 5);
  let adjustedStep = step;

  switch (true) {
    case step < 100 && step > 10 && step % 10 !== 0:
      adjustedStep = step + (10 - (step % 10));
      break;
    case step < 1000 && step > 100 && step % 100 !== 0:
      adjustedStep = step + (100 - (step % 100));
      break;
    case step < 10000 && step > 1000 && step % 1000 !== 0:
      adjustedStep = step + (1000 - (step % 1000));
      break;
    default:
      break;
  }

  switch (true) {
    case adjustedStep < extremumMin:
      return rangeFromMinFn(adjustedStep, extremumMin)(range(1, 5));
    case adjustedStep % 10 === 0 || adjustedStep % 100 === 0 || adjustedStep % 1000 === 0:
      return rangeFn(adjustedStep)(range(1, 5));
    default:
      return rangeFn(adjustedStep)(range(2, 5));
  }
};

export const roundedCostsFn = (number: number) => {
  if (number < 1000) return number;
  const firstGrade = number / 1000;
  if (firstGrade < 1000) return `${firstGrade?.toFixed(1).replace(/\.?0+$/, '')}k`;
  const secondGrade = firstGrade / 1000;
  if (secondGrade < 1000) return `${secondGrade?.toFixed(1).replace(/\.?0+$/, '')}M`;
  return `${secondGrade?.toFixed(1).replace(/\.?0+$/, '')}B`;
};

export const setEmodjiRate = (value: number): string => {
  switch (true) {
    case value === 1:
      return require('assets/emodji-score-1.png');
    case value === 2:
      return require('assets/emodji-score-3.png');
    case value === 3:
      return require('assets/emodji-score-3.png');
    case value === 4:
      return require('assets/emodji-score-4.png');
    case value === 5:
      return require('assets/emodji-score-5.png');
    default:
      return require('assets/emodji-score-1.png');
  }
};
