import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAPI, useProject } from '@hooks';

import { getWorkspaceById, selectors } from '../@slices';

const selector = createSelector(selectors.global, (state) => state.getWorkspaceById);

const useFetchWorkspaceById = () => {
  const { id: projectId } = useProject();

  return useAPI(
    useCallback((vendorId: number) => getWorkspaceById.action(projectId, vendorId), [projectId]),
    selector,
  );
};

export default useFetchWorkspaceById;
