import { useUpdateEffect } from 'react-use';

import { useWs } from '@hooks';

import { UseSubscriptionReturnType } from '../@hooks';
import { checkAllEquals, parseDataFn } from '../@helpers';
import { MESSAGES } from './messages';
import { SubscriptionMainType } from '../types';

type SubscriptionType = SubscriptionMainType<MESSAGES.hiddenInformationChanged>;

const useChangeWsHiddenData = (channel: UseSubscriptionReturnType) => {
  const { lastMessage } = useWs();

  useUpdateEffect(() => {
    if (!lastMessage) return undefined;
    const data = parseDataFn<SubscriptionType>(lastMessage);
    const { identifier, message } = data;
    const checks = checkAllEquals(
      identifier === channel.identifier,
      message?.action === MESSAGES.hiddenInformationChanged,
    );
    if (checks) {
      window.location.reload();
    }
  }, [lastMessage]);
};

export default useChangeWsHiddenData;
