import React from 'react';

import Image from 'components/@ui/Image';

type LogoProps = {
  src: string;
};

const Logo: React.FC<LogoProps> = ({ src }) => {
  return (
    <Image
      src={src}
      alt="logo"
      duration={0}
      width={32}
      height={32}
      sx={{ borderRadius: 30 }}
      fit="contain"
    />
  );
};

export default React.memo(Logo);
