import { combineReducers } from 'redux';

import { createSlice, createSliceSimple } from '@redux/slices';

import { fetchListVendors, fetchRequestDemo, fetchSendRequestDemo } from '../@api';

export const requestDemo = createSlice('FETCH_REQUEST_DEMO', fetchRequestDemo);
export const sendRequestDemo = createSliceSimple('FETCH_SEND_REQUEST_DEMO', fetchSendRequestDemo);

export const listVendors = createSlice('FETCH_LIST_VENDORS_REQUEST_DEMO', fetchListVendors, {
  initialState: [],
});

export default combineReducers({
  requestDemo: requestDemo.reducer,
  listVendors: listVendors.reducer,
});
