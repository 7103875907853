import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';
import { useCountdownState } from '@hooks';

const CountdownTime: React.FC = () => {
  const { time: countdownTime } = useCountdownState();

  return useMemo(
    () => (
      <Box display="flex">
        <Typography>Send code again</Typography>
        <Typography semiBold ml={1.5} sx={{ fontVariantNumeric: 'tabular-nums' }}>
          {countdownTime}
        </Typography>
      </Box>
    ),
    [countdownTime],
  );
};

export default CountdownTime;
