import baseAPI, { APIResponse } from '@api';

import { Note } from '../@types';

const apiCredentials = baseAPI();
//-------------------------------------------------------------------------
// Create Criterion Note
//-------------------------------------------------------------------------
export type FetchNoteCreateResponse = { note: Note.Note };
export type FetchNoteCreateBody = {
  note: string;
  section: Note.Section;
  userId: number;
  vendorId: number;
  criterionReplayId?: number;
};
export function fetchNoteCreate(
  projectId: number,
  criterionId: number,
  { section, userId, vendorId, criterionReplayId, note }: FetchNoteCreateBody,
): APIResponse<FetchNoteCreateResponse> {
  return apiCredentials.post(`/projects/${projectId}/criteria/${criterionId}/criterium_notes`, {
    criterium_note: {
      section,
      client_id: userId,
      product_id: vendorId,
      note,
      ...(criterionReplayId && { criterium_note_id: criterionReplayId }),
    },
  });
}
//-------------------------------------------------------------------------
// Edit Criterion Note
//-------------------------------------------------------------------------
export type FetchNoteEditBody = { note: string; id: number };
export function fetchNoteEdit(
  projectId: number,
  criterionId: number,
  { note, id }: FetchNoteEditBody,
): APIResponse<FetchNoteCreateResponse> {
  return apiCredentials.put(
    `/projects/${projectId}/criteria/${criterionId}/criterium_notes/${id}`,
    { criterium_note: { note } },
  );
}
//-------------------------------------------------------------------------
// Delete Criterion Note
//-------------------------------------------------------------------------
export function fetchNoteDelete(projectId: number, criterionId: number, noteId: number) {
  return apiCredentials.delete(
    `/projects/${projectId}/criteria/${criterionId}/criterium_notes/${noteId}`,
  );
}
//-------------------------------------------------------------------------
// Like Note
//-------------------------------------------------------------------------
export type FetchLikeAndDislikeNoteResponse = { note: Note.Note };
export function fetchNoteLike(
  projectId: number,
  criterionId: number,
  noteId: number,
): APIResponse<FetchLikeAndDislikeNoteResponse> {
  return apiCredentials.post(
    `/projects/${projectId}/criteria/${criterionId}/criterium_notes/${noteId}/upvote`,
  );
}
//-------------------------------------------------------------------------
// Dislike Note
//-------------------------------------------------------------------------
export function fetchNoteDislike(
  projectId: number,
  criterionId: number,
  noteId: number,
): APIResponse<FetchLikeAndDislikeNoteResponse> {
  return apiCredentials.post(
    `/projects/${projectId}/criteria/${criterionId}/criterium_notes/${noteId}/downvote`,
  );
}
//-------------------------------------------------------------------------
