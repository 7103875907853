import React from 'react';

import MenuButton from './MenuButton';
import Badge from './Badge';
import ProjectName from './ProjectName';

const Login: React.FC = () => {
  return (
    <>
      <Badge />
      <MenuButton />
      <ProjectName />
    </>
  );
};

export default Login;
