import React, { useCallback, useMemo, useState } from 'react';
import { useUnmount, useUpdateEffect } from 'react-use';
import { isNotUndefined } from 'ramda-adjunct';

import { createContext } from '@helpers';
import { UseState } from '@types';

import { PhotoCreatorProps } from './PhotoCreator';

export type PhotoCreatorContextType = {
  uploadedFile: File | undefined;
  setUploadedFile: UseState<File | undefined>;
  savedImage: File | string;
  setSavedImage: UseState<File | string>;
  onSetNewFile: (file: File) => Promise<void>;
  isEditing: boolean;
  isControl: boolean;
  handleDelete: () => Promise<void>;
  loading?: boolean;
};

export const [, usePhotoCreator, PhotoCreatorContext] = createContext<PhotoCreatorContextType>();

const PhotoCreatorProvider: React.FC<PhotoCreatorProps> = (props) => {
  const { children, image, setImage, callbackDelete, callbackSave, loading } = props;
  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined);
  const [savedImage, setSavedImage] = useState<File | string>(image || '');
  const isControl = useMemo(() => isNotUndefined(setImage), [setImage]);

  const onSetNewFile = useCallback(
    async (file: File) => {
      await callbackSave?.(file);
      setUploadedFile(undefined);
      isControl && setSavedImage(file);
    },
    [callbackSave, isControl],
  );

  const handleDelete = useCallback(async () => {
    await callbackDelete?.();
    isControl && setSavedImage('');
  }, [callbackDelete, isControl]);

  useUpdateEffect(() => {
    setSavedImage(image || '');
  }, [image]);

  useUpdateEffect(() => {
    setImage?.(savedImage);
  }, [savedImage]);

  useUnmount(() => {
    setUploadedFile(undefined);
    setSavedImage('');
    setImage?.('');
  });

  const contextValue = useMemo<PhotoCreatorContextType>(
    () => ({
      uploadedFile,
      setUploadedFile,
      savedImage,
      setSavedImage,
      onSetNewFile,
      isEditing: Boolean(uploadedFile),
      isControl,
      handleDelete,
      loading,
    }),
    [handleDelete, isControl, loading, onSetNewFile, savedImage, uploadedFile],
  );

  return (
    <PhotoCreatorContext.Provider value={contextValue}>{children}</PhotoCreatorContext.Provider>
  );
};

export default React.memo(PhotoCreatorProvider);
