import React from 'react';
import { Route as BrowserRoute, RouteProps as BrowserRouteProps } from 'react-router-dom';

import { FallbackComponent, FallbackComponentProps } from './@components';

type CustomRouteProps = {
  forbiden?: boolean;
};
export interface DefaultRouteProps
  extends BrowserRouteProps,
    FallbackComponentProps,
    CustomRouteProps {}

const DefaultRoute: React.FC<DefaultRouteProps> = (props) => {
  const {
    forbiden,
    fallback,
    component: Component,
    render: Render,
    redirectToNoMatchPage,
    ...other
  } = props;

  const RenderComponent = forbiden
    ? () => <FallbackComponent fallback={fallback} redirectToNoMatchPage={redirectToNoMatchPage} />
    : Render;

  return (
    <BrowserRoute
      {...other}
      render={RenderComponent || ((props) => Component && <Component {...props} />)}
    />
  );
};

export default React.memo(DefaultRoute);
