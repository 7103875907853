import React from 'react';

import ButtonsMenuList from './ButtonsMenuList';
import HeaderButton, { HeaderButtonProps } from './HeaderButton';

const ViewMenuButtonUI: React.FC<HeaderButtonProps> = ({ iconProps, ...other }) => {
  return (
    <HeaderButton
      iconProps={{ name: ButtonsMenuList.view[0].titleIcon, ...iconProps }}
      children={ButtonsMenuList.view[0].title}
      {...other}
    />
  );
};

export default React.memo(ViewMenuButtonUI);
