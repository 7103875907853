import React, { useMemo } from 'react';

import BadgeUi, { BadgeProps as BadgeUiProps } from 'components/@ui/Badge';
import Box, { BoxProps } from 'components/@ui/Box';
import IconUi, { IconProps } from 'components/@ui/Icon';
import { useIsTablet } from '@hooks';

import { TimeLineBar } from '../@types';
import { MobileBadgeCurrent, MobileBadgeDone } from '../@mobile';

export type BadgeProps = BadgeUiProps &
  BoxProps &
  TimeLineBar.Status & {
    IconProps?: Omit<IconProps, 'name'>;
  };

const Icon: React.FC<IconProps> = ({ name, ...other }) => {
  return (
    <IconUi
      name={name}
      width={12}
      fontSize={12}
      borderRadius={5}
      bgcolor="text.secondary"
      position="absolute"
      {...other}
    >
      <span className="path1" />
      <span className="path2" />
    </IconUi>
  );
};

const Badge: React.FC<BadgeProps> = ({ status, IconProps, ...other }) => {
  const isTablet = useIsTablet();
  const badgeComponent = useMemo(
    () =>
      isTablet
        ? {
            current: MobileBadgeCurrent,
            done: MobileBadgeDone,
            next: null,
          }
        : {
            current: () => <Icon name="phase-status-current" {...IconProps} />,
            done: () => <Icon name="phase-status-done" {...IconProps} />,
            next: null,
          },
    [IconProps, isTablet],
  );

  return <Box component={BadgeUi} components={{ Badge: badgeComponent[status] }} {...other} />;
};

export default React.memo(Badge);
