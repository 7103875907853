import React from 'react';

import HeaderButton, { HeaderButtonProps } from './HeaderButton';

const FilterMenuButtonUI: React.FC<HeaderButtonProps> = ({ iconProps, ...other }) => {
  return (
    <HeaderButton
      iconProps={{ name: 'vendors-filters-path', ...iconProps }}
      children="Filter"
      {...other}
    />
  );
};

export default React.memo(FilterMenuButtonUI);
