import React from 'react';

import { TableBody as MuiTableBody, TableBodyProps } from '@mui/material';

// @ts-ignore
const TableBody: React.FC<TableBodyProps> = React.forwardRef((props, ref) => {
  return <MuiTableBody {...props} ref={ref}></MuiTableBody>;
});

export default React.memo(TableBody);
