import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import Icon from 'components/@ui/Icon';
import ListItemIcon from 'components/@ui/ListItemIcon';
import ListItemText from 'components/@ui/ListItemText';
import { useEvent } from '@hooks';
import { ROUTES } from '@routes/config';

import MenuItem from './MenuItem';
import { useNavbar } from '../@hooks';
import { closeMobileNavbarAfterChangePage } from '../@servises';

export type SettingsProps = {
  onClosePopover: () => void;
};

const Settings: React.FC<SettingsProps> = ({ onClosePopover }) => {
  const history = useHistory();
  const { setIsOpenNavbarMobile } = useNavbar();

  const redirectTo = useEvent((route: string) => () => {
    history.push(route);
    onClosePopover();
    closeMobileNavbarAfterChangePage(setIsOpenNavbarMobile)();
  });

  return useMemo(
    () => (
      <MenuItem onClick={redirectTo(ROUTES.settings.profile.root)}>
        <ListItemIcon>
          <Icon name="setting-icon-path" borderRadius={0} />
        </ListItemIcon>
        <ListItemText>Settings</ListItemText>
      </MenuItem>
    ),
    [redirectTo],
  );
};

export default React.memo(Settings);
