import { NonEmptyString } from '@types';

const permissionsCreateCreator = () => {
  const data = new Set<string>();
  return <T extends string>(name: NonEmptyString<T>) => {
    if (data.has(name) && process.env.NODE_ENV === 'development') {
      console.error('Duplicate PERMISSION is not allowed', name);
      throw new Error(`Duplicate PERMISSION is not allowed ${name}`);
    }
    data.add(name);
    return name;
  };
};

const permissionCreate = permissionsCreateCreator();
export default permissionCreate;
