import React from 'react';
import MuiFormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@mui/material/FormControlLabel';

export interface FormControlLabelProps extends MuiFormControlLabelProps {}

// @ts-ignore
const FormControlLabel: React.FC<MuiFormControlLabelProps> = React.forwardRef((props, ref) => (
  <MuiFormControlLabel ref={ref} {...props} />
));

export default React.memo(FormControlLabel);
