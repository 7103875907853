import { TextFieldProps } from './TextField';

type InCollapseHelperTextProps = Pick<
  TextFieldProps,
  'collapseHelperText' | 'error' | 'helperText'
>;

export const inCollapseHelperText = ({
  collapseHelperText,
  error,
  helperText,
}: InCollapseHelperTextProps) => {
  if (helperText === null) {
    return undefined;
  }
  if (collapseHelperText) {
    return Boolean(error || helperText !== ' ');
  } else {
    return Boolean(error || helperText);
  }
};
