import React, { useMemo } from 'react';

import Step from 'components/@ui/Step';
import StepLabel from 'components/@ui/StepLabel';
import Box from 'components/@ui/Box';
import Icon from 'components/@ui/Icon';
import Button from 'components/@ui/Button';
import Typography from 'components/@ui/Typography';
import Divider from 'components/@ui/Divider';

import MobileCustomPhaseStepIcon from './MobileCustomPhaseStepIcon';
import MobileToDoItemsContainer from './MobileToDoItemsContainer';
import { ToDoStartIcon, useToDo } from '../ToDo';
import { colorGenerator } from '../@helpers';

const MobileCustomPhaseStepIconWrapper: React.FC = () => {
  const { todo } = useToDo();
  return useMemo(() => <MobileCustomPhaseStepIcon status={todo.status} />, [todo.status]);
};

type MobileToDoProps = {
  lastTodo: boolean;
};

const MobileToDo: React.FC<MobileToDoProps> = ({ lastTodo }) => {
  const { todo, handleClickToDo } = useToDo();

  return useMemo(
    () => (
      <>
        <Step>
          <StepLabel
            sx={{ width: '100%', position: 'relative', py: 0 }}
            StepIconComponent={MobileCustomPhaseStepIconWrapper}
          >
            <Button
              aria-describedby={`${todo.id}`}
              variant="contained"
              name={todo.name}
              onClick={handleClickToDo}
              fullWidth
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                bgcolor: 'inherit',
                '&:hover, &:active': { bgcolor: 'inherit' },
                height: 64,
                py: 2,
                pl: 0.5,
                pr: 0,
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography color="text.primary" semiBold fontSize={18} lineHeight="32px" mr={1}>
                  {todo.name}
                </Typography>
                <ToDoStartIcon />
              </Box>
              <Icon name="right" color="text.primary" />
            </Button>
            {!lastTodo && (
              <Divider
                orientation="vertical"
                sx={{
                  position: 'absolute',
                  borderWidth: 2,
                  height: 40,
                  left: 10,
                  top: 44,
                  borderColor: colorGenerator(todo.status, 'stepperConnector'),
                }}
              />
            )}
          </StepLabel>
        </Step>
        <MobileToDoItemsContainer />
      </>
    ),
    [handleClickToDo, lastTodo, todo.id, todo.name, todo.status],
  );
};

export default MobileToDo;
