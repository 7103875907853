import baseAPI, { APIResponse } from '@api';

import { TimeLineBar } from '../@types';

const apiCredentials = baseAPI();
//-------------------------------------------------------------------------
// Get Phases
//-------------------------------------------------------------------------
export type FetchTimeLinePhasesResponse = { phases: TimeLineBar.Phase[] };
export function fetchTimeLinePhases(projectId: number): APIResponse<FetchTimeLinePhasesResponse> {
  return apiCredentials.get(`/projects/${projectId}/timeline`);
}
//-------------------------------------------------------------------------
