import React, { useState } from 'react';
import { useUnmount } from 'react-use';
import { toast } from 'react-toastify';

import { useOpenModal } from '@hooks';
import { ROUTES } from '@routes/config';
import { checkStatus } from '@helpers';
import { User } from '@types';

import ButtonLinkedIn from './ButtonLinkedIn';
import DoalogConfirmLogOut, {
  LOGOUT_ENDPOINT,
  closeAction,
  logoutLinkedin,
} from './DoalogConfirmLogOut';
import useLinkedIn from './useLinkedIn';
import { SocialProps } from '../types';

const LinkedInAuth: React.FC<SocialProps> = ({ fetch }) => {
  const [, setErrorMessage] = useState('');
  const [, setCode] = useState('');
  const { open, handleClose, handleOpen } = useOpenModal();

  useUnmount(() => {
    setErrorMessage('');
    setCode('');
  });

  const onFetch = async (code: string) => {
    const response = await fetch({ provider: User.PROVIDERS.linkedin, accessToken: code });
    if (checkStatus(response?.status, 'gte')) {
      handleOpen();
    }
  };

  const { linkedInLogin } = useLinkedIn({
    redirectUri: `${window.location.origin}${ROUTES.auth.linkedin}`,
    onSuccess: (code) => {
      setErrorMessage('');
      setCode(code);
      onFetch(code);
    },
    onError: (error) => {
      toast.error('LinkedIn authentication Fail!');
      setErrorMessage(error.errorMessage);
    },
  });

  return (
    <>
      <ButtonLinkedIn onClick={linkedInLogin} />
      <DoalogConfirmLogOut
        open={open}
        handleOpen={logoutLinkedin(LOGOUT_ENDPOINT, closeAction(handleClose))}
        handleClose={closeAction(handleClose)}
      />
    </>
  );
};

export default React.memo(LinkedInAuth);
