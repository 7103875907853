import React from 'react';
import { FilterOptionsState } from '@mui/material';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
  createFilterOptions,
} from '@mui/material/Autocomplete';

export type Option = {
  label: string;
};
export type FilteredOptionsState<T> = FilterOptionsState<T>;

export const AutocompleteFilterOptions = <T,>() => createFilterOptions<T>();

export interface AutocompleteProps<T>
  extends MuiAutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined> {
  loading?: boolean;
}

type AutocompleteComponent = <T extends Option = Option>(
  props: AutocompleteProps<T>,
  ref: AutocompleteProps<T>['ref'],
) => JSX.Element;

const Autocomplete = React.forwardRef(
  <T,>(props: AutocompleteProps<T>, ref: AutocompleteProps<T>['ref']) => {
    const { loading, ...other } = props;

    return <MuiAutocomplete ref={ref} loading={loading} {...other} />;
  },
);
export default React.memo(Autocomplete) as AutocompleteComponent;
