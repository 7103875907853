import React from 'react';

import { useIsTablet } from '@hooks';
import { Children } from '@types';

import { TimeLineLayout, TimeLineMobileLayout } from './TimeLineLayout';

const LayoutWrapper: React.FC<Children> = ({ children }) => {
  const isTablet = useIsTablet();

  const Component = isTablet ? TimeLineMobileLayout : TimeLineLayout;

  return <Component>{children}</Component>;
};

export default React.memo(LayoutWrapper);
