import React, { useMemo, useState } from 'react';

import Icon from 'components/@ui/Icon';
import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import { clsx } from '@helpers';

import Logo from './Logo';
import LogoLabel from './LogoLabel';
import { makeSX, useFetchAdvisor } from '../@hook';
import { useToDoItems } from '../Context';
import { useToDoItem } from '../TodoItem';

const AdvisorRequestButton: React.FC = () => {
  const [{ records }] = useFetchAdvisor();
  const { advisor } = records;

  const { todoItem } = useToDoItem();
  const { sendMeetRequest } = useToDoItems();
  const [isDisabled, setIsDisabled] = useState(
    Boolean(todoItem.proposals.find((proposal) => proposal.meetableId === advisor.id)),
  );

  return useMemo(
    () => (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginLeft={4.5}
        bgcolor="transparent"
      >
        <Box display="flex" alignItems="center">
          <Logo src={advisor.avatarSmallUrl} />
          <LogoLabel label={advisor.name} />
        </Box>
        <Button
          sx={clsx(makeSX.buttonStyle)}
          endIcon={
            <Icon
              name={isDisabled ? 'proposal-sent-icon-path' : 'send-mail-icon-path'}
              color="inherit"
              borderRadius={0}
            />
          }
          onClick={sendMeetRequest(todoItem.id, advisor.id, 'Advisor', setIsDisabled)}
          disabled={isDisabled}
        >
          {isDisabled ? 'Sent' : 'Request'}
        </Button>
      </Box>
    ),
    [advisor.avatarSmallUrl, advisor.name, advisor.id, isDisabled, sendMeetRequest, todoItem.id],
  );
};

export default AdvisorRequestButton;
