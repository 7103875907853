import baseAPI, { APIResponse } from '@api';
import { Project } from '@types';

import { Workspace } from '../@types';
import { LabelsType } from '../EliminateModal/config';

const apiCredentials = baseAPI();
//-------------------------------------------------------------------------
// Get Vendors
//-------------------------------------------------------------------------
export type FetchVendorsResponse = { products: Workspace.Vendor[] };
export function fetchVendors(projectId: string | number): APIResponse<FetchVendorsResponse> {
  return apiCredentials.get(`/projects/${projectId}/workspace`);
}
//-------------------------------------------------------------------------
// Get Scorecard Progress Detail for Vendor
//-------------------------------------------------------------------------
export type FetchProgressDetailsQuery = { vendorId: number };
export type FetchProgressDetailsResponse = {
  keyCriteria: Workspace.ProgressDetails[];
};
export function fetchProgressDetails(
  projectId: number,
  { vendorId }: FetchProgressDetailsQuery,
): APIResponse<FetchProgressDetailsResponse> {
  return apiCredentials.get(`/projects/${projectId}/workspace/${vendorId}`);
}
// -------------------------------------------------------------------------
// Finalize Vendor (chosenProduct)
// -------------------------------------------------------------------------
export type FetchFinalizeVendorResponse = { project: Project.Project };
export function fetchFinalizeVendor(
  projectId: number,
  vendorId: number,
): APIResponse<FetchFinalizeVendorResponse> {
  return apiCredentials.post(`/projects/${projectId}/chosen_products`, {
    chosen_product: { product_id: vendorId },
  });
}
//-------------------------------------------------------------------------
// Create Feedbacks for Eliminate Vendor
//-------------------------------------------------------------------------
export type FetchFeedbackRequiredBody = { subject: string; body: string };
export type FetchFeedbackOtherBody = { reason?: LabelsType[]; feedback?: string };
export type FetchFeedbackBody = FetchFeedbackRequiredBody & FetchFeedbackOtherBody;
export type FetchFeedbackResponse = {
  feedback: FetchFeedbackRequiredBody & {
    id: number;
    clientId: number;
    projectId: number;
    vendorId: number;
    reason: string;
    feedback: string;
  };
};
export function fetchFeedbackCreate(
  clientId: number,
  projectId: number,
  vendorId: number,
  body: FetchFeedbackBody,
): APIResponse<FetchFeedbackResponse> {
  return apiCredentials.post(`/projects/${projectId}/feedbacks`, {
    feedback: {
      client_id: clientId,
      product_id: vendorId,
      ...body,
      reason: body.reason?.toString(),
    },
  });
}
//-------------------------------------------------------------------------
// Update Feedbacks for Eliminate Vendor
//-------------------------------------------------------------------------
export function fetchFeedbackUpdate(
  projectId: number,
  feedbackId: number,
  body: Partial<FetchFeedbackBody>,
): APIResponse<FetchFeedbackResponse> {
  return apiCredentials.put(`/projects/${projectId}/feedbacks/${feedbackId}`, {
    feedback: {
      ...body,
      reason: body.reason?.toString(),
    },
  });
}
//-------------------------------------------------------------------------
