import { all, any, gte, lt } from 'ramda';

const methods = { lt, gte };
const methodsStatuses = { lt: all, gte: any };

type Status = number | undefined;
export type Method = 'lt' | 'gte';

/**
 * `checkStatus` for response
 * @arg status number | undefined
 *
 * @arg method 'lt' | 'gte'
 * @default 'lt'
 *
 * @returns boolean,
 *
 * @example
 * ```typescript
 * checkStatus(400));              //=> false
 * checkStatus(400, 'gte'));       //=> true
 * checkStatus(500));              //=> false
 * checkStatus(500, 'gte'));       //=> true
 * checkStatus(200));              //=> true
 * checkStatus(200, 'gte'));       //=> false
 * checkStatus(304));              //=> true
 * checkStatus(304, 'gte'));       //=> false
 * checkStatus(0));                //=> false
 * checkStatus(0, 'gte'));         //=> true
 * checkStatus(undefined));        //=> false
 * checkStatus(undefined, 'gte')); //=> true
 * ```
 */
const checkStatus = (status: Status, method: Method = 'lt') =>
  status ? methods[method](status, 400) : method === 'gte';

/**
 * `checkStatuses` for response
 * @arg statuses (number | undefined)[]
 *
 * @arg method 'lt' | 'gte'
 * @default 'lt'
 *
 * @returns boolean,
 *
 * See also {@link checkStatus}
 */
export const checkStatuses = (statuses: Status[], method: Method = 'lt') =>
  methodsStatuses[method]((status) => checkStatus(status, method), statuses);

export default checkStatus;
