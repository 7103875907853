import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';

import { useFetchTodoItem } from './@hook';

type TodoItemsHeaderProps = {
  name: string;
};

const Description: React.FC = () => {
  const [{ records }] = useFetchTodoItem();
  return useMemo(
    () => (
      <Typography color="gray.main" fontSize={13} lineHeight={'16px'} mt={1.5}>
        {records.description}
      </Typography>
    ),
    [records.description],
  );
};

const ToDoItemsHeader: React.FC<TodoItemsHeaderProps> = ({ name }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" pb={3} px={3}>
      <Typography color="text.primary" semiBold>
        {name}
      </Typography>
      <Description />
    </Box>
  );
};

export default React.memo(ToDoItemsHeader);
