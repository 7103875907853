import React from 'react';

import Typography from 'components/@ui/Typography';
import Box from 'components/@ui/Box';
import Icon from 'components/@ui/Icon';
import { Children } from '@types';

import { colorGenerator } from '../@helpers';

type BadgeWrapperProps = {
  bgColor: string;
};

const BadgeWrapper: React.FC<BadgeWrapperProps & Children> = ({ bgColor, children }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      py={0.25}
      px={1}
      height={17}
      position="absolute"
      left={24}
      top={-8}
      bgcolor={bgColor}
      borderRadius={5}
    >
      {children}
    </Box>
  );
};

export const MobileBadgeDone: React.FC = () => {
  return (
    <BadgeWrapper bgColor={colorGenerator('done', 'phaseBadge')}>
      <Typography semiBold>DONE</Typography>
      <Icon
        name="phase-status-done"
        color="secondary.main"
        ml={0.5}
        fontSize={14}
        width={14}
        sx={{ '& .path2:before': { color: 'secondary.main', ml: 0 } }}
      >
        <span className="path2" />
      </Icon>
    </BadgeWrapper>
  );
};

export const MobileBadgeCurrent: React.FC = () => {
  return (
    <BadgeWrapper bgColor={colorGenerator('current', 'phaseBadge')}>
      <Typography semiBold>CURRENT PHASE</Typography>
    </BadgeWrapper>
  );
};
