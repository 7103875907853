import React, { useMemo } from 'react';

import Icon from 'components/@ui/Icon/Icon';
import Collapse from 'components/@ui/Collapse';

import { TimeLineBar } from '../../@types';
import { useToDoItem } from '../TodoItem';

const iconName = {
  [TimeLineBar.SubscriptionStateEnum['pro']]: 'pro-version-icon-path',
  [TimeLineBar.SubscriptionStateEnum['pro+']]: 'pro-icon-path',
};

const TodoItemProIcons: React.FC = () => {
  const { todoItem } = useToDoItem();
  const { requiredSubscription: proType } = todoItem;

  return useMemo(
    () => (
      <Collapse in={Boolean(proType)}>
        <Icon name={proType ? iconName[proType] : ''} borderRadius={0} minWidth={30}>
          <span className="path1"></span>
          <span className="path2"></span>
        </Icon>
      </Collapse>
    ),
    [proType],
  );
};

export default TodoItemProIcons;
