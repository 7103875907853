import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import IconButton from 'components/@ui/IconButton';
import Typography from 'components/@ui/Typography';
import { Children } from '@types';

import SkipDesignDemoModal from './SkipDesignDemoModal';
import DemoRequest from './DemoRequest';
import HRISProvider from './HRISProvider';
import RequestPrepare from './RequestPrepare';
import DialogPagination from './DialogPagination';
import ActionButtons from './ActionButtons';
import CancelRequestModal from './CancelRequestModal';
import DesignDemoModal from './DesignDemoModal';
import { MODAL_STEP } from './Context';
import { useRequestDemoModal } from './@hooks';

const ContentWrapper: React.FC<Children> = ({ children }) => {
  const { requestDemoModalFlowClose } = useRequestDemoModal();

  return useMemo(
    () => (
      <Box p={3} position="relative" maxWidth={400}>
        <Box display="flex" justifyContent="center">
          <IconButton
            iconName="cross"
            sx={{ position: 'absolute', top: 4, right: 4 }}
            onClick={requestDemoModalFlowClose}
          />
          <Typography fontSize={24} lineHeight="32px" semiBold maxWidth={315} component="h2">
            Request demo
          </Typography>
        </Box>
        {children}
        <DialogPagination />
        <ActionButtons />
      </Box>
    ),
    [requestDemoModalFlowClose, children],
  );
};

const step = {
  [MODAL_STEP.alreadyInTouch]: (
    <ContentWrapper>
      <DemoRequest />
    </ContentWrapper>
  ),
  [MODAL_STEP.HRISProvider]: (
    <ContentWrapper>
      <HRISProvider />
    </ContentWrapper>
  ),
  [MODAL_STEP.requestPrepared]: <RequestPrepare />,
  [MODAL_STEP.cancelRequest]: <CancelRequestModal />,
  [MODAL_STEP.designDemo]: <DesignDemoModal />,
  [MODAL_STEP.skipDesignDemo]: <SkipDesignDemoModal />,
};

const ModalsContent: React.FC = () => {
  const { modalStep } = useRequestDemoModal();

  return useMemo(() => step[modalStep], [modalStep]);
};

export default ModalsContent;
