import React, { useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import { Helmet } from 'react-helmet';

import Dialog from 'components/@ui/Dialog';
import When from 'components/@main/When';
import Loader from 'components/@common/Loader';

import ModalsContent from './ModalsContent';
import RequestDemoModalProvider from './Context';
import { useFetchRequestDemo } from './@hooks';

type ModalsWrapperProps = {
  open: boolean;
  handleClose: () => void;
};

const RequestDemo: React.FC<ModalsWrapperProps> = ({ open, handleClose }) => {
  const [{ loaded, loading }, fetchRequestDemo] = useFetchRequestDemo();

  useUpdateEffect(() => {
    open && fetchRequestDemo();
  }, [open]);

  return useMemo(
    () => (
      <Dialog open={open} PaperProps={{ sx: { maxWidth: 'fit-content', minWidth: 400 } }}>
        <Helmet>
          <title>OutSail - Demo Request</title>
        </Helmet>
        <When condition={loaded && !loading} fallback={<Loader height={400} />}>
          <RequestDemoModalProvider requestDemoModalFlowClose={handleClose}>
            <ModalsContent />
          </RequestDemoModalProvider>
        </When>
      </Dialog>
    ),
    [loaded, loading, open, handleClose],
  );
};

export default RequestDemo;
