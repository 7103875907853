import React from 'react';

import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';
import TypographyMobile from 'components/@ui/TypographyMobile';
import Image from 'components/@ui/Image';
import ScheduleOnboardingButton from './ScheduleOnboardingButton';
import { useIsTablet } from '@hooks';

const MainInformationHeader: React.FC = () => {
  const isTablet = useIsTablet();

  return (
    <Box
      display="flex"
      flexDirection={isTablet ? 'column-reverse' : 'row'}
      alignItems={isTablet ? 'center' : 'start'}
    >
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography semiBold fontSize={24} lineHeight="32px">
          OutSail is a free service for corporate HR, Finance & IT professionals
        </Typography>
        <TypographyMobile mobileRegularFontSize={isTablet} mt={3}>
          To join OutSail and receive unlimited access to our free tools & services, we require a
          brief 15-minute community onboarding meeting
        </TypographyMobile>
        <ScheduleOnboardingButton sx={{ mt: 1.5, width: isTablet ? 1 : 'auto' }} />
      </Box>
      <Box p={2}>
        <Image src={require('assets/time-to-go.png')} width={268} height={268} />
      </Box>
    </Box>
  );
};

export default MainInformationHeader;
