import { TimeLineBar } from '../@types';

export default <T extends TimeLineBar.Phase['status']>(
  phases: TimeLineBar.Phase[],
  phaseId: number,
  status: T,
) => {
  return phases.map((phase) => ({
    ...phase,
    ...(phase.id === phaseId && { status }),
  }));
};
