import React from 'react';

import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';

const makeTip = (numberMatches: number) => {
  switch (numberMatches) {
    case 0:
      return { color: 'gray.main', text: 'Make a strong password' };
    case 1:
      return { color: 'error.main', text: 'Very low security' };
    case 2:
      return { color: 'error.main', text: 'Low security' };
    case 3:
      return { color: 'yellow.main', text: 'You can better' };
    case 4:
      return { color: 'yellow.main', text: 'Good security' };
    default:
      return { color: 'greenLight.main', text: 'Perfect security' };
  }
};

export type StrengthBarProps = {
  numberMatches: number;
};

const StrengthBar: React.FC<StrengthBarProps> = ({ numberMatches }) => {
  const tip = makeTip(numberMatches);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      width="100%"
      rowGap={2}
    >
      <Typography
        fontSize={12}
        semiBold
        color={tip.color}
        sx={{ transition: (theme) => theme.transitions.create('color') }}
      >
        {tip.text}
      </Typography>
      <Box display="flex" gap={0.5}>
        {[1, 2, 3, 4, 5].map((matches) => (
          <Box
            key={matches}
            component="span"
            width={36}
            height={4}
            borderRadius={1}
            position="relative"
            bgcolor="gray.light"
            {...(numberMatches >= matches && { bgcolor: tip.color })}
            sx={{ transition: (theme) => theme.transitions.create('background-color') }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default React.memo(StrengthBar);
