import { useCallback } from 'react';

import { useAPI, useProject } from '@hooks';

import { checkedTodoItem } from '../@slices';

const { action } = checkedTodoItem;

const useFetchCheckedTodoItem = () => {
  const { id: projectId } = useProject();

  return useAPI(
    useCallback(
      (todoId: number, todoCompleted: boolean) => action(projectId, todoId, todoCompleted),
      [projectId],
    ),
  );
};

export default useFetchCheckedTodoItem;
