import { createSliceData, sliceData, updateSliceData } from '@redux/slices';
import { useSliceData } from '@hooks';
import { RootState } from '@redux/store';

const sessionExpiredSelector = (state: RootState) => state.sessionExpired;
const isloginSelector = (state: RootState) => state.islogin;

export const sessionExpired = createSliceData('SESSION_EXPIRED', { isSessionExpired: false });
export const islogin = createSliceData('IS_LOGIN', {
  isLogin: false,
  isFetchLogin: false,
});
export const plug = createSliceData('PLUG', null);
export const updateSessionExpired = updateSliceData(sessionExpired);

export const setSessionExpired = (state: boolean) => {
  updateSessionExpired({ isSessionExpired: state });
};
export const useSessionExpired = () => {
  return useSliceData(sessionExpired, sessionExpiredSelector);
};
export const useIsLoginSlice = () => {
  return useSliceData(islogin, isloginSelector);
};
export const isLoginSlice = () => {
  return sliceData(islogin, isloginSelector);
};
