import baseAPI, { APIResponse } from '@api';
import { MainFields, Product } from '@types';

const api = baseAPI(false);
//-------------------------------------------------------------------------
// Get Vendors
//-------------------------------------------------------------------------
export type FetchVendorsResponse = MainFields<{
  acceptableEmployeeRangeMax: number;
  acceptableEmployeeRangeMin: number;
  description: string;
  idealEmployeeRangeMin: number;
  idealEmployeeRangeMax: number;
  logoUrl: string;
  logoSmallUrl: string;
}>;
export type VendorsResponse = (Product.HeatMapDataVendor & FetchVendorsResponse)[];
export function fetchVendors(): APIResponse<VendorsResponse> {
  return api.get('/products');
}
//-------------------------------------------------------------------------
