import React from 'react';

import Box from 'components/@ui/Box';

import TodoItem from './TodoItem';
import { AdvisorCalendlyButton } from '../@components';

const AdvisorScheduleTodoItem: React.FC = () => {
  return (
    <TodoItem>
      <Box display="flex" flexDirection="column" mt={2}>
        <AdvisorCalendlyButton />
      </Box>
    </TodoItem>
  );
};

export default AdvisorScheduleTodoItem;
