import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAPI } from '@hooks';

import { clientRoles, selectors } from '../@slices';

const selector = createSelector(selectors.global, (state) => state.clientRoles);

const useFetchClientRoles = () => {
  return useAPI(
    useCallback(() => clientRoles.action(), []),
    selector,
  );
};

export default useFetchClientRoles;
