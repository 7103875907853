import React from 'react';

import { DialogProps } from 'components/@ui/Dialog';

import TermsAndPrivacy from './TermsAndPrivacy';

const PrivacyNoticeDialog = React.lazy(() => import('./PrivacyNoticeDialog'));

const PrivacyNotice: React.FC<DialogProps> = (props) => {
  return (
    <TermsAndPrivacy open={props.open}>
      <PrivacyNoticeDialog {...props} />
    </TermsAndPrivacy>
  );
};

export default PrivacyNotice;
