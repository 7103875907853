import React, { useCallback } from 'react';

import Button, { ButtonProps } from 'components/@ui/Button';
import Icon from 'components/@ui/Icon';
import { useFetchAdvisor } from '@api/@hooks';
import { useProject } from '@hooks';

const ScheduleOnboardingButton: React.FC<ButtonProps> = (props) => {
  const project = useProject();
  const [, fetchAdvisor] = useFetchAdvisor();

  const contactWithAdvisor = useCallback(async () => {
    const advisorResponse = await fetchAdvisor(project.id);
    if (advisorResponse.status < 400) {
      return window.open(
        advisorResponse.data.advisor.genericCalendlyUrl,
        '_blank',
        'noopener,noreferrer',
      );
    }
  }, [fetchAdvisor, project.id]);

  return (
    <Button
      onClick={contactWithAdvisor}
      startIcon={<Icon name="calendar-plus-icon-path" color="inherit" />}
      sx={{ maxWidth: 300 }}
      {...props}
    >
      Schedule Onboarding Meeting
    </Button>
  );
};
export default ScheduleOnboardingButton;
