import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@redux/store';

const selector = (state: RootState) => state.project.projectGet;

const useProject = () => {
  const project = useSelector(selector);
  return useMemo(() => project.records.project, [project.records.project]);
};

export default useProject;
