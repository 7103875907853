import React from 'react';

import { AddToWorkplaceIconButton } from '../VendorComponents';
import { WorkspaceAddCell } from '../HeatMapViewComponents';
import { useFetchVendors } from './@hooks';

const HeatMapWorkspaceAdd: React.FC = () => {
  const [{ records }] = useFetchVendors();

  return (
    <>
      {records.map((product) => (
        <WorkspaceAddCell
          AddToWorkplaceButtonComponent={AddToWorkplaceIconButton}
          key={product.id}
          productId={product.id}
        />
      ))}
    </>
  );
};

export default HeatMapWorkspaceAdd;
