import { useCallback, useMemo } from 'react';
import { createSelector } from 'reselect';

import { AxiosResponse } from 'axios';
import { User } from '@types';
import { useAppDispatch } from '@redux/store';
import { useIsLoginSlice } from '@redux/slices';
import { useOnFetchProject } from '@api/@hooks';
import { selfData } from '@api/@slices';
import { useAPI } from '@hooks';
import { jwt } from '@servises';
import { checkStatus } from '@helpers';

import { selectors, signIn } from '../@slices';
import { FetchSignInBody } from '../@api';

export const useDispatchSetUser = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (response: AxiosResponse<User.UserFields, any>, setTokenHeaders: boolean = true) => {
      if (checkStatus(response?.status)) {
        setTokenHeaders && jwt.setTokenHeaders(response?.headers);
        dispatch(selfData.actions.FULFILLED(response));
      }
    },
    [dispatch],
  );
};

const selector = createSelector(selectors.root, (state) => state.signIn);

const useFetchSignIn = () => {
  const [data, fetch] = useAPI(
    useCallback((props: FetchSignInBody) => signIn.action(props), []),
    selector,
  );

  const dispatchSetUser = useDispatchSetUser();

  const fetchSignIn = useCallback(
    async (props: FetchSignInBody) => {
      const response = await fetch(props);
      dispatchSetUser(response);
      return response;
    },
    [dispatchSetUser, fetch],
  );

  return useMemo(() => [data, fetchSignIn] as const, [data, fetchSignIn]);
};

export const useFetchProjectOnLogin = () => {
  const [, setIsLogin] = useIsLoginSlice();
  const onFetchProject = useOnFetchProject();

  return useCallback(
    async (response: AxiosResponse<User.UserFields>) => {
      await onFetchProject(response);
      setIsLogin((state) => ({ ...state, isLogin: true, isFetchLogin: true }));
    },
    [setIsLogin, onFetchProject],
  );
};

export const useFetchSignInAndProject = () => {
  const [data, fetch] = useFetchSignIn();
  const fetchProjectOnLogin = useFetchProjectOnLogin();

  const fetchSignIn = useCallback(
    async (props: FetchSignInBody) => {
      const response = await fetch(props);
      if (checkStatus(response?.status)) {
        await fetchProjectOnLogin(response);
      }
      return response;
    },
    [fetch, fetchProjectOnLogin],
  );

  return useMemo(() => [data, fetchSignIn] as const, [data, fetchSignIn]);
};

export default useFetchSignIn;
