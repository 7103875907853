import { Id } from 'react-toastify';

import { createSliceData, sliceData } from '@redux/slices';
import { RootState } from '@redux/store';

export type StatusesToast = {
  403: Id;
};

const statusesToastSelector = (state: RootState) => state.statusesToast;

export const statusesToast = createSliceData<StatusesToast>('STATUSES_TOAST', { 403: 0 });

export const statusesToastSliceData = () => {
  return sliceData(statusesToast, statusesToastSelector);
};
