import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { GOOGLE_OAUTH } from '@constants';

type GoogleOAuthProviderProps = Parameters<typeof GoogleOAuthProvider>[number];
type GoogleProviderProps = Partial<GoogleOAuthProviderProps> &
  Pick<GoogleOAuthProviderProps, 'children'>;

const Google: React.FC<GoogleProviderProps> = ({ clientId, ...other }) => {
  return <GoogleOAuthProvider clientId={clientId ?? GOOGLE_OAUTH ?? ''} {...other} />;
};

export default React.memo(Google);
