import React from 'react';
import {
  TableContainer as MuiTableContainer,
  TableContainerProps as MuiTableContainerProps,
} from '@mui/material';

export interface TableContainerProps extends MuiTableContainerProps {}

// @ts-ignore
const TableContainer: React.FC<TableContainerProps> = React.forwardRef((props, ref) => {
  return <MuiTableContainer {...props} ref={ref}></MuiTableContainer>;
});

export default React.memo(TableContainer);
