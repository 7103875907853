import { combineReducers } from 'redux';

import { createSlice } from '@redux/slices';

import { fetchProject, fetchWorkspaceProducts } from '@api';

export const workspaceProducts = createSlice('FETCH_WORKSPACE_PRODUCTS', fetchWorkspaceProducts, {
  initialState: [],
});

export const project = createSlice('FETCH_PROJECT', fetchProject, {
  initialState: { project: {} },
});

export default combineReducers({
  workspaceProducts: workspaceProducts.reducer,
  project: project.reducer,
});
