import PropTypes from 'prop-types';
import { ErrorBoundary, init } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { GlobalStyles } from '@mui/material';

import Fallback from 'utils/Fallback';
import { SENTRY_DSN } from '@constants';

process.env.NODE_ENV !== 'development' &&
  SENTRY_DSN &&
  init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });

const SentryProvider = ({ children }) => {
  return (
    <ErrorBoundary showDialog fallback={Fallback}>
      {children}
      <GlobalStyles
        styles={{
          '.sentry-error-embed-wrapper ': {
            zIndex: '2000 !important',
          },
        }}
      />
    </ErrorBoundary>
  );
};

export default SentryProvider;

SentryProvider.propTypes = {
  children: PropTypes.any,
};
