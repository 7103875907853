import React from 'react';

import { Children, UseState } from '@types';

import PhotoCreatorProvider from './Context';

export type PhotoCreatorProps = {
  image?: File | string;
  setImage?: UseState<File | string>;
  callbackDelete?: () => Promise<void> | void;
  callbackSave?: (file: File) => Promise<void> | void;
  loading?: boolean;
} & Children;

const PhotoCreator: React.FC<PhotoCreatorProps> = (props) => {
  return <PhotoCreatorProvider {...props} />;
};

export default React.memo(PhotoCreator);
