import React from 'react';
import { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';

import Avatar from 'components/@ui/Avatar';
import Typography from 'components/@ui/Typography';
import When from 'components/@main/When';
import { clsx, makeSxStyles, parseSx } from '@helpers';

const getFirstLetter = (name: string = '') => name.trim().toUpperCase().at(0);
const parseName = (name: string | null | undefined) => {
  const [firstName, lastName] = (name ?? '').split(' ');
  return { firstLetter: getFirstLetter(firstName), lastLetter: getFirstLetter(lastName) };
};

export type UserAvatarProps = Omit<MuiAvatarProps, 'src'> & {
  name?: string | null;
  pointer?: boolean;
  size?: number;
  src?: string | null;
};

const sxes = makeSxStyles({
  root: {
    bgcolor: 'background.default',
    color: 'divider',
    border: 1,
  },
  pointer: {
    cursor: 'pointer',
  },
})();

// @ts-ignore
const UserAvatar: React.FC<UserAvatarProps> = React.forwardRef((props, ref) => {
  const { name, sx, pointer, alt, size = 40, children, src, ...other } = props;
  const { firstLetter, lastLetter } = parseName(name);

  const makeSrc = () => {
    if (children) return undefined;
    if (src) return src;
    if (name) return undefined;
    return require('assets/Avatar_Default.png') as string;
  };

  return (
    <Avatar
      ref={ref}
      sx={clsx(sxes.root, pointer && sxes.pointer, { width: size, height: size }, ...parseSx(sx))}
      alt={alt ?? (name ?? 'n/a') + ' avatar'}
      src={makeSrc()}
      children={
        <Typography
          fontSize={size * 0.55}
          lineHeight={1}
          aria-labelledby={(name ?? 'n/a') + ' avatar'}
        >
          {children || (
            <When condition={Boolean(name)} disabledWrapper fallback="n/a">
              {firstLetter}
              {lastLetter}
            </When>
          )}
        </Typography>
      }
      {...other}
    />
  );
});

export default React.memo(UserAvatar);
