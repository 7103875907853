import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReadyState } from 'react-use-websocket';
import { useMount, useTimeout, useUnmount, useUpdateEffect } from 'react-use';
import { Id, toast } from 'react-toastify';

import { useEvent, useIsLogin, useOnline, useWs } from '@hooks';
import { useFetchSelfData } from '@api/@hooks';

import useToastsOffline from './useToastsOffline';
import { ToastWsError, ToastWsErrorProps } from '../Toasts';
import { UseSubscriptionProps } from './useSubscriptionWs';
import { defaultTimeoutSubscribe, noClosingToastProps } from '../config';
import { checkAllEquals, parseDataFn, showLog } from '../@helpers';
import { MessageType, SubscriptionType } from '../types';

export type UseSubscriptionReturnType = {
  subscribe(): void;
  unsubscribe(): void;
  identifier: string;
  toastDismiss(): void;
};

const subscribeStauses = {
  initial: { subscribing: false, subscribed: false },
  subscribing: { subscribing: true, subscribed: false },
  subscribed: { subscribing: false, subscribed: true },
};

const useSubscription = (props: UseSubscriptionProps) => {
  const [, fetchSelfData] = useFetchSelfData();
  const { identifier, timeoutSubscribe, fetchRefresh, initialSubscribe } = props;
  const { sendJsonMessage, lastMessage, readyState } = useWs();
  const isOnline = useOnline();
  const isLogin = useIsLogin();
  const toastId = useRef<Id>(0);

  const [allowSubscribe, setAllowSubscribe] = useState(true);
  const [reconection, setReconection] = useState(false);
  const [subscribeStatus, setSubscribeStatus] = useState(subscribeStauses.initial);

  const [isReady, cancel, reset] = useTimeout(timeoutSubscribe ?? defaultTimeoutSubscribe);
  const ready = isReady();

  const subscribe = useCallback(() => {
    cancel();
    sendJsonMessage({ command: MessageType.subscribe, identifier });
    setSubscribeStatus(subscribeStauses.subscribing);
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier]);

  const onFetchRefresh = useCallback(async () => {
    if (!fetchRefresh) return;
    setAllowSubscribe(false);
    const check = await fetchRefresh();
    setAllowSubscribe(check);
  }, [fetchRefresh]);

  const unsubscribe = useCallback(() => {
    sendJsonMessage({ command: MessageType.unsubscribe, identifier });
    setSubscribeStatus(subscribeStauses.initial);
    showLog(identifier, 'unsubscribed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier]);

  const makeToastError = useCallback(
    (errorProps?: ToastWsErrorProps, disableErrorToast?: boolean) => {
      setSubscribeStatus(subscribeStauses.initial);
      cancel();
      unsubscribe();
      if (!disableErrorToast) {
        toastId.current = toast.info(
          <ToastWsError
            {...errorProps}
            reason={errorProps?.reason || identifier}
            fetchSelfData={fetchSelfData}
          />,
          noClosingToastProps,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [identifier],
  );

  const toastDismiss = useEvent(() => {
    toastId.current && toast.dismiss(toastId.current);
  });

  useEffect(() => {
    if (!isLogin) return;
    if (allowSubscribe && initialSubscribe && readyState === ReadyState.OPEN) {
      subscribe();
      setAllowSubscribe(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowSubscribe, initialSubscribe, readyState]);

  useEffect(() => {
    if (!isLogin) return;
    if (readyState === ReadyState.OPEN && reconection) onFetchRefresh();
    if (readyState === ReadyState.CLOSED) setReconection(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyState]);

  useUpdateEffect(() => {
    if (!isLogin) return;
    if (ready && !subscribeStatus.subscribed) {
      makeToastError({ title: 'Connection timeout exceeded' }, props.disableErrorToast);
    }
  }, [ready]);

  useUpdateEffect(() => {
    if (!isLogin) return;
    if (!isOnline && subscribeStatus.subscribed) unsubscribe();
  }, [isOnline]);

  useUpdateEffect(() => {
    if (!isLogin) return;
    if (subscribeStatus.subscribed) {
      return undefined;
    } else if (lastMessage) {
      const { identifier: identifierWs, type } = parseDataFn<SubscriptionType>(lastMessage);
      const checks = checkAllEquals(identifier === identifierWs, type === MessageType.confirm);
      if (checks) {
        setSubscribeStatus(subscribeStauses.subscribed);
        cancel();
        showLog(identifier, 'subscribed');
      }
    } else if (subscribeStatus.subscribing) {
      makeToastError(undefined, props.disableErrorToast);
    }
  }, [lastMessage]);

  useToastsOffline({ refresh: onFetchRefresh });

  useMount(() => cancel());

  useUnmount(() => {
    toastDismiss();
    setAllowSubscribe(true);
    isLogin && unsubscribe();
  });

  return useMemo<UseSubscriptionReturnType>(
    () => ({ subscribe, unsubscribe, identifier, toastDismiss }),
    [subscribe, unsubscribe, identifier, toastDismiss],
  );
};

export default useSubscription;
