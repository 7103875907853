import React, { useMemo } from 'react';
import { Theme } from '@mui/material';

import Box from 'components/@ui/Box';
import SlideUi from 'components/@ui/Slide';
import { Children } from '@types';

import NavbarHeaderMenuMobile from './NavbarHeaderMenuMobile';
import { useNavbar } from './@hooks';

const Slide: React.FC<Children> = ({ children }) => {
  const { isOpenNavbarMobile } = useNavbar();

  return useMemo(
    () => (
      <SlideUi direction="down" in={isOpenNavbarMobile} mountOnEnter>
        <Box
          width="100%"
          height="calc(100% - 80px)"
          position="fixed"
          top={80}
          zIndex={99}
          overflow="scroll"
          bgcolor="primary.contrastText"
          component="nav"
          sx={{ '&': (theme: Theme) => theme.scrollbar(0), overscrollBehavior: 'contain' }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            height="100%"
            pb={10}
          >
            {children}
          </Box>
        </Box>
      </SlideUi>
    ),
    [children, isOpenNavbarMobile],
  );
};

const NavbarMobile: React.FC<Children> = ({ children }) => {
  return (
    <>
      <NavbarHeaderMenuMobile />
      <Slide>{children}</Slide>
    </>
  );
};

export default NavbarMobile;
