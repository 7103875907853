import React, { useMemo } from 'react';

import Avatar from 'components/@ui/Avatar';
import Button from 'components/@ui/Button';
import Icon from 'components/@ui/Icon';
import IconButton from 'components/@ui/IconButton';
import Typography from 'components/@ui/Typography';
import When from 'components/@main/When';
import { makeSxStyles } from '@helpers';

import { useNavbar } from '../@hooks';

const sxes = makeSxStyles({
  avatar: {
    mx: 'auto',
    bgcolor: 'transparent',
    width: 92,
    height: 92,
    '& .icon': { fontSize: 92, width: 92, height: 92 },
  },
  signUpButton: {
    width: '100%',
    marginTop: 1.5,
    backgroundColor: 'blue.main',
    '@media (max-width: 431px)': {
      width: 320,
    },
  },
})();

const Unauthorized: React.FC = () => {
  const { isOpenNavbar } = useNavbar();

  return useMemo(
    () => (
      <When
        condition={isOpenNavbar}
        disabledWrapper
        fallback={
          <IconButton
            sx={{ padding: 0, marginTop: 0.5 }}
            iconName="signup-navbar-button-path"
            IconProps={{ width: 48, height: 48, fontSize: 48 }}
          >
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
          </IconButton>
        }
      >
        <Avatar alt="some avatar" sx={sxes.avatar}>
          <Icon name="default-user-avatar-path" />
        </Avatar>
        <Button sx={sxes.signUpButton}>
          <Typography semiBold>SIGN UP FOR FREE</Typography>
        </Button>
      </When>
    ),
    [isOpenNavbar],
  );
};

export default Unauthorized;
