import { useCallback } from 'react';

import { useFetchTimeLinePhases, useFetchUpdateTimeLinePhases } from '../@hooks';
import { UpdateFinishDateProps } from './useUpdateWsPhaseFinishDate';
import { UpdatePhaseStatusProps } from './useUpdateWsPhaseStatus';
import { UpdateTodoStatusProps } from './useUpdateWsTodoStatus';
import { updateFinishDateFn, updatePhaseStatusFn, updateTodoStatusFn } from '../@helpers';
import {
  useUpdateWsPhaseFinishDate,
  useUpdateWsPhaseStatus,
  useUpdateWsTodoStatus,
} from '../@hooksWs';

const useUpdateWsTimeLine = () => {
  const [{ records }] = useFetchTimeLinePhases();
  const updateTimeLinePhases = useFetchUpdateTimeLinePhases();

  const updateFinishDate = useCallback(
    (props: UpdateFinishDateProps) => {
      const { finishDate, phaseId } = props;
      updateTimeLinePhases({ phases: updateFinishDateFn(records.phases, phaseId, finishDate) });
    },
    [records.phases, updateTimeLinePhases],
  );

  const updatePhaseStatus = useCallback(
    (props: UpdatePhaseStatusProps) => {
      const { status, phaseId } = props;
      updateTimeLinePhases({ phases: updatePhaseStatusFn(records.phases, phaseId, status) });
    },
    [records.phases, updateTimeLinePhases],
  );

  const updateTodoStatus = useCallback(
    (props: UpdateTodoStatusProps) => {
      const { status, phaseId, todoId } = props;
      updateTimeLinePhases({ phases: updateTodoStatusFn(records.phases, phaseId, todoId, status) });
    },
    [records.phases, updateTimeLinePhases],
  );

  useUpdateWsPhaseFinishDate({ update: updateFinishDate });
  useUpdateWsPhaseStatus({ update: updatePhaseStatus });
  useUpdateWsTodoStatus({ update: updateTodoStatus });
};

export default useUpdateWsTimeLine;
