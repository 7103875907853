import React from 'react';

import { IconBox, TableCell } from '../HeatMapViewComponents';
import { useFetchVendors } from './@hooks';

const HeatMapProsAndCons: React.FC = () => {
  const [{ records }] = useFetchVendors();

  return (
    <>
      {records.map((product) => (
        <TableCell key={product.id}>
          <IconBox />
        </TableCell>
      ))}
    </>
  );
};

export default HeatMapProsAndCons;
