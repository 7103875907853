import React, { useEffect } from 'react';
import { GlobalStyles } from '@mui/material';

import { Children } from '@types';

import { useAppLayout } from './@hooks';

export type AppLayoutWrapperProps = {
  hasNavBar?: boolean;
  hasTimeLineBar?: boolean;
  hasTimeLineBarPlug?: boolean;
};

const AppLayoutWrapper: React.FC<AppLayoutWrapperProps & Children> = (props) => {
  const { hasTimeLineBar, hasTimeLineBarPlug, hasNavBar, children } = props;
  const { setHasTimeLineBar, setHasTimeLineBarPlug, setHasNavBar } = useAppLayout();

  useEffect(() => {
    setHasTimeLineBar(Boolean(hasTimeLineBar));
    setHasTimeLineBarPlug(Boolean(hasTimeLineBarPlug));
    setHasNavBar(Boolean(hasNavBar));
  }, [
    hasNavBar,
    hasTimeLineBar,
    hasTimeLineBarPlug,
    setHasNavBar,
    setHasTimeLineBar,
    setHasTimeLineBarPlug,
  ]);

  return (
    <>
      <GlobalStyles styles={{ body: { overflow: 'hidden' } }} />
      {children}
    </>
  );
};

export default React.memo(AppLayoutWrapper);
