import React, { memo } from 'react';
import Fade from '@mui/material/Fade';
import MuiPopover, { PopoverProps as MuiPopoverProps } from '@mui/material/Popover';
import { Theme } from '@mui/material';

import { clsx, makeSxStyles, parseSx } from '@helpers';

export interface PopoverProps extends MuiPopoverProps {}

export const sxes = makeSxStyles({
  paper: {
    boxShadow: 11,
    '&': (theme: Theme) => theme.scrollbar(),
  },
})();

// @ts-ignore
const Popover: React.FC<PopoverProps> = React.forwardRef((props, ref) => {
  const { PaperProps, ...other } = props;

  return (
    <MuiPopover
      ref={ref}
      TransitionComponent={Fade}
      PaperProps={{
        ...PaperProps,
        sx: clsx(sxes.paper, ...parseSx(PaperProps?.sx)),
      }}
      {...other}
    />
  );
});

export default memo(Popover);
