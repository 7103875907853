import React from 'react';
import MuiCollapse, { CollapseProps as MuiCollapseProps } from '@mui/material/Collapse';

export interface CollapseProps extends MuiCollapseProps {}

// @ts-ignore
const Collapse: React.FC<CollapseProps> = React.forwardRef((props, ref) => (
  <MuiCollapse ref={ref} timeout="auto" unmountOnExit {...props} />
));

export default React.memo(Collapse);
