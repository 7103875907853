import React from 'react';
import { Theme } from '@mui/material';

import Box from 'components/@ui/Box';
import IconButton from 'components/@ui/IconButton';
import { Children } from '@types';

type MobileVendorHeaderWrapperProps = {
  open?: boolean;
  handleClick?: () => void;
};

const MobileVendorHeaderWrapper: React.FC<MobileVendorHeaderWrapperProps & Children> = ({
  open,
  handleClick,
  children,
}) => {
  return (
    <Box display="flex" alignItems="center" maxWidth="calc(100% - 150px)">
      <IconButton
        iconName="right"
        sx={{ width: 32, height: 32, borderRadius: 1 }}
        onClick={handleClick}
        IconProps={{
          sx: {
            transition: (theme: Theme) => theme.transitions.create(['transform']),
            transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
          },
        }}
      />
      {children}
    </Box>
  );
};

export default React.memo(MobileVendorHeaderWrapper);
