import React, { useMemo } from 'react';

import Box, { BoxProps } from 'components/@ui/Box';
import { Children } from '@types';
import { useIsTablet } from '@hooks';
import { clsx, makeSxStyles } from '@helpers';

import ToDos, { ToDosProvider } from '../ToDos';
import { PhaseBadge, PhaseBox, PhaseButton, PhaseTooltip } from './PhaseBox';
import { usePhase } from './Context';
import { colorGenerator } from '../@helpers';
import { MobilePhaseButton, MobileToDos } from '../@mobile';

const sxDefault = {
  color: 'secondary.main',
  boxShadow: 6,
  my: 3,
  border: 1,
  borderRadius: 10,
} as const;

export const sxses = makeSxStyles({
  current: {
    bgcolor: colorGenerator('current', 'phaseBackground'),
    ...sxDefault,
    borderColor: colorGenerator('current', 'phaseBorder'),
  },
  done: {
    bgcolor: colorGenerator('done', 'phaseBackground'),
    ...sxDefault,
    borderColor: colorGenerator('done', 'phaseBorder'),
  },
  next: {
    bgcolor: colorGenerator('next', 'phaseBackground'),
    ...sxDefault,
    borderColor: colorGenerator('next', 'phaseBorder'),
  },
})();

const ToDosRecords: React.FC<Children> = ({ children }) => {
  const { phase } = usePhase();
  return useMemo(
    () => (
      <ToDosProvider records={phase.todos} finishDate={phase.finishDate}>
        {children}
      </ToDosProvider>
    ),
    [children, phase.finishDate, phase.todos],
  );
};

const PhaseWrapper: React.FC<BoxProps & Children> = ({ children, ...other }) => {
  return (
    <Box display="flex" flexWrap="nowrap" {...other}>
      {children}
    </Box>
  );
};

const PhaseBoxWrapper: React.FC<Children> = ({ children }) => {
  return (
    <PhaseBox>
      <PhaseBadge>{children}</PhaseBadge>
    </PhaseBox>
  );
};

const PhaseDesktop: React.FC = () => {
  return (
    <PhaseWrapper>
      <PhaseBoxWrapper>
        <PhaseTooltip>
          <PhaseButton />
        </PhaseTooltip>
      </PhaseBoxWrapper>
      <ToDosRecords>
        <ToDos />
      </ToDosRecords>
    </PhaseWrapper>
  );
};

const PhaseMobile: React.FC = () => {
  const { phase, isSelected } = usePhase();

  return useMemo(
    () => (
      <PhaseWrapper
        sx={clsx(sxses[phase.status], isSelected && { pb: 3 })}
        flexDirection={{ mobile: 'column', tablet: 'column' }}
      >
        <PhaseBoxWrapper>
          <MobilePhaseButton />
        </PhaseBoxWrapper>
        <ToDosRecords>
          <MobileToDos />
        </ToDosRecords>
      </PhaseWrapper>
    ),
    [phase.status, isSelected],
  );
};

const Phase: React.FC = () => {
  const isTablet = useIsTablet();

  return useMemo(() => (isTablet ? <PhaseMobile /> : <PhaseDesktop />), [isTablet]);
};

export default Phase;
