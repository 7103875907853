import { ROUTES } from '@routes/config';

export type SortByType =
  | 'default'
  | 'size_match'
  | 'feature_match'
  | 'characteristic_match'
  | 'software_fees_min'
  | 'implementation_costs_min';
export type ButtonType = 'button' | 'options' | 'options-download-report';
export type ListItemProps = {
  title: string;
  titleIcon: string;
  name: string;
  icon: string;
  type: ButtonType;
};
export type ButtonsMenuListKeys = 'options' | 'view' | 'sort';
export type ListViewItemProps = ListItemProps & { route: string; routes: string[] };
export type ListSortItemProps = ListItemProps & { sortBy: SortByType };
export type ListOptionsItemProps = ListItemProps & { link?: string; colapsePath?: boolean };
export type ListItemAnyProps = ListViewItemProps | ListSortItemProps | ListOptionsItemProps;
export type ButtonTypeProp = { buttonType: ButtonsMenuListKeys };
export type ButtonsMenuListType = {
  view: ListViewItemProps[];
  sort: ListSortItemProps[];
  options: ListOptionsItemProps[];
};

const ButtonsMenuList: ButtonsMenuListType = {
  view: [
    {
      title: 'Heat Map',
      titleIcon: 'vendors-heat-map-path',
      name: 'Heat Map',
      icon: 'vendors-heat-map-path',
      type: 'button',
      route: ROUTES.vendors.root,
      routes: [
        ROUTES.vendors.root,
        ROUTES.vendors.heatMap,
        ROUTES.vendors.heatMapChangeGoals,
        ROUTES.vendors.heatMapChangeScope,
      ],
    },
    {
      title: 'Size Match & Scalability',
      titleIcon: 'vendors-size-match-path',
      name: 'Size Match & Scalability',
      icon: 'vendors-size-match-path',
      type: 'button',
      route: ROUTES.vendors.sizeMatchAndScalability,
      routes: [
        ROUTES.vendors.sizeMatchAndScalability,
        ROUTES.vendors.sizeMatchAndScalabilityChangeGoals,
        ROUTES.vendors.sizeMatchAndScalabilityChangeScope,
      ],
    },
    {
      title: 'Feature Match',
      titleIcon: 'vendors-features-match-path',
      name: 'Feature Match',
      icon: 'vendors-features-match-path',
      type: 'button',
      route: ROUTES.vendors.featureMatch,
      routes: [
        ROUTES.vendors.featureMatch,
        ROUTES.vendors.featureMatchChangeGoals,
        ROUTES.vendors.featureMatchChangeScope,
      ],
    },
    {
      title: 'Characteristic Match',
      titleIcon: 'vendors-characteristics-match-path',
      name: 'Characteristic Match',
      icon: 'vendors-characteristics-match-path',
      type: 'button',
      route: ROUTES.vendors.characteristicMatch,
      routes: [
        ROUTES.vendors.characteristicMatch,
        ROUTES.vendors.characteristicMatchChangeGoals,
        ROUTES.vendors.characteristicMatchChangeScope,
      ],
    },
    {
      title: 'Pros and Cons',
      titleIcon: 'vendors-pros-cons-match-path',
      name: 'Pros and Cons',
      icon: 'vendors-pros-cons-match-path',
      type: 'button',
      route: ROUTES.vendors.prosAndCons,
      routes: [
        ROUTES.vendors.prosAndCons,
        ROUTES.vendors.prosAndConsChangeGoals,
        ROUTES.vendors.prosAndConsChangeScope,
      ],
    },
    {
      title: 'Table View',
      titleIcon: 'vendors-table-match-path',
      name: 'Table View',
      icon: 'vendors-table-match-path',
      type: 'button',
      route: ROUTES.vendors.tableView,
      routes: [
        ROUTES.vendors.tableView,
        ROUTES.vendors.tableViewChangeGoals,
        ROUTES.vendors.tableViewChangeScope,
      ],
    },
  ],
  sort: [
    {
      title: 'Sort by Match Score',
      titleIcon: 'vendors-sort-path',
      name: 'by Match Score, %',
      icon: 'vendors-sort-match-score-path',
      type: 'button',
      sortBy: 'default',
    },
    {
      title: 'Sort by Size Match & Scalability',
      titleIcon: 'vendors-sort-path',
      name: 'by Size Match & Scalability ',
      icon: 'vendors-size-match-path',
      type: 'button',
      sortBy: 'size_match',
    },
    {
      title: 'Sort by Feature Match',
      titleIcon: 'vendors-sort-path',
      name: 'by Feature Match',
      icon: 'vendors-features-match-path',
      type: 'button',
      sortBy: 'feature_match',
    },
    {
      title: 'Sort by Characteristic Match',
      titleIcon: 'vendors-sort-path',
      name: 'by Characteristic Match',
      icon: 'vendors-characteristics-match-path',
      type: 'button',
      sortBy: 'characteristic_match',
    },
    {
      title: 'Sort by Software Costs',
      titleIcon: 'vendors-sort-path',
      name: 'by Software Costs',
      icon: 'vendors-sort-software-cost-path',
      type: 'button',
      sortBy: 'software_fees_min',
    },
    {
      title: 'Sort by Implementation Costs',
      titleIcon: 'vendors-sort-path',
      name: 'by Implementation Costs',
      icon: 'vendors-sort-implementation-cost-path',
      type: 'button',
      sortBy: 'implementation_costs_min',
    },
  ],
  options: [
    {
      title: 'Options',
      titleIcon: 'phase-requirements',
      name: 'Change project goals',
      icon: 'phase-requirements',
      type: 'options',
      link: ROUTES.vendors.changeGoals,
      colapsePath: true,
    },
    {
      title: 'Options',
      titleIcon: 'phase-requirements',
      name: 'Change project scope',
      icon: 'phase-requirements',
      type: 'options',
      link: ROUTES.vendors.changeScope,
      colapsePath: true,
    },
    {
      title: 'Options',
      titleIcon: 'phase-requirements',
      name: 'Download shortlist',
      icon: 'cloud-download-2',
      type: 'options-download-report',
    },
  ],
};

export default ButtonsMenuList;

export const FEATURE_MODULES = [
  { name: 'Payroll', iconName: 'feature-payroll' },
  { name: 'Performance', iconName: 'feature-performance' },
  { name: 'ATS / Recruiting', iconName: 'feature-ats-recruiting' },
  { name: 'HRIS – Employee Files', iconName: 'feature-hris-employee-files' },
  { name: 'Comp Management', iconName: 'feature-comp-management' },
  { name: 'Engagement', iconName: 'feature-engagement' },
  { name: 'Benefits Admin', iconName: 'feature-benefits-admin' },
  { name: 'Learning & Training', iconName: 'feature-learning-training' },
  { name: 'Time & Attendance', iconName: 'feature-time-attendance' },
  { name: 'Onboarding', iconName: 'feature-onboarding' },
];

export const CHARACTERISTIC_MODULE_GROUP_NAME = [
  'Primary Priorities',
  'Secondary priorities',
  'Other Available Characteristic',
];

export const FEATURE_MODULE_GROUP_NAME = [
  'Mandatory Modules',
  'Optional Modules',
  'Other Available Modules',
];
