import React, { useMemo } from 'react';

import { Children } from '@types';
import { TimeLineBar } from '../@types';

export type ToDosProviderProps = {
  records: TimeLineBar.ToDo[];
  finishDate: TimeLineBar.Phase['finishDate'];
};

export type ToDosContextType = {
  todos: TimeLineBar.ToDo[];
  finishDate: TimeLineBar.Phase['finishDate'];
};

export const ToDosContext = React.createContext<ToDosContextType>(null as any);
export const ToDosConsumer = ToDosContext.Consumer;

const ToDosProvider: React.FC<ToDosProviderProps & Children> = (props) => {
  const { records, finishDate, children } = props;

  const contextValue = useMemo<ToDosContextType>(
    () => ({ todos: records, finishDate }),
    [finishDate, records],
  );

  return <ToDosContext.Provider value={contextValue}>{children}</ToDosContext.Provider>;
};

export default React.memo(ToDosProvider);
