import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAPI, useProject } from '@hooks';

import { selectors, workspaceProducts } from '../@slices';

const selector = createSelector(selectors.root, (state) => state.workspaceProducts);
const { action } = workspaceProducts;

const useFetchWorkspaceProducts = () => {
  const { id: projectId } = useProject();

  return useAPI(
    useCallback(() => action(projectId), [projectId]),
    selector,
  );
};

export default useFetchWorkspaceProducts;
