import { useCallback, useMemo } from 'react';
import { createSelector } from 'reselect';

import { useDispatchSetUser } from 'components/@common/Auth';
import { useAPI } from '@hooks';

import { createUser, selectors } from '../@slices';
import { FetchCreateUserBody } from '../user';

const selector = createSelector(selectors.user, (state) => state.createUser);

const useFetchCreateUser = () => {
  const [data, fetch] = useAPI(
    useCallback((props: FetchCreateUserBody) => createUser.action(props), []),
    selector,
  );

  const dispatchSetUser = useDispatchSetUser();

  const fetchCreateUser = useCallback(
    async (props: FetchCreateUserBody) => {
      const response = await fetch(props);
      dispatchSetUser(response);
      return response;
    },
    [dispatchSetUser, fetch],
  );

  return useMemo(() => [data, fetchCreateUser] as const, [data, fetchCreateUser]);
};

export default useFetchCreateUser;
