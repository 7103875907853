import { useUpdateEffect } from 'react-use';

import { loadScriptDanteAI, removeScriptDanteAI } from '@helpers';

import useProject from './useProject';
import { useIsLoginSlice } from '../@redux/slices';

const useLoadDanteAIScript = (loadedData: boolean) => {
  const [{ records }] = useIsLoginSlice();
  const { isLogin } = records;
  const { hiddenInformation } = useProject();

  useUpdateEffect(() => {
    if (!loadedData) return;
    if (!isLogin) return removeScriptDanteAI();
    isLogin && loadScriptDanteAI(hiddenInformation);
  }, [isLogin, loadedData]);
};

export default useLoadDanteAIScript;
