import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';
import Icon from 'components/@ui/Icon';
import Button from 'components/@ui/Button';

import { useFetchTodoItem } from '../ToDoItems/@hook';
import { usePhase } from '../Phase';
import { useToDo } from '../ToDo';

type TodoItemsHeaderProps = {
  name: string;
};

const Description: React.FC = () => {
  const [{ records }] = useFetchTodoItem();

  return useMemo(
    () => (
      <Typography color="gray.main" mt={1.5} maxWidth={350} textAlign="center">
        {records.description}
      </Typography>
    ),
    [records.description],
  );
};

const MobileToDoItemsHeader: React.FC<TodoItemsHeaderProps> = ({ name }) => {
  const { phase } = usePhase();
  const { handleCloseToDoPopover } = useToDo();

  return useMemo(
    () => (
      <Box display="flex" flexDirection="column" alignItems="center" pb={3} px={3} mt={3}>
        <Box width={1}>
          <Button
            variant="text"
            size="small"
            onClick={handleCloseToDoPopover}
            startIcon={<Icon name="left" />}
            sx={{ pr: 0.5, py: 0.25, pl: 0, fontSize: 18, lineHeight: '24px' }}
          >
            {phase.name}
          </Button>
        </Box>
        <Typography color="text.primary" semiBold fontSize={18} lineHeight="32px" mt={3}>
          {name}
        </Typography>
        <Description />
      </Box>
    ),
    [phase.name, handleCloseToDoPopover, name],
  );
};

export default MobileToDoItemsHeader;
