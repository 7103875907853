import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { useForm } from 'components/@main/Form';

import { Common } from '../@types';

const ERROR_TEXT = 'Email already exists';

const useMakeToastErrorEmailExists = () => {
  const { setError } = useForm<Common.Email>();

  return useCallback(
    (errorText: string = ERROR_TEXT) => {
      toast.error(errorText);
      setError('email', { message: errorText }, { shouldFocus: true });
    },
    [setError],
  );
};

export default useMakeToastErrorEmailExists;
