import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import { Children } from '@types';

import { useToDo } from '../Context';

const ToDoBox: React.FC<Children> = ({ children }) => {
  const { index } = useToDo();

  return useMemo(
    () => (
      <Box pr={1} py={1.5} {...(index === 0 && { pl: 0 })}>
        {children}
      </Box>
    ),
    [children, index],
  );
};

export default ToDoBox;
