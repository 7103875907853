import React, { useMemo } from 'react';

import Icon from 'components/@ui/Icon';
import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import Dialog from 'components/@ui/Dialog/Dialog';
import { useOpenModal } from '@hooks';

import Logo from './Logo';
import { useFetchAdvisor } from '../@hook';
import { SkipTodoConfirmModal } from './index';
import { useToDoItem } from '../TodoItem';

const AdvisorFirstCalendlyButton: React.FC = () => {
  const { open, handleOpen, handleClose } = useOpenModal();
  const { changeTodoItem, marked } = useToDoItem();
  const [{ records }] = useFetchAdvisor();
  const { advisor } = records;

  return useMemo(
    () => (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginLeft={4.5}
        bgcolor="transparent"
      >
        {marked ? (
          <Button
            onClick={changeTodoItem()}
            startIcon={<Icon name="undo-icon-path" color="inherit" />}
            variant="text"
            size="small"
            sx={{
              ml: -2.5,
              height: { mobile: 56, table: 56, laptop: 32 },
              fontSize: { mobile: 18, table: 18, laptop: 16 },
            }}
          >
            Reset
          </Button>
        ) : (
          <>
            {' '}
            <Box display="flex" alignItems="center">
              <Logo src={advisor.avatarSmallUrl} />
            </Box>
            <Box display="flex">
              <Button
                onClick={handleOpen}
                variant="text"
                size="small"
                sx={{ mr: 3, height: { mobile: 56, table: 56, laptop: 32 } }}
              >
                Skip
              </Button>
              <a target="_blank" href={advisor.calendlyUrl} rel="noopener noreferrer">
                <Button
                  size="small"
                  endIcon={<Icon name="calendar-plus-icon-path" color="inherit" borderRadius={0} />}
                  sx={{
                    height: { mobile: 56, table: 56, laptop: 32 },
                    fontSize: { mobile: 18, table: 18, laptop: 16 },
                  }}
                >
                  Schedule
                </Button>
              </a>
            </Box>
            <Dialog
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              PaperProps={{ sx: { width: '100%', borderRadius: 4, margin: 2, maxWidth: 400 } }}
            >
              <Box bgcolor="background.paper" borderRadius={4}>
                <SkipTodoConfirmModal handleClose={handleClose} confirm={changeTodoItem()} />
              </Box>
            </Dialog>
          </>
        )}
      </Box>
    ),
    [
      marked,
      changeTodoItem,
      advisor.avatarSmallUrl,
      advisor.calendlyUrl,
      handleOpen,
      open,
      handleClose,
    ],
  );
};

export default AdvisorFirstCalendlyButton;
