import { useMemo } from 'react';

import useSubscription from './useSubscription';

export type SubscriptionFetch = () => Promise<boolean> | boolean;

export type UseSubscriptionFetch = {
  fetchRefresh?: SubscriptionFetch;
  initialSubscribe: boolean;
};

export type UseSubscriptionProps = UseSubscriptionFetch & {
  identifier: string;
  timeoutSubscribe?: number;
  disableErrorToast?: boolean;
};

const useSubscriptionWs = (props: UseSubscriptionProps) => {
  const subscription = useMemo(() => props, [props]);
  return useSubscription(subscription);
};

export default useSubscriptionWs;
