import React from 'react';

import Image from 'components/@ui/Image';
import Icon from 'components/@ui/Icon';
import Typography, { TypographyProps } from 'components/@ui/Typography';

import { OptionProps } from './Context';

type OptionsProps = TypographyProps & {
  option: OptionProps;
};

const VendorOption: React.FC<OptionsProps> = ({ option, ...other }) => {
  return (
    <Typography component="li" height={44} px={1.5} {...other} opacity="1 !important">
      {option?.iconName && (
        <Image src={option.iconName} duration={0} fit="contain" width={24} height={24} />
      )}
      <Typography ml={1}>{option.label}</Typography>
    </Typography>
  );
};

const AddNewVendorOption: React.FC<OptionsProps> = ({ option, ...other }) => {
  return (
    <Typography component="li" height={44} px={1.5} {...other} opacity="1 !important">
      {option?.iconName && <Icon name={option.iconName} width={24} height={24} />}
      <Typography ml={1}>{option.label}</Typography>
    </Typography>
  );
};

const NotFoundOption: React.FC<OptionsProps> = ({ option, ...other }) => {
  return (
    <Typography
      component="li"
      height={44}
      width={1}
      px={1.5}
      {...other}
      opacity="1 !important"
      borderBottom={1}
      textAlign="center"
      fontStyle="italic"
    >
      <Typography width={1}>{option.label}</Typography>
    </Typography>
  );
};

const Options: React.FC<OptionsProps> = ({ option, ...other }) => {
  switch (true) {
    case Boolean(option?.added):
      return <AddNewVendorOption option={option} {...other} />;
    case option?.isOptionNotFound:
      return <NotFoundOption option={option} {...other} />;
    default:
      return <VendorOption option={option} {...other} />;
  }
};

export default React.memo(Options);
