import React from 'react';
import { useToggle } from 'react-use';

import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import Icon from 'components/@ui/Icon';
import Typography from 'components/@ui/Typography';
import { FormRender } from 'components/@main/Form';

import { Dialog400 } from './@components';

const DialogWorkspace: React.FC = () => {
  const [open, toogleOpen] = useToggle(true);
  
  return (
    <Dialog400 open={open}>
      <FormRender render={Box} gap={3}>
        <Typography
          semiBold
          mt={2}
          align="center"
          display="flex"
          justifyContent="center"
          gap={1}
          width="85%"
          mx="auto"
        >
          <Icon name="home-workspace-add-path" borderRadius={0} />
          <Typography semiBold align="left" component="span">
            Vendors are waiting to be added to the workspace!
          </Typography>
        </Typography>
        <Typography align="center">
          Add up to 4 vendors as finalists in your workspace. You will be able to request
          personalized demos with these vendor finalists&nbsp;from your workspace
        </Typography>
        <Typography align="center" color="gray.main">
          Go to Vendors page –
          <Typography component="span" position="relative" top={1.5}>
            {'>'}
          </Typography>{' '}
          Press
          <Icon
            mx={0.5}
            name="home-workspace-add-path"
            borderRadius={0}
            sx={{ verticalAlign: 'middle' }}
          />
          button
        </Typography>
        <Button fullWidth onClick={toogleOpen}>
          OK
        </Button>
      </FormRender>
    </Dialog400>
  );
};

export default DialogWorkspace;
