import React from 'react';
import { Controller } from 'react-hook-form';

import TextField, { TextFieldProps } from 'components/@ui/TextField';

import { useForm } from './Context';

export interface TextFieldFormProps extends Omit<TextFieldProps, 'name'> {
  name: string;
  onChangeMutator?: (a: string) => any;
  Component?: React.FC<any>;
  disabledHelperText?: boolean;
}

const TextFieldForm: React.FC<TextFieldFormProps> = (props) => {
  const {
    Component = TextField,
    name,
    helperText,
    onChangeMutator,
    disabledHelperText,
    ...other
  } = props;
  const { control } = useForm();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
        <Component
          inputRef={ref}
          name={name}
          onChange={onChange}
          {...(onChangeMutator && {
            onChange: (e) => onChange(onChangeMutator(e.target.value)),
          })}
          onBlur={onBlur}
          value={value}
          error={Boolean(error?.message)}
          helperText={error?.message ?? helperText ?? ' '}
          {...(disabledHelperText && { helperText: null })}
          {...other}
        />
      )}
    />
  );
};

export default React.memo(TextFieldForm);
