import { combineReducers } from 'redux';

import { p, createSlice, createSliceSimple } from '@redux/slices';

import {
  fetchAddUserToGroup,
  fetchCreateInvitations,
  fetchDeleteInvitation,
  fetchDeleteTeamMember,
  fetchEditInvitation,
  fetchEditTeamMember,
  fetchMemberPreDeleteData,
  fetchRemoveUserFromGroup,
  fetchResendInvitation,
  fetchTeamGroup,
  fetchTeamGroupCreate,
  fetchTeamGroupDelete,
  fetchTeamGroupEdit,
  fetchTeamGroups,
  fetchTeamInvitations,
  fetchTeamMembers,
} from '../@api';

const prefix = 'TEAM';

export const teamMembers = createSlice(p('GET_MEMBERS', prefix), fetchTeamMembers, {
  initialState: [],
});
export const editTeamMember = createSliceSimple(p('EDIT_MEMBER', prefix), fetchEditTeamMember);
export const deleteTeamMember = createSliceSimple(p('EDIT_MEMBER', prefix), fetchDeleteTeamMember);
export const memberPreDeleteData = createSlice(
  p('GET_MEMBER_PRE_DELETE_DATA', prefix),
  fetchMemberPreDeleteData,
  { initialState: { scoresCount: 0, notesCount: 0 } },
);

export const teamInvitations = createSlice(p('GET_INVITATIONS', prefix), fetchTeamInvitations, {
  initialState: [],
});
export const createInvitations = createSliceSimple(
  p('CREATE_INVITATIONS', prefix),
  fetchCreateInvitations,
);
export const editInvitation = createSliceSimple(p('EDIT_INVITATIONS', prefix), fetchEditInvitation);
export const resendInvitation = createSliceSimple(
  p('RESEND_INVITATIONS', prefix),
  fetchResendInvitation,
);
export const deleteInvitation = createSliceSimple(
  p('DELETE_INVITATIONS', prefix),
  fetchDeleteInvitation,
);

export const teamGroups = createSlice(p('GET_GROUPS', prefix), fetchTeamGroups, {
  initialState: { groups: [] },
});
export const teamGroup = createSliceSimple(p('GET_GROUP', prefix), fetchTeamGroup);
export const teamGroupCreate = createSlice(p('CREATE_GROUP', prefix), fetchTeamGroupCreate);
export const teamGroupEdit = createSlice(p('EDIT_GROUP', prefix), fetchTeamGroupEdit);
export const teamGroupDelete = createSliceSimple(p('DELETE_GROUP', prefix), fetchTeamGroupDelete);

export const addUserToGroup = createSliceSimple(
  p('ADD_USER_TO_GROUP', prefix),
  fetchAddUserToGroup,
);
export const removeUserFromGroup = createSliceSimple(
  p('REMOVE_USER_From_GROUP', prefix),
  fetchRemoveUserFromGroup,
);

export default combineReducers({
  teamMembers: teamMembers.reducer,
  memberPreDeleteData: memberPreDeleteData.reducer,
  teamInvitations: teamInvitations.reducer,
  groups: combineReducers({
    teamGroups: teamGroups.reducer,
    teamGroupCreate: teamGroupCreate.reducer,
    teamGroupEdit: teamGroupEdit.reducer,
  }),
});
