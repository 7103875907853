import React from 'react';
import MuiDivider, { DividerProps as MuiDividerProps } from '@mui/material/Divider';

import { BoxProps } from 'components/@ui/Box';

export interface DividerProps extends MuiDividerProps, Pick<BoxProps, 'component'> {}

const Divider: React.FC<MuiDividerProps> = (props) => <MuiDivider {...props} />;

export default Divider;
