import React, { memo } from 'react';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import { alpha, Theme } from '@mui/material';

import { clsx, makeSxStyles, parseSx } from '@helpers';

import Box from '../Box';
import Icon from '../Icon';

type StylesSx = {
  variant?: 'default' | 'green';
  iconName?: string;
};

export interface CheckboxProps extends MuiCheckboxProps, StylesSx {}

const sxes = makeSxStyles({
  root: {
    'input:hover ~ &': {
      backgrobgcolorundColor: (theme: Theme) => alpha(theme.palette.divider, 0.4),
    },
    'input:active ~ &': {
      bgcolor: (theme: Theme) => alpha(theme.palette.divider, 0.8),
    },
    'input:disabled ~ &': { bgcolor: 'divider' },
    'input ~ &': {
      transition: (theme: Theme) => theme.transitions.create(['background-color', 'border-color']),
    },
  },
  default: {
    color: 'secondary.main',
    border: 1,
    borderRadius: 0.5,
    borderColor: 'transparent',
    bgcolor: 'action.hover',
    width: 18,
    height: 18,
    minWidth: 18,
    'input:hover ~ &': { borderColor: 'primary.main' },
    'input:disabled ~ &': { borderColor: 'transparent', bgcolor: 'primary.light' },
    'input:checked ~ &': { bgcolor: 'primary.main' },
    'input:checked:hover ~ &': { borderColor: 'transparent', bgcolor: 'primary.dark' },
    'input:checked:disabled ~ &': { bgcolor: 'primary.light' },
  },
  green: {
    border: 2,
    borderRadius: '50%',
    borderColor: 'divider',
    width: 20,
    height: 20,
    minWidth: 20,
    color: 'green.main',
    'input:checked ~ &': { borderColor: 'green.main' },
  },
})();

const CheckBoxIcon: React.FC<StylesSx> = React.memo(({ variant = 'default' }) => {
  return <Box component="span" sx={clsx(sxes.root, sxes[variant])} className="checkBoxIcon" />;
});

const CheckedIcon: React.FC<StylesSx> = React.memo(
  ({ variant = 'default', iconName = 'check' }) => {
    return (
      <Icon
        name={iconName}
        fontSize={16}
        sx={clsx(sxes.root, sxes[variant])}
        className="checkBoxIcon"
      />
    );
  },
);

// @ts-ignore
const Checkbox: React.FC<CheckboxProps> = React.forwardRef((props, ref) => {
  const { icon, checkedIcon, variant, sx, iconName, ...other } = props;

  return (
    <MuiCheckbox
      ref={ref}
      sx={clsx({ padding: 0.25 }, ...parseSx(sx))}
      inputProps={{ 'aria-label': 'Checkbox' }}
      disableRipple
      icon={<CheckBoxIcon variant={variant} iconName={iconName} />}
      checkedIcon={<CheckedIcon variant={variant} />}
      {...other}
    />
  );
});

export default memo(Checkbox);
