import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAppDispatch } from '@redux/store';
import { useAPI, useProject } from '@hooks';

import { selectors, timeLinePhases } from '../@slices';
import { FetchTimeLinePhasesResponse } from '../@api';

const selector = createSelector(selectors.root, (state) => state.phases);
const { action } = timeLinePhases;

export const useFetchTimeLinePhases = () => {
  const { id: projectId } = useProject();

  return useAPI(
    useCallback(() => action(projectId), [projectId]),
    selector,
  );
};

export const useFetchUpdateTimeLinePhases = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (state: FetchTimeLinePhasesResponse) => {
      dispatch(timeLinePhases.actions.UPDATE({ records: state }));
    },
    [dispatch],
  );
};
