import { makeSxStyles } from '@helpers';

const makeSX = makeSxStyles({
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 'fit-content',
    minHeight: 32,
    padding: '0 8px',
    fontFamily: 'Proxima Nova Bold',
    color: 'blue.main',
    backgroundColor: 'transparent',
    height: { mobile: 56, table: 56, laptop: 32 },
    fontSize: { mobile: 18, table: 18, laptop: 16 },
    my: { mobile: 1.5, table: 1.5, laptop: 0 },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: 'gray.main',
      '& .icon:before': {
        color: 'gray.main',
      },
    },
    '&:hover': {
      backgroundColor: 'action.hover',
    },
    '&:active': {
      backgroundColor: 'blue.main',
      color: 'text.secondary',
      '& .icon:before': {
        color: 'text.secondary',
      },
    },
  },
  buttonStyleIsTouchDevice: {
    height: 56,
    fontSize: 18,
    my: 1.5,
  },
})();

export default makeSX;
