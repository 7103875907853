import React from 'react';
import { Link } from 'react-router-dom';

import Typography from 'components/@ui/Typography';
import { ROUTES } from '@routes/config';

const NoMatchPage: React.FC = () => {
  return (
    <main>
      <Link to={ROUTES.root}>
        <Typography color="text.primary" extraBold component="h1" fontSize={48} lineHeight={1.2}>
          NoMatchPage
        </Typography>
      </Link>
    </main>
  );
};

export default NoMatchPage;
