const storageGreator = () => {
  let storage: Storage;

  const setStorage = (instance: Storage) => (storage = instance);

  const getItem = <T>(key: string) => {
    const state = storage.getItem(key);
    if (!state) return null;
    try {
      return JSON.parse(state) as T;
    } catch {
      return null;
    }
  };

  const setItem = <T>(key: string, value: T) => {
    storage.setItem(key, JSON.stringify(value));
  };

  const removeItem = (key: string) => {
    storage.removeItem(key);
  };

  return { setStorage, getItem, setItem, removeItem };
};

const storage = storageGreator();
export default storage;
