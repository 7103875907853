import React from 'react';

import When from 'components/@main/When';
import { IconButtonCloseModal } from 'components/@ui/DialogTitle';

import { usePhotoCreator } from '../PhotoCreator';

const CloseIconButtonPhotoCreator: React.FC = () => {
  const { isEditing, setUploadedFile } = usePhotoCreator();
  return (
    <When condition={isEditing} disabledWrapper>
      <IconButtonCloseModal onClick={() => setUploadedFile(undefined)} />
    </When>
  );
};
export default CloseIconButtonPhotoCreator;
