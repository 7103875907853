import { useCallback, useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { pick } from 'ramda';

import { useAPI, useOnline } from '@hooks';
import { useIsLoginSlice } from '@redux/slices';
import { jwt, projectIdStorage } from '@servises';
import { checkStatus } from '@helpers';
import { User } from '@types';

import useFetchProject from './useFetchProject';
import { selectors, selfData } from '../@slices';

const useFetchSelfData = () => {
  const isOnline = useOnline();
  const [data, fetch] = useAPI(
    useCallback(() => selfData.action(), []),
    selectors.selfData,
  );

  const fetchSelfData = useCallback(async () => {
    const response = await fetch();
    if (checkStatus(response?.status, 'gte') && isOnline) jwt.removeUserTokenValues();
    return response;
  }, [fetch, isOnline]);

  return useMemo(() => [data, fetchSelfData] as const, [data, fetchSelfData]);
};

export const useOnFetchProject = () => {
  const [, fetchProject] = useFetchProject();

  return useCallback(
    async (response: AxiosResponse<User.UserFields>) => {
      const { data: user } = response;
      const projectId = projectIdStorage.getProjectId(pick(['id', 'projects'], user));
      if (projectId && user.verified) {
        return await fetchProject(projectId);
      }
    },
    [fetchProject],
  );
};

export const useFetchSelfDataAndProject = () => {
  const [data, fetch] = useFetchSelfData();
  const [{ records }, setIsLogin] = useIsLoginSlice();
  const onFetchProject = useOnFetchProject();

  const fetchSelfData = useCallback(
    async (disableLogin?: boolean) => {
      const response = await fetch();
      if (checkStatus(response?.status)) {
        await onFetchProject(response);
        if (!records.isLogin && !disableLogin) setIsLogin((state) => ({ ...state, isLogin: true }));
      }
      return response;
    },
    [fetch, onFetchProject, records.isLogin, setIsLogin],
  );

  return useMemo(() => [data, fetchSelfData] as const, [data, fetchSelfData]);
};

export type FetchSelfData = ReturnType<typeof useFetchSelfData>[1];
export type SelfData = User.User;
export default useFetchSelfData;
