const danteAIIds = {
  danteAiResource: 'dante-ai-resource',
  danteAiChatBot: 'dante-ai-chat-bot',
  danteIframe: 'iframe[src="https://dante-ai.com/thirdparty.html"]',
} as const;

const danteAiClassName = {
  danteEmbedTooltips: 'dante-embed-tooltips',
  danteEmbedIcon: 'dante-image-wrapper',
  danteEmbedChat: 'dante-embed-chat',
} as const;

export const loadScriptDanteAI = (hiddenData: boolean) => {
  if (hiddenData) return;
  const scriptDante = document.createElement('script');
  const scriptResource = document.createElement('script');
  scriptResource.text = `window.danteEmbed = "${process.env.REACT_APP_DANTE_AI_KEYS}";`;
  scriptResource.id = danteAIIds.danteAiChatBot;
  scriptDante.src = process.env.REACT_APP_DANTE_AI_RESOURCE ?? '';
  scriptDante.id = danteAIIds.danteAiResource;
  if (
    !window.document.getElementById(danteAIIds.danteAiResource) &&
    !window.document.getElementById(danteAIIds.danteAiChatBot)
  ) {
    document.body.appendChild(scriptDante);
    document.body.appendChild(scriptResource);
  }
};

export const removeScriptDanteAI = () => {
  const scriptResource = document.getElementById(danteAIIds.danteAiResource);
  const scriptDante = document.getElementById(danteAIIds.danteAiChatBot);
  const danteIframe = document.querySelector(danteAIIds.danteIframe);
  const danteTooltip = document.getElementsByClassName(danteAiClassName.danteEmbedTooltips)[0];
  const danteIcon = document.getElementsByClassName(danteAiClassName.danteEmbedIcon)[0];
  const danteChat = document.getElementsByClassName(danteAiClassName.danteEmbedChat)[0];

  try {
    if (danteTooltip) {
      document.body.removeChild(danteTooltip);
    }
    if (danteIcon) {
      document.body.removeChild(danteIcon);
    }
    if (danteIframe) {
      document.body.removeChild(danteIframe);
    }
    if (danteChat) {
      document.body.removeChild(danteChat);
    }
    if (scriptResource) {
      document.body.removeChild(scriptResource);
    }
    if (scriptDante) {
      document.body.removeChild(scriptDante);
    }
  } catch {
    window.location.reload();
  }
};
