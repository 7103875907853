import React, { useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { useEvent, useMobileTimeline } from '@hooks';
import { createContext } from '@helpers';
import { Children, UseState } from '@types';

import { usePhases } from '../Phases';
import { TimeLineBar } from '../@types';
import { useCheckTodoItemProperties, useFetchTodoItem } from '../ToDoItems/@hook';

export type ToDoProviderProps = {
  record: TimeLineBar.ToDo;
  index: number;
};

export type ToDoContextType = {
  todo: TimeLineBar.ToDo;
  index: number;
  handleCloseToDoPopover: () => void;
  anchorToDoEl: HTMLButtonElement | null;
  setAnchorToDoEl: UseState<HTMLButtonElement | null>;
  isPopoverData: boolean;
  setIsPopoverData: UseState<boolean>;
  handleClickToDo: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void;
};

const [Provider, useToDo] = createContext<ToDoContextType>();
export { useToDo };

const ToDoProvider: React.FC<ToDoProviderProps & Children> = ({ record, index, children }) => {
  const { setIsPopoverOpen } = usePhases();
  const [{ records: isOpenMobileTimeline }] = useMobileTimeline();
  const [isPopoverData, setIsPopoverData] = useState(false);

  const [anchorToDoEl, setAnchorToDoEl] = useState<HTMLButtonElement | null>(null);

  const [, fetchTodoItem] = useFetchTodoItem();
  const { checkTypeTodoItem } = useCheckTodoItemProperties({ setIsPopoverData });

  const handleCloseToDoPopover = useEvent(() => {
    setAnchorToDoEl(null);
    setIsPopoverData(false);
    setIsPopoverOpen(false);
  });

  const handleClickToDo = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      setIsPopoverOpen(Boolean(event.currentTarget));
      setAnchorToDoEl(event.currentTarget);
      const result = await fetchTodoItem(record.id);
      if (result.status < 400) {
        const { todoItems } = result.data;
        checkTypeTodoItem(todoItems);
      }
    },
    [record.id, fetchTodoItem, setAnchorToDoEl, setIsPopoverOpen, checkTypeTodoItem],
  );

  useUpdateEffect(() => handleCloseToDoPopover(), [isOpenMobileTimeline]);

  const contextValue = useMemo<ToDoContextType>(
    () => ({
      todo: record,
      index,
      isPopoverData,
      anchorToDoEl,
      setAnchorToDoEl,
      handleCloseToDoPopover,
      setIsPopoverData,
      handleClickToDo,
    }),
    [record, index, isPopoverData, anchorToDoEl, handleCloseToDoPopover, handleClickToDo],
  );

  return <Provider value={contextValue}>{children}</Provider>;
};

export default React.memo(ToDoProvider);
