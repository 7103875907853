import React from 'react';
import { useToggle } from 'react-use';

import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import Dialog from 'components/@ui/Dialog';
import Icon from 'components/@ui/Icon';
import Typography from 'components/@ui/Typography';
import When from 'components/@main/When';
import { useOpenModal } from '@hooks';

import useReminders from './useReminders';
import { MainInformation, OutSailFeatures } from '../HiddingData';

const DialogRemindersHiddenData: React.FC = () => {
  const [open, toogleOpen] = useToggle(true);
  const dialogInfoModal = useOpenModal();
  const dialogFeaturesModal = useOpenModal();
  const { setCookie, remindHiddenData } = useReminders();

  const onClosed = () => {
    setCookie('hiddenData', 2)();
    toogleOpen();
  };

  return (
    <When condition={remindHiddenData} disabledWrapper>
      <MainInformation openModal={dialogInfoModal.open} closeModal={dialogInfoModal.handleClose} />
      <OutSailFeatures
        closeModal={dialogFeaturesModal.handleClose}
        openModal={dialogFeaturesModal.open}
      />
      <Dialog
        open={open}
        disableScrollLock
        PaperProps={{ sx: { maxWidth: 250, bgcolor: 'transparent', borderRadius: 0 } }}
      >
        <Box
          display="flex"
          gap={3}
          flexDirection="column"
          alignItems="center"
          color="secondary.main"
        >
          <Icon color="inherit" name="lock" borderRadius={0} />
          <Typography semiBold align="center">
            Become a verified buyer to unlock{' '}
            <Typography
              role="button"
              onClick={dialogFeaturesModal.handleOpen}
              component="span"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              OutSail's features{' '}
            </Typography>
            for free
          </Typography>
          <Box width={1}>
            <Button
              fullWidth
              startIcon={<Icon name="unlock-path" color="inherit" borderRadius={0} />}
              onClick={dialogInfoModal.handleOpen}
            >
              Unlock for free
            </Button>
            <Button variant="text" color="secondary" fullWidth onClick={onClosed}>
              Skip
            </Button>
          </Box>
        </Box>
      </Dialog>
    </When>
  );
};

export default DialogRemindersHiddenData;
