import { createSlice } from '@redux/slices';
import { combineReducers } from 'redux';

import { fetchCriteriaSettings, fetchCriterionOrder, fetchTotalWeight } from '../../@api';

export const criterionOrder = createSlice('FETCH_CRITERION_ORDER', fetchCriterionOrder);
export const criteriaSettings = createSlice('FETCH_CRITERIA_SETTINGS', fetchCriteriaSettings, {
  initialState: { keyCriteria: [] },
});
export const totalWeight = createSlice('FETCH_TOTAL_WEIGHT', fetchTotalWeight, {
  initialState: { totalWeight: 0 },
});

export default combineReducers({
  criterionOrder: criterionOrder.reducer,
  criteriaSettings: criteriaSettings.reducer,
  totalWeight: totalWeight.reducer,
});
