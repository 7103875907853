import { combineReducers } from 'redux';

import { createPrefix as p, createSlice } from '@redux/slices';

import {
  fetchFeedbackCreate,
  fetchFeedbackUpdate,
  fetchFinalizeVendor,
  fetchProgressDetails,
  fetchVendors,
} from '../@api';

const prefix = 'WORKSPACE';

export const vendors = createSlice(p('VENDORS', prefix), fetchVendors, {
  initialState: { products: [] },
});
export const progressDetails = createSlice(p('PROGRESS_DETAILS', prefix), fetchProgressDetails, {
  initialState: { keyCriteria: [] },
});
export const finalize = createSlice(p('FINALIZE', prefix), fetchFinalizeVendor);
export const feedbackCreate = createSlice(p('FEEDBACK_CREATE', prefix), fetchFeedbackCreate, {
  initialState: { feedback: {} },
});
export const feedbackUpdate = createSlice(p('FEEDBACK_UPDATE', prefix), fetchFeedbackUpdate);

export default combineReducers({
  vendors: vendors.reducer,
  progressDetails: progressDetails.reducer,
  finalize: finalize.reducer,
  feedback: combineReducers({
    create: feedbackCreate.reducer,
    update: feedbackUpdate.reducer,
  }),
});
