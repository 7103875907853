import React from 'react';
import Box from 'components/@ui/Box';
import Icon from 'components/@ui/Icon';
import Typography from 'components/@ui/Typography';
import Button from 'components/@ui/Button';
import { ROUTES } from '@routes/config';

import { useRequestDemoModal } from './@hooks';
import { MODAL_STEP } from './Context';
import DialogActionsWrapper from './DialogActionsWrapper';

const SkipDesignDemoModal: React.FC = () => {
  const { setModalStep, requestDemoModalFlowClose } = useRequestDemoModal();

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center" mt={3} px={3} width={500}>
        <Icon
          name="wrench"
          fontSize={96}
          width={96}
          height={96}
          color="blue.main"
          borderRadius={0}
        />
        <Box display="flex" flexDirection="column" ml={1.5}>
          <Typography fontSize={24} lineHeight="32px" semiBold component="h2">
            Design demo
          </Typography>
          <Typography mt={1.5}>
            Maximize your vendor demos by telling your finalists exactly what they should present in
            their demo meetings.
          </Typography>
        </Box>
      </Box>
      <DialogActionsWrapper mt={3}>
        <Button fullWidth to={ROUTES.processMapping.moduleQuestions} onClick={requestDemoModalFlowClose}>
          Design demo
        </Button>
        <Button
          fullWidth
          variant="text"
          color="inherit"
          onClick={setModalStep(MODAL_STEP.skipDesignDemo)}
        >
          Skip
        </Button>
      </DialogActionsWrapper>
    </>
  );
};

export default SkipDesignDemoModal;
