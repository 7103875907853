import { useDispatch } from 'react-redux';
import { createLogger } from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';

import reducer from './reducer';

const middlewares: any[] = [];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(
    createLogger({
      collapsed: true,
      logErrors: false,
    }),
  );
}

const store = configureStore({
  reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export default store;
