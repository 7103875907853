import React from 'react';
import MuiMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu';

import { clsx, parseSx } from '@helpers';

import { popoverStyles } from '../Popover';

export interface MenuProps extends MuiMenuProps {}

// @ts-ignore
const Menu: React.FC<MuiMenuProps> = React.forwardRef((props, ref) => {
  const { PaperProps, ...other } = props;

  return (
    <MuiMenu
      ref={ref}
      PaperProps={{
        ...PaperProps,
        sx: clsx(popoverStyles.paper, ...parseSx(PaperProps?.sx)),
      }}
      {...other}
    />
  );
});

export default React.memo(Menu);
