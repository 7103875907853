import React, { useMemo } from 'react';

import AbilityControl, { useAbility } from '@permissions';
import { useFetchProject } from '@api/@hooks';

import TodoItem from './TodoItem';
import ToDoItemWithTooltip from './ToDoItemWithTooltip';
import { DesignMyDemoButton, TooltipTextObject } from '../@components';

const DesignDemoTodoItem: React.FC = () => {
  const [{ records }] = useFetchProject();
  const { project } = records;
  const canUseDesignDemo = useAbility().abilityCheck('WORKSPACE_DESIGN_DEMO');
  const hideTooltip =
    (canUseDesignDemo && Boolean(project.workspacedProducts.length) && project.submitted) ||
    project.finalised;

  return useMemo(
    () => (
      <ToDoItemWithTooltip
        hideTooltip={hideTooltip}
        type="text"
        source={{
          text: project.workspacedProducts.length
            ? TooltipTextObject.needRequestDemo
            : TooltipTextObject.emptyWorkspace,
        }}
      >
        <TodoItem>
          <AbilityControl permission="WORKSPACE_DESIGN_DEMO">
            <DesignMyDemoButton />
          </AbilityControl>
        </TodoItem>
      </ToDoItemWithTooltip>
    ),
    [hideTooltip, project.workspacedProducts.length],
  );
};

export default DesignDemoTodoItem;
