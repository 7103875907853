import React, { useMemo, useState } from 'react';

import Icon from 'components/@ui/Icon';
import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import Dialog from 'components/@ui/Dialog';
import { Product, UseState } from '@types';
import { useIsTablet, useOpenModal } from '@hooks';
import { clsx } from '@helpers';

import Logo from './Logo';
import LogoLabel from './LogoLabel';
import ProposalConfirmModal from './ProposalConfirmModal';
import { makeSX } from '../@hook';
import { useToDoItems } from '../Context';
import { useToDoItem } from '../TodoItem';

type VendorProposalButtonProps = {
  vendor: Product.Product;
  setHideTodoItemTooltip: UseState<boolean>;
};

const VendorProposalButton: React.FC<VendorProposalButtonProps> = ({
  vendor,
  setHideTodoItemTooltip,
}) => {
  const { todoItem } = useToDoItem();
  const { sendMeetRequest } = useToDoItems();
  const { handleClose, handleOpen, open } = useOpenModal();
  const [isDisabled, setIsDisabled] = useState(
    Boolean(todoItem.proposals.find((proposal) => proposal.meetableId === vendor.id)),
  );
  const isTablet = useIsTablet();

  return useMemo(
    () => (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginLeft={4.5}
        mt={1.5}
        bgcolor="transparent"
        minHeight={{ mobile: 56, tablet: 56, laptop: 32 }}
      >
        <Box display="flex" alignItems="center">
          <Logo src={vendor.logoSmallUrl} />
          <LogoLabel label={vendor.name} />
        </Box>
        <Button
          sx={clsx(
            { color: 'blue.main' },
            makeSX.buttonStyle,
            isTablet && makeSX.buttonStyleIsTouchDevice,
          )}
          endIcon={
            <Icon
              name={isDisabled ? 'proposal-sent-icon-path' : 'send-mail-icon-path'}
              borderRadius={0}
              color="inherit"
            />
          }
          onClick={handleOpen}
          disabled={isDisabled}
        >
          {isDisabled ? 'Check email' : 'Request proposal'}
        </Button>
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          PaperProps={{ sx: { width: '100%', borderRadius: 4, margin: 2, maxWidth: 400 } }}
        >
          <Box bgcolor="background.paper" borderRadius={4}>
            <ProposalConfirmModal
              handleClose={handleClose}
              confirm={sendMeetRequest(
                todoItem.id,
                vendor.id,
                'Product',
                setIsDisabled,
                setHideTodoItemTooltip,
              )}
            />
          </Box>
        </Dialog>
      </Box>
    ),
    [
      vendor.logoSmallUrl,
      vendor.name,
      vendor.id,
      isDisabled,
      handleOpen,
      open,
      handleClose,
      sendMeetRequest,
      todoItem.id,
      setHideTodoItemTooltip,
      isTablet,
    ],
  );
};

export default VendorProposalButton;
