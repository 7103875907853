import React, { useMemo } from 'react';

import { Children } from '@types';
import { useIsTablet } from '@hooks';

import NavbarMobile from './NavbarMobile';
import NavbarCustom from './NavbarCustom';

const NavbarWrapper: React.FC<Children> = ({ children }) => {
  const isMobile = useIsTablet();

  const Navbar = isMobile ? NavbarMobile : NavbarCustom;

  return useMemo(() => <Navbar>{children}</Navbar>, [Navbar, children]);
};

export default NavbarWrapper;
