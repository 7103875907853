import { useUpdateEffect } from 'react-use';

import { SubscriptionMainType, checkAllEquals, parseDataFn } from 'components/@main/Ws';
import { useWs } from '@hooks';

import { MESSAGES, Update } from './messages';
import { TimeLineBar } from '../@types';
import { useTimeLineBar } from '../@hooks';

export type UpdatePhaseStatusProps = {
  status: TimeLineBar.Statuses;
  phaseId: number;
};

type SubscriptionType = SubscriptionMainType<MESSAGES.phaseStatusUpdate, UpdatePhaseStatusProps>;

type UseUpdateWsProps = {
  update: Update<UpdatePhaseStatusProps>;
};

const useUpdateWs = (props: UseUpdateWsProps) => {
  const { update } = props;
  const { lastMessage } = useWs();
  const {
    ws: { channel },
  } = useTimeLineBar();

  useUpdateEffect(() => {
    if (!lastMessage) return undefined;
    const data = parseDataFn<SubscriptionType>(lastMessage);
    const { identifier, message: { action, phaseId, status } = {} } = data;
    const checks = checkAllEquals(
      identifier === channel.identifier,
      action === MESSAGES.phaseStatusUpdate,
    );
    if (checks && status && phaseId) {
      update({ phaseId, status });
    }
  }, [lastMessage]);
};

export default useUpdateWs;
