import React, { useState } from 'react';
import { useMount } from 'react-use';

import Box from 'components/@ui/Box';
import Collapse from 'components/@ui/Collapse';
import { useDynamicRefs, useSetTimeout } from '@hooks';
import { clsx } from '@helpers';

import EmptySpase from './EmptySpase';
import ToDos from './ToDos';
import { Badge, Button, Line, PhaseStartIcon } from '../@components';
import { sxesPhaseButton } from '../Phase';
import { icons } from '../Phases';
import { phases } from './config';
import { TimeLineLayout } from '../TimeLineLayout';

const Desktop: React.FC = () => {
  const [getRef, setRef] = useDynamicRefs();
  const [phasesState, setPhasesState] = useState(phases(false));
  const { setTimeout } = useSetTimeout();

  useMount(() => setTimeout(() => setPhasesState(phases(true))));

  return (
    <TimeLineLayout overflow="hidden">
      <Line />
      <Box zIndex={2} width="100vw" display="flex">
        {phasesState.map((phase, index) => (
          <Box display="flex" flexWrap="nowrap" key={phase.id}>
            <Box
              ref={(ref) => setRef(phase.name, ref)}
              pr={1}
              py={1.5}
              {...(index === 0 && { pl: 1 })}
            >
              <Badge status={phase.status} IconProps={{ right: 1, top: -2 }}>
                <Button
                  status={phase.status}
                  startIcon={<PhaseStartIcon name={icons[phase.name]} />}
                  sx={clsx(phase.isSelected && sxesPhaseButton[phase.status])}
                >
                  {phase.name}
                </Button>
              </Badge>
            </Box>
            <Collapse orientation="horizontal" in={phase.isSelected}>
              <EmptySpase getRef={getRef} phases={phasesState}>
                <ToDos todos={phase.todos} />
              </EmptySpase>
            </Collapse>
          </Box>
        ))}
      </Box>
    </TimeLineLayout>
  );
};

export default Desktop;
