import baseAPI, { APIResponse } from '@api';

import {
  CharacteristicModalResponse,
  FeatureModalResponse,
  FetchAddFromListShortlistResponse,
  FetchImplementationProductResponse,
  FetchImplementationTimelineResponse,
  FetchRequirementsMatchResponse,
  FetchSoftwareCostProductResponse,
  FetchVendorModalProps,
  ProsAndConsModalResponse,
  SizeMatchScalabilityModalResponse,
  VendorView,
} from '../@types';

const apiCredentials = baseAPI();

//-------------------------------------------------------------------------
// Fetch Vendor View
//-------------------------------------------------------------------------

export function fetchVendorView(projectId: number, vendorId: number): APIResponse<VendorView> {
  return apiCredentials.get(`/projects/${projectId}/vendor_overview`, {
    params: { product_id: vendorId },
  });
}

//-------------------------------------------------------------------------
// Fetch Requirements Match
//-------------------------------------------------------------------------

export function fetchRequirementsMatch(
  shortlistProduct: boolean,
  projectId: number,
  vendorId: number,
): APIResponse<FetchRequirementsMatchResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendor_overview/requirements_match`, {
    params: { product_id: vendorId, shortlisted_products: shortlistProduct },
  });
}

//-------------------------------------------------------------------------
// Fetch Software Cost
//-------------------------------------------------------------------------

export function fetchSoftwareCost(
  shortlistProduct: boolean,
  projectId: number,
  vendorId: number,
): APIResponse<FetchSoftwareCostProductResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendor_overview/software_costs`, {
    params: { product_id: vendorId, shortlisted_products: shortlistProduct },
  });
}

//-------------------------------------------------------------------------
// Fetch Implementation Cost
//-------------------------------------------------------------------------

export function fetchImplementationCost(
  shortlistProduct: boolean,
  projectId: number,
  vendorId: number,
): APIResponse<FetchImplementationProductResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendor_overview/implementation_costs`, {
    params: { product_id: vendorId, shortlisted_products: shortlistProduct },
  });
}

//-------------------------------------------------------------------------
// Fetch Implementation Cost
//-------------------------------------------------------------------------

export function fetchImplementationTimeline(
  shortlistProduct: boolean,
  projectId: number,
  vendorId: number,
): APIResponse<FetchImplementationTimelineResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendor_overview/implementation_timeline`, {
    params: { product_id: vendorId, shortlisted_products: shortlistProduct },
  });
}

//-------------------------------------------------------------------------
// Size Match Scalability Modal Data
//-------------------------------------------------------------------------

export function fetchSizeMatchModalData({
  projectId,
  vendorId,
}: FetchVendorModalProps): APIResponse<SizeMatchScalabilityModalResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendors/heat_map/size_match_scalability`, {
    params: { product_id: vendorId },
  });
}
//-------------------------------------------------------------------------
// Feature Match Modal Data
//-------------------------------------------------------------------------

export function fetchFeatureMatchModalData({
  projectId,
  vendorId,
}: FetchVendorModalProps): APIResponse<FeatureModalResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendors/heat_map/feature_match`, {
    params: { product_id: vendorId },
  });
}
//-------------------------------------------------------------------------
// Characteristic Match Modal Data
//-------------------------------------------------------------------------

export function fetchCharacteristicMatchModalData({
  projectId,
  vendorId,
}: FetchVendorModalProps): APIResponse<CharacteristicModalResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendors/heat_map/characteristic_match`, {
    params: { product_id: vendorId },
  });
}
//-------------------------------------------------------------------------
// Pros and Cons Modal Data
//-------------------------------------------------------------------------

export function fetchProsAndConsModalData({
  projectId,
  vendorId,
}: FetchVendorModalProps): APIResponse<ProsAndConsModalResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendors/heat_map/pros_cons`, {
    params: { product_id: vendorId },
  });
}

//-------------------------------------------------------------------------
// List for add Shortlist
//-------------------------------------------------------------------------

export function fetchAddFromListShortlist(
  projectId: number,
): APIResponse<FetchAddFromListShortlistResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendor_overview/score_match`);
}

//-------------------------------------------------------------------------
