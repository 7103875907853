import React, { useMemo, useState } from 'react';

import { Children, UseState } from '@types';

type AppLayoutContextType = {
  hasNavBar: boolean;
  hasTimeLineBar: boolean;
  hasTimeLineBarPlug: boolean;
  setHasNavBar: UseState<boolean>;
  setHasTimeLineBar: UseState<boolean>;
  setHasTimeLineBarPlug: UseState<boolean>;
};

export const AppLayoutContext = React.createContext<AppLayoutContextType>(null as any);
export const AppLayoutConsumer = AppLayoutContext.Consumer;

const AppLayoutProvider: React.FC<Children> = ({ children }) => {
  const [hasNavBar, setHasNavBar] = useState(false);
  const [hasTimeLineBar, setHasTimeLineBar] = useState(false);
  const [hasTimeLineBarPlug, setHasTimeLineBarPlug] = useState(false);

  const contextValue = useMemo<AppLayoutContextType>(
    () => ({
      hasNavBar,
      hasTimeLineBar,
      hasTimeLineBarPlug,
      setHasNavBar,
      setHasTimeLineBar,
      setHasTimeLineBarPlug,
    }),
    [hasNavBar, hasTimeLineBar, hasTimeLineBarPlug],
  );

  return <AppLayoutContext.Provider value={contextValue}>{children}</AppLayoutContext.Provider>;
};

export default AppLayoutProvider;
