import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import Typography, { TypographyProps } from 'components/@ui/Typography';

import { usePhase } from '../Context';
import { toltips } from '../../Phases';

const Status: React.FC<TypographyProps> = (props) => {
  return (
    <Typography
      textAlign="center"
      component="span"
      mt={1}
      semiBold
      textTransform="uppercase"
      {...props}
    />
  );
};

const PhaseTooltipTitle: React.FC = () => {
  const { phase } = usePhase();

  const statusTitle = useMemo(
    () => ({
      current: <Status color="phaseTooltip.light">current phase</Status>,
      done: <Status color="phaseTooltip.main">done</Status>,
      next: null,
    }),
    [],
  );

  return useMemo(
    () => (
      <Box display="flex" flexDirection="column">
        <Typography textAlign="center">{toltips[phase.name]}</Typography>
        {statusTitle[phase.status]}
      </Box>
    ),
    [phase.name, phase.status, statusTitle],
  );
};

export default PhaseTooltipTitle;
