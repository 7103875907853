import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import { useMount, useUnmount, useUpdateEffect } from 'react-use';
import { toast } from 'react-toastify';
import { not } from 'ramda';

import Loader from 'components/@common/Loader';
import { useIsLoginSlice } from '@redux/slices';
import { useFetchSelfDataAndProject } from '@api/@hooks';
import { useLoadDanteAIScript, useProject, useSetTimeout, useUser } from '@hooks';
import { createContext } from '@helpers';
import { jwt } from '@servises';
import { ROUTES } from '@routes/config';
import { Children } from '@types';

export type AuthContextType = {
  loaded: boolean;
};

export const [Provider, useAuth] = createContext<AuthContextType>();

const AuthProvider: React.FC<Children> = ({ children }) => {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, fetchSelfData] = useFetchSelfDataAndProject();
  const { chosenProduct } = useProject();
  const { projectId } = useUser();
  const { setTimeout } = useSetTimeout();
  const [{ records }] = useIsLoginSlice();
  const { isLogin, isFetchLogin } = records;

  const onFetch = useCallback(
    async (disableLogin?: boolean) => {
      const match = matchPath(history.location.pathname, [ROUTES.admin.root]);
      setLoading(true);
      if (not(match)) await fetchSelfData(disableLogin);
      setLoading(false);
    },
    [fetchSelfData, history.location.pathname],
  );

  jwt.useWatch(onFetch);

  useLayoutEffect(() => {
    if (isLogin && isFetchLogin) {
      const route = chosenProduct ? ROUTES.workspace : ROUTES.vendors.root;
      history.push(route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchLogin, isLogin]);

  useUpdateEffect(() => {
    if (isLogin && not(loading) && not(projectId)) {
      setTimeout(() => toast.success('You need to start your HRIS matching process'), 500);
    }
  }, [projectId, isLogin]);

  useMount(async () => {
    setLoaded(false);
    await onFetch();
    setLoaded(true);
  });

  useLoadDanteAIScript(loaded);

  useUnmount(() => {
    setLoaded(false);
    setLoading(false);
  });

  const contextValue = useMemo<AuthContextType>(() => ({ loaded }), [loaded]);

  return <Provider value={contextValue}>{loaded ? children : <Loader />}</Provider>;
};

export default React.memo(AuthProvider);
