import React from 'react';

import Icon, { IconProps } from 'components/@ui/Icon';
import Button, { ButtonProps } from 'components/@ui/Button';
import { useIsTablet } from '@hooks';
import { clsx } from '@helpers';

export type HeaderButtonProps = ButtonProps & {
  iconProps?: IconProps;
};

const HeaderButton: React.FC<HeaderButtonProps> = ({ iconProps, ...other }) => {
  const isTablet = useIsTablet();

  return (
    <Button
      variant="text"
      startIcon={<Icon name="" color="inherit" borderRadius={0} {...iconProps} />}
      sx={clsx(
        { minWidth: 'fit-content' },
        isTablet && { fontSize: 18, lineHeight: '32px', height: 58 },
      )}
      {...other}
    />
  );
};

export default React.memo(HeaderButton);
