import { keyframes } from '@emotion/react';

export const clickButtonIconAnimation = keyframes({
  '0%': {
    width: 56,
    height: 56,
    margin: -4,
  },
  '100%': {
    width: 48,
    height: 48,
    margin: 0,
  },
});

export const clickButtonAnimation = keyframes({
  '0%': {
    width: 120,
    height: 100,
    margin: '0',
  },
  '100%': {
    width: 135,
    height: 119,
    margin: '-10px -8px',
  },
});

export const clickButtonMobileAnimation = keyframes({
  '0%': {
    width: '100%',
    height: '100%',
    margin: '0',
  },
  '100%': {
    width: '106%',
    height: '106%',
    margin: '-1% -3%',
  },
});
