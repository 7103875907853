import { combineReducers } from 'redux';

import { CA, P, createS, createSS } from '@redux/slices';

import { recoveryPassword } from '../@api';

const title = 'RECOVERY_PASSWORD';
const { fetchSendEmail, fetchSendCode, fetchUpdatePassword } = recoveryPassword;

export const sendEmail = createSS(CA(P('SEND_EMAIL', title)), fetchSendEmail);
export const sendCode = createS(CA(P('SEND_CODE', title)), fetchSendCode);
export const updatePassword = createSS(CA(P('UPDATE_PASSWORD', title)), fetchUpdatePassword);

export default {
  recoveryPassword: combineReducers({
    sendCode: sendCode.reducer,
  }),
};
