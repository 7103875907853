import React from 'react';

import { TableCellCost } from '../HeatMapViewComponents';
import { useFetchVendors } from './@hooks';

const HeatMapImplementationCost: React.FC = () => {
  const [{ records }] = useFetchVendors();

  return (
    <>
      {records.map((product) => (
        <TableCellCost
          key={product.id}
          min={product.implementationCostsMin}
          max={product.implementationCostsMax}
        />
      ))}
    </>
  );
};

export default HeatMapImplementationCost;
