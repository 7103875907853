import React, { useMemo } from 'react';
import { Theme } from '@mui/material';

import { clsx, makeSxStyles, parseSx } from '@helpers';

import MuiMenuItem, { MenuItemProps } from '../MenuItem';
import Icon from '../Icon';

export interface MenuItemSelectProps extends MenuItemProps {
  disabledIcon?: boolean;
}

const makeSx = makeSxStyles({
  root: {
    px: 1,
    display: 'flex',
    justifyContent: 'space-between',
    bgcolor: 'transparent',
    '&.Mui-selected': {
      bgcolor: 'transparent',
      '&:hover': {
        bgcolor: 'dropdownHover.main',
      },
      '._select-icon': {
        opacity: 1,
      },
    },
  },
});

// @ts-ignore
const MenuItemSelect: React.FC<MenuItemSelectProps> = React.forwardRef((props, ref) => {
  const { sx, children, disabledIcon, ...other } = props;
  const sxes = useMemo(() => makeSx(), []);

  return (
    <MuiMenuItem
      ref={ref}
      disableRipple
      disableTouchRipple
      sx={clsx(sxes.root, ...parseSx(sx))}
      {...other}
    >
      {children}
      {!disabledIcon && (
        <Icon
          className="_select-icon"
          name="check"
          ml={1}
          sx={{
            opacity: 0,
            transition: (theme: Theme) => theme.transitions.create(['opacity']),
          }}
        />
      )}
    </MuiMenuItem>
  );
});

export default React.memo(MenuItemSelect);
