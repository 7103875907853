import React, { useMemo } from 'react';

import { createContext } from '@helpers';
import { Children } from '@types';

import { usePhases } from '../Phases';
import { useTooltip } from './@hooks';
import { TimeLineBar } from '../@types';

export type PhaseProviderProps = {
  record: TimeLineBar.Phase;
  index: number;
};

export type UseTooltip = ReturnType<typeof useTooltip>;

export type PhaseContextType = UseTooltip & {
  phase: TimeLineBar.Phase;
  index: number;
  isSelected: boolean;
};

const [Provider, usePhase] = createContext<PhaseContextType>();
export { usePhase };

const PhaseProvider: React.FC<PhaseProviderProps & Children> = ({ record, index, children }) => {
  const { selected } = usePhases();
  const tooltipState = useTooltip();

  const contextValue = useMemo<PhaseContextType>(
    () => ({
      phase: record,
      index,
      isSelected: selected?.name === record?.name,
      ...tooltipState,
    }),
    [record, index, selected?.name, tooltipState],
  );

  return <Provider value={contextValue}>{children}</Provider>;
};

export default React.memo(PhaseProvider);
