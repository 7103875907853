import React from 'react';

import PhotoViewer from './PhotoViewer';
import PhotoUploader from './PhotoUploader';
import { usePhotoCreator } from './Context';

const PhotoPreview: React.FC = () => {
  const { savedImage } = usePhotoCreator();

  return savedImage ? <PhotoViewer /> : <PhotoUploader />;
};

export default PhotoPreview;
