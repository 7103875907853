import { combineReducers } from 'redux';

import { createPrefix as p, createSlice, createSliceSimple } from '@redux/slices';
import {
  fetchQuestionAdd,
  fetchQuestionEdit,
  fetchQuestionDelete,
  fetchQuestionStatus,
  fetchQuestionOrder,
  fetchQuestionTotalCount,
} from '../../@api';

const prefix = 'QUESTION';
const names = {
  add: p('ADD', prefix),
  edit: p('EDIT', prefix),
  delete: p('DELETE', prefix),
  order: p('ORDER', prefix),
  status: p('STATUS', prefix),
  totalCount: p('TOTAL_COUNT', prefix),
};

export const questionAdd = createSliceSimple(names.add, fetchQuestionAdd);
export const questionEdit = createSliceSimple(names.edit, fetchQuestionEdit);
export const questionDelete = createSliceSimple(names.delete, fetchQuestionDelete);
export const questionOrder = createSliceSimple(names.order, fetchQuestionOrder);
export const questionStatus = createSliceSimple(names.status, fetchQuestionStatus);
export const questionTotalCount = createSlice(names.totalCount, fetchQuestionTotalCount, {
  initialState: { questionsTotalCount: 0 },
});

export default combineReducers({
  questionAdd: questionAdd.reducer,
  questionEdit: questionEdit.reducer,
  questionDelete: questionDelete.reducer,
  questionOrder: questionOrder.reducer,
  questionStatus: questionStatus.reducer,
  questionTotalCount: questionTotalCount.reducer,
});
