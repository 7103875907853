import axios, { AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';
import camelcaseKeys from 'camelcase-keys';

import { isLoginSlice, setSessionExpired } from '@redux/slices';
import { API_URL } from '@constants';
import { jwt } from '@servises';

export const TOKEN_EXPIRED = {
  name: 'Request canceled, token expired',
  status: 1000,
} as const;

export const CANCELED_ERROR = {
  status: 999,
} as const;

export type Credentials = { credentials: boolean };

export type APIErrorResponse<T = unknown> = AxiosError<T>;
export type APIResponse<T, D = unknown> = Promise<AxiosResponse<T, D>>;

export type BaseAPI<S = any, P extends any[] = any[], D = any> = (
  ...payload: P
) => APIResponse<S, D>;

const setApiHeaders = (config: InternalAxiosRequestConfig) => {
  if (config?.headers) {
    const { client, expiry, token, uid } = jwt.getUserTokenValues();
    config.headers['token-type'] = 'Bearer';
    config.headers['client'] = client;
    config.headers['expiry'] = expiry;
    config.headers['uid'] = uid;
    config.headers['access-token'] = token;
  }
};

const cancelingRequest = <T>(config: T): T => {
  const CancelToken = axios.CancelToken;
  return {
    ...config,
    cancelToken: new CancelToken((cancel) => cancel(TOKEN_EXPIRED.name)),
  };
};

function BaseAPIInstance(credentials: boolean = true) {
  const instance = axios.create({
    baseURL: API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
  });

  instance.interceptors.request.use(
    async (config) => {
      if (credentials) {
        if (jwt.isTokenAlive()) {
          setApiHeaders(config);
          return config;
        } else {
          const [{ records }] = isLoginSlice();
          records.isLogin && setSessionExpired(true);
          return cancelingRequest(config);
        }
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  instance.interceptors.response.use(
    (response) => ({ ...response, data: camelcaseKeys(response?.data, { deep: true }) }),
    async (error: AxiosError) => {
      if (error.message === TOKEN_EXPIRED.name) {
        return Promise.reject({
          ...error,
          ...(!error?.response && { response: { status: TOKEN_EXPIRED.status } }),
        });
      }
      if (error.message === 'canceled') {
        const canceledError = {
          ...error,
          ...(!error?.response && { response: { status: CANCELED_ERROR.status } }),
        } as AxiosError<unknown, any>;
        return Promise.reject(canceledError);
      }
      if (credentials) {
        // probably no nedded, only as fuses
        if (error.response?.status === 401) {
          const [{ records }] = isLoginSlice();
          records.isLogin && setSessionExpired(true);
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    },
  );

  return instance;
}

export default BaseAPIInstance;
