import React, { useMemo } from 'react';
import { Theme } from '@mui/material';

import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import Icon from 'components/@ui/Icon';
import { clsx, makeSxStyles } from '@helpers';

import { useNavbar } from './@hooks';

const sxses = makeSxStyles({
  button: {
    border: 1,
    borderColor: 'transparent',
    borderBottom: 'none',
    bgcolor: 'transparent',
    borderRadius: '16px 16px 0 0',
    color: 'primary.light',
    '.MuiButton-endIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  buttonOpenNavbar: {
    borderColor: 'action.hover',
    '&:hover, &:active': {
      borderColor: 'blue.main',
    },
    '.MuiButton-endIcon': {
      marginLeft: 1,
      marginRight: -0.5,
    },
  },
  icon: {
    transition: (theme: Theme) => theme.transitions.create(['transform']),
  },
})();

const NavbarFooter: React.FC = () => {
  const { isOpenNavbar, toggleCollapseNavbar } = useNavbar();

  return useMemo(
    () => (
      <Box
        mt={2}
        width="calc(100% - 16px)"
        display="flex"
        justifyContent="center"
        border={1}
        borderColor="action.hover"
        borderRadius="17px 17px 0 0"
        borderBottom="none"
        {...(!isOpenNavbar && { borderColor: 'transparent' })}
      >
        <Button
          fullWidth
          variant="text"
          onClick={toggleCollapseNavbar}
          endIcon={
            <Icon
              color="inherit"
              name="sidebar-close-icon-path"
              sx={clsx(sxses.icon, !isOpenNavbar && { transform: 'rotate(180deg)' })}
            />
          }
          aria-label={isOpenNavbar ? 'Close Navbar' : 'Open Navbar'}
          sx={clsx(sxses.button, isOpenNavbar && sxses.buttonOpenNavbar)}
        >
          {isOpenNavbar ? 'Minimize' : null}
        </Button>
      </Box>
    ),
    [isOpenNavbar, toggleCollapseNavbar],
  );
};

export default NavbarFooter;
