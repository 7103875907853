import React, { useCallback, useMemo } from 'react';

import IconButton from 'components/@ui/IconButton';
import When from 'components/@main/When';
import { useMobileTimeline } from '@hooks';

import { useNavbar } from '../@hooks';

const TimelineButton: React.FC = () => {
  const { setIsOpenNavbarMobile } = useNavbar();
  const [{ records: open }, toggleOpenTimelineMobile] = useMobileTimeline();

  const handleTimelineButtonClick = useCallback(() => {
    setIsOpenNavbarMobile(false);
    toggleOpenTimelineMobile((state) => !state);
  }, [setIsOpenNavbarMobile, toggleOpenTimelineMobile]);

  return useMemo(
    () => (
      <IconButton
        onClick={handleTimelineButtonClick}
        iconName={open ? 'cross' : 'checked-mobile-navbar-icon-path'}
        sx={{
          bgcolor: 'blue.main',
          p: 1.5,
          '&:hover': { backgroundColor: 'primary.dark' },
          '&:active': { backgroundColor: 'actionPrimary.active1' },
          ...(!open && {
            boxShadow: 7,
            bgcolor: 'background.default',
            '&:hover': { backgroundColor: 'actionPrimary.hover1' },
            '&:active': { backgroundColor: 'actionPrimary.active2' },
          }),
        }}
        IconProps={{ color: !open ? 'text.primary' : 'secondary.main' }}
      >
        <When condition={open} disabledWrapper>
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
        </When>
      </IconButton>
    ),
    [open, handleTimelineButtonClick],
  );
};

export default TimelineButton;
