import React, { useCallback, useEffect } from 'react';

import { createContext } from '@helpers';
import { Children } from '@types';

import { useFetchVendors, useUpdateVendors } from './@hooks';
import { makeRandomVendors } from './@helpers';

export const [, useBackground, BackgroundContext] = createContext<null>();

const BackgroundProvider: React.FC<Children> = ({ children }) => {
  const [{ loaded }, fetchVendors] = useFetchVendors();
  const updateVendors = useUpdateVendors();

  const init = useCallback(async () => {
    const response = await fetchVendors();
    const vendorsRandom = makeRandomVendors(response?.data?.slice(0, 12) || []);
    updateVendors(vendorsRandom);
  }, [fetchVendors, updateVendors]);

  useEffect(() => {
    if (!loaded) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  return <BackgroundContext.Provider value={null}>{children}</BackgroundContext.Provider>;
};

export default BackgroundProvider;
