import React from 'react';
import MuiListItemText, {
  ListItemTextProps as MuiListItemTextProps,
} from '@mui/material/ListItemText';

// @ts-ignore
const ListItemText: React.FC<MuiListItemTextProps> = React.forwardRef((props, ref) => (
  <MuiListItemText ref={ref} {...props} />
));

export default React.memo(ListItemText);
