import React, { useMemo } from 'react';

import When from 'components/@main/When';
import { useAbility } from '@permissions';

import DialogShortLict from './DialogShortLict';
import DialogWorkspace from './DialogWorkspace';
import RemindersCase from './RemindersCase';
import useReminders from './useReminders';

export const Reminders: React.FC = () => {
  const { remindShortlist, remindWorkspace, remindHiddenData } = useReminders();
  const { abilityCheck } = useAbility();
  const canAddToWorkspace = abilityCheck('VENDORS_ADD_VENDOR_TO_WORKSPACE');

  return useMemo(() => {
    switch (true) {
      case remindShortlist && remindWorkspace:
        return (
          <>
            <RemindersCase cases="shortlist" />
            <When condition={!remindHiddenData} disabledWrapper>
              <DialogShortLict />
            </When>
          </>
        );
      case remindWorkspace && canAddToWorkspace:
        return (
          <>
            <RemindersCase cases="workspace" />
            <When condition={!remindHiddenData} disabledWrapper>
              <DialogWorkspace />
            </When>
          </>
        );
      default:
        return null;
    }
  }, [canAddToWorkspace, remindHiddenData, remindShortlist, remindWorkspace]);
};

export default Reminders;
