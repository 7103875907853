export const noClosingToastProps = {
  closeOnClick: false,
  autoClose: false,
  draggable: false,
  closeButton: false,
} as const;

export const CUSTOM_OFFLINE_TOAST_ID = -1;

export const titleToastError = {
  sww: 'Something went wrong when connecting to websockets',
  reconnectionLimit: 'Websocket reconnection limit exceeded',
} as const;

export const defaultTimeoutSubscribe = 5000;
