import React, { useMemo } from 'react';

import Button from 'components/@ui/Button/Button';
import Box from 'components/@ui/Box';
import Icon from 'components/@ui/Icon';

import { useRequestDemoModal } from './@hooks';
import { MODAL_STEP } from './Context';

const ActionButtons: React.FC = () => {
  const { modalStep, setModalStep, isHaveHRIS, hRISValue } = useRequestDemoModal();

  return useMemo(
    () =>
      modalStep === MODAL_STEP.HRISProvider ? (
        <Box display="flex" justifyContent="space-between" mt={1.5}>
          <Button
            onClick={setModalStep(MODAL_STEP.alreadyInTouch)}
            startIcon={<Icon name="left" color="inherit" />}
            variant="text"
            sx={{ height: 48, width: 105 }}
          >
            Back
          </Button>
          <Button
            size="small"
            sx={{ height: 48, width: 210 }}
            disabled={isHaveHRIS && !hRISValue}
            onClick={setModalStep(MODAL_STEP.requestPrepared)}
          >
            Confirm and proceed
          </Button>
        </Box>
      ) : (
        <Box mt={1.5} display="flex" justifyContent="flex-end">
          <Button
            onClick={setModalStep(MODAL_STEP.HRISProvider)}
            endIcon={<Icon name="right" color="inherit" />}
            sx={{ height: 48, width: 105 }}
          >
            Next
          </Button>
        </Box>
      ),
    [modalStep, setModalStep, isHaveHRIS, hRISValue],
  );
};

export default ActionButtons;
