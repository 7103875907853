import React, { useCallback, useMemo } from 'react';

import When from 'components/@main/When';
import IconButton from 'components/@ui/IconButton';
import { useIsTablet, useUser } from '@hooks';
import { ROUTES } from '@routes/config';

import { useToDoItem } from '../TodoItem';
import { onboardingStorage } from '../../Onboarding/@services';

const RepeatHeatMapOnboarding: React.FC = () => {
  const isTablet = useIsTablet();
  const { marked } = useToDoItem();
  const { id } = useUser();

  const handleClick = useCallback(() => {
    onboardingStorage.set(id, false);
    window.location.replace(ROUTES.vendors.root);
  }, [id]);

  return useMemo(
    () => (
      <When disabledWrapper condition={!isTablet}>
        <IconButton
          sx={{ p: 1, width: 24, height: 24 }}
          IconProps={{ fontSize: 14 }}
          onClick={handleClick}
          iconName={marked ? 'undo-icon-path' : 'play-video-icon-path'}
        />
      </When>
    ),
    [marked, isTablet, handleClick],
  );
};

export default RepeatHeatMapOnboarding;
