import { PermissionsRules } from '@permissions';

import { AbilitiesType } from '../ability';

const SETTINGS_USE_PERMITIONS: PermissionsRules = {
  SETTINGS_TRANSFER_OWNERSHIP: false,
};

const rule: AbilitiesType = {
  USE: {
    ...SETTINGS_USE_PERMITIONS,
  },
};

export default rule;
