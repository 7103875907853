import React, { useMemo } from 'react';
import MuiDialogTitle, { DialogTitleProps as MuiDialogTitleProps } from '@mui/material/DialogTitle';

import { clsx, parseSx } from '@helpers';

import IconButtonUi, { IconButtonProps } from '../IconButton';

export interface DialogTitleProps extends MuiDialogTitleProps {
  handleBack?: IconButtonProps['onClick'];
  handleClose?: IconButtonProps['onClick'];
  ComponentBack?: React.ReactElement;
  ComponentClose?: React.ReactElement;
  IconButtonBackProps?: Partial<IconButtonProps>;
  IconButtonCloseProps?: Partial<IconButtonProps>;
}

const IconButton: React.FC<IconButtonProps> = ({ sx, ...others }) => {
  return <IconButtonUi sx={clsx({ position: 'absolute', top: 4 }, ...parseSx(sx))} {...others} />;
};

export const IconButtonCloseModal: React.FC<Partial<IconButtonProps>> = ({ sx, ...others }) => {
  return <IconButton iconName="delete" sx={clsx({ right: 4 }, ...parseSx(sx))} {...others} />;
};

export const IconButtonBackModal: React.FC<Partial<IconButtonProps>> = ({ sx, ...others }) => {
  return <IconButton iconName="left" sx={clsx({ left: 7, top: 14 }, ...parseSx(sx))} {...others} />;
};

// @ts-ignore
const DialogTitle: React.FC<DialogTitleProps> = React.forwardRef((props, ref) => {
  const {
    handleBack,
    handleClose,
    ComponentBack,
    ComponentClose,
    IconButtonBackProps,
    IconButtonCloseProps,
    children,
    sx,
    ...other
  } = props;

  const componentBack = useMemo(() => {
    switch (true) {
      case Boolean(ComponentBack):
        return ComponentBack;
      case Boolean(handleBack):
        return <IconButtonBackModal onClick={handleBack} {...IconButtonBackProps} />;
      default:
        return null;
    }
  }, [ComponentBack, IconButtonBackProps, handleBack]);

  const componentClose = useMemo(() => {
    switch (true) {
      case Boolean(ComponentClose):
        return ComponentClose;
      case Boolean(handleClose):
        return <IconButtonCloseModal onClick={handleClose} {...IconButtonCloseProps} />;
      default:
        return null;
    }
  }, [ComponentClose, IconButtonCloseProps, handleClose]);

  return (
    <MuiDialogTitle
      ref={ref}
      sx={clsx(
        { p: 0, fontWeight: 400, fontSize: 'initial', position: 'relative' },
        ...parseSx(sx),
      )}
      component="div"
      {...other}
    >
      {componentBack}
      {componentClose}
      {children}
    </MuiDialogTitle>
  );
});

export default React.memo(DialogTitle);
