import React from 'react';

import Box, { BoxProps } from 'components/@ui/Box';
import { clsx, makeSxStyles, parseSx } from '@helpers';

const sxes = makeSxStyles({
  toDoItemStyles: {
    '&:hover': {
      backgroundColor: '#F4F5FA',
    },
    '.todo-link': {
      color: 'blue.main',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '.heart-icon': {
      width: 22,
      height: 22,
      verticalAlign: 'middle',
    },
  },
})();

const TodoItemWrapper: React.FC<BoxProps> = ({ sx, ...other }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      py={1.5}
      px={3}
      sx={clsx(sxes.toDoItemStyles, ...parseSx(sx))}
      {...other}
    />
  );
};

export default React.memo(TodoItemWrapper);
