import React, { useMemo } from 'react';

import { Children } from '@types';

import { useToDo } from '../Context';
import { Badge } from '../../@components';

const ToDoBadge: React.FC<Children> = ({ children }) => {
  const { todo } = useToDo();

  return useMemo(
    () => (
      <Badge status={todo.status} IconProps={{ right: -2, top: -4 }}>
        {children}
      </Badge>
    ),
    [children, todo.status],
  );
};

export default ToDoBadge;
