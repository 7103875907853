import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAPI, useProject } from '@hooks';

import { getShortlistById, selectors } from '../@slices';

const selector = createSelector(selectors.global, (state) => state.getShortlistById);

const useFetchShortlistById = () => {
  const { id: projectId } = useProject();

  return useAPI(
    useCallback((vendorId: number) => getShortlistById.action(projectId, vendorId), [projectId]),
    selector,
  );
};

export default useFetchShortlistById;
