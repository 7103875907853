import React from 'react';

import { TableRow as MuiTableRow, TableRowProps } from '@mui/material';

// @ts-ignore
const TableRow: React.FC<TableRowProps> = React.forwardRef((props, ref) => {
  return <MuiTableRow {...props} ref={ref}></MuiTableRow>;
});

export default React.memo(TableRow);
