import { objectStorage } from '@servises';

const HEAT_MAP_OPEN_KEY = 'os_is_onboarding_heat_map' as const;

const heatMapOnboarding = objectStorage<number, boolean>(HEAT_MAP_OPEN_KEY);

const get = (userId: number) => {
  return heatMapOnboarding.get(userId) === true;
};

export default {
  ...heatMapOnboarding,
  get,
};
