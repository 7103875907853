import React, { useMemo, useState } from 'react';

import Icon from 'components/@ui/Icon';
import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import Dialog from 'components/@ui/Dialog';
import { Product } from '@types';
import { useIsTablet, useOpenModal } from '@hooks';
import { clsx } from '@helpers';

import InitialDemoRequestConfirmModal from './InitialDemoRequestConfirmModal';
import Logo from './Logo';
import LogoLabel from './LogoLabel';
import { makeSX } from '../@hook';
import { useToDoItems } from '../Context';
import { useToDoItem } from '../TodoItem';

type VendorScheduleButtonProps = {
  vendor: Product.Product;
};

const InitialDemoVendorScheduleButton: React.FC<VendorScheduleButtonProps> = ({ vendor }) => {
  const { todoItem } = useToDoItem();
  const { sendMeetRequest } = useToDoItems();
  const { handleClose, handleOpen, open } = useOpenModal();
  const [isDisabled, setIsDisabled] = useState(
    Boolean(todoItem.proposals.find((proposal) => proposal.meetableId === vendor.id)),
  );

  const isTablet = useIsTablet();

  return useMemo(
    () => (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginLeft={4.5}
        mt={1.5}
        bgcolor="transparent"
        minHeight={{ mobile: 56, tablet: 56, laptop: 32 }}
      >
        <Box display="flex" alignItems="center">
          <Logo src={vendor.logoSmallUrl} />
          <LogoLabel label={vendor.name} />
        </Box>
        <Button
          sx={clsx(
            { color: 'blue.main' },
            makeSX.buttonStyle,
            isTablet && makeSX.buttonStyleIsTouchDevice,
          )}
          endIcon={
            <Icon
              name={isDisabled ? 'proposal-sent-icon-path' : 'send-mail-icon-path'}
              borderRadius={0}
              color="inherit"
            />
          }
          disabled={isDisabled}
          onClick={handleOpen}
        >
          {isDisabled ? 'Check email' : 'Request meeting'}
        </Button>
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          PaperProps={{ sx: { width: 'auto', borderRadius: 4, margin: 2 } }}
        >
          <Box bgcolor="background.paper" borderRadius={4}>
            <InitialDemoRequestConfirmModal
              handleClose={handleClose}
              confirm={sendMeetRequest(todoItem.id, vendor.id, 'Product', setIsDisabled)}
              vendorName={vendor.name}
            />
          </Box>
        </Dialog>
      </Box>
    ),
    [
      vendor.logoSmallUrl,
      vendor.name,
      vendor.id,
      isDisabled,
      handleOpen,
      open,
      handleClose,
      sendMeetRequest,
      todoItem.id,
      isTablet,
    ],
  );
};

export default InitialDemoVendorScheduleButton;
