import snakecaseKeys from 'snakecase-keys';

import baseAPI, { APIResponse } from '@api';
import { Product } from '@types';

import {
  CharacteristicMatchDataResponse,
  CharacteristicModalResponse,
  FeatureMatchDataResponse,
  FeatureModalResponse,
  FetchVendorModalProps,
  FetchVendorsPageParams,
  FetchWorkplaceListResponse,
  HeatMapDataResponse,
  ProsAndConsDataResponse,
  ProsAndConsModalResponse,
  SizeMatchScalabilityDataResponse,
  SizeMatchScalabilityModalResponse,
  TableViewDataResponse,
} from '../@types';

const apiCredentials = baseAPI();
//-------------------------------------------------------------------------
// Get Vendors
//-------------------------------------------------------------------------

export function fetchVendors(projectId: number): APIResponse<Product.Product[]> {
  return apiCredentials.get(`/projects/${projectId}/workspaced_products`);
}

//-------------------------------------------------------------------------
// Get Workplace list
//-------------------------------------------------------------------------

export function fetchWorkplaceList(projectId: number): APIResponse<FetchWorkplaceListResponse> {
  return apiCredentials.get(`/projects/${projectId}/shortlisted_products`);
}
//-------------------------------------------------------------------------
// Heat Map Data
//-------------------------------------------------------------------------

export function fetchHeatMapData({
  projectId,
  payload,
}: FetchVendorsPageParams): APIResponse<HeatMapDataResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendors/heat_map`, {
    params: snakecaseKeys(payload),
  });
}
//-------------------------------------------------------------------------
// Size Match Scalability Data
//-------------------------------------------------------------------------

export function fetchSizeMatchScalabilityData({
  projectId,
  payload,
}: FetchVendorsPageParams): APIResponse<SizeMatchScalabilityDataResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendors/size_match_scalability`, {
    params: snakecaseKeys(payload),
  });
}
//-------------------------------------------------------------------------
// Feature Match Data
//-------------------------------------------------------------------------

export function fetchFeatureMatchData({
  projectId,
  payload,
}: FetchVendorsPageParams): APIResponse<FeatureMatchDataResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendors/feature_match`, {
    params: snakecaseKeys(payload),
  });
}
//-------------------------------------------------------------------------
// Characteristic Match Data
//-------------------------------------------------------------------------

export function fetchCharacteristicMatchData({
  projectId,
  payload,
}: FetchVendorsPageParams): APIResponse<CharacteristicMatchDataResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendors/characteristic_match`, {
    params: snakecaseKeys(payload),
  });
}
//-------------------------------------------------------------------------
// Pros and Cons Data
//-------------------------------------------------------------------------

export function fetchProsAndConsData({
  projectId,
  payload,
}: FetchVendorsPageParams): APIResponse<ProsAndConsDataResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendors/pros_cons`, {
    params: snakecaseKeys(payload),
  });
}
//-------------------------------------------------------------------------
// Table View Data
//-------------------------------------------------------------------------

export function fetchTableViewData({
  projectId,
  payload,
}: FetchVendorsPageParams): APIResponse<TableViewDataResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendors/table_view`, {
    params: snakecaseKeys(payload),
  });
}
//-------------------------------------------------------------------------
// Size Match Scalability Modal Data
//-------------------------------------------------------------------------

export function fetchSizeMatchModalData({
  projectId,
  vendorId,
}: FetchVendorModalProps): APIResponse<SizeMatchScalabilityModalResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendors/heat_map/size_match_scalability`, {
    params: { product_id: vendorId },
  });
}
//-------------------------------------------------------------------------
// Feature Match Modal Data
//-------------------------------------------------------------------------

export function fetchFeatureMatchModalData({
  projectId,
  vendorId,
}: FetchVendorModalProps): APIResponse<FeatureModalResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendors/heat_map/feature_match`, {
    params: { product_id: vendorId },
  });
}
//-------------------------------------------------------------------------
// Characteristic Match Modal Data
//-------------------------------------------------------------------------

export function fetchCharacteristicMatchModalData({
  projectId,
  vendorId,
}: FetchVendorModalProps): APIResponse<CharacteristicModalResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendors/heat_map/characteristic_match`, {
    params: { product_id: vendorId },
  });
}
//-------------------------------------------------------------------------
// Pros and Cons Modal Data
//-------------------------------------------------------------------------

export function fetchProsAndConsModalData({
  projectId,
  vendorId,
}: FetchVendorModalProps): APIResponse<ProsAndConsModalResponse> {
  return apiCredentials.get(`/projects/${projectId}/vendors/heat_map/pros_cons`, {
    params: { product_id: vendorId },
  });
}

//-------------------------------------------------------------------------
