import React, { useCallback, useMemo, useState } from 'react';
import { useMount, useToggle, useUnmount } from 'react-use';
import { find, not, propEq } from 'ramda';

import { useFetchProject } from '@api/@hooks';
import { Children, UseState } from '@types';

import { ProductProps } from './@api';
import { useFetchRequestDemo } from './@hooks';

export enum MODAL_STEP {
  alreadyInTouch,
  HRISProvider,
  requestPrepared,
  cancelRequest,
  designDemo,
  skipDesignDemo,
}

export type OptionProps = {
  label: string;
  iconName?: string;
  added?: string;
  disabled?: boolean;
  isOptionNotFound?: boolean;
};

export type RequestDemoModalContextType = {
  modalStep: MODAL_STEP;
  setModalStep: (step: MODAL_STEP) => () => void;
  checkBoxState: CheckBoxStateProps[];
  hRISValue: OptionProps | null;
  isHaveHRIS: boolean;
  isFetchedVendorsList: boolean;
  handlerCheckBox: (productId: number, type: 'requestDemo' | 'alreadyInTouch') => () => void;
  handlerHRISSelect: (option: OptionProps | null) => void;
  requestDemoModalFlowClose: () => void;
  handleChangeIsHRIS: () => void;
  setIsFetchedVendorsList: UseState<boolean>;
};

type RequestDemoModalProviderProps = {
  requestDemoModalFlowClose: () => void;
};

type CheckBoxStateProps = { isDisabledRequest: boolean } & ProductProps;

export const RequestDemoModalContext = React.createContext<RequestDemoModalContextType>(
  null as any,
);
export const RequestDemoModalConsumer = RequestDemoModalContext.Consumer;

const RequestDemoModalProvider: React.FC<Children & RequestDemoModalProviderProps> = ({
  children,
  requestDemoModalFlowClose,
}) => {
  const [{ records }] = useFetchRequestDemo();
  const [
    {
      records: { project },
    },
    fetchProject,
  ] = useFetchProject();

  const dataVendors = useMemo(
    () =>
      project.workspacedProducts.map((product) => {
        const workspacedProduct = find(propEq(product.id, 'productId'), records.products);
        return {
          requestDemo: not(workspacedProduct?.requestDemo),
          isDisabledRequest: not(workspacedProduct?.requestDemo),
          alreadyInTouch: Boolean(workspacedProduct?.alreadyInTouch),
          productId: product.id,
          productName: product.name,
          logoURL: product.logoUrl,
        };
      }),
    [project.workspacedProducts, records],
  );
  const [modalStep, setModalStep] = useState<MODAL_STEP>(MODAL_STEP.alreadyInTouch);
  const [checkBoxState, setCheckBoxState] = useState<CheckBoxStateProps[]>(dataVendors);
  const [hRISValue, setHRISValue] = useState<OptionProps | null>(
    Boolean(records.vendors) && records.vendors !== "We don't have an HRIS"
      ? { label: records.vendors }
      : null,
  );
  const [isHaveHRIS, handleChangeIsHRIS] = useToggle(
    not(records.vendors) || records.vendors !== "We don't have an HRIS",
  );
  const [isFetchedVendorsList, setIsFetchedVendorsList] = useState(false);

  useMount(async () => {
    await fetchProject();
  });

  const onSetModalStep = useCallback(
    (step: MODAL_STEP) => () => {
      setModalStep(step);
    },
    [],
  );

  const handlerCheckBox = useCallback(
    (productId: number, type: 'requestDemo' | 'alreadyInTouch') => () => {
      setCheckBoxState((state) =>
        state.map((vendor) =>
          vendor.productId === productId ? { ...vendor, [type]: !vendor[type] } : vendor,
        ),
      );
    },
    [],
  );

  const handlerHRISSelect = useCallback((option: OptionProps | null) => {
    if (!option) return setHRISValue(null);
    if (option?.added) return setHRISValue(option);
    return setHRISValue(option);
  }, []);

  useUnmount(() => {
    setModalStep(MODAL_STEP.alreadyInTouch);
    setIsFetchedVendorsList(false);
  });

  const contextValue = useMemo<RequestDemoModalContextType>(
    () => ({
      modalStep,
      setModalStep: onSetModalStep,
      hRISValue,
      isHaveHRIS,
      isFetchedVendorsList,
      checkBoxState,
      handlerCheckBox,
      handlerHRISSelect,
      requestDemoModalFlowClose,
      handleChangeIsHRIS,
      setIsFetchedVendorsList,
    }),
    [
      modalStep,
      onSetModalStep,
      checkBoxState,
      handlerCheckBox,
      hRISValue,
      isHaveHRIS,
      isFetchedVendorsList,
      handlerHRISSelect,
      requestDemoModalFlowClose,
      handleChangeIsHRIS,
      setIsFetchedVendorsList,
    ],
  );

  return (
    <RequestDemoModalContext.Provider value={contextValue}>
      {children}
    </RequestDemoModalContext.Provider>
  );
};

export default RequestDemoModalProvider;
