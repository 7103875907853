import React, { useCallback, useMemo, useRef, useState } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useMount, useUnmount } from 'react-use';

import { useIsTablet } from '@hooks';
import { createContext } from '@helpers';
import { Children, UseState } from '@types';

import { useDrag, UseEmptyState, useEmptyState } from './@hooks';
import { useFetchTimeLinePhases } from '../@hooks';
import { getSelectedPhase } from '../@helpers';
import { TimeLineBar } from '../@types';

export type ScrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

export type ToogleSelected = (
  phase: TimeLineBar.Phase,
) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

export type PhasesContextType = UseEmptyState & {
  selected: TimeLineBar.Phase | undefined;
  toogleSelected: ToogleSelected;
  dragState: ReturnType<typeof useDrag>;
  scrollMenuApiRef: React.MutableRefObject<ScrollVisibilityApiType>;
  isPopoverOpen: boolean;
  setIsPopoverOpen: UseState<boolean>;
};

const [Provider, usePhases] = createContext<PhasesContextType>();
export { usePhases };

const PhasesProvider: React.FC<Children> = ({ children }) => {
  const [{ records }] = useFetchTimeLinePhases();
  const [selected, toogleSelected] = useState(records.phases.at(0));
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const dragState = useDrag();
  const isTablet = useIsTablet();
  const scrollMenuApiRef = useRef({} as ScrollVisibilityApiType);

  const emptyState = useEmptyState();

  const setSelected = useCallback<ToogleSelected>(
    (phase) => (event) => {
      if (dragState.dragging) {
        return event.stopPropagation();
      }
      if (isTablet && phase.name === selected?.name) {
        return toogleSelected({ ...phase, name: 'CloseAllPhases' });
      }
      return toogleSelected(phase);
    },
    [dragState.dragging, selected?.name, isTablet],
  );

  useMount(() => toogleSelected(getSelectedPhase(records.phases)));
  useUnmount(() => setIsPopoverOpen(false));

  const contextValue = useMemo<PhasesContextType>(
    () => ({
      selected,
      toogleSelected: setSelected,
      dragState,
      scrollMenuApiRef,
      isPopoverOpen,
      setIsPopoverOpen,
      ...emptyState,
    }),
    [selected, setSelected, dragState, isPopoverOpen, emptyState],
  );

  return <Provider value={contextValue}>{children}</Provider>;
};

export default React.memo(PhasesProvider);
