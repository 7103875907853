import React from 'react';
import { useMount } from 'react-use';

import ToastButton from './ToastButton';
import { titleToastWsError } from './config';

export type ToastWsErrorProps = {
  title?: React.ReactNode;
  reason?: React.ReactNode;
  relaodAction?: React.ReactNode;
  fetchSelfData?: () => Promise<any>;
};

const ReloadButton: React.FC = () => {
  const handleClick = () => window.location.reload();

  return <ToastButton onClick={handleClick}>Please reload the page</ToastButton>;
};

const ToastWsError: React.FC<ToastWsErrorProps> = (props) => {
  const {
    reason,
    title = titleToastWsError,
    relaodAction = <ReloadButton />,
    fetchSelfData,
  } = props;

  useMount(() => fetchSelfData?.());

  return (
    <>
      {title}
      <br />
      {reason}
      {relaodAction}
    </>
  );
};

export default React.memo(ToastWsError);
