import React from 'react';

import Box, { BoxProps } from 'components/@ui/Box';
import { useIsTablet } from '@hooks';

type VendorsHeaderWrappewProps = BoxProps & {
  boxInnerPros?: Omit<BoxProps, 'children'>;
};

const VendorsHeaderWrapper: React.FC<VendorsHeaderWrappewProps> = ({
  children,
  boxInnerPros,
  ...other
}) => {
  const isTablet = useIsTablet();

  return (
    <Box
      pb={3}
      pt={isTablet ? 0 : 3}
      width="100%"
      position={isTablet ? 'static' : 'sticky'}
      top={0}
      bgcolor="background.default"
      zIndex={17}
      {...other}
    >
      <Box
        maxWidth={1032}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mx="auto"
        px={isTablet ? 2 : 3}
        {...boxInnerPros}
      >
        {children}
      </Box>
    </Box>
  );
};

export default React.memo(VendorsHeaderWrapper);
