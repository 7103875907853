import React from 'react';
import { toast } from 'react-toastify';

import { User } from '@types';

import ButtonMicrosoft from './ButtonMicrosoft';
import msalInstance from './msalInstance';
import { SocialProps } from '../types';

const MicrosoftAuth: React.FC<SocialProps> = ({ fetch }) => {
  const handleLogin = async () => {
    try {
      const response = await msalInstance.loginPopup({
        scopes: ['openid', 'profile', 'user.read'],
        prompt: 'select_account',
      });
      await fetch({ provider: User.PROVIDERS.microsoft, accessToken: response.accessToken });
    } catch (error) {
      toast.error('Microsoft authentication failed!');
    }
  };

  return <ButtonMicrosoft onClick={handleLogin} />;
};

export default React.memo(MicrosoftAuth);
