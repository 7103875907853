import React, { useMemo } from 'react';

import PhasesScrollMenu from './PhasesScrollMenu';
import Phase, { PhaseProvider } from '../Phase';
import { useFetchTimeLinePhases } from '../@hooks';
import { useUpdateWsTimeLine } from '../@hooksWs';

const Phases: React.FC = () => {
  const [{ records }] = useFetchTimeLinePhases();

  useUpdateWsTimeLine();

  return useMemo(
    () => (
      <PhasesScrollMenu>
        {records.phases.map((phase, index) => (
          <PhaseProvider key={phase.id} record={phase} index={index}>
            <Phase />
          </PhaseProvider>
        ))}
      </PhasesScrollMenu>
    ),
    [records.phases],
  );
};

export default Phases;
