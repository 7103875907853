import { useCallback, useMemo, useRef } from 'react';
import { flushSync } from 'react-dom';
import { useUnmount } from 'react-use';

export type OptionsType = {
  disableFlushSync?: boolean;
};

const useSetTimeout = <T>(defaultTimeout?: number, option?: OptionsType) => {
  const timeoutId = useRef<number | undefined>();

  const setTimeoutFn = useCallback(
    (cb: () => T, timeout?: number, optionFn?: OptionsType) => {
      timeoutId.current = window.setTimeout(() => {
        if (optionFn?.disableFlushSync || option?.disableFlushSync) {
          cb();
        } else {
          flushSync(() => cb());
        }
      }, timeout ?? defaultTimeout);
    },
    [defaultTimeout, option?.disableFlushSync],
  );

  const clearTimeoutFn = useCallback(() => {
    window.clearTimeout(timeoutId.current);
  }, []);

  useUnmount(() => window.clearTimeout(timeoutId.current));

  return useMemo(
    () => ({
      setTimeout: setTimeoutFn,
      clearTimeout: clearTimeoutFn,
    }),
    [clearTimeoutFn, setTimeoutFn],
  );
};

export default useSetTimeout;
