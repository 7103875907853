import React from 'react';

import Box from 'components/@ui/Box';
import Divider from 'components/@ui/Divider';
import Typography from 'components/@ui/Typography';

const DividerOr: React.FC = () => {
  return (
    <Box display="flex" alignItems="center">
      <Divider sx={{ flex: 1 }} />
      <Typography fontSize={13} lineHeight={16 / 13} textAlign="center" mx={1.5}>
        or
      </Typography>
      <Divider sx={{ flex: 1 }} />
    </Box>
  );
};

export default DividerOr;
