import React, { Fragment } from 'react';

import FeatureAndCharacteristicModuleModal from './FeaturesAndCharacteristicsModal';
import { CharacteristicMatchProps } from './types';
import { CHARACTERISTICS } from './constants';

type ListWithCharacteristicsProps = {
  characteristicMatch: CharacteristicMatchProps[];
  start: number;
  end?: number;
};

const ListWithCharacteristics: React.FC<ListWithCharacteristicsProps> = ({
  characteristicMatch,
  start,
  end,
}) => {
  return (
    <>
      {CHARACTERISTICS.slice(start, end).map((characteristic) => {
        const needOneCharacteristic = characteristicMatch.find(
          (element) => element.characteristicName === characteristic.name,
        );
        if (needOneCharacteristic) {
          let status: 'active' | 'onlyProject' | 'disabled' | 'onlyProduct';
          switch (needOneCharacteristic.matchIndicator) {
            case '+':
              status = 'active';
              break;
            case '-':
              status = 'disabled';
              break;
            case '@':
              status = 'onlyProject';
              break;
            case '#':
              status = 'onlyProduct';
              break;
            default:
              status = 'disabled';
          }
          return (
            <FeatureAndCharacteristicModuleModal
              key={characteristic.name}
              moduleEl={characteristic}
              status={status}
            />
          );
        } else {
          return <Fragment key={characteristic.name} />;
        }
      })}
    </>
  );
};

export default React.memo(ListWithCharacteristics);
