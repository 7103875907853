import { useCallback, useMemo, useRef } from 'react';

export type UseDynamicRefs<K = string> = [(key: K) => unknown, (key: K, ref: unknown) => void];

export const useDynamicRefs = () => {
  const refs = useRef<Record<string, unknown>>({});

  const getRef = useCallback((key: string) => {
    return refs.current[key];
  }, []);

  const setRef = useCallback((key: string, ref: unknown) => {
    refs.current[key] = ref;
  }, []);

  return useMemo<UseDynamicRefs<string>>(() => [getRef, setRef], [getRef, setRef]);
};

export type UseDynamicRefsArray<K = number> = [(key: K) => unknown, (ref: unknown) => void];

export const useDynamicRefsArray = () => {
  const refs = useRef<unknown[]>([]);

  const getRef = useCallback((key: number) => {
    return refs.current[key];
  }, []);

  const setRef = useCallback((ref: unknown) => {
    refs.current.push(ref);
  }, []);

  return useMemo<UseDynamicRefsArray<number>>(() => [getRef, setRef], [getRef, setRef]);
};
