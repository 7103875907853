import React, { useMemo } from 'react';
import { isEmpty } from 'ramda';

import Box from 'components/@ui/Box';

import {
  AdvisorFirstScheduleTodoItem,
  AdvisorRequestTodoItem,
  AdvisorScheduleTodoItem,
  DesignDemoTodoItem,
  DownloadTodoItem,
  InitialVendorsScheduleTodoItem,
  RequestDemoTodoItem,
  SimpleToDoItem,
  TodoItemOnboardingHeatMap,
  ToDoItemProvider,
  ToDoItemWithTooltip,
  VendorChoiceReferenceTodoItem,
  VendorsChoiceProposalTodoItem,
  VendorsChoiceScheduleTodoItem,
  VendorsProposalTodoItem,
  VendorsScheduleTodoItem,
} from './TodoItem';
import { useFetchTodoItem } from './@hook';
import { TimeLineBar } from '../@types';

type SwitchPropertiesProps = { todoItem: TimeLineBar.TodoItem };

const SwitchProperties: React.FC<SwitchPropertiesProps> = ({ todoItem }) => {
  if (isEmpty(todoItem.properties)) {
    return <SimpleToDoItem />;
  } else {
    switch (todoItem.properties.type) {
      case 'download':
        return <DownloadTodoItem />;
      case 'TODO add text tooltip V2':
        return (
          <ToDoItemWithTooltip hideTooltip={todoItem.completed} type="text">
            <SimpleToDoItem />
          </ToDoItemWithTooltip>
        );
      case 'TODO add video tooltip V2':
        return (
          <ToDoItemWithTooltip hideTooltip={todoItem.completed} type="video">
            <SimpleToDoItem />
          </ToDoItemWithTooltip>
        );
      case 'schedule advisor':
        return <AdvisorFirstScheduleTodoItem />;
      case 'advisor schedule':
        return <AdvisorScheduleTodoItem />;
      case 'design demo':
        return <DesignDemoTodoItem />;
      case 'vendors schedule':
        return <VendorsScheduleTodoItem />;
      case 'initial vendors schedule':
        return <InitialVendorsScheduleTodoItem />;
      case 'vendors proposal':
        return <VendorsProposalTodoItem />;
      case 'vendor-of-choice proposal':
        return <VendorsChoiceProposalTodoItem />;
      case 'vendor-of-choice request':
        return <VendorsChoiceScheduleTodoItem />;
      case 'vendor-of-choice reference':
        return <VendorChoiceReferenceTodoItem />;
      case 'advisor request':
        return <AdvisorRequestTodoItem />;
      case 'request demo':
        return <RequestDemoTodoItem />;
      case 'onboarding-heat-map':
        return <TodoItemOnboardingHeatMap />;
      default:
        return <SimpleToDoItem />;
    }
  }
};

const ToDoItemsBody: React.FC = () => {
  const [{ records }] = useFetchTodoItem();
  const { todoItems } = records;

  return useMemo(
    () => (
      <Box
        display="flex"
        overflow="auto"
        flexDirection="column"
        mt={{ mobile: 0, tablet: 0, laptop: 3 }}
        maxWidth={{ mobile: '100%', tablet: '50%', laptop: '100%' }}
      >
        {todoItems.map((item) => (
          <ToDoItemProvider key={item.id} todoItem={item}>
            <SwitchProperties todoItem={item} />
          </ToDoItemProvider>
        ))}
      </Box>
    ),
    [todoItems],
  );
};

export default ToDoItemsBody;
