import React from 'react';

import Box from 'components/@ui/Box';
import Image from 'components/@ui/Image';
import { useIsTablet } from '@hooks';
import { clsx } from '@helpers';

import { DialogWrapper, FeaturesContent, ScheduleOnboardingButton } from './@components';

type OutSailFeaturesProps = {
  openModal: boolean;
  closeModal: () => void;
};

const ContentWrapper: React.FC = () => {
  const isTablet = useIsTablet();

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Image
          src={require('assets/main-logo-outsail.svg').default}
          width={76}
          height={30}
          duration={0}
        />
      </Box>
      <FeaturesContent />
      <Box
        display="flex"
        justifyContent="center"
        mt={6}
        sx={clsx(isTablet && { '& a': { width: 1 } })}
      >
        <ScheduleOnboardingButton
          fullWidth={isTablet}
          sx={{ maxWidth: isTablet ? 'none' : 300, fontSize: isTablet ? 18 : 16, height: 'auto' }}
        />
      </Box>
    </>
  );
};

const OutSailFeatures: React.FC<OutSailFeaturesProps> = ({ openModal, closeModal }) => {
  return (
    <DialogWrapper openModal={openModal} closeModal={closeModal}>
      <ContentWrapper />
    </DialogWrapper>
  );
};

export default React.memo(OutSailFeatures);
