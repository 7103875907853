import { makeSxStyles } from '@helpers';

export default makeSxStyles({
  rowHeader: {
    left: 0,
    p: '12px 20px',
    position: 'sticky',
    bgcolor: 'secondary.main',
    zIndex: 13,
    display: 'table-cell',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 215,
  },
  tableHeader: {
    width: 115,
    px: 2,
  },
})();
