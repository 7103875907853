import { Shadows } from '@mui/material';

export default [
  'none',
  '0px 10px 30px rgba(38, 51, 77, 0.03)', // 1
  '',
  '0px 10px 30px rgba(38, 51, 77, 0.05)', // 3
  '',
  '',
  '0px 4px 8px 0px rgba(38, 51, 77, 0.10)', // 6
  '0px 2px 4px 0px rgba(38, 51, 77, 0.16)', // 7
  '0px 10px 30px rgba(38, 51, 77, 0.16)', // 8
  '0px 10px 30px rgba(38, 51, 77, 0.20)', // 9
  '0px -10px 30px rgba(38, 51, 77, 0.16)', // 10
  '0px 4px 16px 0px rgba(18, 29, 84, 0.20)', // 11
  '0px 0px 30px 0px rgba(38, 51, 77, 0.25)', // 12
  '0px 10px 144px rgba(38, 51, 77, 0.16)', // 13
  '',
  '',
  '0px 6px 18px 0px rgba(38, 51, 77, 0.90)', // 16 ,
  '',
  '',
  '0px 0px 16px 0px rgba(238, 240, 251, 1)', // 19
  '',
  '',
  '',
  '',
  '', // 24
] as Shadows;
