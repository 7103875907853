import React from 'react';
import { Theme } from '@mui/material';

import Box, { BoxProps } from 'components/@ui/Box';
import { clsx, parseSx } from '@helpers';
import { useIsTablet } from '@hooks';

const VendorsActionWrapper: React.FC<BoxProps> = ({ sx, ...other }) => {
  const isTablet = useIsTablet();

  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      overflow="auto"
      sx={clsx({ '&': (theme: Theme) => theme.scrollbar(isTablet ? 0 : 1) }, ...parseSx(sx))}
      {...other}
    />
  );
};

export default React.memo(VendorsActionWrapper);
