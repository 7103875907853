import React from 'react';

import Box from 'components/@ui/Box';
import TableCell from 'components/@ui/TableCell';
import TableRow from 'components/@ui/TableRow';
import Typography from 'components/@ui/Typography';
import Icon from 'components/@ui/Icon';
import { Children } from '@types';
import { useIsTablet } from '@hooks';
import { clsx } from '@helpers';

import heatMapRowStyles from './heatMapRowStyles';
import { EmptyCell } from '../VendorsComponents';
import { cellsSize, rowTitles } from './@config';

const RequirementsMatchRow: React.FC<Children> = ({ children }) => {
  const isTablet = useIsTablet();

  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        height={cellsSize.heatMap.height}
        width={isTablet ? 115 : 215}
        sx={clsx(heatMapRowStyles.rowHeader, isTablet && heatMapRowStyles.tableHeader)}
      >
        <Box display="flex" alignItems="center">
          <Icon name="logo-path" mr={1.5} borderRadius={0}>
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
          </Icon>
          <Typography
            maxHeight={1}
            fontSize={isTablet ? 12 : 16}
            lineHeight={isTablet ? 'normal' : 1.5}
            whiteSpace={isTablet ? 'normal' : 'nowrap'}
            semiBold
          >
            {rowTitles.requirementsMatch}
          </Typography>
        </Box>
      </TableCell>
      <EmptyCell />
      {children}
    </TableRow>
  );
};

export default React.memo(RequirementsMatchRow);
