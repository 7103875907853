import React, { useMemo } from 'react';
import { Theme } from '@mui/material';

import Popover from 'components/@ui/Popover';
import Loader from 'components/@common/Loader';
import { ToDoItems } from 'components/@main/TimeLineBar/ToDoItems';

import { useToDo } from '../Context';

type ToDoPopoverProps = {
  todoId: number;
};

const ToDoPopover: React.FC<ToDoPopoverProps> = ({ todoId }) => {
  const { handleCloseToDoPopover, anchorToDoEl, isPopoverData } = useToDo();

  return useMemo(
    () => (
      <Popover
        id={`${todoId}`}
        open={Boolean(anchorToDoEl)}
        anchorEl={anchorToDoEl}
        onClose={handleCloseToDoPopover}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            width: 400,
            padding: '24px 0',
            borderRadius: 2,
            '&': (theme: Theme) => theme.scrollbar(),
            boxShadow: 3,
          },
        }}
      >
        {isPopoverData ? (
          <ToDoItems todoName={anchorToDoEl?.name ?? ''} />
        ) : (
          <Loader height={200} />
        )}
      </Popover>
    ),
    [anchorToDoEl, handleCloseToDoPopover, todoId, isPopoverData],
  );
};

export default ToDoPopover;
