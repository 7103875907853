import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

const root = (state: RootState) => state.scorecard.questions;

const questionAdd = createSelector(root, (state) => state.questionAdd);
const questionEdit = createSelector(root, (state) => state.questionEdit);
const questionDelete = createSelector(root, (state) => state.questionDelete);
const questionOrder = createSelector(root, (state) => state.questionOrder);
const questionStatus = createSelector(root, (state) => state.questionStatus);

export default { root, questionAdd, questionEdit, questionDelete, questionOrder, questionStatus };
