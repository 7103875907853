import React, { useMemo, useState } from 'react';
import { GlobalStyles } from '@mui/material';
import { Id } from 'react-toastify';

import Box from 'components/@ui/Box';
import When from 'components/@main/When';
import { useOnline } from '@hooks';
import { createContext } from '@helpers';
import { Children, UseState } from '@types';

export type UseWsOnlineProps = {
  refresh?: () => Promise<void> | void;
};

export type OfflineContextType = {
  toastId: Id;
  setToastId: UseState<Id>;
};

export const [Provider, useOffline] = createContext<OfflineContextType>();

export const OfflineProvider: React.FC<Children> = ({ children }) => {
  const [toastId, setToastId] = useState<Id>(0);
  const isOnline = useOnline();

  const value = useMemo(() => ({ toastId, setToastId }), [toastId]);

  return (
    <Provider value={value}>
      {children}
      <When condition={!isOnline} disabledWrapper>
        <Box zIndex={1500} width="100vw" height="100vh" position="fixed" top={0} left={0} />
        <GlobalStyles styles={{ body: { overflow: 'hidden' } }} />
      </When>
    </Provider>
  );
};

export default OfflineProvider;
