import { combineReducers } from 'redux';

import { createSlice, createSliceData, createSliceSimple } from '@redux/slices';
import { fetchProjectGoals } from '@api';

import {
  fetchCharacteristic,
  fetchCriteria,
  fetchCriteriaSettings,
  fetchCriterionCreate,
  fetchCriterionDelete,
  fetchCriterionEdit,
  fetchCriterionModalGet,
  fetchCriterionScorecardGet,
  fetchPreDeleteCriteria,
  fetchVendorPersonalScore,
  fetchVendors,
} from '../@api';
import { evaluateReducer } from '../Criterion/@slices';
import { settingsReducer } from '../Settings/@slices';
import { notesReducer } from '../Notes/@slices';
import { questionsReducer } from '../Questions/@slices';

export const vendors = createSlice('FETCH_VENDORS', fetchVendors, { initialState: [] });
export const vendorPersonalScore = createSlice(
  'FETCH_VENDOR_PERSONAL_SCORE',
  fetchVendorPersonalScore,
  { initialState: { score: 0 } },
);
export const criteria = createSlice('FETCH_CRITERIA', fetchCriteria, {
  initialState: { keyCriteria: [], statistic: {} },
});
export const criterionScorecadGet = createSliceSimple(
  'FETCH_CRITERION_SCORECARD_GET',
  fetchCriterionScorecardGet,
);
export const criterionModalGet = createSlice('FETCH_CRITERION_GET', fetchCriterionModalGet);
export const criterionCreate = createSlice('FETCH_CRITERION_CREATE', fetchCriterionCreate);
export const criterionEdit = createSlice('FETCH_CRITERION_EDIT', fetchCriterionEdit);
export const criterionEditSimple = createSliceSimple(
  'FETCH_CRITERION_EDIT_SIMPLE',
  fetchCriterionEdit,
);
export const criterionDelete = createSlice('FETCH_CRITERION_DELETE', fetchCriterionDelete);
export const criteriaModal = createSlice('FETCH_CRITERIA_MODAL', fetchCriteriaSettings, {
  initialState: { keyCriteria: [] },
});
export const criterionPreDeleteData = createSlice(
  'FETCH_PRE_DELETE_CRITERIA_DATA',
  fetchPreDeleteCriteria,
  { initialState: { products: [] } },
);

export const characteristic = createSlice('FETCH_CHARACTERISTIC', fetchCharacteristic);
export const modalLibraryGoals = createSlice(
  'FETCH_CHARACTERISTICS_LIBRARY_MODALS_GOALS',
  fetchProjectGoals,
  { initialState: { characteristics: [] } },
);

export const onboardingModal = createSliceData('ONBOARDING_MODAL_SCORES', false);

export default combineReducers({
  vendors: vendors.reducer,
  vendorPersonalScore: vendorPersonalScore.reducer,
  criteria: criteria.reducer,
  criteriaModal: criteriaModal.reducer,
  criterion: combineReducers({
    criterionScorecadGet: criterionScorecadGet.reducer,
    criterionModalGet: criterionModalGet.reducer,
    criterionCreate: criterionCreate.reducer,
    criterionEdit: criterionEdit.reducer,
    criterionEditSimple: criterionEditSimple.reducer,
    criterionDelete: criterionDelete.reducer,
    criterionPreDeleteData: criterionPreDeleteData.reducer,
  }),
  questions: questionsReducer,
  settings: settingsReducer,
  characteristic: characteristic.reducer,
  evaluate: evaluateReducer,
  notes: notesReducer,
  onboadingModal: onboardingModal.reducer,
  libraryGoals: modalLibraryGoals.reducer,
});
