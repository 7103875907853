import { IdField, MainFields } from '@types';

enum Names {
  'Requirements' = 'Requirements',
  'Matches' = 'Matches',
  'Evaluation' = 'Evaluation',
  'Finalization' = 'Finalization',
  'Implementation' = 'Implementation',
  'Go-Live' = 'Go-Live',
  'CloseAllPhases' = 'CloseAllPhases',
}

enum Statuses {
  current = 'current',
  done = 'done',
  next = 'next',
}

const SubscriptionStateVariants = {
  null: null,
  pro: 'pro',
  'pro+': 'pro+',
} as const;

type SubscriptionStateVariantsKeys = keyof typeof SubscriptionStateVariants;

export namespace TimeLineBar {
  export const NamesEnum = Names;
  export const StatusesEnum = Statuses;
  export const SubscriptionStateEnum = SubscriptionStateVariants;
  export type Names = keyof typeof NamesEnum;
  export type Statuses = keyof typeof StatusesEnum;
  export type SubscriptionState = (typeof SubscriptionStateVariants)[SubscriptionStateVariantsKeys];

  export type Proposals = IdField & {
    meetableType: string;
    meetableId: number;
  };
  export type TooltipVideo = {
    source: string;
    image: string;
  };
  export type TodoItemProperties = {
    type?: string;
    video?: TooltipVideo;
  };

  export type TodoItem = MainFields & {
    properties: TodoItemProperties;
    completed: boolean;
    proposals: Proposals[];
    requiredSubscription?: SubscriptionState;
  };

  export type Status<S = Statuses> = {
    status: S;
  };

  export type Phase = MainFields<Status, Names> & {
    todos: ToDo[];
    finishDate: string | null;
  };

  export type ToDo = MainFields<Status> & {
    requiredSubscription?: SubscriptionState;
    description?: string;
  };
}
