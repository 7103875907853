import { useMemo } from 'react';

import { checkAllStatusesResponse } from '@helpers';
import { useEvent } from '@hooks';
import { UseState } from '@types';
import { useFetchProject } from '@api/@hooks';

import { advisorProperties, vendorsProperties } from '../@config';
import { useFetchAdvisor } from '../@hook';
import { TimeLineBar } from '../../@types';

type UseCheckTodoItemPropertiesProps = {
  setIsPopoverData: UseState<boolean>;
};

const useCheckTodoItemProperties = ({ setIsPopoverData }: UseCheckTodoItemPropertiesProps) => {
  const [, fetchAdvisor] = useFetchAdvisor();
  const [, fetchProject] = useFetchProject();

  const checkTypeTodoItem = useEvent(async (todoItems: TimeLineBar.TodoItem[]) => {
    const hasFetchProp = todoItems.reduce(
      (acc, item) => {
        const hasVendorsProp = vendorsProperties.includes(item?.properties?.type ?? '');
        const hasAdvisorProp = advisorProperties.includes(item?.properties?.type ?? '');
        return {
          vendor: hasVendorsProp || acc.vendor,
          advisor: hasAdvisorProp || acc.advisor,
        };
      },
      { vendor: false, advisor: false },
    );

    const fectches = [
      hasFetchProp.vendor ? fetchProject() : Promise.resolve({ status: 200 }),
      hasFetchProp.advisor ? fetchAdvisor() : Promise.resolve({ status: 200 }),
    ];

    const result = await Promise.all(fectches);
    setIsPopoverData(checkAllStatusesResponse(result));
  });

  return useMemo(() => ({ checkTypeTodoItem }), [checkTypeTodoItem]);
};

export default useCheckTodoItemProperties;
