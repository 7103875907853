import { PermissionsRules } from './permissions';

enum ABILITY {
  USE = 'USE',
}

export default ABILITY;

export type AbilityType = keyof typeof ABILITY;

export type AbilitiesType = Partial<Record<AbilityType, PermissionsRules>>;
