import React, { useMemo } from 'react';

import Tooltip from 'components/@ui/Tooltip';
import Typography from 'components/@ui/Typography';
import When from 'components/@main/When';
import { useUser } from '@hooks';

import { useNavbar } from '../@hooks';

const ProjectName: React.FC = () => {
  const { projectName } = useUser();
  const { isOpenNavbar } = useNavbar();

  return useMemo(
    () => (
      <When condition={isOpenNavbar} disabledWrapper>
        <Tooltip
          hideWhenContentFits
          title={projectName}
          placement="bottom"
          PopperProps={{ sx: { '.MuiTooltip-tooltip': { ml: '-24px !important' } } }}
        >
          <Typography
            fontSize={13}
            lineHeight="16px"
            color="gray.main"
            mt={1}
            px={3}
            textOverflow="ellipsis"
            overflow="hidden"
            maxWidth={250}
          >
            {projectName}
          </Typography>
        </Tooltip>
      </When>
    ),
    [isOpenNavbar, projectName],
  );
};

export default ProjectName;
