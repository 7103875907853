import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAPI } from '@hooks';

import { projectGoals, selectors } from '../@slices';
import { FetchProjectGoalsProps } from '../global';

const selector = createSelector(selectors.global, (state) => state.projectGoals);

const useFetchProjectGoals = () => {
  return useAPI(
    useCallback((params?: FetchProjectGoalsProps) => projectGoals.action(params), []),
    selector,
  );
};

export default useFetchProjectGoals;
