import React from 'react';
import Dialog from 'components/@ui/Dialog';
import Button from 'components/@ui/Button';
import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';
import Image from 'components/@ui/Image';
import { UseOpenModal } from '@hooks';

const getPopupPositionProperties = ({ width = 600, height = 600 }) => {
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;
  return `left=${left},top=${top},width=${width},height=${height}`;
};

export const closeAction = (action: () => void) => () => action();

export const LOGOUT_ENDPOINT = 'https://linkedin.com/m/logout';
export const logoutLinkedin =
  (url: string, closeAction?: () => void, openFlowAction?: () => void) => () => {
    closeAction?.();
    const win = window.open(url, '_blank', getPopupPositionProperties({ width: 600, height: 600 }));

    setTimeout(() => {
      win?.close();
      openFlowAction?.();
    }, 3000);
  };

const DoalogConfirmLogOut: React.FC<UseOpenModal> = ({ handleOpen, handleClose, open }) => {
  return (
    <Dialog open={open} PaperProps={{ sx: { p: 3, maxWidth: 450 } }}>
      <Box display="flex" gap={1.5} mb={6}>
        <Box mx={1.5}>
          <Image width={77} height={72} src={require('assets/modal/warning.png')} />
        </Box>
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Typography component="h3" semiBold fontSize={24} lineHeight={32 / 24}>
            Are you sure you want to log out of LinkedIn?
          </Typography>
          <Typography component="h2" semiBold>
            The system logs you out of all your current LinkedIn sessions
          </Typography>
          <Typography color="gray.main">
            After the system logs you out, click on “Sign In with LinkedIn“ again to Log in with the
            correct email
          </Typography>
        </Box>
      </Box>
      <Button onClick={handleOpen}>Confirm</Button>
      <Button onClick={handleClose} variant="text" color="inherit">
        Cancel
      </Button>
    </Dialog>
  );
};

export default React.memo(DoalogConfirmLogOut);
