import { useCallback } from 'react';
import { useMount, useUpdateEffect } from 'react-use';
import { matchPath, useHistory } from 'react-router-dom';
import { usePageVisibility } from 'react-page-visibility';
import { AxiosResponse } from 'axios';

import { useSessionExpired } from '@redux/slices';
import { ROUTES } from '@routes/config';
import { useIsLogin, useOnline, useSignOut } from '@hooks';
import { Expansion } from '@types';

import storage from './storage';
import projectIdStorage from './projectId.storage';

export type UserTokenValues<T = Expansion> = {
  client?: string | null;
  expiry?: string | null;
  token?: string | null;
  uid?: string | null;
} & T;

const CLIENT_KEY = 'os_client';
const EXPIRY_KEY = 'os_expiry';
const TOKEN_KEY = 'os_token';
const UID_KEY = 'os_uid';

const getUserTokenValues = () => {
  const client = storage.getItem<string>(CLIENT_KEY);
  const expiry = storage.getItem<string>(EXPIRY_KEY);
  const token = storage.getItem<string>(TOKEN_KEY);
  const uid = storage.getItem<string>(UID_KEY);
  return { client, expiry, token, uid };
};

const setUserTokenValues = (userTokenValues: UserTokenValues) => {
  const { client, expiry, token, uid } = userTokenValues;
  client && storage.setItem(CLIENT_KEY, client);
  expiry && storage.setItem(EXPIRY_KEY, expiry);
  token && storage.setItem(TOKEN_KEY, token);
  uid && storage.setItem(UID_KEY, uid);
};

const removeUserTokenValues = () => {
  storage.removeItem(CLIENT_KEY);
  storage.removeItem(EXPIRY_KEY);
  storage.removeItem(TOKEN_KEY);
  storage.removeItem(UID_KEY);
};

const getToken = () => getUserTokenValues().token;

const getExpiry = () => getUserTokenValues().expiry;

/**
 * is the token still alive.
 * `delta` token validation delay difference to account for request time in miliseconds.
 * @default 6000
 */
const isTokenAlive = (delta = 6e3) => {
  return Boolean(getToken()) && Number(getExpiry()) * 1000 >= Date.now() + delta;
};

const setTokenHeaders = (headers: AxiosResponse['headers']) => {
  setUserTokenValues({
    client: headers['client'],
    expiry: headers['expiry'],
    token: headers['access-token'],
    uid: headers['uid'],
  });
};

const useWatch = (fetchUpdate: (disableLogin?: boolean) => Promise<void>) => {
  const history = useHistory();
  const signOut = useSignOut();
  const isVisible = usePageVisibility();
  const isOnline = useOnline();
  const isLogin = useIsLogin();
  const [{ records }] = useSessionExpired();

  useUpdateEffect(() => {
    if (records.isSessionExpired) {
      if (isLogin) {
        signOut();
        history.push(ROUTES.auth.login.root);
        history.replace(ROUTES.auth.login.root);
      }
    }
  }, [records]);

  useUpdateEffect(() => {
    const pagesDisableLogin = matchPath(history.location.pathname, {
      path: [ROUTES.auth.signUp.verify, ROUTES.auth.signUp.editEmail, ROUTES.invitations.verify],
      exact: true,
    });
    if (isVisible && isOnline) {
      fetchUpdate(Boolean(pagesDisableLogin));
    }
  }, [isVisible]);

  const onStorageChange = useCallback(
    (e: StorageEvent) => {
      if (e.key === projectIdStorage.storageKey) {
        isLogin && window.location.reload();
      }
    },
    [isLogin],
  );

  useMount(() => {
    window.addEventListener('storage', onStorageChange);
    return () => window.removeEventListener('storage', onStorageChange);
  });
};

export default {
  useWatch,
  getUserTokenValues,
  setUserTokenValues,
  setTokenHeaders,
  removeUserTokenValues,
  getToken,
  getExpiry,
  isTokenAlive,
  storageKeys: {
    expiryKey: CLIENT_KEY,
    clientKey: EXPIRY_KEY,
    tokenKey: TOKEN_KEY,
    uidKey: UID_KEY,
  } as const,
};
