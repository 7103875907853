import React from 'react';

import Button, { ButtonProps } from 'components/@ui/Button';
import { clsx, parseSx } from '@helpers';

const ResendButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      variant="text"
      children="Resend"
      {...props}
      sx={clsx({ ml: 1, height: 24, px: 0.5, py: 0 }, ...parseSx(props.sx))}
    />
  );
};

export default React.memo(ResendButton);
