import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAPI, useUser } from '@hooks';

import { projectGet, selectors } from '../@slices';

const selectorProjectGet = createSelector(selectors.project, (state) => state.projectGet);

const useFetchProject = () => {
  const { projectId } = useUser();

  return useAPI(
    useCallback(
      (projectIdGlobal?: number) => projectGet.action(projectIdGlobal ?? projectId ?? 0),
      [projectId],
    ),
    selectorProjectGet,
  );
};

export default useFetchProject;
