import { useCallback, useMemo, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import { makeMapping, ProductsPDF, sortWorkspace } from 'components/@common/@ProductsPDFConfig';
import { checkAllStatusesResponse } from '@helpers';
import { filterAndConstructActualProjectMappings } from 'components/@common/ProcessMapping';

import { useFetchProject, useFetchWorkspaceProducts } from '../@hooks';

type UseWorkspaceProductsPDF = {
  isFinalized: boolean;
  isSaving: boolean;
  generatePDFDocument: (fileName?: string) => () => void | Promise<void>;
};

const useWorkspaceProductsPDF = () => {
  const [, fetchWorkspaceProducts] = useFetchWorkspaceProducts();
  const [{ records: recordsProject }, fetchProject] = useFetchProject();
  const [isSaving, setIsSaving] = useState(false);

  const onFetchGeneratePDFDocument = useCallback(async () => {
    const response = await Promise.all([fetchProject(), fetchWorkspaceProducts()]);
    const [responseProject, responseWorkspaceProducts] = response;
    if (checkAllStatusesResponse(response, 'gte')) return undefined;
    return {
      name: responseProject.data.project.name,
      list: sortWorkspace(
        responseProject.data.project.chosenProduct,
        responseWorkspaceProducts.data,
      ),
      mapping: makeMapping(
        filterAndConstructActualProjectMappings(
          responseProject.data.project.projectMappings,
          responseProject.data.project.mandatoryModules,
          responseProject.data.project.optionalModules,
        ),
      ),
      isFinalized: responseProject.data.project.finalised,
      chosenProduct: responseProject.data.project.chosenProduct,
    };
  }, [fetchProject, fetchWorkspaceProducts]);

  const generatePDFDocument = useCallback(
    (fileName?: string) => async () => {
      if (isSaving) return undefined;
      setIsSaving(true);
      const result = await onFetchGeneratePDFDocument();
      if (result) {
        const blob = await pdf(
          <ProductsPDF
            projectMappings={result.mapping}
            list={result.list}
            name={result.name}
            isFinalized={result.isFinalized}
            chosenProduct={result.chosenProduct}
            pdfFileTitle="Vendor Workspace Report"
          />,
        ).toBlob();
        saveAs(blob, fileName ?? 'Outsail_Vendor_Workspace_Report.pdf');
        setIsSaving(false);
      }
    },
    [isSaving, onFetchGeneratePDFDocument],
  );

  return useMemo<UseWorkspaceProductsPDF>(
    () => ({
      isFinalized: recordsProject.project.finalised,
      isSaving,
      generatePDFDocument,
    }),
    [generatePDFDocument, isSaving, recordsProject.project.finalised],
  );
};

export default useWorkspaceProductsPDF;
