import React from 'react';

import TableCell, { TableCellProps } from 'components/@ui/TableCell';
import { clsx, parseSx } from '@helpers';

const TableHeaderEmptyCell: React.FC<TableCellProps> = (props) => (
  <TableCell
    {...props}
    sx={clsx(
      { position: 'sticky', left: 0, zIndex: 16, bgcolor: 'secondary.main' },
      ...parseSx(props.sx),
    )}
  />
);

export default React.memo(TableHeaderEmptyCell);
