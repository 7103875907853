import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAPI, useProject } from '@hooks';

import { addToWorkspace, deleteFromWorkspace, selectors } from '../@slices';

export type ActoinTypeWorkspace = 'add' | 'delete';

const action = {
  add: addToWorkspace.action,
  delete: deleteFromWorkspace.action,
};

const selector = {
  add: createSelector(selectors.global, (state) => state.addToWorkspace),
  delete: createSelector(selectors.global, (state) => state.deleteFromWorkspace),
};

const useFetchChangeWorkspace = (type: ActoinTypeWorkspace) => {
  const { id: projectId } = useProject();

  return useAPI(
    useCallback((vendorId: number) => action[type](projectId, vendorId), [projectId, type]),
    selector[type],
  );
};

export default useFetchChangeWorkspace;
