import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import Icon from 'components/@ui/Icon';
import ListItemIcon from 'components/@ui/ListItemIcon';
import ListItemText from 'components/@ui/ListItemText';
import { useEvent } from '@hooks';
import { ROUTES } from '@routes/config';

import MenuItem from './MenuItem';
import { useNavbar } from '../@hooks';

export type AddNewProjectProps = {
  onClosePopover: () => void;
};

const AddNewProject: React.FC<AddNewProjectProps> = ({ onClosePopover }) => {
  const history = useHistory();
  const { setIsOpenNavbarMobile } = useNavbar();

  const redirectTo = useEvent((route: string) => () => {
    history.push(route, history.location.pathname);
    onClosePopover();
    setIsOpenNavbarMobile(false);
  });

  return useMemo(
    () => (
      <MenuItem onClick={redirectTo(ROUTES.addNewProject.activate)}>
        <ListItemIcon>
          <Icon name="plus" borderRadius={0} color="gray.dark" />
        </ListItemIcon>
        <ListItemText>Add new project</ListItemText>
      </MenuItem>
    ),
    [redirectTo],
  );
};

export default React.memo(AddNewProject);
