import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import Icon from 'components/@ui/Icon';
import RequestDemo from 'components/@main/RequestDemo';
import { useOpenModal } from '@hooks';
import { clsx } from '@helpers';
import { useFetchProject } from '@api/@hooks';

import { makeSX } from '../@hook';

const RequestDemoButton: React.FC = () => {
  const { open, handleOpen, handleClose } = useOpenModal();
  const [
    {
      records: {
        project: { submitted, workspacedProducts },
      },
    },
  ] = useFetchProject();

  return useMemo(
    () => (
      <>
        <Box
          display="flex"
          justifyContent={{ mobile: 'flex-end', tablet: 'flex-start', laptop: 'flex-end' }}
          alignItems="center"
          pl={{ mobile: 0, tablet: 4.5, laptop: 0 }}
        >
          <Button
            sx={clsx(submitted && makeSX.buttonStyle, {
              width: { mobile: '100%', tablet: 'auto', laptop: 'auto' },
              height: { mobile: 56, laptop: 32 },
              fontSize: { mobile: 18, laptop: 16 },
              lineHeight: { mobile: '32px', laptop: '24px' },
              my: { mobile: 1.5, laptop: 0 },
            })}
            onClick={handleOpen}
            disabled={submitted || workspacedProducts.length === 0}
            size="small"
            endIcon={
              <Icon
                name={submitted ? 'proposal-sent-icon-path' : 'send-mail-icon-path'}
                color="inherit"
              />
            }
          >
            {submitted ? 'Check email' : 'Request demo'}
          </Button>
        </Box>
        <RequestDemo open={open} handleClose={handleClose} />
      </>
    ),
    [submitted, open, workspacedProducts, handleOpen, handleClose],
  );
};
export default RequestDemoButton;
