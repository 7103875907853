import React from 'react';

import Tooltip from 'components/@ui/Tooltip';
import IconButton, { IconButtonProps } from 'components/@ui/IconButton';
import Icon from 'components/@ui/Icon';
import Button, { ButtonProps } from 'components/@ui/Button';
import { clsx, parseSx } from '@helpers';

import ShortlistButtonHOC, { ShortlistButtonHOCWrapperType } from './ShortlistedButtonHOC';
import { switchShortlistIconName } from './@helpers';

type AddOrDeleteShortlistProps = ShortlistButtonHOCWrapperType & {
  hideToolTip?: boolean;
};

type AddOrDeleteShortlistIconButtonProps = AddOrDeleteShortlistProps & {
  iconButtonProps?: Partial<IconButtonProps>;
};

export const AddOrDeleteShortlistIconButton: React.FC<
  Partial<AddOrDeleteShortlistIconButtonProps>
> = ({
  iconButtonProps,
  hideToolTip,
  isShortlisted,
  isLoadingState,
  isDisabled,
  tooltipTitle,
  handleClick,
  open,
  handleOpen,
  handleClose,
}) => (
  <Tooltip
    disableInteractive
    title={!hideToolTip && tooltipTitle}
    placement="right"
    maxWidth={170}
    open={open}
    onOpen={handleOpen}
    onClose={handleClose}
  >
    <div style={{ height: '100%' }}>
      <IconButton
        disabled={(isDisabled && !isShortlisted) || isLoadingState}
        iconName={switchShortlistIconName(isShortlisted, isDisabled, isLoadingState)}
        onClick={handleClick}
        {...iconButtonProps}
        sx={clsx(
          {
            width: '100%',
            height: '100%',
            borderRadius: 0,
            '&:disabled': {
              color: 'primary.light',
              opacity: 1,
              'i::before': { color: 'primary.light' },
            },
          },
          ...parseSx(iconButtonProps?.sx),
        )}
        IconProps={{
          sx: clsx({ color: 'inherit' }, ...parseSx(iconButtonProps?.IconProps?.sx)),
          ...iconButtonProps?.IconProps,
        }}
      />
    </div>
  </Tooltip>
);

type AddOrDeleteShortlistIconTextButtonProps = AddOrDeleteShortlistProps & {
  buttonProps?: ButtonProps;
};

export const AddOrDeleteShortlistIconTextButton: React.FC<
  Partial<AddOrDeleteShortlistIconTextButtonProps>
> = ({
  buttonProps,
  hideToolTip,
  isShortlisted,
  isLoadingState,
  isDisabled,
  tooltipTitle,
  handleClick,
  open,
  handleOpen,
  handleClose,
}) => (
  <Tooltip
    disableInteractive
    title={!hideToolTip && tooltipTitle}
    placement="right"
    maxWidth={170}
    open={open}
    onOpen={handleOpen}
    onClose={handleClose}
  >
    <div>
      <Button
        variant="text"
        onClick={handleClick}
        disabled={(isDisabled && !isShortlisted) || isLoadingState}
        startIcon={
          <Icon
            name={switchShortlistIconName(isShortlisted, isDisabled, isLoadingState)}
            color="inherit"
          />
        }
        {...buttonProps}
        sx={clsx(
          {
            height: 32,
            px: 1.5,
            py: 0.5,
            '&:disabled': { color: 'primary.light', opacity: 1 },
          },
          ...parseSx(buttonProps?.sx),
        )}
      >
        {isShortlisted ? 'Shortlisted' : 'Add to Shortlist'}
      </Button>
    </div>
  </Tooltip>
);

export const AddOrDeleteShortlistIconButtonWithHOC = ShortlistButtonHOC(
  React.memo(AddOrDeleteShortlistIconButton),
);

export const AddOrDeleteShortlistIconTextButtonWithHOC = ShortlistButtonHOC(
  React.memo(AddOrDeleteShortlistIconTextButton),
);
