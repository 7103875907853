import React from 'react';

import Typography from 'components/@ui/Typography';
import When from 'components/@main/When';
import { Children } from '@types';

import { PhotoEditor, usePhotoCreator } from '../PhotoCreator';

const EditPhotoWrapper: React.FC<Children> = React.memo(({ children }) => {
  const { isEditing } = usePhotoCreator();
  return (
    <When condition={isEditing} disabledWrapper fallback={children}>
      <Typography component="h2" extraBold fontSize={24} lineHeight={32 / 24} textAlign="center">
        Edit photo
      </Typography>
      <PhotoEditor editorWrapperProps={{ style: { overflowX: 'auto' } }} />
    </When>
  );
});

export default EditPhotoWrapper;
