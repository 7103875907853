import React, { useCallback, useMemo } from 'react';

import Box from 'components/@ui/Box';
import Image from 'components/@ui/Image';
import Button from 'components/@ui/Button';
import Typography from 'components/@ui/Typography';

import { useFetchAdvisor } from '../@hook';

type SkipToDoConfirmModalProps = {
  handleClose: () => void;
  confirm: () => void;
};

const SkipTodoConfirmModal: React.FC<SkipToDoConfirmModalProps> = ({ handleClose, confirm }) => {
  const handleConfirm = useCallback(() => {
    handleClose();
    confirm();
  }, [confirm, handleClose]);

  const [
    {
      records: { advisor },
    },
  ] = useFetchAdvisor();

  return useMemo(
    () => (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding={3}
        width={1}
        maxWidth={400}
        boxShadow="0px 10px 30px 0px #26334D0D"
        borderRadius={4}
        bgcolor="secondary.main"
      >
        <Typography color="gray.dark" semiBold ml={1.25}>
          Are you sure you want to skip this to-do item?
        </Typography>
        <Box
          display="flex"
          flexDirection={{ mobile: 'column', table: 'column', laptop: 'row' }}
          mt={3}
        >
          <Image src={advisor.avatarUrl} alt="avatar" width={140} height={168} duration={0} />
          <Typography
            color="gray.dark"
            ml={{ mobile: 0, tablet: 0, laptop: 3 }}
            mt={{ mobile: 3, tablet: 3, laptop: 0 }}
          >
            Your OutSail advisor, {advisor.name}, has helped over 700+ businesses find the right
            HRIS and can share additional guidance and recommendations with your team
          </Typography>
        </Box>

        <Button fullWidth sx={{ marginTop: 3 }} onClick={handleConfirm}>
          Yes, skip this to-do item
        </Button>
        <Button onClick={handleClose} fullWidth variant="text">
          Cancel
        </Button>
      </Box>
    ),
    [handleConfirm, handleClose, advisor],
  );
};

export default SkipTodoConfirmModal;
