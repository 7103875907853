import { combineReducers } from 'redux';

import { CA, P, createS } from '@redux/slices';

import {
  fetchAssignClientToProject,
  fetchMinGoLiveDate,
  fetchProject,
  fetchProjectCreate,
  fetchProjectUpdate,
} from '../project';

const SUBTITLE = 'PROJECT';

export const projectCreate = createS(CA(P('CREATE', SUBTITLE)), fetchProjectCreate, {
  initialState: { project: {} },
});
export const projectGet = createS(CA(P('GET', SUBTITLE)), fetchProject, {
  initialState: { project: {} },
});
export const projectUpdate = createS(CA(P('UPDATE', SUBTITLE)), fetchProjectUpdate, {
  initialState: { project: {} },
});
export const assignClientToProject = createS(
  CA(P('ASSIGN_CLIENT_TO_PROJECT')),
  fetchAssignClientToProject,
);
export const minGoLiveDate = createS(CA(P('MIN_GO_LIVE_DATE')), fetchMinGoLiveDate);

export default combineReducers({
  projectCreate: projectCreate.reducer,
  projectGet: projectGet.reducer,
  projectUpdate: projectUpdate.reducer,
  minGoLiveDate: minGoLiveDate.reducer,
});
