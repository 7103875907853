import React from 'react';

import Box, { BoxProps } from 'components/@ui/Box';

const TimeLineLayout: React.FC<BoxProps> = (props) => {
  return (
    <Box
      component="section"
      height={60}
      boxShadow={3}
      position="relative"
      top={0}
      zIndex={199}
      display="flex"
      minHeight="100%"
      flexDirection="row"
      alignItems="center"
      {...props}
    />
  );
};

export default React.memo(TimeLineLayout);
