import React, { useMemo } from 'react';

import Box from 'components/@ui/Box';
import { Children } from '@types';
import { useIsTablet } from '@hooks';

import { usePhases } from '../../Phases';
import { usePhase } from '../Context';

const PhaseBox: React.FC<Children> = ({ children }) => {
  const { phase, index } = usePhase();
  const { refState } = usePhases();
  const [, setRef] = refState;
  const isTablet = useIsTablet();

  return useMemo(
    () => (
      <Box
        width={{ mobile: '100%', laptop: 'auto' }}
        ref={(ref) => setRef(phase.name, ref)}
        pr={1}
        py={1.5}
        {...(index === 0 && { pl: 1 })}
        {...(isTablet && {
          p: 0,
          bgcolor: 'inherit',
          borderRadius: 10,
        })}
      >
        {children}
      </Box>
    ),
    [children, index, phase.name, setRef, isTablet],
  );
};

export default PhaseBox;
