import React from 'react';

import TableRow from 'components/@ui/TableRow';
import When from 'components/@main/When';
import { useIsTablet } from '@hooks';
import { Children } from '@types';

import Reminders from '../Reminders';
import {
  EmptyCell,
  EmptyRow,
  TableHeaderEmptyCell,
  TabletHeatMapHeaderRowTitle,
} from '../VendorsComponents';

const TableHeaderRow: React.FC<Children> = ({ children }) => {
  const isTablet = useIsTablet();

  return (
    <>
      <EmptyRow />
      <TableRow>
        <When
          disabledWrapper
          condition={isTablet}
          fallback={
            <TableHeaderEmptyCell>
              <Reminders />
            </TableHeaderEmptyCell>
          }
        >
          <TabletHeatMapHeaderRowTitle />
        </When>
        <EmptyCell />
        {children}
      </TableRow>
    </>
  );
};

export default React.memo(TableHeaderRow);
