import React from 'react';

import TableRow from 'components/@ui/TableRow';
import TableCell from 'components/@ui/TableCell';

const EmptyRow: React.FC = () => {
  return (
    <TableRow sx={{ height: 24 }}>
      <TableCell
        component="th"
        scope="row"
        sx={{ position: 'sticky', left: 0, zIndex: 5, bgcolor: 'secondary.main', p: 0 }}
      />
    </TableRow>
  );
};

export default EmptyRow;
