import React, { useCallback } from 'react';

import Box from 'components/@ui/Box';
import TypographyMobile from 'components/@ui/TypographyMobile';
import AbilityControl from '@permissions';
import { Children } from '@types';

import { MobileVendorHeaderIconWrapper } from '../VendorsComponents';
import {
  AddOrDeleteShortlistIconButtonWithHOC,
  AddToWorkplaceIconButtonWithHOC,
} from '../VendorComponents';

type MobileVendorHeaderProps = {
  vendorId: number;
  vendorName: string;
  open?: boolean;
  handleOpen?: () => void;
  handleClose?: () => void;
  AddOrDeleteShortlistedComponent?: React.FC<any>;
  AddToWorkplaceButtonComponent?: React.FC<any>;
};

const MobileVendorHeader: React.FC<MobileVendorHeaderProps & Children> = ({
  vendorId,
  vendorName,
  open,
  handleOpen,
  handleClose,
  AddOrDeleteShortlistedComponent = AddOrDeleteShortlistIconButtonWithHOC,
  AddToWorkplaceButtonComponent = AddToWorkplaceIconButtonWithHOC,
  children,
}) => {
  const handleClick = useCallback(
    () => (open ? handleClose?.() : handleOpen?.()),
    [open, handleClose, handleOpen],
  );

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <MobileVendorHeaderIconWrapper open={open} handleClick={handleClick}>
        <TypographyMobile
          semiBold
          fontSize={24}
          lineHeight="32px"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {vendorName}
        </TypographyMobile>
      </MobileVendorHeaderIconWrapper>
      <Box display="flex">
        <AddOrDeleteShortlistedComponent
          vendorId={vendorId}
          hideToolTip
          iconButtonProps={{ sx: { width: 48, height: 48, borderRadius: 1 } }}
        />
        <AbilityControl permission="VENDORS_ADD_VENDOR_TO_WORKSPACE">
          <AddToWorkplaceButtonComponent
            iconButtonProps={{ sx: { width: 48, height: 48, borderRadius: 1 } }}
            vendorId={vendorId}
            hideToolTip
          />
        </AbilityControl>
        {children}
      </Box>
    </Box>
  );
};

export default React.memo(MobileVendorHeader);
