import React, { useRef } from 'react';
import { toast } from 'react-toastify';

import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import Icon from 'components/@ui/Icon';
import IconButton from 'components/@ui/IconButton';
import Typography from 'components/@ui/Typography';
import When from 'components/@main/When';

import { usePhotoCreator } from './Context';

export type PhotoUploaderProps = {
  fileSize?: number;
};

const PhotoUploader: React.FC<PhotoUploaderProps> = ({ fileSize = 10 }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { setUploadedFile, isEditing } = usePhotoCreator();

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file?.type.includes('image/')) {
      if (file.size < fileSize * 1.02 * 1024 * 1024) {
        setUploadedFile(file);
      } else {
        toast.error('File is too big!');
      }
    } else {
      toast.error('File upload error!');
    }
  };

  return (
    <Box display="flex" alignItems="center" position="relative">
      <When disabledWrapper condition={!isEditing}>
        <input
          type="file"
          ref={inputRef}
          accept="image/png, image/jpeg, image/jpg"
          style={{ display: 'none', position: 'absolute', width: 0, height: 0 }}
          onChange={handleChange}
        />
      </When>
      <IconButton
        iconName="plus"
        IconProps={{ color: 'blue.main' }}
        sx={{ width: 80, height: 80, bgcolor: 'dropdownHover.light' }}
        onClick={handleUpload}
      />
      <Box display="flex" flexDirection="column" ml={1.25}>
        <Button
          size="small"
          variant="text"
          endIcon={<Icon name="cloud-download-icon-path" />}
          sx={{ px: 1.5, width: 'fit-content' }}
          onClick={handleUpload}
        >
          Upload photo
        </Button>
        <Typography fontSize={13} mt={0.5} ml={1.5}>
          jpg, jpeg, png (max 5 MB)
        </Typography>
      </Box>
    </Box>
  );
};

export default PhotoUploader;
