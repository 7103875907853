import React, { useMemo } from 'react';
import { useToggle } from 'react-use';

import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import Icon from 'components/@ui/Icon';
import Collapse from 'components/@ui/Collapse';
import { Children } from '@types';

type FAQBlockWrapperProps = {
  title: string;
};

const FAQBlockWrapper: React.FC<FAQBlockWrapperProps & Children> = ({ children, title }) => {
  const [open, toogleOpen] = useToggle(false);

  return useMemo(
    () => (
      <Box
        borderRadius={7}
        padding="12px 24px"
        border={1}
        borderColor="dropdownHover.contrastText"
        display="flex"
        width={1}
        flexDirection="column"
      >
        <Button
          onClick={toogleOpen}
          size="small"
          variant="text"
          color="inherit"
          sx={{ p: 0, justifyContent: 'flex-start', '&:hover, &:focus': { bgcolor: 'unset' } }}
        >
          <Icon
            name={open ? 'minus' : 'plus'}
            fontSize={32}
            width={32}
            color="text.primary"
            mr={1.5}
          />
          {title}
        </Button>
        <Collapse in={open}>
          <Box mt={3}>{children}</Box>
        </Collapse>
      </Box>
    ),
    [toogleOpen, open, title, children],
  );
};

export default FAQBlockWrapper;
