import { NonEmptyString } from '@types';

const makeTitleFn = (title: string | undefined | null) => {
  if (title === undefined) return 'FETCH_';
  if (title === null) return '';
  return title + '_';
};

const makeSubTitleFn = (subTitle: string | undefined | null) => {
  if (!subTitle) return '';
  return subTitle + '_';
};

/**
 * `createPrefix` for current slise
 * @param name string `NonEmptyString`, last element
 *
 * @param subTitle string `NonEmptyString` | undefined, second element
 * @default ''

 * @param title string `NonEmptyString` | null | undefined, the first element
 * @default 'FETCH'
 *
 * @returns string, 
 * 
 * @example
 * ```typescript
 * p('GROUPS', 'TEAM_PAGE')      //=> FETCH_TEAM_PAGE_GROUPS
 * createPrefix('GROUPS')        //=> FETCH_GROUPS
 * p('GROUPS', 'TEAM_PAGE', 'F') //=> F_TEAM_PAGE_GROUPS
 * p('GROUPS', null, null')      //=> GROUPS
 * P('')                         //=> typescript error
 * ```
 */

const createPrefix = <
  N extends string,
  S extends string | undefined = undefined,
  T extends string | undefined | null = 'FETCH',
>(
  name: NonEmptyString<N>,
  subTitle?: S extends string ? NonEmptyString<S> : undefined,
  title?: T extends string ? NonEmptyString<T> : undefined | null,
) => {
  type SubTitle = S extends undefined | null ? '' : `${S}_`;
  type Title = T extends null ? '' : T extends undefined ? 'FETCH_' : `${T}_`;
  const makeSubTitle = makeSubTitleFn(subTitle) as SubTitle;
  const makeTitle = makeTitleFn(title) as Title;
  return `${makeTitle}${makeSubTitle}${name}` as const;
};

export default createPrefix;
