import React from 'react';

import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';

import DemoRequestVendorBox from './DemoRequestVendorBox';
import { useRequestDemoModal } from './@hooks';

const DemoRequestVendorsContainer: React.FC = () => {
  const { checkBoxState } = useRequestDemoModal();

  return (
    <>
      <Box display="flex" mt={3}>
        <Typography semiBold fontSize={13} lineHeight="normal" color="gray.main" width={145}>
          Vendor
        </Typography>
        <Typography semiBold fontSize={13} lineHeight="normal" color="gray.main" width={110}>
          Request demo
        </Typography>
        <Typography semiBold fontSize={13} lineHeight="normal" color="gray.main" width={100}>
          Already in touch
        </Typography>
      </Box>

      <Box
        mx="auto"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        flexWrap="wrap"
        columnGap={3}
        maxWidth={420}
      >
        {checkBoxState.map((product) => (
          <DemoRequestVendorBox
            name={product.productName}
            touched={product.alreadyInTouch}
            request={product.requestDemo}
            productId={product.productId}
            isDisabled={product.isDisabledRequest}
            key={product.productId}
          />
        ))}
      </Box>
    </>
  );
};

export default DemoRequestVendorsContainer;
