import { getUserLocales } from 'get-user-locale';

const userLocales = getUserLocales();

type FormatParams = Parameters<Intl.NumberFormat['format']>;
type NumberFormatParams = ConstructorParameters<typeof Intl.NumberFormat>;

export default (value: FormatParams[0], ...options: NumberFormatParams) => {
  const [locales = userLocales, numberFormatOptions] = options;
  return new Intl.NumberFormat(locales, numberFormatOptions).format(value);
};
