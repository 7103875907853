const advisorProperties = ['schedule advisor', 'advisor schedule', 'advisor request'];
const vendorsProperties = [
  'vendors schedule',
  'vendors proposal',
  'vendor-of-choice proposal',
  'vendor-of-choice request',
  'vendor-of-choice reference',
  'design demo',
  'initial vendors schedule',
];

const allProperties = [
  'advisor schedule',
  'advisor request',
  'design demo',
  'schedule advisor',
  'initial vendors schedule',
  'vendors schedule',
  'vendors proposal',
  'vendor-of-choice proposal',
  'vendor-of-choice request',
  'vendor-of-choice reference',
];

export { advisorProperties, allProperties, vendorsProperties };
