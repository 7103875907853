import { combineReducers } from 'redux';

import { createSlice } from '@redux/slices';

import {
  fetchAddFromListShortlist,
  fetchCharacteristicMatchModalData,
  fetchFeatureMatchModalData,
  fetchImplementationCost,
  fetchImplementationTimeline,
  fetchProsAndConsModalData,
  fetchRequirementsMatch,
  fetchSizeMatchModalData,
  fetchSoftwareCost,
  fetchVendorView,
} from '../@api';

export const vendorView = createSlice('FETCH_VENDOR_VIEW', fetchVendorView);
export const requirementsMatch = createSlice(
  'FETCH_VENDOR_PROFILE_REQUIREMENTS_MATCH',
  fetchRequirementsMatch,
);
export const softwareCosts = createSlice('FETCH_VENDOR_PROFILE_SOFTWARE_COST', fetchSoftwareCost);
export const implementationCost = createSlice(
  'FETCH_VENDOR_PROFILE_IMPLEMENTATION_COST',
  fetchImplementationCost,
);
export const implementationTimeline = createSlice(
  'FETCH_VENDOR_PROFILE_IMPLEMENTATION_TIMELINE',
  fetchImplementationTimeline,
);
export const sizeMatchScalabilityModal = createSlice(
  'FETCH_VENDOR_PROFILE_SIZE_SCALABILITY_MODAL',
  fetchSizeMatchModalData,
);
export const featureMatchModalData = createSlice(
  'FETCH_VENDOR_PROFILE_FEATURE_MODAL',
  fetchFeatureMatchModalData,
);
export const characteristicMatchModalData = createSlice(
  'FETCH_VENDOR_PROFILE_CHARACTERISTIC_MODAL',
  fetchCharacteristicMatchModalData,
);

export const prosAndConsModalData = createSlice(
  'FETCH_VENDOR_PROFILE_PROS_AND_CONS_MODAL',
  fetchProsAndConsModalData,
);

export const addShortlistFromList = createSlice(
  'FETCH_VENDOR_PROFILE_ADD_SHORTLIST_FROM_LIST',
  fetchAddFromListShortlist,
  { initialState: [] },
);

export default combineReducers({
  vendorView: vendorView.reducer,
  requirementsMatch: requirementsMatch.reducer,
  softwareCosts: softwareCosts.reducer,
  implementationCost: implementationCost.reducer,
  implementationTimeline: implementationTimeline.reducer,
  sizeMatchScalabilityModal: sizeMatchScalabilityModal.reducer,
  featureMatchModalData: featureMatchModalData.reducer,
  characteristicMatchModalData: characteristicMatchModalData.reducer,
  prosAndConsModalData: prosAndConsModalData.reducer,
  addShortlistFromList: addShortlistFromList.reducer,
});
