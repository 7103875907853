export const QUESTIONS_TEXT = {
  final: { goLiveDate: 'What is your target go-live date' },
} as const;

const QUESTIONS = [
  {
    id: '1',
    name: 'Payroll',
    title: 'Payroll processing requirements',
    questions: [
      {
        id: 1,
        question: 'What is your payroll processing frequency?',
        type: 'checkbox-adds',
        name: 'open_enrollment',
        answers: [
          { id: 1, value: 'Weekly' },
          { id: 2, value: 'Bi-Weekly' },
          { id: 3, value: 'Semi-Monthly' },
          { id: 4, value: 'Monthly' },
        ],
      },
      {
        id: 2,
        question: 'Does your payroll processing rely on importing data from other systems',
        type: 'radio-button',
        name: 'other_systems',
        answers: [
          { id: 1, value: 'No', input: false },
          { id: 'other', value: 'Yes (please specify)', input: true },
        ],
      },
      {
        id: 3,
        question: 'Which accounting / general ledger system do you use?',
        type: 'radio-button',
        name: 'general_ledger_system',
        answers: [
          { id: 1, value: 'NetSuite', input: false },
          { id: 2, value: 'Quickbooks ', input: false },
          { id: 3, value: 'Microsoft Dynamics', input: false },
          { id: 4, value: 'SAP', input: false },
          { id: 5, value: 'Sage Intacct ', input: false },
          { id: 6, value: 'Microsoft GP', input: false },
          { id: 7, value: 'Microsoft Business Central', input: false },
          { id: 'other', value: 'Other (please specify)', input: true },
        ],
      },
      {
        id: 4,
        question: 'Do you process payroll globally?',
        type: 'radio-button',
        name: 'process_globally',
        answers: [
          { id: 1, value: 'No', input: false },
          {
            id: 'other',
            value: 'Yes (please specify)',
            input: true,
          },
        ],
      },
      {
        id: 5,
        question: 'Are there any other payroll considerations to consider?',
        type: 'radio-button',
        name: 'payroll_considerations',
        answers: [
          { id: 1, value: 'No', input: false },
          { id: 'other', value: 'Yes (please specify)', input: true },
        ],
      },
    ],
  },
  {
    id: '3',
    name: 'Benefits Admin',
    title: 'Benefits Admin requirements',
    questions: [
      {
        id: 1,
        question: 'When is your next open enrollment?',
        type: 'calendar',
        answers: [],
      },
      {
        id: 2,
        question: 'Are there any carriers your HRIS will need to integrate with?',
        type: 'textarea',
        name: 'carriers',
        answers: [],
      },
      {
        id: 3,
        question: 'Are there any other benefits considerations to consider?',
        type: 'radio-button',
        name: 'other_benefits_considerations',
        answers: [
          { id: 1, value: 'No', input: false },
          { id: 'other', value: 'Yes (please specify)', input: true },
        ],
      },
    ],
  },
  {
    id: '4',
    name: 'Time & Attendance',
    title: 'Time & Attendance requirements',
    questions: [
      {
        id: 1,
        question: 'How would your team like to gather time data from hourly workers?',
        type: 'checkbox',
        name: 'time_data',
        answers: [
          { id: 1, value: 'Paper-based' },
          { id: 2, value: 'Online timesheets (desktop)' },
          { id: 3, value: 'Online timesheets (mobile app)' },
          { id: 4, value: 'Hardware clocks' },
          { id: 5, value: 'Kiosks' },
        ],
      },
      {
        id: 2,
        question: 'What additional features are required in your time tracking process?',
        type: 'checkbox-adds',
        name: 'time_tracking_process',
        answers: [
          {
            id: 1,
            value: 'Ability to create and assign schedules',
            input: false,
          },
          {
            id: 2,
            value: 'Employees paid multiple rates',
            input: false,
          },
          {
            id: 3,
            value: 'Biometric clock-in',
            input: false,
          },
          {
            id: 4,
            value: 'Geofencing',
            input: false,
          },
          {
            id: 5,
            value: 'Job costing',
            input: false,
          },
          {
            id: 6,
            value: 'Grant management / journal entries',
            input: false,
          },
          {
            id: 'other',
            value: 'Other (please specify)',
            input: true,
          },
        ],
      },
    ],
  },
  {
    id: '5',
    name: 'Onboarding',
    title: 'Onboarding requirements',
    questions: [
      {
        id: 1,
        question: 'What tasks need to be completed during onboarding',
        type: 'checkbox-adds',
        name: 'completed_tasks',
        answers: [
          {
            id: 1,
            value: 'Complete I-9',
            input: false,
          },
          {
            id: 2,
            value: 'Complete W-4',
            input: false,
          },
          {
            id: 3,
            value: 'Review employee handbook',
            input: false,
          },
          {
            id: 4,
            value: 'Compliance trainings',
            input: false,
          },
          {
            id: 5,
            value: 'Build employee profile',
            input: false,
          },
          {
            id: 6,
            value: 'Access to other employee applications',
            input: false,
          },
          {
            id: 7,
            value: 'Onboarding / training content',
            input: false,
          },
          {
            id: 'other',
            value: 'Other (please specify)',
            input: true,
          },
        ],
      },
    ],
  },
  {
    id: '6',
    name: 'Performance',
    title: 'Performance requirements',
    questions: [
      {
        id: 1,
        question: 'How often would you like to run performance reviews?',
        type: 'radio-button',
        name: 'performance_reviews',
        answers: [
          { id: 1, value: 'Annually', input: false },
          { id: 2, value: 'Semi-annually', input: false },
          { id: 3, value: 'Quarterly', input: false },
          { id: 4, value: 'Not administered', input: false },
          {
            id: 'other',
            value: 'Other (please specify)',
            input: true,
          },
        ],
      },
      {
        id: 2,
        question: 'What features are important in your review process?',
        type: 'checkbox-adds',
        name: 'features_review_process',
        answers: [
          {
            id: 1,
            value: 'Custom questions for different groups',
            input: false,
          },
          {
            id: 2,
            value: 'Ability to track company goals and cascade those down',
            input: false,
          },
          {
            id: 3,
            value: 'Nine-box reporting, support for manager 1:1s',
            input: false,
          },
          { id: 4, value: '360 reviews', input: false },
          {
            id: 5,
            value: 'Ability to support multiple reviewers and non-linear reporting relationships',
            input: false,
          },
          {
            id: 'other',
            value: 'Other (please specify)',
            input: true,
          },
        ],
      },
    ],
  },
  {
    id: '8',
    name: 'Learning',
    title: 'Learning process details',
    questions: [
      {
        id: 1,
        question: 'What types of trainings would you like to administer?',
        type: 'checkbox-adds',
        name: 'types_of_trainings',
        answers: [
          {
            id: 1,
            value: 'Onboarding',
            input: false,
          },
          {
            id: 2,
            value: 'Compliance training',
            input: false,
          },
          {
            id: 3,
            value: 'Technical skill development',
            input: false,
          },
          {
            id: 4,
            value: 'Soft skill development',
            input: false,
          },
          {
            id: 5,
            value: 'External training to customers/partners',
            input: false,
          },
          {
            id: 'other',
            value: 'Other (please specify)',
            input: true,
          },
        ],
      },
    ],
  },
  {
    id: '9',
    name: 'ATS',
    title: 'Recruiting requirements',
    questions: [
      {
        id: 1,
        question: 'How many hires do you expect to make in the next 12 months',
        type: 'input',
      },
      {
        id: 2,
        question: 'Are there any recruiting vendors that your ATS needs to integrate with?',
        type: 'radio-button',
        name: 'recruiting_vendors',
        answers: [
          { id: 1, value: 'No', input: false },
          { id: 'other', value: 'Yes (please specify)', input: true },
        ],
      },
      {
        id: 3,
        question: 'What additional features are needed in your recruitment process?',
        type: 'checkbox-adds',
        name: 'additional_features',
        answers: [
          {
            id: 1,
            value: 'Candidate scorecard tools',
            input: false,
          },
          {
            id: 2,
            value: 'Candidate scheduling tools',
            input: false,
          },
          {
            id: 3,
            value: 'Unconscious bias prevention tools',
            input: false,
          },
          {
            id: 4,
            value: 'Video interviewing capabilities',
            input: false,
          },
          {
            id: 5,
            value: 'Screening questions',
            input: false,
          },
          {
            id: 6,
            value: 'Integrations with assessment',
            input: false,
          },
          {
            id: 7,
            value: 'Text recruiting',
            input: false,
          },
          {
            id: 8,
            value: 'Integrations with background check',
            input: false,
          },
          {
            id: 'other',
            value: 'Other (please specify)',
            input: true,
          },
        ],
      },
    ],
  },
  {
    id: 'additional',
    name: '',
    title: 'Additional Requirements',
    questions: [
      {
        id: 1,
        question:
          'Beyond the standard reports (OSHA, EEO-1, FMLA, ACA, etc) are there any specific reports that you will need to run in your system?',
        type: 'radio-button',
        name: 'specific_reports',
        answers: [
          { id: 1, value: 'No', input: false },
          { id: 'other', value: 'Yes (please specify)', input: true },
        ],
      },
      {
        id: 2,
        question:
          'Are there additional employee details that you will need to track in your HRIS system?',
        type: 'radio-button',
        name: 'additional_details',
        answers: [
          { id: 1, value: 'No', input: false },
          { id: 'other', value: 'Yes (please specify)', input: true },
        ],
      },
    ],
  },
  {
    id: 'final',
    name: '',
    title: 'Final Details',
    questions: [
      {
        id: 1,
        question: QUESTIONS_TEXT.final.goLiveDate,
        type: 'calendar',
        name: 'go-live-date',
        answers: [],
      },
      {
        id: 2,
        question:
          'Are there any additional questions or scenarios that you would like to have vendors cover in your meetings?',
        type: 'radio-button',
        name: 'additional_questions',
        answers: [
          { id: 1, value: 'No', input: false },
          { id: 'other', value: 'Yes (please specify)', input: true },
        ],
      },
    ],
  },
];

export default QUESTIONS;
