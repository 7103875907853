import { Selector } from 'react-redux';

import store, { RootState } from '@redux/store';
import { CreateSliceDataReturn } from '@redux/slices';
import { Update } from '@hooks';

export const updateSliceData = <T,>(slice: CreateSliceDataReturn<T>) => {
  return (records: T) => store.dispatch(slice.actions.FULFILLED<T>(records));
};

export const getSliceData = <T,>(selector: Selector<RootState, { records: T }>) => {
  return selector(store.getState());
};

const sliceData = <T,>(
  slice: CreateSliceDataReturn<T>,
  selector: Selector<RootState, { records: T }>,
) => {
  const data = getSliceData(selector);

  const update: Update<T> = (records) => {
    if (records instanceof Function) {
      const newRecords = records(data.records);
      updateSliceData(slice)(newRecords);
    } else {
      updateSliceData(slice)(records);
    }
  };

  return [getSliceData(selector), update] as const;
};

export default sliceData;
