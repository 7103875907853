import React from 'react';

import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';
import { useIsMobile } from '@hooks';

const BenefitOutSailInfo: React.FC = () => {
  const isMobile = useIsMobile();

  return (
    <Box
      display="flex"
      flexWrap={isMobile ? 'wrap' : 'nowrap'}
      gap="8px 0"
      justifyContent="space-between"
    >
      <Box>
        <Typography semiBold>Market Insights</Typography>
        <Box component="ul" sx={{ listStyle: 'disc', pl: 3 }}>
          <Typography component="li">50+ HRIS vendor profiles</Typography>
          <Typography component="li">User-reported pros & cons</Typography>
          <Typography component="li">Verified pricing estimates</Typography>
          <Typography component="li">Sorted by your goals & needs</Typography>
        </Box>
      </Box>
      <Box>
        <Typography semiBold>Evaluation Tools</Typography>
        <Box component="ul" sx={{ listStyle: 'disc', pl: 3 }}>
          <Typography component="li">Project plans & timelines</Typography>
          <Typography component="li">Shared scorecards</Typography>
          <Typography component="li">Suggested vendor questions</Typography>
          <Typography component="li">Team collaboration</Typography>
        </Box>
      </Box>
      <Box>
        <Typography semiBold>Advisory Services</Typography>
        <Box component="ul" sx={{ listStyle: 'disc', pl: 3 }}>
          <Typography component="li">Access to HRIS industry experts</Typography>
          <Typography component="li">Help with proposal review</Typography>
          <Typography component="li">Negotiation guidance</Typography>
          <Typography component="li">Implementation prep</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BenefitOutSailInfo;
