import { useCallback } from 'react';
import { ToastContent, toast } from 'react-toastify';
import { not } from 'ramda';

import { Common } from 'components/@common/Auth/@types';
import { useForm } from 'components/@main/Form';
import { APIResponse } from '@api';
import { useCountdownState, useSetTimeout } from '@hooks';
import { checkStatus } from '@helpers';
import { UseState } from '@types';

export type Fetch = () => APIResponse<any>;
export type OnFetchResendProps<T> = {
  disabled: boolean;
  setDisabled: UseState<boolean>;
  ready: boolean;
  fetch: T;
  toastText?: ToastContent<unknown>;
  showPrompt?: UseState<boolean>;
};

const useOnFetchResendCode = () => {
  const { start: countdownStart } = useCountdownState();
  const { setFocus } = useForm<Common.Code>();
  const { setTimeout } = useSetTimeout();

  return useCallback(
    <T extends Fetch>(props: OnFetchResendProps<T>) =>
      async () => {
        const {
          disabled,
          setDisabled,
          ready,
          fetch,
          toastText = 'The code has been resent',
          showPrompt,
        } = props;
        if (not(disabled || ready)) {
          showPrompt?.(false);
          setDisabled(true);
          const response = await fetch();
          if (checkStatus(response?.status)) {
            toast.success(toastText);
            setTimeout(() => {
              countdownStart();
              setFocus('code');
            }, 500);
          }
          setDisabled(false);
          setTimeout(() => showPrompt?.(true));
          return response;
        }
      },
    [countdownStart, setFocus, setTimeout],
  );
};

export default useOnFetchResendCode;
