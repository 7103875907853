import snakecaseKeys from 'snakecase-keys';

import baseAPI, { APIResponse } from '@api';
import { Project } from '@types';

const apiCredentials = baseAPI(true);

//--------------------------------------------------------------------------------------------------
// Project Get by id
//--------------------------------------------------------------------------------------------------
export type FetchProjectResponse = { project: Project.Project };
export function fetchProject(projectId: number): APIResponse<FetchProjectResponse> {
  return apiCredentials.get(`/projects/${projectId}`);
}
//--------------------------------------------------------------------------------------------------
// Project Create
//--------------------------------------------------------------------------------------------------
export type RequirementAttributesResponse =
  | 'payroll'
  | 'learning'
  | 'benefits'
  | 'additional'
  | 'attendance'
  | 'recruiting'
  | 'onboarding'
  | 'performance';
export type ProjectMappingsAttributesResponse = RequirementAttributesResponse | 'finalDetails';
export type FetchProjectBodyRequire = {
  name: string;
  website: string;
  currentHeadcount: number;
  futureHeadcount: number;
  topGoalIds: number[];
  mediumGoalIds: number[];
  mandatoryModuleIds: number[];
  optionalModuleIds: number[];
};
export type FetchProjectBodyMandatory = {
  finalDetails?: string;
  requirementAttributes?: Partial<Record<ProjectMappingsAttributesResponse, string | null>>;
  goLiveDate?: string | null;
};
export type FetchProjectCreateBody = FetchProjectBodyRequire & FetchProjectBodyMandatory;
export function fetchProjectCreate(
  props: FetchProjectCreateBody,
): APIResponse<FetchProjectResponse> {
  return apiCredentials.post(`/projects`, {
    project: snakecaseKeys(props),
  });
}
//--------------------------------------------------------------------------------------------------
// Project Update
//--------------------------------------------------------------------------------------------------
export type FetchProjectBodyUpdate = Partial<FetchProjectBodyRequire & FetchProjectBodyMandatory>;
export function fetchProjectUpdate(
  projectId: number,
  props: FetchProjectBodyUpdate,
): APIResponse<FetchProjectResponse> {
  return apiCredentials.put(`/projects/${projectId}`, {
    project: snakecaseKeys(props),
  });
}
//--------------------------------------------------------------------------------------------------
// Assign client to the Project, TODO: currently not used
//--------------------------------------------------------------------------------------------------
export function fetchAssignClientToProject(projectId: number) {
  return apiCredentials.post(`/project_clients`, { project_client: { project_id: projectId } });
}
//--------------------------------------------------------------------------------------------------
// Get Project min Go-live date
//-------------------------------------------------------------------------
export type FetchMinGoLiveDateResponse = { goLiveDate: string };
export function fetchMinGoLiveDate(projectId: number): APIResponse<FetchMinGoLiveDateResponse> {
  return apiCredentials.get(`/projects/${projectId}/go_live_date`);
}
//-------------------------------------------------------------------------
