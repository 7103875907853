const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const API_URL = process.env.REACT_APP_API_KEY;
const WS_URL = process.env.REACT_APP_WS_KEY ?? null;
const GA = process.env.REACT_APP_GA;
const GOOGLE_OAUTH = process.env.REACT_APP_GOOGLE_OAUTH;
const MICROSOFT_OAUTH_CLIENT_ID = process.env.REACT_APP_MICROSOFT_OAUTH_CLIENT_ID;
const LINKEDIN_OAUTH_CLIENT_ID = process.env.REACT_APP_LINKEDIN_OAUTH_CLIENT_ID;

const REG_EXP_EMAIL = /^([A-Za-z0-9_\-, +.])+@([A-Za-z0-9_\-, +.])+\.([A-Za-z]{2,4})$/;

const REG_EXP_URL = /^((ftp|http|https):\/\/|(www.))[^ "]+$/;

const MAX_WORKSPACE_ITEMS = 4;

const MAX_SHORTLISTED_ITEMS = 8;

const actualModulesIds = [1, 3, 4, 5, 6, 8, 9];

const OVERVIEW_MODULES = [
  {
    id: 1,
    name: 'payrollRequirements',
    title: 'Payroll processing requirements',
  },
  {
    id: 3,
    name: 'benefitsRequirements',
    title: 'Benefits Admin requirements',
  },
  {
    id: 4,
    name: 'attendanceRequirements',
    title: 'Time & Attendance requirements',
  },
  { id: 5, name: 'onboardingRequirements', title: 'Onboarding requirements' },
  {
    id: 6,
    name: 'performanceRequirements',
    title: 'Performance requirements',
  },
  { id: 8, name: 'learningRequirements', title: 'Learning process details' },
  { id: 9, name: 'recruitingRequirements', title: 'Recruiting requirements' },
  {
    id: 'additional',
    name: 'additionalRequirements',
    title: 'Additional Requirements',
  },
  { id: 'final', name: 'finalDetails', title: 'Final Details' },
];

const MIN_SIZE_DESKTOP_RESOLUTION = 1023;

const MAX_SIZE_MOBILE_RESOLUTION = 743;
const MIN_SIZE_MOBILE_RESOLUTION = 320;

export {
  SENTRY_DSN,
  API_URL,
  WS_URL,
  GA,
  GOOGLE_OAUTH,
  MICROSOFT_OAUTH_CLIENT_ID,
  LINKEDIN_OAUTH_CLIENT_ID,
  REG_EXP_EMAIL,
  REG_EXP_URL,
  MAX_WORKSPACE_ITEMS,
  actualModulesIds,
  OVERVIEW_MODULES,
  MAX_SHORTLISTED_ITEMS,
  MIN_SIZE_DESKTOP_RESOLUTION,
  MAX_SIZE_MOBILE_RESOLUTION,
  MIN_SIZE_MOBILE_RESOLUTION,
};
