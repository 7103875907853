import baseAPI, { APIResponse } from '@api';

import { TimeLineBar } from '../@types';

const api = baseAPI(false);
const apiCredentials = baseAPI();

//-------------------------------------------------------------------------
// Get Todo Item
//-------------------------------------------------------------------------
export type FetchTodoItemResponse = {
  id: number;
  name: string;
  description: string;
  todoItems: TimeLineBar.TodoItem[];
};
export function fetchTodoItem(
  projectId: number,
  todoId: number,
): APIResponse<FetchTodoItemResponse> {
  return apiCredentials.get(`/projects/${projectId}/project_phase_todos/${todoId}`);
}
//-------------------------------------------------------------------------
// Get Advisor
//-------------------------------------------------------------------------
export type FetchAdvisorResponse = {
  advisor: {
    id: number;
    avatarUrl: string;
    avatarSmallUrl: string;
    name: string;
    email: string;
    phoneNumber: string;
    calendlyUrl: string;
  };
};
export function fetchAdvisor(projectId: number): APIResponse<FetchAdvisorResponse> {
  return api.get(`/projects/${projectId}/advisor`);
}
//-------------------------------------------------------------------------
// Checked Todo Item
//-------------------------------------------------------------------------
export function fetchCheckedTodoItem(
  projectId: number,
  todoItemId: number,
  todoCompleted: boolean,
) {
  return apiCredentials.put(`/projects/${projectId}/project_phase_todo_items/${todoItemId}`, {
    todo_item: { completed: todoCompleted },
  });
}
//-------------------------------------------------------------------------
// Send Meet Request Todo Item
//-------------------------------------------------------------------------
export type FetchSendMeetRequestProps = {
  itemId: number;
  itemType: string;
};
export function fetchSendMeetRequestTodoItem(
  projectId: number,
  todoItemId: number,
  props: FetchSendMeetRequestProps,
) {
  const data = {
    meeting: {
      meetable_id: props.itemId,
      meetable_type: props.itemType,
      project_id: projectId,
    },
  };
  return apiCredentials.post(
    `/projects/${projectId}/project_phase_todo_items/${todoItemId}/meeting_proposal`,
    data,
  );
}
//-------------------------------------------------------------------------
