const formatDateForDatePicker = (date: Date | string) => {
  if (typeof date === 'string') {
    return date;
  } else {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}`;
  }
};

export default formatDateForDatePicker;
