import { createSlice as createSliceToolkit, Dispatch } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

import { BaseAPI } from '@api';

import makeToastError from './makeToastError';
import { ErrorState, OptionsType } from './createSlice';

type OptionsTypeKeys = 'hideToastMessageError' | 'initialState' | 'omitErrorStatus';

export default <T, P extends unknown[], N extends string>(
  name: N,
  request: BaseAPI<T, P> | undefined,
  options: Pick<OptionsType<T>, OptionsTypeKeys> = {},
) => {
  const { hideToastMessageError, initialState, omitErrorStatus } = options;

  const initialStateSlice = {
    records: initialState as T,
  };

  const slice = createSliceToolkit({
    name,
    initialState: initialStateSlice,
    reducers: {
      PENDING: () => {},
      FULFILLED: () => {},
      REJECTED: () => {},
    },
  });

  const { FULFILLED, REJECTED, PENDING } = slice.actions;

  const action = (...payload: P) => {
    return async (dispatch: Dispatch) => {
      try {
        if (request) {
          dispatch(PENDING());
          const response = await request(...payload);
          dispatch(FULFILLED());
          return response;
        }
      } catch (e) {
        dispatch(REJECTED());
        hideToastMessageError || makeToastError(e as AxiosError<ErrorState>, omitErrorStatus);
        return e as AxiosResponse<T>;
      }
    };
  };

  return { ...slice, action };
};
