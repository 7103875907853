import React from 'react';
import MuiListItemIcon, {
  ListItemIconProps as MuiListItemIconProps,
} from '@mui/material/ListItemIcon';

// @ts-ignore
const ListItemIcon: React.FC<MuiListItemIconProps> = React.forwardRef((props, ref) => (
  <MuiListItemIcon ref={ref} {...props} />
));

export default React.memo(ListItemIcon);
