import { useEvent } from '@hooks';

import { useForm } from './Context';

const usePasswordMutator = (name: string, resetField?: boolean) => {
  const {
    reset,
    formState: { errors },
  } = useForm();
  return useEvent((e: string) => {
    const value = e.replace(/s\+/g, '');
    resetField && errors[name] && reset({ [name]: value });
    return value;
  });
};

export default usePasswordMutator;
