import React from 'react';

import IconUi, { IconProps } from 'components/@ui/Icon';
import { ROUTES } from '@routes/config';
import { MainFields } from '@types';

const Icon: React.FC<IconProps> = (props) => (
  <IconUi {...props} beforeProps={{ color: 'inherit', ...props.beforeProps }} />
);

export type NavbarButtonType = MainFields<
  {
    img?: JSX.Element;
    link: string;
    links: string[];
    comingSoon?: boolean;
    exact?: boolean;
  },
  JSX.Element | string
>;

const NavbarButtonsData: NavbarButtonType[] = [
  {
    id: 1,
    name: 'Workspace',
    img: <Icon name="home-icon-path" />,
    link: ROUTES.workspace,
    links: [
      ROUTES.workspace,
      ROUTES.processMapping.moduleQuestions,
      ROUTES.processMapping.editModuleQuestions,
      ROUTES.processMapping.finalDetails,
      ROUTES.processMapping.editFinalDetails,
      ROUTES.processMapping.projectOverview,
    ],
  },
  {
    id: 2,
    name: 'To-do',
    img: <Icon name="to-do-icon-path" />,
    link: ROUTES.todo,
    links: [ROUTES.todo],
    comingSoon: true,
  },
  {
    id: 3,
    name: 'Vendors',
    img: <Icon name="vendors-icon-path" />,
    link: ROUTES.vendors.root,
    links: [ROUTES.vendors.root, ROUTES.vendorDetails, ROUTES.vendorPage, ROUTES.vendors.view],
  },
  {
    id: 4,
    name: 'Scores',
    img: <Icon name="scores-icon-path" />,
    link: ROUTES.scores,
    links: [ROUTES.scores, ROUTES.scorecard.vendor, ROUTES.scorecard.settings],
  },
  {
    id: 5,
    name: 'Team',
    img: <Icon name="team-icon-path" />,
    link: ROUTES.team,
    links: [ROUTES.team],
  },
  {
    id: 6,
    name: 'Advisor',
    img: <Icon name="advisor-icon-path" />,
    link: ROUTES.advisor,
    links: [ROUTES.advisor],
  },
  {
    id: 7,
    name: 'Resources',
    img: <Icon name="resourcesicon-path" />,
    link: ROUTES.resources,
    links: [ROUTES.resources],
    comingSoon: true,
  },
  {
    id: 8,
    name: 'Documents',
    img: <Icon name="documents-icon-path" />,
    link: ROUTES.documents,
    links: [ROUTES.documents],
    comingSoon: true,
  },
];

export default NavbarButtonsData;

export const OtherPages: NavbarButtonType[] = [
  {
    id: 9,
    name: 'Settings',
    exact: false,
    link: ROUTES.settings.root,
    links: [ROUTES.settings.root],
  },
];
