import React, { memo } from 'react';
import Typography, { TypographyProps } from '../Typography';

export interface TypographyMobileProps extends TypographyProps {
  mobileSmallFontSize?: boolean;
  mobileRegularFontSize?: boolean;
}

// @ts-ignore
const TypographyMobile: React.FC<TypographyMobileProps> = React.forwardRef((props, ref) => {
  const { mobileSmallFontSize, mobileRegularFontSize, ...other } = props;
  return (
    <Typography
      ref={ref}
      {...(mobileSmallFontSize && { fontSize: 12, lineHeight: 'normal' })}
      {...(mobileRegularFontSize && { fontSize: 18, lineHeight: '32px' })}
      {...other}
    />
  );
});
export default memo(TypographyMobile);
