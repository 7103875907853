import React from 'react';

import { TableCellCost } from '../HeatMapViewComponents';
import { useFetchVendors } from './@hooks';

const HeatMapSoftwareCost: React.FC = () => {
  const [{ records }] = useFetchVendors();

  return (
    <>
      {records.map((product) => (
        <TableCellCost
          key={product.id}
          min={product.softwareFeesMin}
          max={product.softwareFeesMax}
        />
      ))}
    </>
  );
};

export default HeatMapSoftwareCost;
