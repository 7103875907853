import React, { useMemo } from 'react';
import { Theme } from '@mui/material';

import Box, { BoxProps } from 'components/@ui/Box';
import SlideUi from 'components/@ui/Slide';
import { useMobileTimeline } from '@hooks';

const TimeLineMobileLayout: React.FC<BoxProps> = (props) => {
  const [{ records: isOpenTimelineMobile }] = useMobileTimeline();

  return useMemo(
    () => (
      <SlideUi direction="down" in={isOpenTimelineMobile} mountOnEnter>
        <Box
          display="flex"
          flexDirection="column"
          width={1}
          height="calc(100vh - 80px)"
          position="fixed"
          top={80}
          zIndex={199}
          overflow="scroll"
          bgcolor="background.default"
          sx={{ '&': (theme: Theme) => theme.scrollbar(0), overscrollBehavior: 'contain' }}
          pb={12}
        >
          <Box component="section" position="relative" px={2} {...props} />
        </Box>
      </SlideUi>
    ),
    [isOpenTimelineMobile, props],
  );
};

export default React.memo(TimeLineMobileLayout);
