import React, { useMemo } from 'react';

import Fonts from 'styles/fonts';
import ButtonUi, { ButtonProps as ButtonPropsUi } from 'components/@ui/Button';
import { clsx, makeSxStyles, parseSx } from '@helpers';
import { TimeLineBar } from '../@types';

export type ButtonProps = ButtonPropsUi & {
  status: TimeLineBar.Statuses;
  isEndOfDate?: boolean;
};

const makeSx = makeSxStyles({
  button: {
    fontFamily: Fonts.ProximaNova,
    fontSize: 12,
    borderRadius: 36,
    height: 36,
    bgcolor: 'primary.contrastText',
    border: 1,
    borderColor: 'gray.light',
    color: 'gray.main',
    whiteSpace: 'nowrap',
    px: 1.25,
    '.MuiButton-startIcon': {
      ml: 0,
    },
  },
  current: {
    '&:hover': {
      borderColor: 'yellow.contrastText',
      bgcolor: 'yellow.light',
      color: 'yellow.main',
    },
    '&:active': {
      bgcolor: 'yellow.contrastText',
      color: 'yellow.main',
    },
  },
  done: {
    '&:hover': {
      borderColor: 'greenLight.contrastText',
      bgcolor: 'greenLight.light',
      color: 'greenLight.main',
    },
    '&:active': {
      bgcolor: 'greenLight.contrastText',
      color: 'greenLight.main',
    },
  },
  next: {
    '&:hover': {
      borderColor: 'action.active',
      bgcolor: 'action.hover',
    },
    '&:active': {
      bgcolor: 'action.active',
      color: 'gray.dark',
    },
  },
  endOfDate: {
    '&:hover': {
      borderColor: 'gray.light',
      bgcolor: 'primary.contrastText',
    },
    '&:active': {
      borderColor: 'gray.light',
      bgcolor: 'primary.contrastText',
    },
  },
});

// @ts-ignore
const Button: React.FC<ButtonProps> = React.forwardRef((props, ref) => {
  const { sx, status, isEndOfDate, ...other } = props;
  const sxes = useMemo(() => makeSx(), []);

  return (
    <ButtonUi
      ref={ref}
      sx={clsx(sxes.button, sxes[status], isEndOfDate && sxes.endOfDate, ...parseSx(sx))}
      {...other}
    />
  );
});

export default React.memo(Button);
