import React, { useMemo } from 'react';
import { find, pluck, propEq } from 'ramda';

import Stepper from 'components/@ui/Stepper';

import MobileToDo from './MobileToDo';
import MobileFinishDate from './MobileFinishDate';
import ToDosWrapper from '../ToDos/ToDosWrapper';
import { ToDoProvider } from '../ToDo';
import { useToDos } from '../ToDos/@hooks';

const ToDosMap: React.FC = () => {
  const { todos } = useToDos();

  const todosIds = useMemo(() => pluck('id', todos), [todos]);
  const currentTodoId = useMemo(() => find(propEq('current', 'status'), todos)?.id, [todos]);

  return useMemo(
    () => (
      <>
        <Stepper
          activeStep={todosIds.indexOf(currentTodoId ?? 0)}
          orientation="vertical"
          connector={null}
          sx={{ width: '100%', px: 3 }}
        >
          {todos.map((todo, index, array) => (
            <ToDoProvider key={todo.id} index={index} record={todo}>
              <MobileToDo lastTodo={array.length - 1 === index} />
            </ToDoProvider>
          ))}
        </Stepper>
        <MobileFinishDate lastToDoStatus={todos[todos.length - 1].status} />
      </>
    ),
    [todos, currentTodoId, todosIds],
  );
};

const MobileToDos: React.FC = () => {
  return (
    <ToDosWrapper>
      <ToDosMap />
    </ToDosWrapper>
  );
};

export default MobileToDos;
