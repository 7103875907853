import React from 'react';
import MuiBox, { BoxProps as MuiBoxProps } from '@mui/material/Box';

export interface BoxProps extends MuiBoxProps {}

// @ts-ignore
const Box: React.FC<BoxProps> = React.forwardRef((props, ref) => {
  return <MuiBox ref={ref} {...props} />;
});

export default React.memo(Box);
