import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { useIsLogin, useIsTablet, useUser } from '@hooks';
import { Children, UseState } from '@types';

import { navbarCollapse } from './@servises';

type NavbarContextProps = {
  isOpenNavbar: boolean;
  isOpenNavbarMobile: boolean;
  setIsOpenNavbarMobile: UseState<boolean>;
  toggleCollapseNavbar: () => void;
};

export const NavbarContext = createContext<NavbarContextProps>(null as any);

const NavbarProvider: React.FC<Children> = ({ children }) => {
  const [isCollapseNavbar, setIsCollapseNavbar] = useState(false);
  const [isOpenNavbarMobile, setIsOpenNavbarMobile] = useState(false);
  const { id: userId } = useUser();
  const isTablet = useIsTablet();
  const isLogin = useIsLogin();

  const toggleCollapseNavbar = useCallback(() => {
    setIsCollapseNavbar((state) => {
      if (!isLogin) return true;
      navbarCollapse.set(userId, !state);
      return !state;
    });
  }, [isLogin, userId]);

  useEffect(() => {
    setIsCollapseNavbar(() => {
      if (isTablet) return false;
      if (!isLogin) return true;
      return navbarCollapse.get(userId);
    });
  }, [userId, isTablet, isLogin]);

  const contextValue = useMemo(
    () => ({
      isOpenNavbar: !isCollapseNavbar,
      isOpenNavbarMobile,
      setIsOpenNavbarMobile,
      toggleCollapseNavbar,
    }),
    [isCollapseNavbar, isOpenNavbarMobile, setIsOpenNavbarMobile, toggleCollapseNavbar],
  );

  return <NavbarContext.Provider value={contextValue}>{children}</NavbarContext.Provider>;
};

export default React.memo(NavbarProvider);
