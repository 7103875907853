import React, { useMemo } from 'react';

import Fade from 'components/@ui/Fade';
import When from 'components/@main/When';

import VendorsHeader from './VendorsHeader';
import VendorsBody from './VendorsBody';
import { useFetchVendors } from './@hooks';

const Background: React.FC = () => {
  const [{ loaded }] = useFetchVendors();

  return useMemo(
    () => (
      <When condition={loaded} disabledWrapper>
        <Fade in timeout={750}>
          <div>
            <VendorsHeader />
            <VendorsBody />
          </div>
        </Fade>
      </When>
    ),
    [loaded],
  );
};

export default Background;
