import React from 'react';

import { AddOrDeleteShortlistIconButton } from '../VendorComponents';
import { ShortlistAddCell } from '../HeatMapViewComponents';
import { useFetchVendors } from './@hooks';

const HeatMapShortlistAdd: React.FC = () => {
  const [{ records }] = useFetchVendors();

  return (
    <>
      {records.map((product) => (
        <ShortlistAddCell
          AddOrDeleteShortlistedComponent={AddOrDeleteShortlistIconButton}
          key={product.id}
          cellId={product.id}
        />
      ))}
    </>
  );
};

export default HeatMapShortlistAdd;
