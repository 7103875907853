import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { LOG_OUT_TYPE, RootState, useAppDispatch } from '@redux/store';
import { jwt, projectIdStorage } from '@servises';
import { useIsLoginSlice } from '@redux/slices';

const selector = (state: RootState) => state.selfData;

const useUserData = () => useSelector(selector);

export const useUser = () => {
  const { records } = useUserData();
  return useMemo(() => ({ ...records, ...projectIdStorage.getProject(records) }), [records]);
};

export const useIsLogin = () => useIsLoginSlice()[0].records.isLogin;

export const useSignOut = () => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch({ type: LOG_OUT_TYPE });
    jwt.removeUserTokenValues();
  }, [dispatch]);
};

export default useUserData;
