import React, { useMemo } from 'react';

import Box from 'components/@ui/Box/Box';
import Icon from 'components/@ui/Icon/Icon';
import Button from 'components/@ui/Button';
import { useWorkspaceProductsPDF } from 'components/@common/WorkspaceProductsPDF';
import { useIsTablet } from '@hooks';

import { makeSX } from '../@hook';

const DownloadButton: React.FC = () => {
  const { generatePDFDocument, isSaving } = useWorkspaceProductsPDF();
  const isTablet = useIsTablet();

  return useMemo(
    () => (
      <Box
        display="flex"
        justifyContent={{ mobile: 'start', laptop: 'flex-end' }}
        pl={{ mobile: 0, tablet: 4.5, laptop: 0 }}
        alignItems="center"
        width="100%"
      >
        <Button
          endIcon={<Icon name="cloud-download-icon-path" color="inherit" />}
          sx={{
            ...(!isTablet && makeSX.buttonStyle),
            marginRight: 0,
            width: { mobile: '100%', tablet: '215px', laptop: 'auto' },
            my: { mobile: 1.5, laptop: 0 },
            fontSize: { mobile: 18, laptop: 16 },
          }}
          disabled={isSaving}
          onClick={generatePDFDocument()}
        >
          Download report
        </Button>
      </Box>
    ),
    [isSaving, generatePDFDocument, isTablet],
  );
};

export default DownloadButton;
