import React from 'react';
import { Theme } from '@mui/material';

import { clsx, parseSx } from '@helpers';

import { FormProps, Form } from './Form';

export type FormRenderProps = FormProps & {
  render?: React.FC;
};

const FormRender: React.FC<FormRenderProps> = ({ sx, render: Component = Form, ...other }) => {
  return (
    <Component
      flex={1}
      display="flex"
      flexDirection="column"
      my={3}
      px={3}
      sx={clsx({ overflowY: 'auto', '&': (theme: Theme) => theme.scrollbar() }, ...parseSx(sx))}
      {...other}
    />
  );
};

export default React.memo(FormRender);
