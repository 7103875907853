import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import Box from 'components/@ui/Box';
import { UseDynamicRefs } from '@hooks';
import { Children } from '@types';

import { TimeLineBar } from '../@types';

type EmptySpaseProps = {
  getRef: UseDynamicRefs[0];
  phases: TimeLineBar.Phase[];
} & Children;

const EmptySpase: React.FC<EmptySpaseProps> = ({ getRef, phases, children }) => {
  const [emptySpase, setEmptySpase] = useState(0);
  const { width } = useWindowSize();

  useEffect(() => {
    setEmptySpase(
      phases.reduce(
        (acc, { name }) => acc - (getRef(name) as HTMLElement)?.offsetWidth || 0,
        width - phases.length,
      ),
    );
  }, [getRef, setEmptySpase, phases, width]);

  return (
    <Box
      display="flex"
      alignItems="center"
      minWidth={emptySpase > 0 ? emptySpase : 0}
      height="100%"
    >
      {children}
    </Box>
  );
};

export default React.memo(EmptySpase);
