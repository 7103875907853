import React, { memo, useMemo } from 'react';
import { SubmitHandler, FieldValues } from 'react-hook-form';

import { useForm } from './Context';
import Box, { BoxProps } from 'components/@ui/Box';

export type FormProps<TFieldValues extends FieldValues = any> = {
  onSubmit?: SubmitHandler<TFieldValues>;
} & Omit<BoxProps, 'onSubmit'>;

export const Form: React.FC<FormProps> = ({ onSubmit, ...other }) => {
  const { handleSubmit } = useForm();

  const onSubmitProp = useMemo(
    () => ({ ...(onSubmit && { onSubmit: handleSubmit(onSubmit) }) }),
    [handleSubmit, onSubmit],
  );

  return <Box component="form" {...onSubmitProp} {...other} />;
};

export default memo(Form);
