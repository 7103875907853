import React from 'react';

import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import Icon from 'components/@ui/Icon';
import Typography from 'components/@ui/Typography';

type ConfirmModalProps = {
  handleClose: () => void;
  confirm: () => void;
  vendorName: string;
};

const InitialDemoRequestConfirmModal: React.FC<ConfirmModalProps> = ({
  handleClose,
  confirm,
  vendorName,
}) => {
  const handleConfirm = () => {
    confirm();
    handleClose();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="28px 24px"
      width={1}
      maxWidth={400}
      boxShadow="0px 10px 30px 0px #26334D0D"
      borderRadius={4}
      bgcolor="secondary.main"
    >
      <Box display="flex">
        <Icon name="proposal-sent-icon-path" color="gray.dark" borderRadius={0} />
        <Typography color="gray.dark" semiBold ml={1.25}>
          Request sent
        </Typography>
      </Box>
      <Typography textAlign="center" mt={2} color="gray.dark">
        Success! You just requested a demo with {vendorName}. Your sales rep will respond to
        schedule the meeting within 1-2 days.
      </Typography>
      <Button fullWidth sx={{ marginTop: 3 }} onClick={handleConfirm}>
        OK
      </Button>
      <Button onClick={handleClose} variant="text" fullWidth>
        Cancel request
      </Button>
    </Box>
  );
};

export default React.memo(InitialDemoRequestConfirmModal);
