import React from 'react';

import Box from 'components/@ui/Box';
import Icon from 'components/@ui/Icon';
import Typography from 'components/@ui/Typography';
import Button from 'components/@ui/Button';

import { useRequestDemoModal } from './@hooks';
import { MODAL_STEP } from './Context';
import DialogActionsWrapper from './DialogActionsWrapper';

const SkipDesignDemoModal: React.FC = () => {
  const { requestDemoModalFlowClose, setModalStep } = useRequestDemoModal();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={3}
        px={3}
        width={500}
        boxShadow={3}
      >
        <Icon
          name="warning"
          fontSize={96}
          width={96}
          height={96}
          color="blue.main"
          borderRadius={0}
        />
        <Box display="flex" flexDirection="column" ml={1.5}>
          <Typography fontSize={24} lineHeight="32px" semiBold component="h2">
            Are you sure you want to skip?
          </Typography>
          <Typography mt={1.5}>
            We strongly suggest completing the 'Design demo' form as it will prevent you from
            answering the same questions from each of the vendors.
          </Typography>
        </Box>
      </Box>
      <DialogActionsWrapper pt={6} mt={0} bgcolor="secondary.main">
        <Button fullWidth onClick={requestDemoModalFlowClose}>
          Yes, skip design demo
        </Button>
        <Button
          fullWidth
          variant="text"
          color="inherit"
          onClick={setModalStep(MODAL_STEP.designDemo)}
        >
          No, let's design demo
        </Button>
      </DialogActionsWrapper>
    </>
  );
};

export default SkipDesignDemoModal;
