import { createSlice, CA, P } from '@redux/slices';
import {
  fetchAddToShortlisted,
  fetchAddToWorkspace,
  fetchAdvisor,
  fetchClientRoles,
  fetchCountries,
  fetchDeleteFromShortlist,
  fetchDeleteFromWorkspace,
  fetchProjectGoals,
  fetchProjectScope,
  fetchShortlistProductById,
  fetchWorkspaceProductById,
} from '@api/global';

const SUBTITLE = 'GLOBAL';

export const advisor = createSlice(CA(P('ADVISOR', SUBTITLE)), fetchAdvisor, {
  initialState: { advisor: {} },
});
export const getShortlistById = createSlice(
  CA(P('PROJECT_GET_SHORTLIST_BY_ID', SUBTITLE)),
  fetchShortlistProductById,
);
export const addToShortlist = createSlice(
  CA(P('PROJECT_ADD_TO_SHORTLIST_VENDOR', SUBTITLE)),
  fetchAddToShortlisted,
);
export const deleteFromShortlist = createSlice(
  CA(P('PROJECT_DELETE_FROM_SHORTLIST_VENDOR', SUBTITLE)),
  fetchDeleteFromShortlist,
);
export const getWorkspaceById = createSlice(
  CA(P('PROJECT_GET_WORKSPACE_BY_ID', SUBTITLE)),
  fetchWorkspaceProductById,
);
export const addToWorkspace = createSlice(
  CA(P('PROJECT_ADD_TO_WORKSPACE_VENDOR', SUBTITLE)),
  fetchAddToWorkspace,
);
export const deleteFromWorkspace = createSlice(
  CA(P('PROJECT_DELETE_FROM_WORKSPACE_VENDOR', SUBTITLE)),
  fetchDeleteFromWorkspace,
);

export const countries = createSlice(CA(P('COUNTRIES', SUBTITLE)), fetchCountries, {
  initialState: { countries: [] },
});
export const projectGoals = createSlice(CA(P('PROJECT_GOALS', SUBTITLE)), fetchProjectGoals, {
  initialState: { characteristics: [] },
});
export const projectScope = createSlice(CA(P('PROJECT_SCOPE', SUBTITLE)), fetchProjectScope, {
  initialState: { productModules: [] },
});
export const clientRoles = createSlice(CA(P('CLIENT_ROLES', SUBTITLE)), fetchClientRoles, {
  initialState: { clientRoles: [] },
});

export default {
  advisor: advisor.reducer,

  getShortlistById: getShortlistById.reducer,
  addToShortlist: addToShortlist.reducer,
  deleteFromShortlist: deleteFromShortlist.reducer,

  getWorkspaceById: getWorkspaceById.reducer,
  addToWorkspace: addToWorkspace.reducer,
  deleteFromWorkspace: deleteFromWorkspace.reducer,

  countries: countries.reducer,
  projectGoals: projectGoals.reducer,
  projectScope: projectScope.reducer,
  clientRoles: clientRoles.reducer,
};
