import camelcaseKeys from 'camelcase-keys';

export default <T>(lastMessage: MessageEvent<T>) => {
  const { data = '{}' } = lastMessage;
  try {
    return camelcaseKeys(JSON.parse(data as any), { deep: true }) as T;
  } catch {
    return {} as T;
  }
};
