import React, { useLayoutEffect } from 'react';
import {
  CloseButtonProps,
  Slide,
  ToastContainer as Container,
  ToastContainerProps,
  TypeOptions,
} from 'react-toastify';
import { alpha, Theme, useTheme } from '@mui/material/styles';

import Icon from 'components/@ui/Icon';
import { useIsTablet } from '@hooks';

import injectStyle from './injectStyle';

import './styles.scss';

const TYPE: Record<TypeOptions, TypeOptions> = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
  default: 'default',
};

const setColor = (type: TypeOptions) => {
  switch (type) {
    case TYPE.info:
    case TYPE.success:
      return 'secondary.main';
    default:
      return 'inherit';
  }
};

const CloseButton: React.FC<CloseButtonProps> = ({ closeToast, type }) => {
  return (
    <Icon
      name="delete"
      hover
      color={setColor(type)}
      sx={{ '&:hover': { background: (theme: Theme) => alpha(theme.palette.divider, 0.2) } }}
      onClick={closeToast}
    />
  );
};

const ToastContainer: React.FC<ToastContainerProps> = (props) => {
  const theme = useTheme();
  const isTablet = useIsTablet();

  useLayoutEffect(() => injectStyle(theme));

  return (
    <Container
      position={isTablet ? 'top-left' : 'bottom-left'}
      autoClose={3000}
      icon={false}
      hideProgressBar
      theme="colored"
      bodyClassName="Toastify__toast-body-custom"
      closeButton={CloseButton}
      transition={Slide}
      {...props}
    />
  );
};

export default ToastContainer;
