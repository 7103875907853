import React from 'react';

import Typography from 'components/@ui/Typography';

const ForVendorInfo: React.FC = () => {
  return (
    <Typography>
      We love partnering with{' '}
      <a
        href="https://www.outsail.co/brokers-consultants"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography component="span" color="blue.main" sx={{ textDecoration: 'underline' }}>
          brokers & consultants
        </Typography>
      </a>{' '}
      and bringing innovative{' '}
      <a href="https://www.outsail.co/software-vendors" target="_blank" rel="noopener noreferrer">
        <Typography component="span" color="blue.main" sx={{ textDecoration: 'underline' }}>
          vendors
        </Typography>
      </a>{' '}
      to our customers. Follow those links to learn more about how we can partner together and bring
      OutSail's benefits to your customers.
    </Typography>
  );
};

export default ForVendorInfo;
