import React from 'react';

import { RequirementsMatchCell } from '../HeatMapViewComponents';
import { useFetchVendors } from './@hooks';

const HeatMapRequirementsMatch: React.FC = () => {
  const [{ records }] = useFetchVendors();

  return (
    <>
      {records.map((product) => (
        <RequirementsMatchCell key={product.id} cellContent={product.scoreMatch} />
      ))}
    </>
  );
};

export default HeatMapRequirementsMatch;
