import { clone, find, findIndex, propEq, toPairs } from 'ramda';

import { OVERVIEW_MODULES } from '@constants';
import { Project, WorkspaceProducts } from '@types';

export const sortWorkspace = (
  chosenProduct: Project.ChosenProduct,
  workspaceProducts: WorkspaceProducts.Product[],
) => {
  if (!chosenProduct) return workspaceProducts;
  const cloneProducts = clone(workspaceProducts);
  const indexChosen = findIndex(propEq(chosenProduct.productId, 'id'), cloneProducts);
  if (indexChosen < 0) return workspaceProducts;
  cloneProducts.splice(indexChosen, 1);
  return [workspaceProducts[indexChosen], ...cloneProducts];
};

export const makeMapping = (projectMappings: Project.ProjectMappings) => {
  return toPairs(projectMappings);
};

export const makeTitle = (title: string) => {
  if (title === 'additionalRequirements') return 'Additional Requirements';
  if (title === 'finalDetails') return 'Final Details';
  const existingModule = find(propEq(title, 'name'), OVERVIEW_MODULES);
  return existingModule ? existingModule.title : '';
};

type ItemsList = { question: string; answer: string };

export const makeItemsList = (questions: string | null) => {
  let itemsList: ItemsList[] = [];
  const questionsList = questions?.split(';\n');

  questionsList?.forEach((question) => {
    const questionWithAnswer = question.split('\n ');
    const stringsArr = questionWithAnswer[0].split(' Answer: ');
    const questionString = stringsArr[0].replace('Question: ', '');
    const answerString = stringsArr[1].includes(' (please specify)')
      ? stringsArr[1].replace(' (please specify)', '')
      : stringsArr[1];
    const item = {
      question: questionString,
      answer: answerString,
    };
    itemsList.push(item);
  });

  return itemsList;
};
