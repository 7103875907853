import React from 'react';

import { TableCell as MuiTableCell, TableCellProps as MuiTableCellProps } from '@mui/material';

export interface TableCellProps extends MuiTableCellProps {}

// @ts-ignore
const TableCell: React.FC<TableCellProps> = React.forwardRef((props, ref) => {
  return <MuiTableCell {...props} ref={ref}></MuiTableCell>;
});

export default React.memo(TableCell);
