import { useEffect, useState } from 'react';
import { equals, filter } from 'ramda';
import { useMount } from 'react-use';

import { useForm } from 'components/@main/Form';

import { min, minLowercase, minNumbers, minSymbols, minUppercase } from './schemaPassword';

const numberMatchesFn = (...arg: boolean[]) => filter(equals(true))(arg).length;

export type UsePasswordStrengthBarProps = {
  name?: string;
};

const usePasswordStrengthBar = <T extends string>(props: UsePasswordStrengthBarProps = {}) => {
  const { name: nameProps = 'password' as T } = props;
  const [password, setPassword] = useState('');
  const { watch, getValues } = useForm();

  const hasUppercase = minUppercase.isValidSync(password);
  const hasLowercase = minLowercase.isValidSync(password);
  const hasNumber = minNumbers.isValidSync(password);
  const hasSymbol = minSymbols.isValidSync(password);
  const hasLength = min.isValidSync(password);

  const numberMatches = numberMatchesFn(
    hasUppercase,
    hasLowercase,
    hasNumber,
    hasSymbol,
    hasLength,
  );

  useMount(() => {
    setPassword(getValues(nameProps));
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === nameProps) setPassword(value[nameProps]);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return { hasUppercase, hasLowercase, hasNumber, hasSymbol, hasLength, numberMatches };
};

export default usePasswordStrengthBar;

export type UsePasswordStrengthBar = ReturnType<typeof usePasswordStrengthBar>;
