import React from 'react';

import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';
import Icon from 'components/@ui/Icon';
import TableCell from 'components/@ui/TableCell';
import Fade from 'components/@ui/Fade';
import { clsx, makeSxStyles } from '@helpers';
import { useIsTablet } from '@hooks';

import { cellsSize } from './@config';

const sxses = makeSxStyles({
  rowHeader: {
    left: 0,
    p: '12px 20px',
    position: 'sticky',
    bgcolor: 'secondary.main',
    zIndex: 13,
    display: 'table-cell',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 215,
  },
  tableHeader: {
    width: 115,
    px: 2,
  },
})();

export type TableHeaderCellProps = {
  hover?: () => void;
  leave?: () => void;
  title?: string;
  isHoverSidebarCell?: boolean;
  isHideArrow?: boolean;
  isDisabledClick?: boolean;
};

export type onClick = {
  onClick?: () => void;
};

const TableHeaderCellUi: React.FC<TableHeaderCellProps & onClick> = ({
  hover,
  leave,
  title,
  isHoverSidebarCell,
  isHideArrow = true,
  isDisabledClick = true,
  onClick,
}) => {
  const isTablet = useIsTablet();

  return (
    <TableCell
      component="th"
      scope="row"
      height={cellsSize.heatMap.height}
      onMouseOver={hover}
      onMouseLeave={leave}
      onClick={onClick}
      sx={clsx(
        sxses.rowHeader,
        !isDisabledClick && { cursor: 'pointer' },
        isHoverSidebarCell && { zIndex: 16 },
        isTablet && sxses.tableHeader,
      )}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
        <Typography
          maxHeight={1}
          maxWidth={isHoverSidebarCell && !isHideArrow ? 145 : 'none'}
          fontSize={isTablet ? 12 : 16}
          lineHeight={isTablet ? 'normal' : 1.5}
          whiteSpace={isTablet ? 'normal' : 'nowrap'}
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {title}
        </Typography>
        {isHoverSidebarCell && !isHideArrow && !isTablet && (
          <Fade in>
            <Icon name="right" />
          </Fade>
        )}
      </Box>
    </TableCell>
  );
};

export default React.memo(TableHeaderCellUi);
