import React, { useCallback, useMemo } from 'react';
import { Redirect, matchPath, useLocation } from 'react-router-dom';
import { any, equals, isEmpty, not } from 'ramda';

import { useUser } from '@hooks';
import { useAuth } from 'components/@common/Auth';
import { useIsLoginSlice } from '@redux/slices';

import DefaultRoute, { DefaultRouteProps } from './DefaultRoute';
import { ROUTES } from './config';

const PrivateRoute: React.FC<DefaultRouteProps> = (props) => {
  const { forbiden: forbidenProp, fallback: Fallback, ...other } = props;
  const { projects, verified } = useUser();
  const { loaded } = useAuth();
  const [{ records }] = useIsLoginSlice();
  const { isLogin } = records;
  const location = useLocation();

  const noProject = useMemo(() => isLogin && isEmpty(projects), [isLogin, projects]);

  const allowedPagesNoVerified = useMemo(
    () =>
      matchPath(location.pathname, {
        path: [ROUTES.auth.verify],
        exact: true,
      }),
    [location.pathname],
  );

  const allowedPagesNoProject = useMemo(
    () => matchPath(location.pathname, [ROUTES.addNewProject.root, ROUTES.auth.verify]),
    [location.pathname],
  );

  const forbiden = useMemo(() => {
    return any(equals(true), [
      Boolean(forbidenProp),
      loaded && !isLogin,
      not(verified) && not(allowedPagesNoVerified),
      noProject && not(allowedPagesNoProject),
    ]);
  }, [
    allowedPagesNoProject,
    allowedPagesNoVerified,
    forbidenProp,
    isLogin,
    loaded,
    noProject,
    verified,
  ]);

  const FallbackComponent = useCallback(() => {
    if (isLogin && not(verified) && not(allowedPagesNoVerified)) {
      return <Redirect to={ROUTES.auth.verify} />;
    }
    if (isLogin && noProject && not(allowedPagesNoProject)) {
      return <Redirect to={ROUTES.addNewProject.startMatching} />;
    }
    return isLogin && Fallback ? <Fallback /> : <Redirect to={ROUTES.auth.login.root} />;
  }, [Fallback, allowedPagesNoProject, allowedPagesNoVerified, isLogin, noProject, verified]);

  return <DefaultRoute forbiden={forbiden} fallback={FallbackComponent} {...other} />;
};

export default React.memo(PrivateRoute);
