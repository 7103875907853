import React, { useMemo } from 'react';

import { Children, User } from '@types';

import { abilityCreate } from './@helpers';
import { useUser } from '@hooks';

export type AbilityContextType = ReturnType<typeof abilityCreate>;

export const AbilityContext = React.createContext<AbilityContextType>(null as any);

const AbilityProvider: React.FC<Children> = ({ children }) => {
  const { clientRole } = useUser();

  const contextValue = useMemo(() => abilityCreate(clientRole ?? User.ROLES.client), [clientRole]);

  return <AbilityContext.Provider value={contextValue}>{children}</AbilityContext.Provider>;
};

export default React.memo(AbilityProvider);
