import { combineReducers } from 'redux';

import { createSlice } from '@redux/slices';

import { fetchProject } from '@api';

import { fetchShortlistPDFReport } from '../@api';

export const project = createSlice('FETCH_PROJECT', fetchProject, {
  initialState: { project: {} },
});

export const shortlistPDF = createSlice('FETCH_SHORTLIST_PDF', fetchShortlistPDFReport);

export default combineReducers({
  project: project.reducer,
  shortlistPDF: shortlistPDF.reducer,
});
