import React from 'react';

import { DialogProps } from 'components/@ui/Dialog';

import TermsAndPrivacy from './TermsAndPrivacy';

const TermsOfUseDialog = React.lazy(() => import('./TermsOfUseDialog'));

const TermsOfUse: React.FC<DialogProps> = (props) => {
  return (
    <TermsAndPrivacy open={props.open}>
      <TermsOfUseDialog {...props} />
    </TermsAndPrivacy>
  );
};

export default TermsOfUse;
