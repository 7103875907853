import React from 'react';

import { useIsTablet } from '@hooks';

import Desktop from './Desktop';
import Mobile from './Mobile';

const TimeLineBarPlug: React.FC = () => {
  const isTablet = useIsTablet();

  return <>{isTablet ? <Mobile /> : <Desktop />}</>;
};

export default TimeLineBarPlug;
