import React, { useMemo } from 'react';

import When from 'components/@main/When';
import Icon from 'components/@ui/Icon';
import Box, { BoxProps } from 'components/@ui/Box';
import { useProject } from '@hooks';

const HideIconWrapper: React.FC<BoxProps> = ({ children, ...other }) => {
  const { hiddenInformation } = useProject();

  return useMemo(
    () => (
      <When disabledWrapper condition={hiddenInformation} fallback={children}>
        {children}
        <Box
          position="fixed"
          width={1}
          height={24}
          sx={{ backdropFilter: 'blur(4px)' }}
          zIndex={15}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          {...other}
        >
          <Icon name="lock" borderRadius={0} />
        </Box>
      </When>
    ),
    [children, other, hiddenInformation],
  );
};

export default HideIconWrapper;
