import React, { useCallback, useMemo } from 'react';

import { clsx, makeSxStyles } from '@helpers';

import { Button, PhaseStartIcon } from '../../@components';
import { usePhase } from '../Context';
import { icons, usePhases } from '../../Phases';

const sxDefault = {
  borderColor: 'transparent',
  color: 'secondary.main',
} as const;

export const sxes = makeSxStyles({
  current: {
    bgcolor: 'yellow.main',
    ...sxDefault,
    '&:hover, &:active': {
      bgcolor: 'yellow.dark',
      ...sxDefault,
    },
  },
  done: {
    bgcolor: 'greenLight.main',
    ...sxDefault,
    '&:hover, &:active': {
      bgcolor: 'greenLight.dark',
      ...sxDefault,
    },
  },
  next: {
    bgcolor: 'blue.main',
    ...sxDefault,
    '&:hover, &:active': {
      bgcolor: 'primary.main',
      ...sxDefault,
    },
  },
})();

const PhaseButton: React.FC = () => {
  const { phase, onCloseTooltip, isSelected } = usePhase();
  const { toogleSelected } = usePhases();

  const handleMouseDown = useCallback(() => {
    !isSelected && onCloseTooltip();
  }, [isSelected, onCloseTooltip]);

  return useMemo(
    () => (
      <Button
        onMouseDown={handleMouseDown}
        status={phase.status}
        onClick={toogleSelected(phase)}
        startIcon={<PhaseStartIcon name={icons[phase.name]} />}
        sx={clsx(isSelected && sxes[phase.status])}
      >
        {phase.name}
      </Button>
    ),
    [handleMouseDown, isSelected, phase, toogleSelected],
  );
};

export default PhaseButton;
