import React, { useEffect, useLayoutEffect } from 'react';
import ReactGA from 'react-ga4';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import ThemeProvider from '@theme';
import ToastContainer from 'components/@main/ToastContainer';
import Routes from '@routes';
import { AuthProvider } from 'components/@common/Auth';
import { ProjectWs, WsProvider } from 'components/@main/Ws';
import { ReduxProvider, SentryProvider } from '@providers';
import { AbilityProvider } from '@permissions';
import { AppLayoutProvider } from '@layout/AppLayout';
import { useOnline } from '@hooks';
import { GA } from '@constants';
import { storage } from '@servises';
import { ROUTES } from '@routes/config';

process.env.NODE_ENV !== 'development' && GA && ReactGA.initialize(GA);
storage.setStorage(window.localStorage);

const App: React.FC<RouteComponentProps> = ({ history }) => {
  history.listen(() => {
    window.scrollTo({ top: 0 });
  });

  const isOnline = useOnline();

  useLayoutEffect(() => {
    if (
      window.location.ancestorOrigins[0] &&
      window.location.ancestorOrigins[0].includes('https://outsail')
    ) {
      if (history.location.pathname !== ROUTES.embedSignUp.root) {
        history.replace(ROUTES.embedSignUp.root);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  useEffect(() => {
    history.block(() => {
      if (!isOnline) return false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  return (
    <ThemeProvider>
      <SentryProvider>
        <ReduxProvider>
          <WsProvider>
            <AuthProvider>
              <AbilityProvider>
                <AppLayoutProvider>
                  <ProjectWs />
                  <Routes />
                </AppLayoutProvider>
                <ToastContainer />
              </AbilityProvider>
            </AuthProvider>
          </WsProvider>
        </ReduxProvider>
      </SentryProvider>
    </ThemeProvider>
  );
};

export default withRouter(App);
