import { useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';

import { useSetTimeout } from '@hooks';

import { usePhases } from '../Context';

const useControlScroll = () => {
  const [hasScroll, setHasScroll] = useState(false);
  const { selected, scrollMenuApiRef } = usePhases();
  const { width } = useWindowSize();
  const { setTimeout } = useSetTimeout();

  useEffect(() => {
    setTimeout(() => {
      const { scrollWidth = 0, clientWidth = 0 } =
        scrollMenuApiRef.current.scrollContainer?.current || {};
      setHasScroll(scrollWidth > clientWidth);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, selected]);

  useEffect(() => {
    setTimeout(() => {
      scrollMenuApiRef.current?.scrollToItem?.(
        scrollMenuApiRef.current?.getItemElementById(selected?.name ?? '') || undefined,
        undefined,
        'start',
      );
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return useMemo(() => hasScroll, [hasScroll]);
};

export default useControlScroll;
