import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';

import { User } from '@types';

import ButtonGoogle from './ButtonGoogle';
import GoogleProvider from './GoogleProvider';
import { SocialProps } from '../types';

const Button: React.FC<SocialProps> = ({ fetch }) => {
  const onFetch = async (token: string) => {
    await fetch({ provider: User.PROVIDERS.google, accessToken: token });
  };

  const login = useGoogleLogin({
    onSuccess: (response) => {
      onFetch(response.access_token);
    },
    onError: () => {
      toast.error('Google authentication Fail!');
    },
  });

  const handleLogin = () => login();

  return <ButtonGoogle onClick={handleLogin} />;
};

const GoogleAuth: React.FC<SocialProps> = ({ fetch }) => {
  return (
    <GoogleProvider>
      <Button fetch={fetch} />
    </GoogleProvider>
  );
};

export default React.memo(GoogleAuth);
