import { useEffect, useState } from 'react';

const useOnline = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine ?? false);

  const handleChange = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('online', handleChange);
    window.addEventListener('offline', handleChange);
    return () => {
      window.removeEventListener('online', handleChange);
      window.removeEventListener('offline', handleChange);
    };
  }, []);

  return isOnline;
};

export default useOnline;
