import React from 'react';

import Box from 'components/@ui/Box';
import Icon from 'components/@ui/Icon';
import Typography from 'components/@ui/Typography';

const ToastOnline: React.FC = () => {
  return (
    <>
      <Box display="flex" mb={0.5}>
        <Icon name="Cloud-check" />
        <Typography fontSize={14} lineHeight={24 / 14} ml={1} semiBold>
          You are online
        </Typography>
      </Box>
      <Typography fontSize={14} lineHeight={24 / 14}>
        Updating...
      </Typography>
    </>
  );
};

export default ToastOnline;
