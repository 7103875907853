import React from 'react';

import When from 'components/@main/When';
import { DialogProps } from 'components/@ui/Dialog';

import { Children } from '@types';

const TermsAndPrivacy: React.FC<Pick<DialogProps, 'open'> & Children> = React.memo((props) => {
  const { children, open } = props;

  return (
    <React.Suspense fallback={null}>
      <When disabledWrapper condition={open}>
        {children}
      </When>
    </React.Suspense>
  );
});

export default TermsAndPrivacy;
