import React from 'react';

import Box, { BoxProps } from 'components/@ui/Box';

const MobileVendorContainerWrapper: React.FC<BoxProps> = (props) => (
  <Box
    bgcolor="secondary.main"
    borderRadius={4}
    boxShadow={3}
    border={1}
    borderColor="gray.light"
    py={1.5}
    {...props}
  />
);

export default React.memo(MobileVendorContainerWrapper);
