import React, { useMemo } from 'react';

import ToDo, { ToDoProvider } from '../ToDo';
import ToDosWrapper from './ToDosWrapper';
import { FinishDate } from '../Phase';
import { useToDos } from './@hooks';

const ToDosMap: React.FC = () => {
  const { todos } = useToDos();
  return useMemo(
    () => (
      <>
        {todos.map((todo, index) => (
          <ToDoProvider key={todo.id} index={index} record={todo}>
            <ToDo />
          </ToDoProvider>
        ))}
      </>
    ),
    [todos],
  );
};

const ToDos: React.FC = () => {
  return (
    <ToDosWrapper>
      <ToDosMap />
      <FinishDate />
    </ToDosWrapper>
  );
};

export default ToDos;
