import React from 'react';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import { alpha, Theme } from '@mui/material';

import { clsx, parseSx } from '@helpers';

export interface DialogProps extends MuiDialogProps {}

// @ts-ignore
const Dialog: React.FC<DialogProps> = React.forwardRef((props, ref) => {
  const { PaperProps, ...other } = props;

  return (
    <MuiDialog
      ref={ref}
      fullWidth
      PaperProps={{
        ...PaperProps,
        sx: clsx(
          {
            position: 'relative',
            maxHeight: 'calc( 100% - 32px )',
            borderRadius: 4,
          },
          ...parseSx(PaperProps?.sx),
        ),
      }}
      scroll="paper"
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: (theme: Theme) => alpha(theme.palette.gray.dark, 0.8),
        },
      }}
      {...other}
    />
  );
});

export default React.memo(Dialog);
