import React from 'react';

import {
  FilterMenuButtonUI,
  OptionsMenuButtonUI,
  SortMenuButtonUI,
  VendorsActionWrapper,
  VendorsHeaderWrapper,
  VendorsTitle,
  ViewMenuButtonUI,
} from '../VendorsComponents';

const VendorsHeader: React.FC = () => {
  return (
    <VendorsHeaderWrapper>
      <VendorsTitle />
      <VendorsActionWrapper>
        <ViewMenuButtonUI />
        <FilterMenuButtonUI />
        <SortMenuButtonUI />
        <OptionsMenuButtonUI />
      </VendorsActionWrapper>
    </VendorsHeaderWrapper>
  );
};

export default VendorsHeader;
