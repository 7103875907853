import React from 'react';
import { useLocation } from 'react-router-dom';

import { VendorButton } from '../HeatMapViewComponents';
import { useFetchVendors } from './@hooks';

const HeatMapTableHeader: React.FC = () => {
  const location = useLocation();
  const [{ records }] = useFetchVendors();

  return (
    <>
      {records.map((product) => (
        <VendorButton key={product.id} product={product} linkProps={{ to: location.pathname }} />
      ))}
    </>
  );
};

export default HeatMapTableHeader;
