import React, { useCallback, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import { all, any, equals, find, propEq } from 'ramda';
import { toast } from 'react-toastify';

import { useFetchChangeShortlist, useFetchProject } from '@api/@hooks';
import { UseOpenModal, useOpenModal } from '@hooks';
import { checkStatus } from '@helpers';
import { MAX_SHORTLISTED_ITEMS } from '@constants';

const useTooltipText = () => {
  const [{ records }] = useFetchProject();
  const { shortlistedProducts } = records.project;

  if (shortlistedProducts.length >= MAX_SHORTLISTED_ITEMS) {
    return 'Your shortlist is full';
  }
  return 'Add to shortlist';
};

type WrapperProps = {
  vendorId: number;
};

export type ShortlistButtonHOCWrapperType = UseOpenModal & {
  isLoadingState: boolean;
  isDisabled: boolean;
  isShortlisted: boolean;
  tooltipTitle: string;
  handleClick: () => void;
};

const ShortlistButtonHOC = <T,>(Component: React.FC<T & ShortlistButtonHOCWrapperType>) => {
  const Wrapper: React.FC<T & WrapperProps> = (props) => {
    const { vendorId } = props;
    const { handleClose, handleOpen, open } = useOpenModal();
    const [{ records, loading }, fetchProject] = useFetchProject();
    const [{ loading: loadingAdd }, fetchAdd] = useFetchChangeShortlist('add');
    const [{ loading: loadingDelete }, fetchDelete] = useFetchChangeShortlist('delete');
    const { shortlistedProducts } = records.project;

    const isShortlisted = useMemo(
      () => Boolean(find(propEq(vendorId, 'id'), shortlistedProducts)),
      [shortlistedProducts, vendorId],
    );

    const isDisabled = useMemo(
      () =>
        any(equals(true))([
          loading,
          loadingAdd,
          loadingDelete,
          shortlistedProducts.length >= MAX_SHORTLISTED_ITEMS,
        ]),
      [loading, loadingAdd, loadingDelete, shortlistedProducts.length],
    );

    const isOpen = useMemo(
      () => all(equals(false))([isShortlisted, loading, loadingAdd, loadingDelete]),
      [isShortlisted, loading, loadingAdd, loadingDelete],
    );

    const handleClick = useCallback(async () => {
      const fetch = isShortlisted ? fetchDelete : fetchAdd;
      const response = await fetch(vendorId);
      if (checkStatus(response?.status)) {
        !isShortlisted && toast.success('Vendor added to shortlist');
        fetchProject();
      }
    }, [fetchAdd, fetchDelete, fetchProject, isShortlisted, vendorId]);

    useUpdateEffect(() => {
      if (open && isOpen) {
        handleClose();
      }
    }, [isOpen]);

    return (
      <Component
        isLoadingState={loading || loadingAdd || loadingDelete}
        handleClick={handleClick}
        isDisabled={isDisabled}
        isShortlisted={isShortlisted}
        tooltipTitle={useTooltipText()}
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open && isOpen}
        {...props}
      />
    );
  };
  return Wrapper;
};

export default ShortlistButtonHOC;
