import React, { useMemo } from 'react';
import { Theme } from '@mui/material';

import Box from 'components/@ui/Box';
import { clsx, makeSxStyles } from '@helpers';
import { Children } from '@types';
import { useIsTablet } from '@hooks';

import { useNavbar } from '../@hooks';

const sxses = makeSxStyles({
  root: {
    transition: (theme: Theme) => theme.transitions.create(['margin-top']),
    '@media (max-height: 759px)': { marginTop: 2 },
  },
  open: {
    width: { mobile: '100%', tablet: '100%', laptop: '240px' },
    px: { mobile: 2, tablet: 2, laptop: 0 },
    '& .navbar-body_link': {
      width: { mobile: '50%', tablet: '50%', laptop: 'auto' },
    },
    mt: 4,
    '& a:first-of-type button': {
      borderTopLeftRadius: '16px',
    },
    '& a:nth-of-type(2) button': {
      borderTopRightRadius: '16px',
    },
    '& a:nth-of-type(7) button': {
      borderBottomLeftRadius: '16px',
    },
    '& a:nth-of-type(8) button': {
      borderBottomRightRadius: '16px',
    },
    '@media (max-width: 431px)': {
      width: '100%',
      padding: '0 16px',
    },
  },
  openIsTouchDevice: {
    width: '100%',
    px: 2,
    '& .navbar-body_link': {
      width: '50%',
    },
  },
})();

const Wrapper: React.FC<Children> = ({ children }) => {
  const { isOpenNavbar } = useNavbar();
  const isTablet = useIsTablet();

  return useMemo(
    () => (
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        width={80}
        mt={14.5}
        flex={1}
        sx={clsx(sxses.root, isOpenNavbar && sxses.open, isTablet && sxses.openIsTouchDevice)}
      >
        {children}
      </Box>
    ),
    [children, isOpenNavbar, isTablet],
  );
};

export default Wrapper;
