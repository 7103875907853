import { combineReducers } from 'redux';

import { createSlice, p } from '@redux/slices';

import {
  fetchCharacteristicMatchData,
  fetchCharacteristicMatchModalData,
  fetchFeatureMatchData,
  fetchFeatureMatchModalData,
  fetchHeatMapData,
  fetchProsAndConsData,
  fetchProsAndConsModalData,
  fetchSizeMatchModalData,
  fetchSizeMatchScalabilityData,
  fetchTableViewData,
  fetchVendors,
  fetchWorkplaceList,
} from '../@api';

export const vendors = createSlice(p('VENDORS'), fetchVendors, { initialState: [] });
export const workplaceList = createSlice(p('WORKPLACE_LIST_VENDORS_PAGE'), fetchWorkplaceList);
export const heatMap = createSlice(p('VENDORS_HEAT_MAP_DATA'), fetchHeatMapData, {
  initialState: { products: [], productsCount: 0 },
});
export const sizeMatch = createSlice(p('VENDORS_SIZE_MATCH_DATA'), fetchSizeMatchScalabilityData, {
  initialState: { products: [], productsCount: 0 },
});
export const featureMatch = createSlice(p('VENDORS_FEATURE_MATCH_DATA'), fetchFeatureMatchData, {
  initialState: { products: [], productsCount: 0 },
});
export const characteristicMatch = createSlice(
  p('VENDORS_CHARACTERISTIC_MATCH_DATA'),
  fetchCharacteristicMatchData,
  { initialState: { products: [], productsCount: 0 } },
);
export const prosAndCons = createSlice(p('VENDORS_PROS_AND_CONS_DATA'), fetchProsAndConsData, {
  initialState: { products: [], productsCount: 0 },
});
export const tableView = createSlice(p('VENDORS_TABLE_VIEW_DATA'), fetchTableViewData, {
  initialState: { products: [], productsCount: 0 },
});
export const sizeMatchScalabilityModal = createSlice(
  p('VENDORS_SIZE_SCALABILITY_MODAL'),
  fetchSizeMatchModalData,
);
export const featureMatchModalData = createSlice(
  p('VENDORS_FEATURE_MODAL'),
  fetchFeatureMatchModalData,
);
export const characteristicMatchModalData = createSlice(
  p('VENDORS_CHARACTERISTIC_MODAL'),
  fetchCharacteristicMatchModalData,
);

export const prosAndConsModalData = createSlice(
  p('VENDORS_PROS_AND_CONS_MODAL'),
  fetchProsAndConsModalData,
);

export default combineReducers({
  vendors: vendors.reducer,
  workplaceList: workplaceList.reducer,
  heatMap: heatMap.reducer,
  sizeMatch: sizeMatch.reducer,
  featureMatch: featureMatch.reducer,
  characteristicMatch: characteristicMatch.reducer,
  prosAndCons: prosAndCons.reducer,
  tableView: tableView.reducer,
  sizeMatchScalabilityModal: sizeMatchScalabilityModal.reducer,
  featureMatchModalData: featureMatchModalData.reducer,
  characteristicMatchModalData: characteristicMatchModalData.reducer,
  prosAndConsModalData: prosAndConsModalData.reducer,
});
